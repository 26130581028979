import React, { Fragment, useState, useCallback, useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { ThemeProvider, StyledEngineProvider, adaptV4Theme } from '@mui/material';
import { createTheme } from '@mui/material/styles';
import withStyles from '@mui/styles/withStyles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TablePagination from '@mui/material/TablePagination';
import Paper from '@mui/material/Paper';

import * as authHttp from '../http/auth';
import * as examsHttp from '../http/exams';
import * as clinicsHttp from '../http/clinics';
import * as utils from '../utils';


const styles = theme => ({
  root: {
    width: '100%'
  },
  table: {
    minWidth: 700
  },
  tableHead: {
    fontWeight: "bold",
  },
  tableContainer: {
    height: 320
  },
  margin: {
    margin: theme.spacing(1),
    marginLeft: 'auto'
  },
  examRow: {
    padding: 0
  },
  autoUpdateText: {
    padding: '5px'
  },
  firefoxSpecialAlign: {
    position: "relative",
    top: "-6px"
  }
});


const compactRowTheme = createTheme(adaptV4Theme({
  overrides: {
    MuiTableCell: {
      root: {  //This can be referred from Material UI API documentation. 
        padding: '20px',
        fontSize: '12px',
      },
    },
  },
}));


const mapStateToProps = (state) => {
  return {
    exams: state.exams.list,
    examsCount: state.exams.count,
    exam: state.exams.current,
    searchingExams: state.exams.searchingExams,
    search: state.exams.search,
    clinics: state.clinics.list,
    clinics_map: state.clinics.clinics_map,
    users: state.clinics.users,
    categories: state.clinics.categories,
    user: state.auth.currentUser,
  };
};

const DEFAULT_ROWS_PER_PAGE = 20;

function ExamsToReview(props) {
  const { classes, exams, examsCount, user, clinics_map, users } = props;

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(DEFAULT_ROWS_PER_PAGE);

  const filterFromState = useCallback(() => {
    if (user === null) {
      return null;
    }
    return {
      medic: user !== null ? user.id : null,
      status: "digitado",
      limit: rowsPerPage,
      offset: page * rowsPerPage,
      ordering: "study_datetime"
    };
  }, [rowsPerPage, page, user]);

  const doSearch = useCallback(() => {
    var filters = filterFromState();
    if (filters == null) {
      return;
    }
    examsHttp.listExamsToReview(filters);
  }, [filterFromState]);

  useEffect(() => {
    authHttp.getCurrentUser();
    clinicsHttp.list();
    examsHttp.listExamsToReview(filterFromState());

    return () => {
      examsHttp.clearAll();
    }
  }, []);

  useEffect(() => {
    doSearch();
  }, [page, rowsPerPage, user, doSearch]);

  const showExam = useCallback((examId, event) => {
    event.stopPropagation();
    props.history.push(`/exams/exam/${examId}`)
  }, [props]);

  const getClinic = useCallback((clinicId) => {
    var clinics = clinics_map;
    if (clinics !== undefined) {
        if (clinicId in clinics) {
            return clinics[clinicId];
        }
    }
    return {};
  }, [clinics_map]);

  const getUser = useCallback((userId) => {
    if (users !== undefined) {
        if (userId in users) {
            return users[userId];
        }
    }
    return {};
  }, [users])

  const getUserName = useCallback((userId) => {
    var user = getUser(userId);
    if (user !== {}) {
        return user.first_name + " " + user.last_name;
    }
    return "";
  }, [getUser]);


    const tableHeaders = [
      { label: 'Paciente', field: 'patient_name' },
      { label: 'Data Exame', field: 'study_datetime' },
      { label: 'Mod', align: 'left' },
      { label: 'Clínica', field: 'clinic_name', align: 'left' },
      { label: 'Descrição', field: 'description', align: 'left' },
      { label: 'Médico', align: 'left' },
    ]

    return (
      <Fragment>
        <div className={classes.tableContainer}>
          <Paper className={classes.root}>
            <Table className={classes.table}>
              <StyledEngineProvider injectFirst>
                <ThemeProvider theme={compactRowTheme}>
                <TableHead className={classes.tableHead}>
                  <TableRow>
                    {tableHeaders.map(h => (
                      <TableCell align={h.align} key={h.label} className={classes.tableHead} >
                        {h.label}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                  <TableBody>
                    {exams && exams.map(e => (
                      <TableRow
                        key={e.id}
                        hover
                        className={classes.examRow}
                      >
                        <TableCell onClick={(event) => showExam(e.id, event)} component="th" scope="row">
                          {e.patient_name}
                        </TableCell>
                        <TableCell onClick={(event) => showExam(e.id, event)} align="left"> {utils.formatDate(e.study_datetime)}</TableCell>
                        <TableCell onClick={(event) => showExam(e.id, event)} align="left">{e.modality}</TableCell>
                        <TableCell onClick={(event) => showExam(e.id, event)} align="left">{getClinic(e.clinic_id).name}</TableCell>
                        <TableCell onClick={(event) => showExam(e.id, event)} align="left">{e.description}</TableCell>
                        <TableCell onClick={(event) => showExam(e.id, event)} align="left">{e.report != null ? getUserName(e.report.medic) : ""}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </ThemeProvider>
              </StyledEngineProvider>
              </Table>
            <TablePagination
              rowsPerPageOptions={[5, 10, 20, 50, 100]}
              component="div"
              count={examsCount}
              rowsPerPage={rowsPerPage}
              labelRowsPerPage='Itens por página'
              page={page}
              backIconButtonProps={{
                'aria-label': 'Página Anterior',
              }}
              nextIconButtonProps={{
                'aria-label': 'Próxima Página',
              }}
              onPageChange={setPage}
              onRowsPerPageChange={setRowsPerPage}
            />
          </Paper>
        </div>
      </Fragment>
    );
}

ExamsToReview.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withRouter(withStyles(styles)(connect(mapStateToProps)(ExamsToReview)));
