import { CLINICS_GET_MARGINS,  RETRIEVE_EXAMS_DELIVERY_TIME_REQUEST,
        RETRIEVE_EXAMS_DELIVERY_TIME_SUCCESS, RETRIEVE_EXAMS_DELIVERY_TIME_FAILURE,
        CLINICS_LIST_REQUEST, CLINICS_LIST_SUCCESS, CLINICS_LIST_FAILURE } from '../actions/clinics';

const initialState = {
  list: [],
  clinicsLoading: false,
  clinicsLoadingError: null,
  clinics_map: {},
  categories: {},
  clinicsUrgentCategoryId: {},
  currentMargins: null,
  users: {},

  retrievingExamsDeliveryTime: false,
  examsDeliveryTime: {},
  examsDeliveryTimeError: null,

};

const clinics = (state = initialState, action) => {
  switch (action.type) {
    case CLINICS_LIST_REQUEST:
      {
        return {
        ...state,
        clinicsLoading: true,
        };
      }
    case CLINICS_LIST_SUCCESS:
      {
        return {
        ...state,
        clinicsLoading:false,
        list: action.data,
        clinics_map: createClinicMap(action.data),
        categories: createCategoriesMap(action.data),
        clinicsUrgentCategoryId: createClinicsUrgentCategoryIdMap(action.data),
        users: createUsersMap(action.data),
        };
      }
    case CLINICS_LIST_FAILURE:
      {
        return {
          ...state,
          clinicsLoading: false,
          clinicsLoadingError: action.data,
        };
      }
    case CLINICS_GET_MARGINS:
      return {
        ...state,
        currentMargins: action.data
      };
    case RETRIEVE_EXAMS_DELIVERY_TIME_REQUEST:
      {
        return {
          ...state,
          retrievingExamsDeliveryTime: true,
        };
      }
    case RETRIEVE_EXAMS_DELIVERY_TIME_SUCCESS:
      {
        return {
          ...state,
          retrievingExamsDeliveryTime: false,
          examsDeliveryTime: action.data,
        };
      }
    case RETRIEVE_EXAMS_DELIVERY_TIME_FAILURE:
      {
        return {
          ...state,
          retrievingExamsDeliveryTime: false,
          examsDeliveryTimeError: action.data,
        };
      }
    default:
      return state;
  }
};

function createCategoriesMap(clinics) {
    var result = {};
    for (var i = 0; i < clinics.length; i++) {
        var c = clinics[i];
        if (c.profile == null) {
            continue;
        }
        for (var j = 0; j < c.profile.exam_categories.length; j++) {
            var category = c.profile.exam_categories[j];
            if (!(category.id in result)) {
                result[category.id] = category;
            }
        }
    }
    return result;
}

function createClinicsUrgentCategoryIdMap(clinics) {
    var result = {};
    for (var i = 0; i < clinics.length; i++) {
        var c = clinics[i];
        if (c.profile == null) {
            continue;
        }
        for (var j = 0; j < c.profile.exam_categories.length; j++) {
            var category = c.profile.exam_categories[j];
            if (category.name === 'URGÊNCIA') {
                result[c.id] = category;
            }
        }
    }
    return result;
}

function createClinicMap(clinics) {
    var result = {};
    for (var i = 0; i < clinics.length; i++) {
        var c = clinics[i];
        result[c.id] = c;
    }
    return result;
}

function createUsersMap(clinics) {
    var result = {};
    for (var i = 0; i < clinics.length; i++) {
        var c = clinics[i];
        for (var j = 0; j < c.members.length; j++) {
            var member = c.members[j];
            result[member.id] = member;
        }
    }
    return result;
}

export default clinics;
