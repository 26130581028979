import React, { useState, useEffect } from 'react';
import withStyles from '@mui/styles/withStyles';
import { connect } from 'react-redux';
import Grid from '@mui/material/Grid';

import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';

import Loading from '../components/Loading';
import ReportPreview from '../components/ReportPreview';

import * as examsHttp from '../http/exams'
import * as authHttp from '../http/auth';
import * as reportsHttp from '../http/reports';
import * as utils from '../utils';


const drawerWidth = 240;

const styles = theme => ({
  root: {
    display: 'flex',
  },
  toolbar: {
    paddingRight: 24, // keep right padding when drawer closed
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  title: {
    flexGrow: 1,
    paddingLeft: 24,
  },
});

const mapStateToProps = (state) => {
  return {
    exam: state.exams.current,
    user: state.auth.currentUser,
  };
};


function ReportPdfViewer (props) {
  const { classes, exam } = props;
  const [ pdf , setPdf ]= useState(null);

  useEffect(() => {
    authHttp.getCurrentUser();
    if (!props.creating) {
      examsHttp.get(props.match.params.id);
    }
  }, [props.creating, props.match.params.id]);

  useEffect(() => {
    async function fetchData() {
      if (props.exam) {

        var pdfData = await utils.downloadUrlData(
                          `/api/reports/exam/${exam.id}/download`,
                          'application/pdf',
                          `Laudo ${exam.patient_name} ${exam.study_datetime}`
                        );

        setPdf(pdfData)
      }
    }
    fetchData();
    return () => {
     reportsHttp.clearCurrent();
     };
  }, [props.exam, exam]);


    return (
      <div className={classes.root}>
        <AppBar
          position="absolute"
        >
          <Toolbar className={classes.toolbar}>
            <Typography
              component="h1"
              variant="h6"
              color="inherit"
              noWrap
              className={classes.title}
            >
              Visualizador de laudo médico
            </Typography>
          </Toolbar>
        </AppBar>

        <main className={classes.content}>
          <div className={classes.appBarSpacer} />
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Grid style={{ height: '100vh'}} justifyContent="center">
                  {exam ? pdf && <ReportPreview data={pdf}/> :  <Loading />}
                </Grid>
              </Grid>
            </Grid>
        </main>
      </div>
    );
}

export default withStyles(styles)(connect(mapStateToProps)(ReportPdfViewer));