import React, { useState, useCallback } from 'react';
import { connect } from 'react-redux';
import withStyles from '@mui/styles/withStyles';

import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';
import Container from '@mui/material/Container';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';

import Cookies from 'js-cookie';

import * as authHttp from '../http/auth';
import ResetPasswordRequestButton from '../components/ResetPasswordRequestButton';
import Copyright from '../components/Copyright';
import FooterLGPD from '../components/FooterLGPD'

const styles = theme => ({
  bannerLogo: {
      maxWidth: '100px',
  },
  passwordReset: {
    paddingTop: '10px',
  },
  passwordResetButton: {
    background: 'none!important',
    border: 'none',
    padding: '0!important',
    textDecoration: 'underline',
    cursor: 'pointer',
  },
  form: {
      maxWidth: '250px',
  },
  paper: {
    marginTop: theme.spacing(8),
    color: 'inherit',
    backgroundColor: 'transparent',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
});

const mapStateToProps = (state) => {
  return {
    errorMessage: state.auth.errorMessage
  };
};

const useStyles = makeStyles((theme) => ({
  bannerLogo: {
      maxWidth: '150px'
  },
  paper: {
      marginTop: theme.spacing(8),
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
  form: {
      width: '100%', // Fix IE 11 issue.
      marginTop: theme.spacing(1),
    },
  submit: {
      margin: theme.spacing(3, 0, 2),
    },
  usefulLinks: {
      width: '100%',
      marginTop: theme.spacing(1),
      fontSize: 12,
      whiteSpace: 'nowrap',
    },
  list: {
      display: 'flex',
      flexDirection: 'row',
  },
  listItem: {
      display: 'flex',
      justifyContent: 'center',
  },
}));

function Login(props) {
  const classes = useStyles();

  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");

  const usernameCallback = useCallback((event) => {
    setUsername(event.target.value)
  }, []);
  const passwordCallback = useCallback((event) => {
    setPassword(event.target.value)
  }, []);

  const submitCallback = useCallback(async (event) => {
    event.preventDefault();
    var cookieConsent = Cookies.get('CookieConsent');
    if (cookieConsent == null || !cookieConsent) {
      alert("Por favor, aceite a política de cookies para continuar utilizando o sistema");
      return;
    }
    await authHttp.login(username, password);
  }, [username, password]);

  return (
      <Container component="main" maxWidth="xs">
        <div className={classes.paper}>
          <img src="/imagiris-logo.png" alt="logo" className={classes.bannerLogo} /><br />
          {
            props.errorMessage &&
            <Typography color='error'>{props.errorMessage}</Typography>
          }
          <form className={classes.form} noValidate>
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="email"
              label="Usuário"
              name="email"
              autoComplete="email"
              autoFocus
              onChange={usernameCallback}
            />
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              name="password"
              label="Senha"
              type="password"
              id="password"
              autoComplete="current-password"
              onChange={passwordCallback}
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
              onClick={submitCallback}
             >
              Entrar
            </Button>
            <Grid container>
              <Grid item xs>
                <ResetPasswordRequestButton/>
              </Grid>
            </Grid>
        </form>
        </div>
        <Box className={classes.usefulLinks} >
          <List className={classes.list}>
              <ListItem button className={classes.listItem} component="a" href="techsupport/">Suporte Técnico</ListItem>
              <ListItem button className={classes.listItem} component="a" href="contact/">Contato</ListItem>
              <ListItem button className={classes.listItem} component="a" href="privacy/">Política de Privacidade</ListItem>
          </List>
        </Box>
        <Box mt={30}>
          <Copyright />
        </Box>
        <Box>
          <FooterLGPD />
        </Box>
      </Container>
    );
}

export default connect(mapStateToProps)(withStyles(styles)(Login));
