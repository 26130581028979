import React, { useState, useCallback } from 'react';
import { connect } from 'react-redux';
import withStyles from '@mui/styles/withStyles';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';
import TextField from '@mui/material/TextField';

import * as authHttp from '../http/auth';
import Loading from '../components/Loading';
import QRCodeGenerator from '../components/QRCodeGenerator'


const styles = theme => ({
  root: {
    minWidth: 275,
  },
  input: {
    width: "100%",
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
    marginTop:12,
  },
});

const mapStateToProps = (state) => {
  return {
    twoFactorSetupStatus: state.auth.twoFactorSetupStatus,
  };
};


function TwoFactorAuth(props) {
  const { classes, twoFactorSetupStatus } = props;
  const [ loading, setLoading ] = useState(false);
  const [ twoFactorAuthDialogOpen, setTwoFactorAuthDialogOpen] = useState(false);
  const [ deactivateTwoFactorDialogOpen, setDeactivateTwoFactorDialogOpen ] = useState(false);
  const [ validationToken, setValidationToken ] = useState('');
  const [ enabledValidationButton, setEnabledValidationButton ] = useState(false);
  const [ qrText, setQrText ] = useState("");


  const handleActivationClose = useCallback(async (value) => {
    await authHttp.deactivateTwoFactorAuth();
    setTwoFactorAuthDialogOpen(false);
  }, []);

  const handleDeactivationClose = useCallback((value) => {
    setDeactivateTwoFactorDialogOpen(false);
  }, []);

  const handleDeactivationButton = useCallback((value) => {
    setDeactivateTwoFactorDialogOpen(true);
  }, []);

  const handleDeactivation = useCallback(async (event) => {
    setEnabledValidationButton(true);
    const data = {
      token: validationToken,
    };
    const res = await authHttp.deactivateTwoFactorAuth(data);
    if(!res.result) {
      alert('Não foi possível desativar a autenticação em duas etapas. Verifique o código informado')
      setEnabledValidationButton(false);
    } else {
      alert('Autenticação em duas etapas desativada.')
      setDeactivateTwoFactorDialogOpen(false);
    }
  }, [validationToken]);

  const handleActivation = useCallback(async (event) => {
    event.preventDefault();
    setLoading(true);
    const res = await authHttp.activateTwoFactorAuth();
    setLoading(false);
    if (!res.result) {
      alert('Não foi possível ativar autenticação em duas etapas.')
    } else {
      setTwoFactorAuthDialogOpen(true);
      setQrText(res.data.data.totp_url);
    }
  }, []);

  const handleValidation = useCallback(async (event) => {
    event.preventDefault();
    setEnabledValidationButton(true);
    const data = {
      token: validationToken,
    };
    const res = await authHttp.validateTwoFactorAuthToken(data);
    if (!res.result) {
      alert('Não foi possível validar o dispositivo, tente novamente.');
      setEnabledValidationButton(false);
    } else {
      alert('Dispositivo validado com sucesso.');
      setEnabledValidationButton(false);
      setTwoFactorAuthDialogOpen(false);
    }
  }, [validationToken]);

  return (
    <React.Fragment>
    <div className={classes.root}>
      <Grid container spacing={2}>
      {
        loading
          ?
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Grid container justifyContent="center">
                <Loading />
              </Grid>
            </Grid>
          </Grid>
          :
          <Grid container spacing={0}>
            {
              twoFactorSetupStatus === 'activated'
                ?
                <Grid item xs={12}>
                  <Grid container >
                    Status: autenticação em dois fatores ativa. Clique no botão para desativar.
                  </Grid>
                  <Button className={classes.pos} variant="contained" color="primary" disableElevation onClick={handleDeactivationButton}>
                    Desativar
                  </Button>
                </Grid>
                :
                <Grid item xs={12}>
                  <Grid container >
                    Status: autenticação em dois fatores não está ativa. Clique no botão para ativar.
                  </Grid>
                  <Button className={classes.pos} variant="contained" color="primary" disableElevation onClick={handleActivation}>
                    Ativar
                  </Button>
                </Grid>
            }
            <Dialog onClose={handleActivationClose} aria-labelledby="simple-dialog-title" open={twoFactorAuthDialogOpen}>
              <DialogTitle id="activation-dialog-title" align="center">Autenticação em dois fatores</DialogTitle>
                <DialogContent>
                  <DialogContentText>
                    Escaneie o QR code com seu aplicativo Google Authenticator para ativar autenticação em dois fatores.
                    Em seguida preencha o campo abaixo com o código de verificação para validar o dispositivo.
                  </DialogContentText>
                  <DialogContentText align="center">
                    <QRCodeGenerator qrText={qrText} />
                  </DialogContentText>
                  <TextField
                    className={classes.input}
                    margin="dense"
                    id="two-factor-auth-validation"
                    label="Código de verificação"
                    required={true}
                    helperText="Preencher com o código que aparece em seu aplicativo."
                    onChange={event => setValidationToken(event.target.value)}
                    autoComplete="off"
                  />
                </DialogContent>
                <DialogActions>
                  <Button onClick={handleActivationClose} variant="contained" color="primary" className={classes.submitButton}>
                    Fechar
                  </Button>
                  <Button onClick={handleValidation} disabled={enabledValidationButton} variant="contained" color="primary" className={classes.submitButton}>
                    Confirmar
                  </Button>
                </DialogActions>
              </Dialog>
            <Dialog onClose={handleDeactivationClose} aria-labelledby="simple-dialog-title" open={deactivateTwoFactorDialogOpen}>
              <DialogTitle id="deactivation-dialog-title" align="center">Autenticação em dois fatores</DialogTitle>
                <DialogContent>
                  <DialogContentText>
                    Para continuar com a desativação da autenticação em dois fatores, insira o código que aparece
                    em seu dispositivo.
                  </DialogContentText>
                  <TextField
                    className={classes.input}
                    margin="dense"
                    id="desactive-two-factor-auth-validation"
                    label="Código de verificação"
                    required={true}
                    helperText="Preencher com o código que aparece em seu aplicativo."
                    onChange={event => setValidationToken(event.target.value)}
                    autoComplete="off"
                  />
                </DialogContent>
                <DialogActions>
                  <Button onClick={handleDeactivationClose} variant="contained" color="primary" className={classes.submitButton}>
                    Fechar
                  </Button>
                  <Button onClick={handleDeactivation} disabled={enabledValidationButton} variant="contained" color="primary" className={classes.submitButton}>
                    Confirmar
                  </Button>
                </DialogActions>
              </Dialog>
          </Grid>
      }
      </Grid>
    </div>
    </React.Fragment>
  );
}

export default withStyles(styles)(connect(mapStateToProps)(TwoFactorAuth));