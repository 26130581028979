import axios from 'axios';
import store from '../store';

import { receiveLogout, PrivacyPolicyRequired, twoFactorAuthValidateDeviceRequired } from '../actions/auth';


export const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: {
    'Content-Type': 'application/json',
    'Accept': 'application/json'
  }
});

api.interceptors.request.use((config) => {
  config.headers['Authorization'] = 'Bearer ' + localStorage.getItem('access_token');
  return config;
});

api.interceptors.response.use(null, async (error) => {
  if (!error.response) {
    throw error;
  }
  switch (error.response.status) {
    case 401:
      const refresh = localStorage.getItem('refresh_token');
      if (!refresh) {
        store.dispatch(receiveLogout());
      } else {
        try {
          const response = await axios.post(`${process.env.REACT_APP_API_URL}/auth/token/refresh/`, { refresh });
          localStorage.setItem('access_token', response.data.access);
          error.config.headers['Authorization'] = 'Bearer ' + response.data.access;
          return api.request(error.config);
        } catch (err) {
          localStorage.removeItem('access_token');
          localStorage.removeItem('refresh_token');
          store.dispatch(receiveLogout());
        }
      }
      break;
    case 403:
      if (error.response.data['code'] === "needs-validate-2fa-token") {
        store.dispatch(twoFactorAuthValidateDeviceRequired());
      }
      else if (error.response.data['code'] === "needs-to-accept-privacy-policy") {
        store.dispatch(PrivacyPolicyRequired());
      } else {
        throw error;
      }
      break;
    default:
      throw error;
  }
});
