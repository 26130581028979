export const CLINICS_LIST_REQUEST = 'CLINICS_LIST_REQUEST';
export const CLINICS_LIST_SUCCESS = 'CLINICS_LIST_SUCCESS';
export const CLINICS_LIST_FAILURE = 'CLINICS_LIST_FAILURE';
export const CLINICS_GET_MARGINS = 'CLINICS_GET_MARGINS';
export const RETRIEVE_EXAMS_DELIVERY_TIME_REQUEST = 'RETRIEVE_EXAMS_DELIVERY_TIME_REQUEST';
export const RETRIEVE_EXAMS_DELIVERY_TIME_SUCCESS = 'RETRIEVE_EXAMS_DELIVERY_TIME_SUCCESS';
export const RETRIEVE_EXAMS_DELIVERY_TIME_FAILURE = 'RETRIEVE_EXAMS_DELIVERY_TIME_FAILURE';

export const listRequest = () => {
  return {
    type: CLINICS_LIST_REQUEST,
  };
};

export const listSuccess = (data) => {
  return {
    type: CLINICS_LIST_SUCCESS,
    data
  };
};

export const listFailure = (data) => {
  return {
    type: CLINICS_LIST_FAILURE,
    data
  };
};

export const getMargins = (data) => {
  return {
    type: CLINICS_GET_MARGINS,
    data
  };
};

export const retrieveExamsDeliveryTimeRequest = () => {
  return {
    type: RETRIEVE_EXAMS_DELIVERY_TIME_REQUEST,
  };
};

export const retrieveExamsDeliveryTimeSuccess = (data) => {
  return {
    type: RETRIEVE_EXAMS_DELIVERY_TIME_SUCCESS,
    data
  };
};

export const retrieveExamsDeliveryTimeFailure = (data) => {
  return {
    type: RETRIEVE_EXAMS_DELIVERY_TIME_FAILURE,
    data
  };
};
