import React, { useState, useCallback, useEffect } from 'react';
import { connect } from 'react-redux';
import withStyles from '@mui/styles/withStyles';
import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';

import * as examsHttp from '../http/exams';

const styles = theme => ({
  formControl: {
    margin: theme.spacing(2),
  },
  submitButton: {
    margin: theme.spacing(2),
  }
});

const mapStateToProps = (state) => {
  return {
    clinics: state.clinics.clinics_map,
  };
};

function ExamCategoryForm(props) {
  const { classes,
          exam,
          clinics,
          updatedCallback,
        } = props;

  const [updating, setUpdating] = useState("");
  const [category, setCategory] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  useEffect(() => {
    if (exam == null || exam === undefined) {
      return;
    }
    setCategory(exam.category)
  }, [exam])

  const doUpdate = useCallback(async () => {
    setUpdating("Atualizando...");
    setErrorMessage("");

    var {result, data} = await examsHttp.updateCategory({
        subexam: exam.id,
        category: category,
    });
    if (result) {
      setUpdating("Atualizado"); 
      if (updatedCallback != null && updatedCallback !== undefined) {
        updatedCallback(exam, category)
      }
    } else {
      setUpdating(""); 
      if (data.response.status === 404) {
        setErrorMessage("Exame não encontrado");
      } else if (data.response.status === 500) {
        setErrorMessage("Um erro foi encontrado");
      } else {
        var message = data.response.data.message;
        setErrorMessage(message);
      }
    }
  }, [exam, category, updatedCallback]);

  if (exam == null || exam === undefined) {
    return null;
  }

  const clinic = clinics[exam.clinic_id];
  var catValue = category;
  if (clinic === undefined) {
    catValue = "";
  }

  return (
      <React.Fragment>
      <Typography variant="subtitle1">Exame: {exam.patient_name}</Typography>
        <form>
          <FormControl className={classes.formControl}>
            <Select
               id="category-select"
               value={catValue}
               onChange={(event) => setCategory(event.target.value)}
            >
              {clinic !== undefined ? 
                clinic.profile.exam_categories.map((c) => (
                  <MenuItem value={c.id} key={c.id}>{c.name}</MenuItem>
                ))
              : null }
            </Select>
            <Button onClick={doUpdate} variant="contained" color="primary" className={classes.submitButton}>
              Atualizar
            </Button>
          </FormControl>
        </form>
        <Typography color="primary" variant="body1">{updating}</Typography>
        <Typography color="secondary" variant="body1">{errorMessage}</Typography>
      </React.Fragment>
  );
};

export default withStyles(styles)(connect(mapStateToProps)(ExamCategoryForm));
