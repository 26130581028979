import React, { useCallback } from 'react';
import withStyles from '@mui/styles/withStyles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import * as utils from '../utils';

const styles = theme => ({
  root: {
    width: '100%',
  },
  table: {
    minWidth: 250,
  },
  tableHeader: {
    fontWeight: "bold",
  },
});

function ClinicFinanceSummary(props) {
  const { classes, summary, totals, urgCategory, valueField } = props;

  const getUrgencyCount = useCallback((catMap) => {
    if (urgCategory == null) {
      return "";
    }

    if (urgCategory in catMap) {
      return catMap[urgCategory].count;
    }
    return 0;
  }, [urgCategory]);

  return <TableContainer component={Paper}>
      <Table className={classes.table} size="small" aria-label="exames">
        <TableHead>
          <TableRow>
            <TableCell className={classes.tableHeader}>Mod</TableCell>
            <TableCell align="left" className={classes.tableHeader}>Exames</TableCell>
            <TableCell align="left" className={classes.tableHeader}>Urg</TableCell>
            <TableCell align="left" className={classes.tableHeader}>Total</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {Object.keys(summary).map((key) => (
            <TableRow key={key}>
              <TableCell>{key}</TableCell>
              <TableCell>{summary[key].count}</TableCell>
              <TableCell>{getUrgencyCount(summary[key].perCategory)}</TableCell>
              <TableCell>{ utils.formatBillValue(summary[key][valueField]) }</TableCell>
            </TableRow>
      ))}
          <TableRow>
            <TableCell className={classes.tableHeader} align="left">Subtotal</TableCell>
            <TableCell align="left">{ totals.count }</TableCell>
            <TableCell>{getUrgencyCount(totals.perCategory)}</TableCell>
            <TableCell align="left">{ utils.formatBillValue(totals[valueField]) }</TableCell>
          </TableRow>
          <TableRow>
            <TableCell></TableCell>
            <TableCell></TableCell>
            <TableCell></TableCell>
            <TableCell></TableCell>
          </TableRow>
          <TableRow>
            <TableCell className={classes.tableHeader}>Impostos</TableCell>
            <TableCell></TableCell>
            <TableCell></TableCell>
            <TableCell></TableCell>
          </TableRow>
          {Object.keys(totals.taxesConstants).map((key) => (
            <TableRow key={key}>
              <TableCell>{key}</TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
              <TableCell>{ utils.formatBillValue(totals.taxesValues[key]) }</TableCell>
            </TableRow>
      ))}
          <TableRow>
            <TableCell className={classes.tableHeader} align="left">Total</TableCell>
            <TableCell></TableCell>
            <TableCell></TableCell>
            <TableCell>{ utils.formatBillValue(totals.finalValue)}</TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
}

export default withStyles(styles)(ClinicFinanceSummary);
