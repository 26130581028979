import {
  SPEECH_GLOBAL_CONFIG_GET_SUCCESS,
  SPEECH_CONFIG_GET_SUCCESS
} from '../actions/speech';

const initialState = {
  config: {
    globalTextReplacements: [],
    textReplacements: [],
  },
};

const speech = (state = initialState, action) => {
  switch (action.type) {
    case SPEECH_GLOBAL_CONFIG_GET_SUCCESS:
      return {
        ...state,
	config: {
          ...state.config,
          globalTextReplacements: parseTextReplacements(action.data)
	}
      };
    case SPEECH_CONFIG_GET_SUCCESS:
      return {
        ...state,
	config: {
          ...state.config,
          textReplacements: parseTextReplacements(action.data)
	}
      };
    default:
      return state;
  }
};

function parseTextReplacements(data) {
  return data;
}

export default speech;
