import { v4 as uuidv4 } from 'uuid';
import { api } from './axios';
import store from '../store';
import * as reportActions from '../actions/reports';

export const list = async (query) => {
  try {
    store.dispatch(reportActions.listRequest());
    const res = await api.get('/api/reports/', { params: query });
    store.dispatch(reportActions.listSuccess(res.data));
  } catch (err) {
    store.dispatch(reportActions.listFailure(err));
  }
};

export const get = async (id) => {
  try {
    store.dispatch(reportActions.getRequest());
    const res = await api.get(`/api/reports/${id}`);
    store.dispatch(reportActions.getSuccess(res.data));
  } catch (err) {
    store.dispatch(reportActions.getFailure(err));
  }
};

export const update = async (id, data) => {
  try {
    store.dispatch(reportActions.uptadeRequest());
    const res = await api.patch(`/api/reports/${id}`, data);
    store.dispatch(reportActions.uptadeSuccess(res.data));
    return {result: true, data: res.data};
  } catch (err) {
    store.dispatch(reportActions.uptadeFailure(err));
    return {result: false, data: err};
  }
};

export const create = async (data) => {
  try {
    store.dispatch(reportActions.createRequest());
    const res = await api.post(`/api/reports/`, data);
    store.dispatch(reportActions.createSuccess(res.data));
    return {result: true, data: res.data};
  } catch (err) {
    store.dispatch(reportActions.createFailure(err));
    return {result: false, data: err};
  }
};

export const clearCurrent = () => {
  store.dispatch(reportActions.clearCurrent());
};

export const preview = async (data) => {
  try {
    // TODO preview for exams is sending the whole exam object.
    let rid = uuidv4();
    store.dispatch(reportActions.previewRequest({requestId: rid}));
    const res = await api.post(`/api/reports/preview`, data);
    store.dispatch(reportActions.previewSuccess({data: res.data, requestId: rid}));
  } catch (err) {
    reportActions.previewFailure(err);
  }
}

export const setPreviewExam = (exam) => {
  store.dispatch(reportActions.previewSetExam(exam));
}

export const setPreviewContent = (content) => {
  store.dispatch(reportActions.previewSetContent(content));
}

export const setPreviewClinic = (clinic) => {
  store.dispatch(reportActions.previewSetClinic(clinic));
}

export const setPreviewModality = (mod) => {
  store.dispatch(reportActions.previewSetModality(mod));
}

export const updatePreview = async () => {
  let state = store.getState();
  await preview(state.reports.previewData);
}

export const clearCurrentClinicReportsForExam = () => {
  store.dispatch(reportActions.clearCurrentReportsForExam());
};

export const listClinicReportsForExam = async (exam_id) => {
  try {
    store.dispatch(reportActions.listClinicReportForExamRequest());
    const res = await api.get(`/api/exams/${exam_id}/reportmodel`);
    store.dispatch(reportActions.listClinicReportForExamSuccess(res.data));
    return true;
  } catch (err) {
    store.dispatch(reportActions.listClinicReportForExamFailure(err));
    return false;
  }
}
