import _ from 'lodash';

const getErrorData = (error) => {
  return (error && error.response && error.response.data) || {};
}

const error = (state = {}, action) => {
  if (!action) return {};

  const { type, data } = action;
  const matches = /(.*)_(REQUEST|FAILURE)/.exec(type);


  // not a *_REQUEST / *_FAILURE actions, so we ignore them
  if (!matches) return state;

  const [, requestName, requestState] = matches;
  return {
    ...state,
    [requestName]: requestState === 'FAILURE' ? getErrorData(data) : {},
  };
};

export const createErrorDataSelector = (actions) => (state) => {
  return _(actions).map(action => _.get(state, `error.${action}`)).compact().first() || {};
};

export default error;
