import React, { useState, useEffect, useCallback } from 'react';
import { connect } from 'react-redux';
import withStyles from '@mui/styles/withStyles';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import BillingGroupFinanceSummary from '../components/BillingGroupFinanceSummary';
import * as billingHttp from '../http/billing';
import * as clinicHttp from '../http/clinics';
import * as authHttp from '../http/auth';

const styles = theme => ({
  root: {
    width: '100%',
  },
  title: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(1),
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
    verticalAlign: 'baseline',
  },
});

const mapStateToProps = (state) => {
  return {
    summary: state.billing.billinggroup.summary,
    clinics: state.clinics.list,
    errorMessage: state.billing.billinggroup.requestError != null ? state.billing.billinggroup.requestError.response.data.detail: null,
    loading: state.billing.billinggroup.loading,
    currentUser: state.auth.currentUser,
  };
};

function BillingGroupFinances(props) {
  const { classes,
          clinics,
          loading,
          summary,
          errorMessage,
        } = props;
  
  const [clinicFilter, setClinicFilter] = useState();

  useEffect(() => {
    authHttp.getCurrentUser();
    clinicHttp.list();
    billingHttp.clearCurrent();
  }, []);

  const doQuery = useCallback(() => {
    billingHttp.getBillingGroupFinance(clinicFilter);
  }, [clinicFilter]);

  const handleClinicChange = useCallback(async (event) => {
    setClinicFilter(event.target.value);
  }, [clinics, clinicFilter]);

  const errorComponent = errorMessage != null ?
      <Typography variant="subtitle1" color="error">{errorMessage}</Typography>
      : null

  const loadingComponent = loading ?
      <Typography variant="subtitle1" color="primary">Carregando</Typography>
      : null

  return <div className={classes.root}>
    <form>
      <FormControl className={classes.formControl}>
        <InputLabel htmlFor="clinic-selector">Clínica</InputLabel>
        <Select
          value={clinicFilter || ''}
          inputProps={{
            name: 'clinics',
            id: 'clinics-selector'
          }}
          label="Clínica"
          onChange={handleClinicChange}
          >
          <MenuItem><em>Todas</em></MenuItem>
          {clinics && clinics.map(clinic => (
            <MenuItem key={clinic.id} value={clinic.id}>{clinic.name}</MenuItem>
            ))}
        </Select>
      </FormControl>
      <Button variant="contained" color="primary" onClick={doQuery}>
        Carregar
      </Button>
      <FormControl className={classes.formControl}>
        { loadingComponent }
      </FormControl>
    </form>
    { errorComponent }
    <Grid container spacing={2}>   
    {Object.keys(summary.perClinic).map((key) => (
        <Grid item xs={12} sm={6} md={6} lg={4} xl={3} key={"billinggroup-finance-summary-" + key} >
          <BillingGroupFinanceSummary  
            summary={ summary.perClinic[key] } 
          />       
        </Grid>
      ))}
    </Grid>  
    </div>
};

export default withStyles(styles)(connect(mapStateToProps)(BillingGroupFinances));
