import React, { useState, useCallback } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';

import * as authHttp from '../http/auth';
import Loading from '../components/Loading';


const useStyles = makeStyles((theme) => ({
  submit: {
      margin: theme.spacing(3, 0, 2),
    },
  passwordResetButton: {
    background: 'none!important',
    border: 'none',
    padding: '0!important',
    textDecoration: 'underline',
    cursor: 'pointer',
  },
}));


export default function ResetPasswordRequest(props) {
  const classes = useStyles();
  const [resetPasswordRequestDialogOpen, setResetPasswordRequestDialogOpen] = useState(false);
  const [resetPasswordEmail, setResetPasswordEmail] = useState('')
  const [ loading, setLoading ] = useState(false);


  const handleResetPasswordRequest = useCallback(() => {
    setResetPasswordRequestDialogOpen(true);
  }, []);

  const handleClose = useCallback((value) => {
    setResetPasswordRequestDialogOpen(false);
  }, []);

  const sendResetPasswordRequest = useCallback(async (value) => {
    const data = {
      email: value.resetPasswordEmail,
    };
    setLoading(true);
    await authHttp.sendResetPasswordEmail(data);
    setLoading(false);
    setResetPasswordRequestDialogOpen(false);
    alert ('Enviamos um e-mail com as instruções para recuperação de senha, você deverá receber em instantes.' +
      ' Caso não receba, por favor verifique o e-mail digitado e cheque sua caixa de spam.')
  }, []);


  return (
    <React.Fragment>
      <Button className={classes.passwordResetButton} disableElevation onClick={handleResetPasswordRequest}>
        Esqueceu sua senha?
      </Button>
      {
        loading
          ?
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Grid container justifyContent="center">
                <Loading />
              </Grid>
            </Grid>
          </Grid>
          :
          <Dialog onClose={handleClose} aria-labelledby="simple-dialog-title" open={resetPasswordRequestDialogOpen}>
            <form id="access-key-form" onSubmit={(e) => { e.preventDefault(); sendResetPasswordRequest({resetPasswordEmail}); handleClose(); } }>
              <DialogTitle id="form-dialog-title">Esqueceu sua senha?</DialogTitle>
                <DialogContent>
                  <DialogContentText>
                    Informe o e-mail de cadastro para resetar sua senha.
                  </DialogContentText>
                  <TextField
                    autoFocus
                    margin="dense"
                    id="reset-password-email-input"
                    label="E-mail"
                    required={true}
                    helperText="Preencher com o e-mail de cadastro"
                    onChange={event => setResetPasswordEmail(event.target.value)}
                    fullWidth
                  />
                </DialogContent>
                <DialogActions>
                  <Button onClick={handleClose} variant="contained" color="primary" className={classes.submitButton}>
                    Cancelar
                  </Button>
                  <Button variant="contained" color="primary" className={classes.submitButton} type="submit">
                    Enviar e-mail
                  </Button>
                </DialogActions>
            </form>
          </Dialog>
      }
    </React.Fragment>
  );
}