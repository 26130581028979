import React from 'react';
import withStyles from '@mui/styles/withStyles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

import * as utils from '../utils';
import * as links from '../links';
import ExamValueTableCell from './ExamValueTableCell';

const styles = theme => ({
  root: {
    width: '100%',
  },
  table: {
    minWidth: 650,
  },
  tableHeader: {
    fontWeight: "bold",
  },
});

function ClinicFinanceEntry(props) {
  const { index, entry, urgCategory, valueField, enableTooltip } = props;

  return <TableRow style={{cursor: 'pointer'}} key={entry.subexam.id} hover onClick={()=> window.open(links.examLink(entry.subexam.id), "_blank")}>
      <TableCell align="left">{index+1}</TableCell>
      <TableCell align="left">{utils.formatDate(entry.exam.study_datetime)}</TableCell>
      <TableCell align="left">{utils.formatDate(entry.exam.created_time)}</TableCell>
      <TableCell align="left">{utils.formatDate(entry.timestamp)}</TableCell>
      <TableCell align="left">{entry.subexam.patient_name}</TableCell>
      <TableCell align="left">{entry.modality}</TableCell>
      <TableCell align="left">{(entry.subexam.description != null) ? entry.subexam.description : ""}</TableCell>
      <TableCell align="left">{(urgCategory != null && entry.category === urgCategory) ? "x" : ""}</TableCell>
      <TableCell align="left">{entry.medic.username}</TableCell>
      <TableCell align="left">{(entry.typer != null) ? entry.typer.username : ""}</TableCell>
      <ExamValueTableCell entry={entry} valueField={valueField} enableTooltip={enableTooltip}/>
          </TableRow>
}

function ClinicFinanceEntriesList(props) {
  const { classes, urgCategory, valueField, user } = props;

  const canViewFinanceTooltip = utils.user_has_permission(user, 'billing.view_bills_details');

  return <TableContainer component={Paper}>
      <Table className={classes.table} size="small" aria-label="exames">
        <TableHead>
          <TableRow>
            <TableCell ></TableCell>
            <TableCell className={classes.tableHeader}>Data</TableCell>
            <TableCell align="left" className={classes.tableHeader}>Incl. PACS</TableCell>
            <TableCell align="left" className={classes.tableHeader}>Liberação</TableCell>
            <TableCell align="left" className={classes.tableHeader}>Paciente</TableCell>
            <TableCell align="left" className={classes.tableHeader}>Mod</TableCell>
            <TableCell align="left" className={classes.tableHeader}>Exame</TableCell>
            <TableCell align="left" className={classes.tableHeader}>Urg</TableCell>
            <TableCell align="left" className={classes.tableHeader}>Médico</TableCell>
            <TableCell align="left" className={classes.tableHeader}>Digitador</TableCell>
            <TableCell align="right" className={classes.tableHeader}>Total</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {props.entries.map((e, index) => (
            <ClinicFinanceEntry index={index} entry={e} key={e.subexam.id} urgCategory={urgCategory} valueField={valueField} enableTooltip={canViewFinanceTooltip} />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
}

export default withStyles(styles)(ClinicFinanceEntriesList);