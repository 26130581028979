import { api } from './axios';
import store from '../store';
import * as superExamActions from '../actions/super-exams';
import * as utils from '../utils';


export const getSuperExam = async (id) => {
  try {
    store.dispatch(superExamActions.getSuperExamRequest());
    const res = await api.get(`/api/superexams/${id}`);
    store.dispatch(superExamActions.getSuperExamSuccess(res.data));
    return { result: true, data: res.data };
  } catch (err) {
    store.dispatch(superExamActions.getSuperExamFailure(err));
  }
};

export const addExam = async (examId, data) => {
  try {
    store.dispatch(superExamActions.addExamRequest());
    const res = await api.post(`/api/superexams/${examId}/subexam`, data);
    store.dispatch(superExamActions.addExamSuccess(res.data));
    return { result: true, data: res.data };
  } catch (err) {
    store.dispatch(superExamActions.addExamFailure(err));
  }
};

export const downloadSuperExamImages = async (examId) => {
  try {
    store.dispatch(superExamActions.downloadExamImagesRequest(examId));
    const res = await api.post(`/api/superexams/${examId}/downloadImages`);
    if (res.data !== undefined) {
      if (res.data.link !== undefined) {
          utils.openInTab(res.data.link);
      }
    }
    store.dispatch(superExamActions.downloadExamImagesSuccess(examId));
    return { result: true, data: res.data };
  } catch (err) {
    const res_failure = { err: err, examId: examId };
    store.dispatch(superExamActions.downloadExamImagesFailure(res_failure));
  }
};

export const downloadAccessTokenSuperExamImages = async (data) => {
  try {
    store.dispatch(superExamActions.downloadAccessTokenExamImagesRequest(data.examId));
    const res = await api.post(`/api/superexams/token/access/downloadImages`, data);
    if (res.data !== undefined) {
      if (res.data.link !== undefined) {
          utils.openInTab(res.data.link);
      }
    }
    store.dispatch(superExamActions.downloadAccessTokenExamImagesSuccess(data.examId));
    return { result: true, data: res.data };
  } catch (err) {
    const res_failure = { err: err, examId: data.examId };
    store.dispatch(superExamActions.downloadAccessTokenExamImagesFailure(res_failure));
    return res_failure;
  }
};

export const updateSuperExamFields = async (examId, data) => {
  try {
    store.dispatch(superExamActions.updateSuperExamFieldsRequest());
    const res = await api.patch(`/api/superexams/${examId}/edit`, data);
    store.dispatch(superExamActions.updateSuperExamFieldsSuccess(res.data));
    return {result: true, data: res};
  } catch (err) {
    store.dispatch(superExamActions.updateSuperExamFieldsFailure(err));
    return {result: false, data: err};
  }
}