import React, { useEffect } from 'react';
import withStyles from '@mui/styles/withStyles';
import { connect } from 'react-redux';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import SpeechConfig from './SpeechConfig';
import TwoFactorAuth from './TwoFactorAuth';
import Notifications from './Notifications';
import * as authHttp from '../http/auth';
import * as utils from '../utils';


const styles = theme => ({
  heading: {
    fontSize: theme.typography.pxToRem(18),
    flexBasis: '33.33%',
    flexShrink: 0,
    fontWeight: 'bold',
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
  descriptionText: {
    margin: 'auto',
    padding: 5,
    textAlign:'center',
    maxWidth: 400,
    maxHeight: 100
  },
  inactive: {
    opacity: 0.4
  }
});

const mapStateToProps = (state) => {
  return {
    currentUser: state.auth.currentUser,
  };
};


function Config (props) {
  const { classes, currentUser } = props;

  useEffect(() => { authHttp.getCurrentUser(); authHttp.twoFactorAuthIsActive() }, []);

  const canEditTextReplacementCommand = utils.user_has_permission(currentUser, 'speechrecognition.add_textreplacementcommand') ||
                                        utils.user_has_permission(currentUser, 'speechrecognition.delete_textreplacementcommand') ||
                                        utils.user_has_permission(currentUser, 'speechrecognition.change_textreplacementcommand') ||
                                        utils.user_has_permission(currentUser, 'speechrecognition.view_textreplacementcommand');

  return (
    <React.Fragment>
    {canEditTextReplacementCommand ?
      <div className={classes.root}>
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="ditadobh-content"
            id="ditadobh-header"
          >
            <Typography className={classes.heading}>Ditado</Typography>
            <Typography className={classes.secondaryHeading}>Substituições de texto e outras opções para ditado</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <SpeechConfig />
          </AccordionDetails>
        </Accordion>
      </div>
    : null }
    <div className={classes.root}>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="twofactorauthbh-content"
          id="twofactorauthbh-header"
        >
          <Typography className={classes.heading}>Autenticação de dois fatores</Typography>
          <Typography className={classes.secondaryHeading}>Ativação da autenticação em duas etapas</Typography>
        </AccordionSummary>
        <AccordionDetails>
    <TwoFactorAuth />
        </AccordionDetails>
      </Accordion>
    </div>
    <div className={classes.root}>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="notification-content"
          id="notification-header"
        >
          <Typography className={classes.heading}>Notificações</Typography>
          <Typography className={classes.secondaryHeading}>Ativação das notificações por e-mail</Typography>
        </AccordionSummary>
        <AccordionDetails>
    <Notifications />
        </AccordionDetails>
      </Accordion>
    </div>
    </React.Fragment>
    )
}

export default withStyles(styles)(connect(mapStateToProps)(Config));
