import { api } from './axios';
import store from '../store';
import * as clinicActions from '../actions/clinics';

export const list = async (query) => {
  try {
    store.dispatch(clinicActions.listRequest());
    const res = await api.get('/api/clinics');
    store.dispatch(clinicActions.listSuccess(res.data));
  } catch (err) {
    store.dispatch(clinicActions.listFailure(err));
  }
};

export const getMargins = async (clinic) => {
  try {
    if (clinic) {
      const res = await api.get(`/api/clinics/${clinic}/margins`);
      store.dispatch(clinicActions.getMargins(res.data));
    } else {
      store.dispatch(clinicActions.getMargins({
        top: '1.00', bottom: '1.00', left: '1.00', right: '1.00'
      }));
    }
  } catch (err) {
    store.dispatch(clinicActions.getMargins(null));
  }
};

export const retrieveExamsDeliveryTime = async () => {
  try {
    store.dispatch(clinicActions.retrieveExamsDeliveryTimeRequest());
    const res = await api.get(`/api/clinics/delivery_time`);
    store.dispatch(clinicActions.retrieveExamsDeliveryTimeSuccess(res.data));
    return {result: true, data: res};
  } catch (err) {
    store.dispatch(clinicActions.retrieveExamsDeliveryTimeFailure(err));
    return {result: false, data: err};
  }
};

