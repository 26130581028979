import { api } from './axios';
import store from '../store';
import * as examActions from '../actions/exams';

export const list = async (query) => {
  try {
    store.dispatch(examActions.search(query));
    const res = await api.get('/api/exams', { params: query });
    store.dispatch(examActions.list(res.data));
  } catch (err) {
    store.dispatch(examActions.searchFailure(err));
  }
};

export const listPendingDashboard = async (query) => {
  try {
    store.dispatch(examActions.searchPendingExams(query));
    const res = await api.get('/api/exams', { params: query });
    store.dispatch(examActions.listPendingDashboard(res.data));
  } catch (err) {
    store.dispatch(examActions.searchPendingExamsFailure(err));
  }
};

export const listReleasedDashboard = async (query) => {
  try {
    store.dispatch(examActions.searchReleasedExams(query));
    const res = await api.get('/api/exams', { params: query });
    store.dispatch(examActions.listReleasedDashboard(res.data));
  } catch (err) {
    store.dispatch(examActions.searchReleasedExamsFailure(err));
  }
};

export const get = async (id) => {
  try {
    store.dispatch(examActions.clearCurrent());
    const res = await api.get(`/api/exams/${id}`);
    store.dispatch(examActions.get(res.data));
  } catch (err) {
    console.error(err);
  }
};

export const listExamsToReview = async (query) => {
  try {
    store.dispatch(examActions.searchExamsToReview(query));
    const res = await api.get('/api/exams', { params: query });
    store.dispatch(examActions.list(res.data));
  } catch (err) {
    store.dispatch(examActions.searchExamsToReviewFailure(err));
  }
};

export const clearAll = async () => {
  store.dispatch(examActions.clearAll());
}

export const searchPatientExams = async (query) => {
  try {
    store.dispatch(examActions.clearPatientExams());
    const searchParams = new URLSearchParams(query);
    const res = await api.get(`/api/exams?` + searchParams.toString());
    store.dispatch(examActions.searchPatientExams(res.data));
  } catch (err) {
    console.error(err);
  }
};

export const clearPatientExams = async (query) => {
  try {
    store.dispatch(examActions.clearPatientExams());
  } catch (err) {
    console.error(err);
  }
};

export const createExamReport = async (data) => {
  try {
    store.dispatch(examActions.createExamReportRequest());
    const subexam_id = data.subexam;
    const res = await api.post(`/api/exams/${subexam_id}/report`, data);
    store.dispatch(examActions.createExamReportSuccess(res.data));
    return {result: true, data: res};
  } catch (err) {
    store.dispatch(examActions.createExamReportFailure(err));
    return {result: false, data: err};
  }
};

export const createExamReportReview = async (data) => {
  try {
    store.dispatch(examActions.createExamReportReviewRequest());
    const subexam_id = data.subexam;
    const res = await api.post(`/api/exams/${subexam_id}/report/review`);
    store.dispatch(examActions.createExamReportReviewSuccess(res.data));
    return {result: true, data: res};
  } catch (err) {
    store.dispatch(examActions.createExamReportReviewFailure(err));
    return {result: false, data: err};
  }
};

export const deleteExamReportReview = async (data) => {
  try {
    store.dispatch(examActions.deleteExamReportReviewRequest());
    const subexam_id = data.subexam;
    const res = await api.delete(`/api/exams/${subexam_id}/report/review`);
    store.dispatch(examActions.deleteExamReportReviewSuccess(res.data));
    return true;
  } catch (err) {
    store.dispatch(examActions.deleteExamReportReviewFailure(err));
    return false;
  }
};

export const getExamImages = async (examId, sortBy) => {
  try {
    store.dispatch(examActions.getExamImagesRequest());
    const res = await api.get(`/api/exams/${examId}/images`, { params: { ordering: sortBy }});
    store.dispatch(examActions.getExamImagesSuccess(res.data));
    return true;
  } catch (err) {
    store.dispatch(examActions.getExamImagesFailure(err));
  }
};

export const getExamImageData = async (examId, generated_id) => {
  try {
    store.dispatch(examActions.getExamImageDataRequest());
    const res = await api.get(`/api/exams/${examId}/images/${generated_id}`, { responseType: 'blob' });
    store.dispatch(examActions.getExamImageDataSuccess({ generated_id, data: res.data }));
  } catch (err) {
    store.dispatch(examActions.getExamImageDataFailure(err));
  }
};

export const updateExamImages = async (examId, data) => {
  try {
    store.dispatch(examActions.updateExamImagesRequest());
    const formData = new FormData();
    data.files.forEach((f, i) => formData.append(`file_${i}`, f));
    const res = await api.put(`/api/exams/${examId}/images`, formData, { headers: { 'Content-Type': 'multipart/form-data' }});
    store.dispatch(examActions.updateExamImagesSuccess(res.data));
  } catch (err) {
    store.dispatch(examActions.updateExamImagesFailure(err));
  }
};

export const bulkUpdateExams = async (examsData) => {
  try {
    store.dispatch(examActions.bulkUpdateExamsRequest());
    const res = await api.patch(`/api/exams/bulk`, examsData)
    store.dispatch(examActions.bulkUpdateExamsSuccess(res.data));
  } catch (err) {
    store.dispatch(examActions.bulkUpdateExamsFailure(err));
  }
};

export const uploadExamAnnex = async (examId, file) => {
  try {
    store.dispatch(examActions.uploadExamAnnexRequest());
    const formData = new FormData();
    formData.append('file_annex', file);
    const res = await api.post(`/api/exams/${examId}/annex`, formData, { headers: { 'Content-Type': 'multipart/form-data' }});
    store.dispatch(examActions.uploadExamAnnexSuccess(res.data));
    return res;
  } catch (err) {
    store.dispatch(examActions.uploadExamAnnexFailure(err));
    return err;
  }
};

export const updateMultiplier = async (data) => {
  try {
    //store.dispatch(examActions.createExamReportReviewRequest());
    const subexam_id = data.subexam;
    const res = await api.put(`/api/exams/${subexam_id}/multiplier`, data);
    //store.dispatch(examActions.createExamReportReviewSuccess(res.data));
    return {result: true, data: res};
  } catch (err) {
    //store.dispatch(examActions.createExamReportReviewFailure(err));
    return {result: false, data: err};
  }
};

export const updateCategory = async (data) => {
  try {
    //store.dispatch(examActions.createExamReportReviewRequest());
    const subexam_id = data.subexam;
    const res = await api.put(`/api/exams/${subexam_id}/category`, data);
    //store.dispatch(examActions.createExamReportReviewSuccess(res.data));
    return {result: true, data: res};
  } catch (err) {
    //store.dispatch(examActions.createExamReportReviewFailure(err));
    return {result: false, data: err};
  }
};

export const deleteExam = async (subExamId) => {
  try {
    store.dispatch(examActions.deleteSubExamRequest());
    const res = await api.delete(`/api/exams/${subExamId}`);
    store.dispatch(examActions.deleteSubExamSuccess(subExamId));
    return { result: true, data: res.data };
  } catch (err) {
    store.dispatch(examActions.deleteSubExamFailure(err));
  }
};

export const createAccessToken = async (data) => {
  try {
    store.dispatch(examActions.createMedicalReportAccessTokenRequest(data));
    const res = await api.post(`/api/exams/report/token`, data);
    store.dispatch(examActions.createMedicalReportAccessTokenSuccess(res.data));
    return res
  } catch (err) {
    store.dispatch(examActions.createMedicalReportAccessTokenFailure(err));
    return err;
  }
};

export const getAccessTokenExam = async (data) => {
  try {
    store.dispatch(examActions.getAccessTokenExamRequest(data));
    const res = await api.get(`/api/exams/report/token/exam/`, { params: data });
    store.dispatch(examActions.getAccessTokenExamSuccess(res.data));
    return { result: true, data: res.data };
  } catch (err) {
    store.dispatch(examActions.getAccessTokenExamFailure(err));
    return { result: false, err: err};
  }
};

export const associateExams = async (examsData) => {
  try {
    store.dispatch(examActions.associateExamsRequest());
    const res = await api.post(`/api/exams/associate`, examsData)
    store.dispatch(examActions.associateExamsSuccess(res.data));
    return res;
  } catch (err) {
    store.dispatch(examActions.associateExamsFailure(err));
    return {result: false, err: err};
  }
};

export const disassociateExams = async (examsData) => {
  try {
    store.dispatch(examActions.disassociateExamsRequest());
    const res = await api.post(`/api/exams/associate`, examsData)
    store.dispatch(examActions.disassociateExamsSuccess());
    return {result: true, data: res};
  } catch (err) {
    store.dispatch(examActions.disassociateExamsFailure(err));
    return {result: false, data: err}
  }
};

export const retrievePatInfo = async (data) => {
  try {
    store.dispatch(examActions.retrievePatInfoRequest(data));
    const res = await api.get(`/api/exams/patient/`, { params: data })
    store.dispatch(examActions.retrievePatInfoSuccess(res.data));
    return {result: true, data: res};
  } catch (err) {
    store.dispatch(examActions.retrievePatInfoFailure(err));
    return {result: false, data: err}
  }
};

export const createExamManually = async (data) => {
  try {
    store.dispatch(examActions.createExamManuallyRequest(data));
    const res = await api.post(`/api/exams/superexam/`, data);
    store.dispatch(examActions.createExamManuallySuccess(res.data));
    return {result: true, data: res};
  } catch (err) {
    store.dispatch(examActions.createExamManuallyFailure(err));
    return {result: false, data: err}
  }
};
