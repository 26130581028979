import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import withStyles from '@mui/styles/withStyles';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import * as examsHttp from '../http/exams';
import * as utils from '../utils';

const styles = theme => ({
  root: {
    width: '100%',
  },
  table: {
    minWidth: 650,
  },
  tableHeader: {
    fontWeight: "bold",
  },
});


const mapStateToProps = (state) => {
  return {
    patient_exams: state.exams.patientExams,
  };
};

function ExamActionsMenu(props) {
  const { exam, user, patient_exams } = props;
  const [anchorEl, setAnchorEl] = React.useState(null);

  React.useEffect(() => {
    if (exam !== null) {
        examsHttp.searchPatientExams({
          "patient_id": exam["patient_id"],
          "patient_name": exam["patient_name"],
          "patient_birthdate": exam["patient_birthdate"],
        });
    }
    return (() => {
        examsHttp.clearPatientExams()
    })
  }, [exam]);

  if (exam == null || exam === undefined) {
    return null;
  }

  if (!utils.user_has_permission(user, 'exams.view_subexam')) {
    return null;
  }

  var processed_patient_exams = null;
  if (patient_exams != null) {
    processed_patient_exams = patient_exams.filter((m) => m["id"] !== exam["id"]);
  }


  var menuItemsElements = []
  if (patient_exams == null) {
    menuItemsElements.push(
      <MenuItem key="no-exams-loading">
        Carregando
      </MenuItem>
    )
  } else if (patient_exams.length === 0) {
    menuItemsElements.push(
      <MenuItem key="no-exams">
        Nenhum exame encontrado
      </MenuItem>
    )
  } else if (patient_exams != null) {
    menuItemsElements = processed_patient_exams.map((m) =>
        <MenuItem key={"other-patient-exam-" + m["id"]}>
            <a target="_blank" href={"/exams/exam/" + m["id"]}>{m["study_datetime"] + " " + m["modality"] + " - " + m["description"]}</a>
        </MenuItem>
    )
  }

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return <React.Fragment>
      <Button variant="contained" color="primary" aria-controls="options-menu" aria-haspopup="true" onClick={handleClick} startIcon={<ExpandMoreIcon />}>
        Exames anteriores ({processed_patient_exams === null ? "..." : processed_patient_exams.length})
      </Button>
      <Menu
        id="options-menu"
        anchorEl={anchorEl}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {menuItemsElements.map((m) => (m))}
      </Menu>
  </React.Fragment>
}

export default withRouter(withStyles(styles)(connect(mapStateToProps)(ExamActionsMenu)));
