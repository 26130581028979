import _ from 'lodash';
import { SUPEREXAMS_GET_SUCCESS, SUPEREXAMS_ADD_EXAM_SUCCESS,
         SUPEREXAMS_DOWNLOAD_IMAGES_REQUEST, SUPEREXAMS_DOWNLOAD_IMAGES_SUCCESS, SUPEREXAMS_DOWNLOAD_IMAGES_FAILURE,
         SUPEREXAMS_DOWNLOAD_ACCESS_TOKEN_IMAGES_REQUEST, SUPEREXAMS_DOWNLOAD_ACCESS_TOKEN_IMAGES_SUCCESS,
         SUPEREXAMS_DOWNLOAD_ACCESS_TOKEN_IMAGES_FAILURE,
         SUPEREXAMS_UPDATE_FIELDS_REQUEST, SUPEREXAMS_UPDATE_FIELDS_SUCCESS, SUPEREXAMS_UPDATE_FIELDS_FAILURE
       } from '../actions/super-exams';
import { EXAMS_DELETE_SUCCESS } from '../actions/exams';

import * as utils from '../utils';

const initialState = {
  current: null,
  downloadingExamsImages: [],
  downloadingImages: false,
  downloadingImagesError: null,

  updatingSuperExamFields: false,
  updatingSuperExamFieldsError: null,

};

const superExams = (state = initialState, action) => {
  switch (action.type) {
    case SUPEREXAMS_GET_SUCCESS:
      {
        let superExam = action.data;
        if (superExam.subexam_set) {
          superExam.subexam_set = utils.processExams(superExam.subexam_set)
        }
        return {
          ...state,
          current: superExam
        };
      }
    case SUPEREXAMS_ADD_EXAM_SUCCESS:
      {
        let newSubExam = action.data;
        utils.processExam(newSubExam);
        let superExam = _.clone(state.current);
        superExam.subexam_set.push(newSubExam);
        return {
          ...state,
          current: superExam
        };
      }
    case EXAMS_DELETE_SUCCESS:
      {
        let subExamId = action.data;
        let superExam = _.clone(state.current);
        _.remove(superExam.subexam_set, se => se.id === subExamId);
        return {
          ...state,
          current: superExam
        };
      }
    case SUPEREXAMS_DOWNLOAD_IMAGES_REQUEST:
      {
        return {
          ...state,
          downloadingExamsImages: [...state.downloadingExamsImages, action.data],
          downloadingImages: true,
          downloadingImagesError: null,
        };
      }
    case SUPEREXAMS_DOWNLOAD_IMAGES_SUCCESS:
      {
        return {
          ...state,
          downloadingExamsImages: removeExamDownloadingImages(state, action.data),
          downloadingImages: false,
          downloadingImagesError: null,
        };
      }
    case SUPEREXAMS_DOWNLOAD_IMAGES_FAILURE:
      {
        return {
          ...state,
          downloadingExamsImages: removeExamDownloadingImages(state, action.data),
          downloadingImages: false,
          downloadingImagesError: parseDownloadImagesError(action.data),
        };
      }
    case SUPEREXAMS_DOWNLOAD_ACCESS_TOKEN_IMAGES_REQUEST:
      {
        return {
          ...state,
          downloadingExamsImages: [...state.downloadingExamsImages, action.data],
          downloadingImages: true,
          downloadingImagesError: null,
        };
      }
    case SUPEREXAMS_DOWNLOAD_ACCESS_TOKEN_IMAGES_SUCCESS:
      {
        return {
          ...state,
          downloadingExamsImages: removeExamDownloadingImages(state, action.data),
          downloadingImages: false,
          downloadingImagesError: null,
        };
      }
    case SUPEREXAMS_DOWNLOAD_ACCESS_TOKEN_IMAGES_FAILURE:
      {
        return {
          ...state,
          downloadingExamsImages: removeExamDownloadingImages(state, action.data),
          downloadingImages: false,
          downloadingImagesError: parseDownloadImagesError(action.data),
        };
      }
    case SUPEREXAMS_UPDATE_FIELDS_REQUEST:
      return {
          ...state,
          updatingSuperExamFields: true,
          updatingSuperExamFieldsError: null,
      }
    case SUPEREXAMS_UPDATE_FIELDS_SUCCESS:
      return {
          ...state,
          updatingSuperExamFields: false,
          updatingSuperExamFieldsError: null,
      }
    case SUPEREXAMS_UPDATE_FIELDS_FAILURE:
      return {
          ...state,
          updatingSuperExamFields: false,
          updatingSuperExamFieldsError: parseUpdatingSuperExamFieldsError(action.data),
      }
    default:
      return state;
  }
};

function removeExamDownloadingImages(state, data) {
    var i = 0;
    let downloadingExamsImages = state.downloadingExamsImages
    while (i < downloadingExamsImages.length) {
      if (downloadingExamsImages[i] === data.examId) {
        downloadingExamsImages.splice(i, 1);
        ++i
      } else {
        ++i;
      }
    }

    return downloadingExamsImages;
}

function parseDownloadImagesError(data) {
    return utils.getErrorMessageOrDefaultMessage(data.err);
}

function parseUpdatingSuperExamFieldsError(data) {
    return utils.getErrorMessageOrDefaultMessage(data);
}

export default superExams;
