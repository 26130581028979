import React, { Fragment, useEffect } from 'react';
import { connect } from 'react-redux';
import makeStyles from '@mui/styles/makeStyles';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';

import SuperExamDetailsTable from '../components/SuperExamDetailsTable';
import SuperExamExamsList from '../components/SuperExamExamsList';
import SuperExamAddExam from '../components/SuperExamAddExam';
import Title from '../components/Title';

import * as authHttp from '../http/auth';
import * as superExamsHttp from '../http/super-exams';
import * as utils from '../utils';

const mapStateToProps = (state) => {
  return {
    currentUser: state.auth.currentUser,
    exam: state.superExams.current
  };
};

const useStyles = makeStyles(theme => ({
  title: {
    marginTop: theme.spacing(5)
  }
}));

function Loading(props) {
  return <Typography>Carregando...</Typography>;
}

function SuperExamDetails(props) {
  const { exam, currentUser } = props;
  const classes = useStyles();

  useEffect(() => { authHttp.getCurrentUser(); superExamsHttp.getSuperExam(props.match.params.id); }, [props.match.params.id]);

  if (exam == null) {
    return <Loading />;
  }

  return (
    <Container maxWidth="xl">
      <Title>Gerência - Exame {exam.id}</Title>
      <SuperExamDetailsTable exam={exam} />
      <Title className={classes.title}>Sub Exames</Title>
      <SuperExamExamsList subExams={exam.subexam_set} user={currentUser} />
      {utils.user_has_permission(currentUser, "exams.add_subexam") && (
        <Fragment>
          <Title className={classes.title}>Adicionar Sub Exame</Title>
          <SuperExamAddExam exam={exam} user={currentUser} />
        </Fragment>
      )}
    </Container>
  );
};

export default connect(mapStateToProps)(SuperExamDetails);
