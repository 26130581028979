import React, { useState, useEffect, useCallback } from 'react';
import withStyles from '@mui/styles/withStyles';
import { connect } from 'react-redux';

import Title from '../components/Title';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import { Button } from '@mui/material';

import Loading from '../components/Loading';
import SuperExamDetailsTable from '../components/SuperExamDetailsTable';
import * as authHttp from '../http/auth';
import * as superExamsHttp from '../http/super-exams';

import * as utils from '../utils';

const styles = theme => ({
  title: {
    marginTop: theme.spacing(3),
  },
  inputTextField: {
    minWidth: 500,
  },
});

const mapStateToProps = (state) => {
  return {
    superExam: state.superExams.current,
    user: state.auth.currentUser,
  };
};


function ExamSetFields (props) {
  const { classes, superExam, user } = props;
  const [ updating, setUpdating ] = useState(false);
  const [ referredPhysician, setReferredPhysician ] = useState('');

  useEffect(() => { authHttp.getCurrentUser(); superExamsHttp.getSuperExam(props.match.params.id); }, [props.match.params.id]);

  const editRefPhysician = utils.user_has_permission(user, 'exams.update_ref_physician');
  const anyPermission = editRefPhysician;

  const updateSuperExamFields = useCallback(async (referredPhysician) => {
    var data = {}
    if (editRefPhysician) {
        data['ref_physician'] = referredPhysician.referredPhysician;
    }

    if (Object.keys(data).length === 0) {
        alert("Nenhum dado a atualizar para o exame.");
        return;
    }
    setUpdating(true);
    const response = await superExamsHttp.updateSuperExamFields(superExam.id, data);
    if (response.result) {
      alert("Exame atualizado com sucesso!");
    } else {
      alert("Não foi possível atualizar o exame.");
    }
    setUpdating(false);
  }, [superExam, editRefPhysician]);

  if (superExam == null) {
    return <Loading />;
  }

  var updateRefPhysicianField = null;
  if (editRefPhysician) {
    updateRefPhysicianField = <Grid container justifyContent="left">
                                 <TextField
                                   className={classes.inputTextField}
                                   id="referred-physician"
                                   onChange={event => setReferredPhysician(event.target.value)}
                                   label="Informe o médico solicitante"
                                   variant="outlined"
                                 />
                               </Grid>;
  }

  return (
    <div className={classes.root}>
      <Container maxWidth="xl">
        <Title className={classes.title}>Gerência - Exame {superExam.id}</Title>
        <SuperExamDetailsTable exam={superExam} />
        <Title className={classes.title}>Editar - Exame {superExam.id}</Title>
          {
            updating
              ?
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <Grid container justifyContent="center">
                    <Loading />
                  </Grid>
                </Grid>
              </Grid>
              :
              anyPermission ?
              <form id="update-password-form" onSubmit={(e) => { e.preventDefault(); updateSuperExamFields({referredPhysician}); } }>
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                     {updateRefPhysicianField}
                  </Grid>
                  <Grid item xs={12}>
                    <Grid container justifyContent="left">
                      <Button
                        type="submit"
                        variant="contained"
                        color="primary"
                      >
                      Atualizar
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </form>
              :
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  Usuário não tem permissões para editar campos do exame.
                </Grid>
              </Grid>
          }
      </Container>
    </div>
  );
}

export default withStyles(styles)(connect(mapStateToProps)(ExamSetFields));
