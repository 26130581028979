import _ from 'lodash';
import moment from 'moment';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import withStyles from '@mui/styles/withStyles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TablePagination from '@mui/material/TablePagination';
import TableSortLabel from '@mui/material/TableSortLabel';
import Paper from '@mui/material/Paper';
import IconButton from '@mui/material/IconButton';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Fab from '@mui/material/Fab';
import AddIcon from '@mui/icons-material/Add';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import SearchIcon from '@mui/icons-material/Search'

import { createLoadingSelector } from '../reducers/loading';
import  { REPORTS_LIST } from '../actions/reports';
import * as authHttp from '../http/auth';
import * as reportsHttp from '../http/reports';
import * as clinicsHttp from '../http/clinics';
import { modalities } from '../constants'
import Loading from '../components/Loading';

const styles = theme => ({
  root: {
    width: '100%'
  },
  table: {
    minWidth: 700
  },
  tableContainer: {
    height: 320
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  margin: {
    margin: theme.spacing(1),
    marginLeft: 'auto'
  }
});

const mapStateToProps = (state) => {
  return {
    reports: state.reports.list,
    reportsCount: state.reports.count,
    report: state.reports.current,
    clinics: state.clinics.list,
    user: state.auth.currentUser,
    loadingReports: createLoadingSelector([ REPORTS_LIST ])(state)
  };
};

class Reports extends Component {

  constructor(props) {
    super(props);

    this.state = {
      clinicFilter: undefined,
      modalityFilter: undefined,
      searchFilter: undefined,
      page: 0,
      rowsPerPage: 5,
      orderBy: 'description',
      order: 'asc'
    }
  }

  filterFromState = () => {
    return {
      clinic: this.state.clinicFilter,
      modality: this.state.modalityFilter,
      search: this.state.searchFilter,
      limit: this.state.rowsPerPage,
      offset: this.state.page * this.state.rowsPerPage,
      ordering: (this.state.order === 'desc' ? '-' : '') + this.state.orderBy
    };
  }

  componentWillMount() {
    authHttp.getCurrentUser();
    clinicsHttp.list();
    reportsHttp.list(this.filterFromState());
  }

  showReport = reportId => event => {
    event.stopPropagation();
    this.props.history.push(`/laudario/laudos/${reportId}`)
  }

  handleFilterChange = (filterName) => (event) => {
    this.setState({ [filterName]: event.target.value }, () => {
      reportsHttp.list(this.filterFromState());
    });
  }

  handleOrderChange = (property) => (event) => {
    const { orderBy, order } = this.state;
    const isDesc = orderBy === property && order === 'desc';
    this.setState({ orderBy: property, order: isDesc ? 'asc' : 'desc' }, () => {
      reportsHttp.list(this.filterFromState());
    });
  }

  handleSearchChange = (event) => {
    this.setState({ searchFilter: event.target.value });
  }

  handleSearch = (event) => {
    event.preventDefault();
    reportsHttp.list(this.filterFromState());
  }

  handleChangePage = (event, page) => {
    this.setState({ page }, () =>{
      reportsHttp.list(this.filterFromState());
    });
  }

  handleChangeRowsPerPage = (event) => {
    this.setState({ rowsPerPage: event.target.value }, () => {
      reportsHttp.list(this.filterFromState());
    });
  }

  handleCreateButtonClick = (event) => {
    this.props.history.push(`/laudario/laudos/create`)
  }

  render() {
    const { classes, reports, clinics, user, loadingReports } = this.props;
    const { orderBy, order } = this.state;

    const canViewClinic = user && (
      _.includes(user.roles, 'manager') || _.includes(user.roles, 'admin')
    )

    return (
      <div className={classes.tableContainer}>
        <div style={{display: 'flex'}}>
          <form onSubmit={this.handleSearch}>
            {canViewClinic &&
              <FormControl className={classes.formControl}>
                <InputLabel htmlFor="clinics-selector">Clínica</InputLabel>
                <Select
                  value={this.state.clinicFilter || ''}
                  inputProps={{
                    name: 'clinics',
                    id: 'clinics-selector'
                  }}
                  onChange={this.handleFilterChange('clinicFilter')}
                  >
                  <MenuItem><em>Todas</em></MenuItem>
                  {clinics && clinics.map(clinic => (
                    <MenuItem key={clinic.id} value={clinic.id}>{clinic.name}</MenuItem>
                    ))}
                </Select>
              </FormControl>
            }
            <FormControl className={classes.formControl}>
              <InputLabel htmlFor="modalities-selector">Modalidade</InputLabel>
              <Select
                value={this.state.modalityFilter || ''}
                inputProps={{
                  name: 'modalities',
                  id: 'modalities-selector'
                }}
                onChange={this.handleFilterChange('modalityFilter')}
              >
                <MenuItem><em>Todas</em></MenuItem>
                {modalities.map(modality => (
                  <MenuItem key={modality} value={modality}>{modality}</MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl className={classes.formControl}>
              <TextField
                value={this.state.searchFilter || ''}
                label='Busca'
                onChange={this.handleSearchChange}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton aria-label="search" onClick={this.handleSearch} size="large">
                        <SearchIcon/>
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </FormControl>
          </form>
          <Fab
            color="secondary"
            aria-label="Criar"
            className={classes.margin}
            onClick={this.handleCreateButtonClick}
          >
            <AddIcon />
          </Fab>
        </div>
        <Paper className={classes.root}>
          {
            loadingReports
            ? <Loading />
            :
            <Table className={classes.table}>
              <TableHead>
                <TableRow>
                  <TableCell
                    sortDirection={orderBy === 'description' ? order : false}
                  >
                    <TableSortLabel
                      active={orderBy === 'description'}
                      direction={order}
                      onClick={this.handleOrderChange('description')}
                    >
                      Descrição
                    </TableSortLabel>
                  </TableCell>
                  <TableCell align="center"
                    sortDirection={orderBy === 'modality' ? order : false}
                  >
                    <TableSortLabel
                      active={orderBy === 'modality'}
                      direction={order}
                      onClick={this.handleOrderChange('modality')}
                    >
                      Modalidade
                    </TableSortLabel>
                  </TableCell>
                  {canViewClinic &&
                    <TableCell align="center"
                      sortDirection={orderBy === 'clinic' ? order : false}
                    >
                      <TableSortLabel
                        active={orderBy === 'clinic__name'}
                        direction={order}
                        onClick={this.handleOrderChange('clinic__name')}
                      >
                        Clínica
                      </TableSortLabel>
                    </TableCell>
                  }
                  <TableCell align="center"
                    sortDirection={orderBy === 'created_at' ? order : false}
                  >
                    <TableSortLabel
                      active={orderBy === 'created_at'}
                      direction={order}
                      onClick={this.handleOrderChange('created_at')}
                    >
                      Data de criação
                    </TableSortLabel>
                  </TableCell>
                  <TableCell align="center"
                    sortDirection={orderBy === 'updated_at' ? order : false}
                  >
                    <TableSortLabel
                      active={orderBy === 'updated_at'}
                      direction={order}
                      onClick={this.handleOrderChange('updated_at')}
                    >
                      Última modificação
                    </TableSortLabel>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {reports && reports.map(r => (
                  <TableRow
                    key={r.id}
                    hover
                    onClick={this.showReport(r.id)}
                  >
                    <TableCell component="th" scope="row">
                      {r.description}
                    </TableCell>
                    <TableCell align="center">{r.modality}</TableCell>
                    {canViewClinic && <TableCell align="center">{r.clinic.name || '-'}</TableCell>}
                    <TableCell align="center">{moment(r.created_at).format('LLLL')}</TableCell>
                    <TableCell align="center">{moment(r.updated_at).format('LLLL')}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          }
          <TablePagination
            rowsPerPageOptions={[5, 10, 20, 50, 100]}
            component="div"
            count={this.props.reportsCount}
            rowsPerPage={this.state.rowsPerPage}
            labelRowsPerPage='Itens por página'
            page={this.state.page}
            backIconButtonProps={{
              'aria-label': 'Página Anterior',
            }}
            nextIconButtonProps={{
              'aria-label': 'Próxima Página',
            }}
            onPageChange={this.handleChangePage}
            onRowsPerPageChange={this.handleChangeRowsPerPage}
          />
        </Paper>
        <Fab
          color="secondary"
          aria-label="Criar"
          className={classes.margin}
          onClick={this.handleCreateButtonClick}
        >
          <AddIcon />
        </Fab>
      </div>
    );
  }
}

Reports.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withRouter(withStyles(styles)(connect(mapStateToProps)(Reports)));
