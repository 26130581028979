import { api } from './axios';
import store from '../store';
import * as accountsActions from '../actions/accounts';


export const activateNotification = async (data) => {
    try {
      store.dispatch(accountsActions.notificationActivateRequest());
      const res = await api.patch(`/api/accounts/notifications`, data);
      store.dispatch(accountsActions.notificationActivateSuccess());
      return {result: true, data: res};
    } catch (err) {
        store.dispatch(accountsActions.notificationActivateFailure('Não foi possível ativar as notificações por e-mail.'));
        return {result: false, data: err};
    }
  };

  export const deactivateNotification = async (data) => {
    try {
      store.dispatch(accountsActions.notificationDeactivateRequest());
      const res = await api.patch(`/api/accounts/notifications`, data);
      store.dispatch(accountsActions.notificationDeactivateSuccess());
      return {result: true, data: res};
    } catch (err) {
        store.dispatch(accountsActions.notificationDeactivateFailure('Não foi possível desativar as notificações por e-mail.'));
        return {result: false, data: err};
    }
  };

  export const notificationIsActive = async () => {
    try {
      const res = await api.get(`/api/accounts/notifications`);
      store.dispatch(accountsActions.notificationIsActive(res.data));
    } catch (err) {
    }
  };

  export const retrieveUserFlags = async () => {
    try {
      store.dispatch(accountsActions.retrieveUserFlagsRequest());
      const res = await api.get(`/api/accounts/userflags`);
      store.dispatch(accountsActions.retrieveUserFlagsSuccess(res.data));
      return {result: true, data: res};
    } catch (err) {
      store.dispatch(accountsActions.retrieveUserFlagsFailure('Falha ao buscar as flags do usuário.'));
      return {result: false, data: err};
    }
  }