export const NOTIFICATION_ACTIVATE_REQUEST = 'NOTIFICATION_ACTIVATE_REQUEST';
export const NOTIFICATION_ACTIVATE_SUCCESS = 'NOTIFICATION_ACTIVATE_SUCCESS';
export const NOTIFICATION_ACTIVATE_FAILURE = 'NOTIFICATION_ACTIVATE_FAILURE';
export const NOTIFICATION_DEACTIVATE_REQUEST = 'NOTIFICATION_DEACTIVATE_REQUEST';
export const NOTIFICATION_DEACTIVATE_SUCCESS = 'NOTIFICATION_DEACTIVATE_SUCCESS';
export const NOTIFICATION_DEACTIVATE_FAILURE = 'NOTIFICATION_DEACTIVATE_FAILURE';
export const NOTIFICATION_IS_ACTIVE = 'NOTIFICATION_IS_ACTIVE';
export const RETRIEVE_USER_FLAGS_REQUEST = 'RETRIEVE_USER_FLAGS_REQUEST';
export const RETRIEVE_USER_FLAGS_SUCCESS = 'RETRIEVE_USER_FLAGS_SUCCESS';
export const RETRIEVE_USER_FLAGS_FAILURE = 'RETRIEVE_USER_FLAGS_FAILURE';


export const notificationActivateRequest = () => {
    return {
        type: NOTIFICATION_ACTIVATE_REQUEST,
    };
};

export const notificationActivateSuccess = () => {
    return {
        type: NOTIFICATION_ACTIVATE_SUCCESS,
    };
};

export const notificationActivateFailure = (message) => {
    return {
        type: NOTIFICATION_ACTIVATE_FAILURE,
        message
    };
};

export const notificationDeactivateRequest = () => {
    return {
        type: NOTIFICATION_DEACTIVATE_REQUEST,
    };
};

export const notificationDeactivateSuccess = () => {
    return {
        type: NOTIFICATION_DEACTIVATE_SUCCESS,
    };
};

export const notificationDeactivateFailure = (message) => {
    return {
        type: NOTIFICATION_DEACTIVATE_FAILURE,
        message
    };
};

export const notificationIsActive = (data) => {
    return {
        type: NOTIFICATION_IS_ACTIVE,
        data
    };
};

export const retrieveUserFlagsRequest = () => {
    return {
        type: RETRIEVE_USER_FLAGS_REQUEST,
    };
};

export const retrieveUserFlagsSuccess = (data) => {
    return {
        type: RETRIEVE_USER_FLAGS_SUCCESS,
        data
    };
};

export const retrieveUserFlagsFailure = (data) => {
    return {
        type: RETRIEVE_USER_FLAGS_FAILURE,
        data
    };
};

