import React, { useState } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';

import * as speechHttp from '../http/speech';

const useStyles = makeStyles({
  root: {
    minWidth: 275,
  },
  title: {
    fontSize: 14,
    fontWeight: "bold",
  },
  pos: {
    marginBottom: 12,
  },
});

export default function SpeechConfigTextReplacement(props) {
  const classes = useStyles();
  const [ editable, setEditable ] = useState(false);
  const [text, setText] = useState('');
  const [replacementText, setReplacementText] = useState('');
  const textreplacement = props.textreplacementcommand;

  const handleDelete = async (event) => {
    const deleted = await speechHttp.deleteTextReplacement(textreplacement.id);
    if (deleted) {
      speechHttp.getConfig();
    }
  }

  const enableEdit = async (event) => {
    setText(textreplacement.text);
    setReplacementText(textreplacement.replacement_text);
    setEditable(true);
  }

  const handleTextChange = (event) => {
    setText(event.target.value);
  };
  const handleReplacementTextChange = (event) => {
    setReplacementText(event.target.value);
  };

  const disableEdit = async (event) => {
    setEditable(false);
  }

  const submit = async (event) => {
    const created = await speechHttp.updateTextReplacement({
      id: textreplacement.id,
      text: text,
      replacement_text: replacementText,
    });
    if (created) {
      speechHttp.getConfig();
      disableEdit()
    } else {
      alert('Não foi possível adicionar a substituição.');
    }
  }

  if (editable) {
    return (
      <Card className={classes.root}>
      <CardContent>
        <form className={classes.root} noValidate autoComplete="off">
          <Grid container spacing={2}>
        	<Grid item xs={12}>
        	  <TextField
        	    className={classes.input}
        	    id="text"
                    label="Texto"
                    value={text}
                    onChange={handleTextChange}
        	    variant="outlined"
                  />
        	</Grid>

        	<Grid item xs={12}>
        	  <TextField
        	    className={classes.input}
                    id="replacement-text"
                    label="Substituição"
                    multiline
                    rows={4}
        	    value={replacementText}
        	    onChange={handleReplacementTextChange}
                    variant="outlined"
                  />
        	</Grid>
          </Grid>
        </form>
      </CardContent>
      <CardActions>
        <Button color="secondary" onClick={submit}>Salvar</Button>
        <Button size="small" color="secondary" onClick={disableEdit}>Cancelar</Button>
      </CardActions>
      </Card>
    );
  }

  return (
    <Card className={classes.root}>
      <CardContent>
        <Typography className={classes.title} color="textSecondary" gutterBottom>
	  {textreplacement.text}
        </Typography>
        <Typography variant="body2" component="p">
	  {textreplacement.replacement_text}
        </Typography>
      </CardContent>
      <CardActions>
        <Button color="secondary" onClick={enableEdit}>Editar</Button>
        <Button size="small" color="secondary" onClick={handleDelete}>Excluir</Button>
      </CardActions>
    </Card>
  );
}

