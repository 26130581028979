import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import withStyles from '@mui/styles/withStyles';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';

import * as speechHttp from '../http/speech';
import * as authHttp from '../http/auth';
import * as utils from '../utils';


const styles = theme => ({
  root: {
    minWidth: 275,
  },
  input: {
    width: "100%",
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
});

const mapStateToProps = (state) => {
  return {
    currentUser: state.auth.currentUser,
  };
};

function SpeechConfigTextReplacementForm(props) {
  const { classes, currentUser } = props;
  const [text, setText] = useState('');
  const [replacementText, setReplacementText] = useState('');

  useEffect(() => { authHttp.getCurrentUser(); }, []);

  const canEditTextReplacementCommand = utils.user_has_permission(currentUser, 'speechrecognition.add_textreplacementcommand') &&
                                        utils.user_has_permission(currentUser, 'speechrecognition.delete_textreplacementcommand') &&
                                        utils.user_has_permission(currentUser, 'speechrecognition.change_textreplacementcommand') &&
                                        utils.user_has_permission(currentUser, 'speechrecognition.view_textreplacementcommand');

  const handleTextChange = (event) => {
    setText(event.target.value);
  };
  const handleReplacementTextChange = (event) => {
    setReplacementText(event.target.value);
  };
  const clear = () => {
    setText('');
    setReplacementText('');
  }
  const submit = async (event) => {
    const created = await speechHttp.addTextReplacement({
        text: text,
	replacement_text: replacementText,
    });
    if (created) {
      clear();
      speechHttp.getConfig();
    } else {
      alert('Não foi possível adicionar a substituição.');
    }
  }

  return (
    <React.Fragment>
    {canEditTextReplacementCommand ?
      <Card className={classes.root}>
        <CardContent>
          <Typography className={classes.title} color="textPrimary" gutterBottom>
  	  Nova substituição
          </Typography>
      	<form className={classes.root} noValidate autoComplete="off">
          <Grid container spacing={2}>
  	  <Grid item xs={12}>
  	  <TextField
  	    className={classes.input}
  	    id="text"
              label="Texto"
              value={text}
              onChange={handleTextChange}
  	    variant="outlined"
            />
  	  </Grid>

  	  <Grid item xs={12}>
  	  <TextField
  	    className={classes.input}
              id="replacement-text"
              label="Substituição"
              multiline
              rows={4}
  	    value={replacementText}
  	    onChange={handleReplacementTextChange}
              variant="outlined"
            />
  	  </Grid>
  	</Grid>
  	</form>
        </CardContent>
        <CardActions>
          <Button size="small" variant="contained" color="primary" onClick={submit}>Adicionar</Button>
        </CardActions>
      </Card>
      : null }
    </React.Fragment>
  );
}

export default withStyles(styles)(connect(mapStateToProps)(SpeechConfigTextReplacementForm));