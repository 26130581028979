import React, { useEffect } from 'react'
import MicIcon from '@mui/icons-material/Mic';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import SpeechRecognition, { useSpeechRecognition } from 'react-speech-recognition'
import { useHotkeys } from 'react-hotkeys-hook';

const SpeechRecognitionPanel = (props) => {
    const transcribing = true;
    const clearTranscriptOnListen = true;
    const { handleInterimTranscript = null, handleFinalTranscript = null,
            handleTranscript = null} = props;
    const {
        transcript,
        interimTranscript,
        finalTranscript,
        resetTranscript,
        listening,
    } = useSpeechRecognition({ transcribing, clearTranscriptOnListen })

    useHotkeys('ctrl+.', (event) => {
      event.preventDefault();
      if (listening) {
        SpeechRecognition.stopListening();
      } else {
        SpeechRecognition.startListening({ language: 'pt-BR', continuous: true });
      }
    }, [listening]);

    useEffect(() => {
        if (interimTranscript !== '') {
            if (handleInterimTranscript != null) {
                handleInterimTranscript(interimTranscript, listening);
            }
        }
        if (finalTranscript !== '') {
            if (handleFinalTranscript != null) {
                handleFinalTranscript(finalTranscript, listening);
            }
            if (!listening) {
                resetTranscript();
            }
        }
        if (transcript !== '') {
            if (handleTranscript != null && listening) {
                handleTranscript(transcript, listening);
            }
        }
    }, [transcript, interimTranscript, finalTranscript, listening, handleInterimTranscript, handleFinalTranscript,
        handleTranscript, resetTranscript]);

    if (!SpeechRecognition.browserSupportsSpeechRecognition()) {
       return null
    }

    return (
        <div>
	  <Tooltip title={(!listening ? "Iniciar ditado" : "Finalizar ditado") + " (atalho: ctrl+.)"}>
            <IconButton
                aria-label={ listening ?
                                        "Desligar reconhecimento de voz" :
                                        "Ligar reconhecimento de voz" }
                color={listening ? "primary" : "default"}
                onClick={
                    listening ?
                        () => SpeechRecognition.stopListening() :
                        () => SpeechRecognition.startListening({ language: 'pt-BR', continuous: true })
                }
                size="large">
                <MicIcon />
            </IconButton>
	  </Tooltip>
        </div>
    );
}
export default SpeechRecognitionPanel
