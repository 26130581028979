import React from 'react';
import withStyles from '@mui/styles/withStyles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import * as utils from '../utils';

const styles = theme => ({
  root: {
    width: '100%',
  },
  table: {
    minWidth: 250,
  },
  tableHeader: {
    fontWeight: "bold",
  },
});

function MessageFinanceSummary(props) {
  const { classes, summary, totals, valueField } = props;


  return <TableContainer component={Paper}>
      <Table className={classes.table} size="small" aria-label="messages">
        <TableHead>
          <TableRow>
            <TableCell className={classes.tableHeader}>Método de envio</TableCell>
            <TableCell align="left" className={classes.tableHeader}>Número de envios</TableCell>
            <TableCell align="left" className={classes.tableHeader}>Total</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {Object.keys(summary).map((key) => (
            <TableRow key={key}>
              <TableCell>{key}</TableCell>
              <TableCell>{summary[key].count}</TableCell>
              <TableCell>{ utils.formatBillValue(summary[key][valueField]) }</TableCell>
            </TableRow>
      ))}
          <TableRow>
            <TableCell className={classes.tableHeader} align="left">Total</TableCell>
            <TableCell align="left">{ totals.count }</TableCell>
            <TableCell align="left">{ utils.formatBillValue(totals[valueField]) }</TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
}

export default withStyles(styles)(MessageFinanceSummary);
