import React, { useState, useCallback } from 'react';
import { api } from '../http/axios';
import withStyles from '@mui/styles/withStyles';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import { Button } from '@mui/material';
import Typography from '@mui/material/Typography';


const styles = theme => ({
  root: {
    display: 'flex',
  },
  toolbar: {
    paddingRight: 24, // keep right padding when drawer closed
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    minHeight: '100vh'
  },
  title: {
    flexGrow: 1,
    paddingLeft: 24,
  },
  submitButton: {
    margin: theme.spacing(1),
  },
});

const mapStateToProps = (state) => {
  return {
  };
};


const subjects = [
  {
    value: 'Sugestão',
    label: 'Sugestão',
  },
  {
    value: 'Crítica',
    label: 'Crítica',
  },
  {
    value: 'Solicitação',
    label: 'Solicitação',
  },
];

function Contact (props) {
  const { classes } = props;

  const [ name, setName ] = useState('');
  const [ company, setCompany ] = useState('');
  const [ phone, setPhone ] = useState('');
  const [ mail, setMail ] = useState('');
  const [ subject, setSubject ] = useState('');
  const [ content, setContent ] = useState('');
  const [ disableSubmitButton, setDisableSubmitButton ] = useState(false);

  let history = useHistory();

  const handleReturnButton = useCallback(async () => {
    history.push('/');
  }, [history]);

  const handleSubjectChange = (event) => {
    setSubject(event.target.value);
  };

  const handleSubmit = useCallback(async () => {
    const data = {
      name: name,
      company: company,
      phone: phone,
      mail: mail,
      subject: subject,
      content: content,
    };
    setDisableSubmitButton(true);
    try{
      await api.post('/api/landing/contact', data);
      alert("Mensagem enviada com sucesso")
    } catch (err) {
    alert("Não foi possível enviar a mensagem.");
  }
    setDisableSubmitButton(false);
    // Cleaning the form
    setName('');
    setCompany('');
    setPhone('');
    setMail('');
    setSubject('');
    setContent('');
  }, [name, company, phone, mail, subject, content]);


    return (
      <div className={classes.root}>
        <AppBar
          position="absolute"
        >
          <Toolbar className={classes.toolbar}>
            <Typography
              component="h1"
              variant="h6"
              color="inherit"
              noWrap
              className={classes.title}
            >
              Contato
            </Typography>
            <Button variant="contained" color="primary" disableElevation onClick={handleReturnButton}>
              VOLTAR
            </Button>
          </Toolbar>
        </AppBar>

        <main className={classes.content}>
          <div className={classes.appBarSpacer} />
            <Box
              component="form"
              sx={{
                '& .MuiTextField-root': { m: 1, width: '100ch' },
              }}
              noValidate
              autoComplete="off"
            >
              <div>
                <TextField
                  required
                  id="outlined-required-name"
                  label="Nome"
                  value={name}
                  onChange={e => setName(e.target.value)}
                />
                <TextField
                  id="outlined--company"
                  label="Empresa"
                  value={company}
                  onChange={e => setCompany(e.target.value)}
                />
                <TextField
                  required
                  id="outlined-phonenumber"
                  label="Telefone"
                  type="number"
                  helperText="Formato: (DDD) + telefone"
                  onChange={e => setPhone(e.target.value)}
                />
                <TextField
                  required
                  id="outlined-required-email"
                  label="Email"
                  value={mail}
                  onChange={e => setMail(e.target.value)}
                />
                <TextField
                  id="outlined-required-subject"
                  select
                  label="Assunto"
                  value={subject}
                  onChange={handleSubjectChange}
                >
                  {subjects.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
                <TextField
                  required
                  id="outlined-multiline-content"
                  label="Conteúdo"
                  multiline
                  rows={8}
                  value={content}
                  onChange={e => setContent(e.target.value)}
                />
              </div>
              <div>
                <Button
                 className={classes.submitButton}
                 type="submit"
                 variant="contained"
                 color="primary"
                 disabled={disableSubmitButton}
                 onClick={handleSubmit}>
                  Enviar
                </Button>
              </div>
            </Box>
        </main>
      </div>
    );
}

export default withStyles(styles)(connect(mapStateToProps)(Contact));