export const LOGIN_REQUEST = 'LOGIN_REQUEST';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAILURE = 'LOGIN_FAILURE';
export const LOGOUT_REQUEST = 'LOGOUT_REQUEST';
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';
export const LOGOUT_FAILURE = 'LOGOUT_FAILURE';
export const RESET_PASSWORD_REQUEST = 'RESET_PASSWORD_REQUEST';
export const RESET_PASSWORD_SUCCESS = 'RESET_PASSWORD_SUCCESS';
export const RESET_PASSWORD_FAILURE = 'RESET_PASSWORD_FAILURE';
export const CURRENT_USER_GET = 'CURRENT_USER_GET';
export const PRIVACY_POLICY_REQUIRED = 'PRIVACY_POLICY_REQUIRED';
export const PRIVACY_POLICY_GET = 'PRIVACY_POLICY_GET';
export const PRIVACY_POLICY_GET_SUCCESS = 'PRIVACY_POLICY_GET_SUCCESS';
export const PRIVACY_POLICY_GET_FAILURE = 'PRIVACY_POLICY_GET_FAILURE';
export const PRIVACY_POLICY_ACCEPT = 'PRIVACY_POLICY_ACCEPT';
export const PRIVACY_POLICY_ACCEPT_SUCCESS = 'PRIVACY_POLICY_ACCEPT_SUCCESS';
export const PRIVACY_POLICY_ACCEPT_FAILURE = 'PRIVACY_POLICY_ACCEPT_FAILURE';
export const TWO_FACTOR_AUTH_CREATE_DEVICE_REQUEST = 'TWO_FACTOR_AUTH_CREATE_DEVICE_REQUEST';
export const TWO_FACTOR_AUTH_CREATE_DEVICE_SUCCESS = 'TWO_FACTOR_AUTH_CREATE_DEVICE_SUCCESS';
export const TWO_FACTOR_AUTH_CREATE_DEVICE_FAILURE = 'TWO_FACTOR_AUTH_CREATE_DEVICE_FAILURE';
export const TWO_FACTOR_AUTH_DEACTIVATE_REQUEST = 'TWO_FACTOR_AUTH_DEACTIVATE_REQUEST';
export const TWO_FACTOR_AUTH_DEACTIVATE_SUCCESS = 'TWO_FACTOR_AUTH_DEACTIVATE_SUCCESS';
export const TWO_FACTOR_AUTH_DEACTIVATE_FAILURE = 'TWO_FACTOR_AUTH_DEACTIVATE_FAILURE';
export const TWO_FACTOR_AUTH_IS_ACTIVE = 'TWO_FACTOR_AUTH_IS_ACTIVE';
export const TWO_FACTOR_AUTH_VALIDATE_DEVICE_REQUEST = 'TWO_FACTOR_AUTH_VALIDATE_DEVICE_REQUEST';
export const TWO_FACTOR_AUTH_VALIDATE_DEVICE_SUCCESS = 'TWO_FACTOR_AUTH_VALIDATE_DEVICE_SUCCESS';
export const TWO_FACTOR_AUTH_VALIDATE_DEVICE_FAILURE = 'TWO_FACTOR_AUTH_VALIDATE_DEVICE_FAILURE';
export const TWO_FACTOR_AUTH_VALIDATE_DEVICE_REQUIRED = 'TWO_FACTOR_AUTH_VALIDATE_DEVICE_REQUIRED';
export const TWO_FACTOR_AUTH_VALIDATE_LOGIN_REQUEST = 'TWO_FACTOR_AUTH_VALIDATE_LOGIN_REQUEST';
export const TWO_FACTOR_AUTH_VALIDATE_LOGIN_SUCCESS = 'TWO_FACTOR_AUTH_VALIDATE_LOGIN_SUCCESS';
export const TWO_FACTOR_AUTH_VALIDATE_LOGIN_FAILURE = 'TWO_FACTOR_AUTH_VALIDATE_LOGIN_FAILURE';


export const requestLogin = (creds) => {
  return {
    type: LOGIN_REQUEST,
    isFetching: true,
    isAuthenticated: false,
    creds
  }
};

export const loginSuccess = (data) => {
  return {
    type: LOGIN_SUCCESS,
    isFetching: false,
    isAuthenticated: true,
    access_token: data.access_token,
    refresh_token: data.refresh_token
  }
};

export const loginError = (message) => {
  return {
    type: LOGIN_FAILURE,
    isFetching: false,
    isAuthenticated: false,
    message
  }
};

export const requestLogout = () => {
  return {
    type: LOGOUT_REQUEST,
    isFetching: true,
    isAuthenticated: true
  }
};

export const receiveLogout = () => {
  return {
    type: LOGOUT_SUCCESS,
    isFetching: false,
    isAuthenticated: false
  }
};

export const requestResetPassword = () => {
  return {
    type: RESET_PASSWORD_REQUEST,
    resetingPassword: true
  }
};

export const resetPasswordSuccess = () => {
  return {
    type: RESET_PASSWORD_SUCCESS,
    resetingPassword: false
  }
};

export const resetPasswordFailure = (message) => {
  return {
    type: RESET_PASSWORD_FAILURE,
    resetingPassword: false,
    message
  }
};

export const getCurrentUser = (data) => {
  return {
    type: CURRENT_USER_GET,
    data
  }
};

export const PrivacyPolicyRequired = () => {
  return {
    type: PRIVACY_POLICY_REQUIRED,
    hasPrivacyPolicy: false,
  }
};

export const getPrivacyPolicy = () => {
  return {
    type: PRIVACY_POLICY_GET
  }
};

export const getPrivacyPolicySuccess = (data) => {
  return {
    type: PRIVACY_POLICY_GET_SUCCESS,
    data
  }
};

export const getPrivacyPolicyError = (message) => {
  return {
    type: PRIVACY_POLICY_GET_FAILURE,
    message
  }
};

export const acceptPrivacyPolicy = () => {
  return {
    type: PRIVACY_POLICY_ACCEPT
  }
};

export const acceptPrivacyPolicySuccess = () => {
  return {
    type: PRIVACY_POLICY_ACCEPT_SUCCESS
  }
};

export const acceptPrivacyPolicyError = (message) => {
  return {
    type: PRIVACY_POLICY_ACCEPT_FAILURE,
    message
  }
};

export const twoFactorAuthCreateDeviceRequest = () => {
  return {
    type: TWO_FACTOR_AUTH_CREATE_DEVICE_REQUEST,
    creatingTwoFactorDevice: true,
    twoFactorSetupStatus: 'deactivated',
  }
}

export const twoFactorAuthCreateDeviceSuccess = () => {
  return {
    type: TWO_FACTOR_AUTH_CREATE_DEVICE_SUCCESS,
    creatingTwoFactorDevice: false,
    twoFactorSetupStatus: 'activating',
  }
}

export const twoFactorAuthCreateDeviceFailure = (message) => {
  return {
    type: TWO_FACTOR_AUTH_CREATE_DEVICE_FAILURE,
    creatingTwoFactorDevice: false,
    twoFactorSetupStatus: 'deactivated',
    message
  }
}

export const twoFactorAuthDeactivateRequest = () => {
  return {
    type: TWO_FACTOR_AUTH_DEACTIVATE_REQUEST,
    deactivatingTwoFactor: true,
  }
}

export const twoFactorAuthDeactivateSuccess = () => {
  return {
    type: TWO_FACTOR_AUTH_DEACTIVATE_SUCCESS,
    deactivatingTwoFactor: false,
    twoFactorSetupStatus: 'deactivated',
  }
}

export const twoFactorAuthDeactivateFailure = (message) => {
  return {
    type: TWO_FACTOR_AUTH_DEACTIVATE_FAILURE,
    deactivatingTwoFactor: false,
    message
  }
}

export const twoFactorAuthIsActive = (data) => {
  return {
    type: TWO_FACTOR_AUTH_IS_ACTIVE,
    data
  }
}

export const twoFactorAuthValidateDeviceRequest = () => {
  return {
    type: TWO_FACTOR_AUTH_VALIDATE_DEVICE_REQUEST,
    validatingTwoFactor: true,
  }
}

export const twoFactorAuthValidateDeviceSuccess = () => {
  return {
    type: TWO_FACTOR_AUTH_VALIDATE_DEVICE_SUCCESS,
    validatingTwoFactor: false,
    twoFactorSetupStatus: 'activated',
  }
}

export const twoFactorAuthValidateDeviceFailure = (message) => {
  return {
    type: TWO_FACTOR_AUTH_VALIDATE_DEVICE_FAILURE,
    validatingTwoFactor: false,
    message
  }
}

export const twoFactorAuthValidateDeviceRequired = () => {
  return {
    type: TWO_FACTOR_AUTH_VALIDATE_DEVICE_REQUIRED,
    isVerified: false,
  }
};

export const twoFactorAuthValidateLoginRequest = () => {
  return {
    type: TWO_FACTOR_AUTH_VALIDATE_LOGIN_REQUEST,
    validatingTwoFactor: true,
    isVerified: false,
  }
}

export const twoFactorAuthValidateLoginSuccess = () => {
  return {
    type: TWO_FACTOR_AUTH_VALIDATE_LOGIN_SUCCESS,
    validatingTwoFactor: false,
    isVerified: true,
  }
}

export const twoFactorAuthValidateLoginFailure = (message) => {
  return {
    type: TWO_FACTOR_AUTH_VALIDATE_LOGIN_FAILURE,
    validatingTwoFactor: false,
    isVerified: false,
    message
  }
}