import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import makeStyles from '@mui/styles/makeStyles';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import SpeechConfigTextReplacement from './SpeechConfigTextReplacement';
import SpeechConfigTextReplacementForm from './SpeechConfigTextReplacementForm';

import * as speechHttp from '../http/speech';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
}));

const mapStateToProps = (state) => {
  return {
    user: state.auth.currentUser,
    speechConfig: state.speech.config,
  };
};

function SpeechConfig(props) {
  const classes = useStyles();

   useEffect( () => {
      // Anything in here is fired on component mount.
      speechHttp.getGlobalConfig();
      speechHttp.getConfig();
   }, []);

  return (
    <div className={classes.root}>
	    <Typography variant='h5'>Substituições</Typography>
        <Grid container spacing={2}>
          {props.speechConfig.textReplacements.length > 0 ? props.speechConfig.textReplacements.map(c => (
	          <Grid item xs={12} key={c.text}>
              <SpeechConfigTextReplacement textreplacementcommand={c} />
            </Grid>
          ))
          :
            <Grid item xs={12}>
              <Typography>Nenhum comando de substituição encontrado.</Typography>
            </Grid>
          }
          <Grid item xs={12}>
            <SpeechConfigTextReplacementForm />
          </Grid>
        </Grid>
    </div>
  );
}

export default connect(mapStateToProps)(SpeechConfig);
