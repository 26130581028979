export const SUPEREXAMS_GET_REQUEST = 'SUPEREXAMS_GET_REQUEST';
export const SUPEREXAMS_GET_SUCCESS = 'SUPEREXAMS_GET_SUCCESS';
export const SUPEREXAMS_GET_FAILURE = 'SUPEREXAMS_GET_FAILURE';
export const SUPEREXAMS_ADD_EXAM_REQUEST = 'SUPEREXAMS_ADD_EXAM_REQUEST';
export const SUPEREXAMS_ADD_EXAM_SUCCESS = 'SUPEREXAMS_ADD_EXAM_SUCCESS';
export const SUPEREXAMS_ADD_EXAM_FAILURE = 'SUPEREXAMS_ADD_EXAM_FAILURE';
export const SUPEREXAMS_DOWNLOAD_IMAGES_REQUEST = 'SUPEREXAMS_DOWNLOAD_IMAGES_REQUEST';
export const SUPEREXAMS_DOWNLOAD_IMAGES_SUCCESS = 'SUPEREXAMS_DOWNLOAD_IMAGES_SUCCESS';
export const SUPEREXAMS_DOWNLOAD_IMAGES_FAILURE = 'SUPEREXAMS_DOWNLOAD_IMAGES_FAILURE';
export const SUPEREXAMS_DOWNLOAD_ACCESS_TOKEN_IMAGES_REQUEST = 'SUPEREXAMS_DOWNLOAD_ACCESS_TOKEN_IMAGES_REQUEST';
export const SUPEREXAMS_DOWNLOAD_ACCESS_TOKEN_IMAGES_SUCCESS = 'SUPEREXAMS_DOWNLOAD_ACCESS_TOKEN_IMAGES_SUCCESS';
export const SUPEREXAMS_DOWNLOAD_ACCESS_TOKEN_IMAGES_FAILURE = 'SUPEREXAMS_DOWNLOAD_ACCESS_TOKEN_IMAGES_FAILURE';
export const SUPEREXAMS_UPDATE_FIELDS_REQUEST = 'SUPEREXAMS_UPDATE_FIELDS_REQUEST';
export const SUPEREXAMS_UPDATE_FIELDS_SUCCESS = 'SUPEREXAMS_UPDATE_FIELDS_SUCCESS';
export const SUPEREXAMS_UPDATE_FIELDS_FAILURE = 'SUPEREXAMS_UPDATE_FIELDS_FAILURE';

export const getSuperExamRequest = (data) => {
  return {
    type: SUPEREXAMS_GET_REQUEST,
    data
  };
}

export const getSuperExamSuccess = (data) => {
  return {
    type: SUPEREXAMS_GET_SUCCESS,
    data
  };
}

export const getSuperExamFailure = (data) => {
  return {
    type: SUPEREXAMS_GET_FAILURE,
    data
  };
}

export const addExamRequest = (data) => {
  return {
    type: SUPEREXAMS_ADD_EXAM_REQUEST,
    data
  };
}

export const addExamSuccess = (data) => {
  return {
    type: SUPEREXAMS_ADD_EXAM_SUCCESS,
    data
  };
}

export const addExamFailure = (data) => {
  return {
    type: SUPEREXAMS_ADD_EXAM_FAILURE,
    data
  };
}

export const downloadExamImagesRequest = (data) => {
  return {
    type: SUPEREXAMS_DOWNLOAD_IMAGES_REQUEST,
    data
  };
}

export const downloadExamImagesSuccess = (data) => {
  return {
    type: SUPEREXAMS_DOWNLOAD_IMAGES_SUCCESS,
    data
  };
}

export const downloadExamImagesFailure = (data) => {
  return {
    type: SUPEREXAMS_DOWNLOAD_IMAGES_FAILURE,
    data
  };
}

export const downloadAccessTokenExamImagesRequest = (data) => {
  return {
    type: SUPEREXAMS_DOWNLOAD_ACCESS_TOKEN_IMAGES_REQUEST,
    data
  };
}

export const downloadAccessTokenExamImagesSuccess = (data) => {
  return {
    type: SUPEREXAMS_DOWNLOAD_ACCESS_TOKEN_IMAGES_SUCCESS,
    data
  };
}

export const downloadAccessTokenExamImagesFailure = (data) => {
  return {
    type: SUPEREXAMS_DOWNLOAD_ACCESS_TOKEN_IMAGES_FAILURE,
    data
  };
}

export const updateSuperExamFieldsRequest = () => {
  return {
    type: SUPEREXAMS_UPDATE_FIELDS_REQUEST
  };
}

export const updateSuperExamFieldsSuccess = () => {
  return {
    type: SUPEREXAMS_UPDATE_FIELDS_SUCCESS,
  };
}

export const updateSuperExamFieldsFailure = (data) => {
  return {
    type: SUPEREXAMS_UPDATE_FIELDS_FAILURE,
    data
  };
}
