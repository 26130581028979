import React, { useState, useEffect, useCallback } from 'react';
import withStyles from '@mui/styles/withStyles';
import { connect } from 'react-redux';

import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';

import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import classNames from 'classnames';
import { Button } from '@mui/material';
import Typography from '@mui/material/Typography';


import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';

import * as authHttp from '../http/auth';
import Loading from '../components/Loading';
import LogoutButton from '../components/LogoutButton'


const drawerWidth = 0;

const styles = theme => ({
  root: {
    display: 'flex',
  },
  toolbar: {
    paddingRight: 24, // keep right padding when drawer closed
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    minHeight: '100vh'
  },
  title: {
    flexGrow: 1,
    paddingLeft: 24,
  },
  paper: {
    padding: theme.spacing(2),
    maxHeight: "70vh",
    overflow: 'auto',
  },
});

const mapStateToProps = (state) => {
  return {
    user: state.auth.currentUser,
    privacyPolicyContentHtml: state.auth.privacyPolicyContentHtml,
    fetchingPolicy: state.auth.fetchingPolicy
  };
};


function PrivacyPolicy (props) {
  const { classes, privacyPolicyContentHtml, fetchingPolicy } = props;

  const [ checked, setChecked ] = useState(false);
  const [ disabledScroll, setDisabledScroll ] = useState(true);
  const [ disableAcceptance, setDisableAcceptance ] = useState(true);

  useEffect(() => {
    authHttp.getPrivacyPolicy();
  }, []);

  const handleSubmit = useCallback(async () => {
    if (checked) {
      const data = {
        acceptance: checked,
      };
      setDisableAcceptance(true);
      await authHttp.acceptPrivacyPolicy(data);
      setDisableAcceptance(false);
    } else {
      setDisableAcceptance(false);
      alert("Necessário aceitar os termos da Política de Privacidade para prosseguir.");
    }
  }, [checked]);

  const handleScroll = useCallback(async (event) => {
    const target = event.target;

      // Add this margin of error
    if (target.scrollHeight - target.scrollTop - 5 <= target.clientHeight) {
      setDisabledScroll(false);
      setDisableAcceptance(false);
    }
  }, []);

    return (
      <div className={classes.root}>
        <AppBar
          position="absolute"
          className={classNames(classes.appBar && classes.appBarShift)}
        >
          <Toolbar className={classes.toolbar}>
            <Typography
              component="h1"
              variant="h6"
              color="inherit"
              noWrap
              className={classes.title}
            >
              Termos da Política de Privacidade
            </Typography>
            <LogoutButton/>
          </Toolbar>
        </AppBar>

        <main className={classes.content}>
          <div className={classes.appBarSpacer} />
          {
            fetchingPolicy
              ?
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <Grid container justifyContent="center">
                    <Loading />
                  </Grid>
                </Grid>
              </Grid>
              :
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <Grid container justifyContent="center">
                    <Paper className={classes.paper} dangerouslySetInnerHTML={{__html: privacyPolicyContentHtml}} onScroll={handleScroll}></Paper>
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <Grid container justifyContent="center">
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={checked}
                          onChange={(event) => setChecked( checked ? false : true)}
                          name="checked"
                          color="primary"
                          disabled={disabledScroll}
                        />
                      }
                      label="Li e aceito os termos da política de privacidade"
                    />
                    <Button
                      type="submit"
                      variant="contained"
                      color="primary"
                      disabled={disableAcceptance}
                      onClick={handleSubmit}
                    >
                    Aceitar
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
          }
        </main>
      </div>
    );
}

export default withStyles(styles)(connect(mapStateToProps)(PrivacyPolicy));
