import React from 'react';
import { withRouter } from 'react-router-dom';
import withStyles from '@mui/styles/withStyles';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import * as utils from '../utils';

const styles = theme => ({
  root: {
    width: '100%',
  },
  table: {
    minWidth: 650,
  },
  tableHeader: {
    fontWeight: "bold",
  },
});

function ExamActionsMenu(props) {
  const { exam, user } = props;
  const [anchorEl, setAnchorEl] = React.useState(null);

  if (exam == null) {
    return null;
  }

  var menuItems = [];
  if (utils.user_has_permission(user, 'exams.add_subexam') || utils.user_has_permission(user, 'exams.delete_subexam')) {
    menuItems.push((
        <MenuItem key="manage-subexams"
          onClick={(event) => {
            event.stopPropagation();
            props.history.push(`/exams/${exam.exam_id}/subexams`)
          }} >
          Gerenciar Subexames
        </MenuItem>
    ))
  }

  if (utils.user_has_permission(user, 'exams.change_exam')) {
    menuItems.push((
        <MenuItem key="edit-exam-fields"
          onClick={(event) => {
            event.stopPropagation();
            props.history.push(`/exams/${exam.exam_id}/edit`)
          }} >
          Editar Exame
        </MenuItem>
    ))
  }

  if (menuItems.length === 0) {
    return null;
  }

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return <React.Fragment>
    <Button variant="contained" color="primary" aria-controls="options-menu" aria-haspopup="true" onClick={handleClick} startIcon={<ExpandMoreIcon />}>
      Opções
    </Button>
    <Menu
      id="options-menu"
      anchorEl={anchorEl}
      getContentAnchorEl={null}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
      keepMounted
      open={Boolean(anchorEl)}
      onClose={handleClose}
    >
      {menuItems.map((m) => (m))}
    </Menu>
  </React.Fragment>
}

export default withRouter(withStyles(styles)(ExamActionsMenu));
