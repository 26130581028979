import React, { Component } from 'react';
import { connect } from 'react-redux';
import withStyles from '@mui/styles/withStyles';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Accordion  from '@mui/material/Accordion';
import AccordionSummary  from '@mui/material/AccordionSummary';
import AccordionDetails  from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExamDetailsTable from './ExamDetailsTable.js';
import ExamReportEditor from './ExamReportEditor.js';
import ExamImageUploader from './ExamImageUploader.js';
import PanelTitle from './PanelTitle.js';
import Typography from '@mui/material/Typography';
import * as authHttp from '../http/auth';
import * as reportsHttp from '../http/reports';
import * as examsHttp from '../http/exams';
import * as clinicsHttp from '../http/clinics';
import * as utils from '../utils';

import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

const styles = theme => ({
  content: {
    display: 'flex',
    height: '100%',
    width: '100%',
    flexFlow: 'row',
    flex: '0 1 auto',
    padding: '10px',
    overflow: 'hidden'
  },
  flex: {
    flex: 1,
  },
  wrapper: {
    border: '1px solid #696',
    padding: '5px',
    width: '21cm',
    height: '27.1cm',
    display: 'flex',
    flexDirection: 'column'
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  fab: {
    margin: theme.spacing(1),
    zIndex: 1
  }
});

const mapStateToProps = (state) => {
  var clinic = null;
  if (state.exams.current !== null && state.clinics.clinics_map !== undefined) {
    clinic = state.clinics.clinics_map[state.exams.current.clinic_id];
  }
  return {
    exam: state.exams.current,
    reportsForExam: state.reports.currentReportsForExam,
    user: state.auth.currentUser,
    clinic: clinic
  };
};

const ExpandableItem = (props) => {
  return (
    <Accordion defaultExpanded={ props.expanded }>
        <AccordionSummary style={{ marginBottom: '-5px'}}
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <PanelTitle>{props.title}</PanelTitle>
        </AccordionSummary>
        <AccordionDetails>
          {props.children}
        </AccordionDetails>
      </Accordion>
  )
}

class ExamDetails extends Component {
  constructor(props) {
    super(props);

    this.state = {
        exam: null,
        reportsForExam: [],
        user: null,
        clinic: null,
    };
  }

  componentWillMount = () => {
    authHttp.getCurrentUser();
    examsHttp.get(this.props.match.params.id);
    reportsHttp.listClinicReportsForExam(this.props.match.params.id);
    clinicsHttp.list();
  }

  showReport = (examStatus, user) => {
    const addMedicalReport = utils.user_has_permission(user, "exams.add_medicalreport");
    if (addMedicalReport) {
      return true;
    }

    const viewMedicalreport = utils.user_has_permission(user, "exams.view_medicalreport");
    const viewMedicalReportInProgress = utils.user_has_permission(user, "exams.view_medicalreport_in_progress");

    if (examStatus === 'liberado') {
      return (viewMedicalreport || viewMedicalReportInProgress);
    } else if (examStatus === 'digitado') {
      return viewMedicalReportInProgress;
    }
    return false;
  }

  render() {
    const { classes, clinic, exam, reportsForExam, user } = this.props;

    if (exam === null || exam === undefined) {
      return null;
    }

    return (
      <Container maxWidth="xl" className={classes.container}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={12} lg={12}>
            <ExamDetailsTable exam={ exam } clinic={clinic}/>
          </Grid>
          {this.showReport(exam.status, user) ? (
          <React.Fragment>
            <Grid item xs={12}>
              <ExamReportEditor exam={ exam } reports={ reportsForExam } creating={ true } />
            </Grid>

            {utils.user_has_permission(user, "exams.add_subexamimage") ? (
            <Grid item xs={12}>
              <ExpandableItem title="Imagens"><ExamImageUploader exam={ exam }/></ExpandableItem>
            </Grid>
            ) : (<div/>)
            }
          </React.Fragment>
          ) : (
            <Grid item xs={12} margin={10}>
              <Typography variant="h3" align="center">
                Laudo não disponível
              </Typography>
            </Grid>
          )}
        </Grid>
      </Container>
    )
  }
}

export default withStyles(styles)(connect(mapStateToProps)(ExamDetails));
