
import {
  NOTIFICATION_ACTIVATE_REQUEST, NOTIFICATION_ACTIVATE_SUCCESS, NOTIFICATION_ACTIVATE_FAILURE, NOTIFICATION_DEACTIVATE_REQUEST,
  NOTIFICATION_DEACTIVATE_SUCCESS, NOTIFICATION_DEACTIVATE_FAILURE, NOTIFICATION_IS_ACTIVE, RETRIEVE_USER_FLAGS_REQUEST,
  RETRIEVE_USER_FLAGS_SUCCESS, RETRIEVE_USER_FLAGS_FAILURE
} from "../actions/accounts";

import * as utils from '../utils';

const initialState = {
  receive_exam_association_email: false,
  userFlags: [],

  retrievingUserFlags: false,
  retrievingUserFlagsError: null,
};

  const notification = (state = initialState, action) => {

    switch (action.type) {
      case NOTIFICATION_ACTIVATE_REQUEST:
        return {
          ...state,
        };

      case NOTIFICATION_ACTIVATE_SUCCESS:
        return {
          ...state,
          receive_exam_association_email: true,
        };

      case NOTIFICATION_ACTIVATE_FAILURE:
        return {
          ...state,
          receive_exam_association_email: false,
          errorMessage: action.message
        };
      case NOTIFICATION_DEACTIVATE_REQUEST:
        return {
          ...state,
        };
      case NOTIFICATION_DEACTIVATE_SUCCESS:
        return {
          ...state,
          receive_exam_association_email: false,
        };
      case NOTIFICATION_DEACTIVATE_FAILURE:
        return {
          ...state,
          receive_exam_association_email: true,
          errorMessage: action.message
        };
      case NOTIFICATION_IS_ACTIVE:
        return {
          ...state,
          receive_exam_association_email: action.data.receive_exam_association_email
        };
      case RETRIEVE_USER_FLAGS_REQUEST:
        return {
          ...state,
          retrievingUserFlags: true,
        }
      case RETRIEVE_USER_FLAGS_SUCCESS:
        return {
          ...state,
          userFlags: action.data,
          retrievingUserFlags: false,
        }
      case RETRIEVE_USER_FLAGS_FAILURE:
        return {
          ...state,
          retrievingUserFlags: false,
          retrievingUserFlagsError: parseRetrievingUserFlagsError(action.data)
        }
      default:
        return state;
    }
  };

function parseRetrievingUserFlagsError(data) {
    return utils.getErrorMessageOrDefaultMessage(data);
}


  export default notification;
