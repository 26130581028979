import * as utils from '../utils';
import {
  BILLING_FINANCE_MINE_GET,
  BILLING_FINANCE_MINE_GET_SUCCESS,
  BILLING_FINANCE_MINE_GET_FAILURE,
  BILLING_FINANCE_CLINIC_GET,
  BILLING_FINANCE_CLINIC_GET_SUCCESS,
  BILLING_FINANCE_CLINIC_GET_FAILURE,
  BILLING_FINANCE_MESSAGE_GET,
  BILLING_FINANCE_MESSAGE_GET_SUCCESS,
  BILLING_FINANCE_MESSAGE_GET_FAILURE,
  BILLING_FINANCE_GENERAL_GET,
  BILLING_FINANCE_GENERAL_GET_SUCCESS,
  BILLING_FINANCE_GENERAL_GET_FAILURE,
  BILLING_FINANCE_BILLING_GROUP_GET,
  BILLING_FINANCE_BILLING_GROUP_GET_SUCCESS,
  BILLING_FINANCE_BILLING_GROUP_GET_FAILURE,
  BILLING_CLEAR_CURRENT,
} from '../actions/billing';

const initialState = {
  my: {
    loading: false,
    requestId: "",
    requestError: null,
    entries: [],
    summary: {
      perModality: {
      },
      perClinic: {
      },
      perCategory: {
      },
      medic_value: 0,
      typer_value: 0,
      count: 0,
    },
  },
  clinic: {
    loading: false,
    requestId: "",
    requestError: null,
    entries: [],
    summary: {
      perModality: {
      },
      perClinic: {
      },
      perCategory: {
      },
      taxesConstants: {
      },
      taxesValues: {
      },
      taxTotal: 0,
      finalValue: 0,
      value: 0,
      count: 0,
    },
  },
  message: {
    loading: false,
    requestId: "",
    requestError: null,
    entries: [],
    summary: {
      perMethod: {
      },
      value: 0,
      count: 0,
    },
  },
  general: {
    loading: false,
    requestId: "",
    requestError: null,
    entries: [],
    summary: {
      perModality: {
      },
      perClinic: {
      },
      perCategory: {
      },
      perMedic: {
      },
      perTyper: {
      },
      taxesConstants: {
      },
      taxesValues: {
      },
      taxTotal: 0,
      finalValue: 0,
      imagi_value: 0,
      medic_value: 0,
      typer_value: 0,
      value: 0,
      count: 0,
    },
  },
  billinggroup: {
    loading: false,
    requestId: "",
    requestError: null,
    entries: [],
    summary: {
      perClinic: {
      },
      perGroup: {
      },
      perMember: {
      }
    },
  },
};

const billing = (state = initialState, action) => {
  switch (action.type) {
    case BILLING_FINANCE_MINE_GET:
      return {
        ...state,
        my: {
          ...state.my,
          loading: true,
          requestId: action.data.requestId,
        }
      };
    case BILLING_FINANCE_MINE_GET_SUCCESS:
      if (state.my.requestId !== action.data.requestId) {
        return {
          ...state,
        };
      }
      var data = processData(action.data.data);

      return {
        ...state,
        my: {
          ...state.my,
          entries: data,
          summary: buildSummary(data),
          loading: false,
          requestError: null,
        }
      };
    case BILLING_FINANCE_MINE_GET_FAILURE:
      return {
        ...state,
        my: {
          ...state.my,
          entries: [],
          loading: false,
          requestError: action.err,
        }
      };
    case BILLING_FINANCE_CLINIC_GET:
      return {
        ...state,
        clinic: {
          ...state.clinic,
          loading: true,
          requestId: action.data.requestId,
        }
      };
    case BILLING_FINANCE_CLINIC_GET_SUCCESS:
      if (state.clinic.requestId !== action.data.requestId) {
        return {
          ...state,
        };
      }
      data = processData(action.data.data);

      return {
        ...state,
        clinic: {
          ...state.clinic,
          entries: data,
          summary: buildClinicSummary(data),
          loading: false,
          requestError: null,
        }
      };
    case BILLING_FINANCE_CLINIC_GET_FAILURE:
      return {
        ...state,
        clinic: {
          ...state.clinic,
          entries: [],
          loading: false,
          requestError: action.err,
        }
      };
    case BILLING_FINANCE_MESSAGE_GET:
      return {
        ...state,
        message: {
          ...state.message,
          loading: true,
          requestId: action.data.requestId,
        }
      };
    case BILLING_FINANCE_MESSAGE_GET_SUCCESS:
      if (state.message.requestId !== action.data.requestId) {
        return {
          ...state,
        };
      }
      data = messageProcessData(action.data.data);

      return {
        ...state,
        message: {
          ...state.message,
          entries: data,
          summary: buildMessageSummary(data),
          loading: false,
          requestError: null,
        }
      };
    case BILLING_FINANCE_MESSAGE_GET_FAILURE:
      return {
        ...state,
        message: {
          ...state.message,
          entries: [],
          loading: false,
          requestError: action.err,
        }
      };
    case BILLING_FINANCE_GENERAL_GET:
      return {
        ...state,
        general: {
          ...state.general,
          loading: true,
          requestId: action.data.requestId,
        }
      };
    case BILLING_FINANCE_GENERAL_GET_SUCCESS:
      if (state.general.requestId !== action.data.requestId) {
        return {
          ...state,
        };
      }
      data = processData(action.data.data);

      return {
        ...state,
        general: {
          ...state.general,
          entries: data,
          summary: buildGeneralSummary(data),
          loading: false,
          requestError: null,
        }
      };
    case BILLING_FINANCE_GENERAL_GET_FAILURE:
      return {
        ...state,
        general: {
          ...state.general,
          entries: [],
          loading: false,
          requestError: action.err,
        }
      };
    case BILLING_FINANCE_BILLING_GROUP_GET:
      return {
        ...state,
        billinggroup: {
          ...state.billinggroup,
          loading: true,
          requestId: action.data.requestId,
        }
      };
    case BILLING_FINANCE_BILLING_GROUP_GET_SUCCESS:
      if (state.billinggroup.requestId !== action.data.requestId) {
        return {
          ...state,
        };
      }
      var data = processData(action.data.data);

      return {
        ...state,
        billinggroup: {
          ...state.billinggroup,
          entries: data,
          summary: buildBillingGroupSummary(data),
          loading: false,
          requestError: null,
        }
      };
    case BILLING_FINANCE_BILLING_GROUP_GET_FAILURE:
      return {
        ...state,
        billinggroup: {
          ...state.billinggroup,
          entries: [],
          loading: false,
          requestError: action.err,
        }
      };
    case BILLING_CLEAR_CURRENT:
      return {
        ...state,
        my: {
          loading: false,
          requestId: "",
          requestError: null,
          entries: [],
          summary: {
            perModality: {
            },
            perClinic: {
            },
            perCategory: {
            },
            medic_value: 0,
            typer_value: 0,
            count: 0,
          },
        },
        clinic: {
          loading: false,
          requestId: "",
          requestError: null,
          entries: [],
          summary: {
            perModality: {
            },
            perClinic: {
            },
            perCategory: {
            },
            taxesConstants: {
            },
            taxesValues: {
            },
            taxTotal: 0,
            finalValue: 0,
            value: 0,
            count: 0,
          },
        },
        message: {
          loading: false,
          requestId: "",
          requestError: null,
          entries: [],
          summary: {
            perMethod: {
            },
            value: 0,
            count: 0,
          },
        },
        general: {
          loading: false,
          requestId: "",
          requestError: null,
          entries: [],
          summary: {
            perModality: {
            },
            perClinic: {
            },
            perCategory: {
            },
            perMedic: {
            },
            perTyper: {
            },
            taxesConstants: {
            },
            taxesValues: {
            },
            taxTotal: 0,
            finalValue: 0,
            imagi_value: 0,
            medic_value: 0,
            typer_value: 0,
            value: 0,
            count: 0,
          },
        },
        billinggroup: {
          loading: false,
          requestId: "",
          requestError: null,
          entries: [],
          summary: {
            perClinic: {
            },
            perGroup: {
            },
            perMember: {        
            }
          },
        },
      };
    default:
      return state;
  }
};

function convertValue(valueAsString) {
  if (valueAsString === undefined || valueAsString == null) {
    return 0;
  }
  if ((valueAsString[valueAsString.length - 3] !== '.') && (valueAsString[valueAsString.length - 5] !== '.')) {
    console.log("Malformed monetary value: ", valueAsString);
    return null;
  }
  var cents = valueAsString.substring(valueAsString.length - 2);
  var reais = valueAsString.substring(0, valueAsString.length - 3);

  return Number(reais) * 100 + Number(cents);
}

function processData(entries) {
  entries.forEach((e) => {
    e.medic_value = convertValue(e.medic_value);
    e.typer_value = convertValue(e.typer_value);
    e.imagi_value = convertValue(e.imagi_value);
    e.finalValue = convertValue(e.finalValue);
    e.taxTotal = convertValue(e.taxTotal);
    e.value = convertValue(e.value);
  });
  return entries;
}

function messageProcessData(entries) {
  entries.forEach((e) => {
    e.value = convertValue(e.unit_value);
  });
  return entries;
}

function addToCategory(perCategory, entry) {
  var cat = entry.category;
  if (!(cat in perCategory)) {
    perCategory[cat] = {
      medic_value: 0,
      typer_value: 0,
      count: 0,
    };
  }

  perCategory[cat].medic_value += entry.medic_value;
  perCategory[cat].typer_value += entry.typer_value;
  perCategory[cat].count += entry.multiplier;
}

function clinicAddToCategory(perCategory, entry) {
  var cat = entry.category;
  if (!(cat in perCategory)) {
    perCategory[cat] = {
      value: 0,
      count: 0,
    };
  }

  perCategory[cat].value += entry.value;
  perCategory[cat].count += entry.multiplier;
}

function addToModality(perModality, entry) {
  var mod = entry.modality;
  if (!(mod in perModality)) {
    perModality[mod] = {
      medic_value: 0,
      typer_value: 0,
      value: 0,
      count: 0,
      perCategory: {}
    };
  }

  addToCategory(perModality[mod].perCategory, entry);
  perModality[mod].medic_value += entry.medic_value;
  perModality[mod].typer_value += entry.typer_value;
  perModality[mod].value += entry.value;
  perModality[mod].count += entry.multiplier;
}

function clinicAddToModality(perModality, entry) {
  var mod = entry.modality;
  if (!(mod in perModality)) {
    perModality[mod] = {
      value: 0,
      count: 0,
      perCategory: {}
    };
  }

  clinicAddToCategory(perModality[mod].perCategory, entry);
  perModality[mod].value += entry.value;
  perModality[mod].count += entry.multiplier;
}

function addToClinic(perClinic, entry) {
  var clinic = entry.clinic;
  if (!(clinic in perClinic)) {
    perClinic[clinic] = {
      medic_value: 0,
      typer_value: 0,
      value: 0,
      count: 0,
      perModality: {},
      perCategory: {}
    };
  }

  perClinic[clinic].medic_value += entry.medic_value;
  perClinic[clinic].value += entry.value;
  perClinic[clinic].count += entry.multiplier;

  addToModality(perClinic[clinic].perModality, entry);
  addToCategory(perClinic[clinic].perCategory, entry);
}

function generalAddToClinic(perClinic, entry) {
  var clinic = entry.clinic;
  if (!(clinic in perClinic)) {
    perClinic[clinic] = {
      medic_value: 0,
      typer_value: 0,
      value: 0,
      count: 0,
      perModality: {},
      perCategory: {},
      perMedic: {},
      perTyper: {}
    };
  }

  perClinic[clinic].medic_value += entry.medic_value;
  perClinic[clinic].typer_value += entry.typer_value;
  perClinic[clinic].value += entry.value;
  perClinic[clinic].count += entry.multiplier;

  addToModality(perClinic[clinic].perModality, entry);
  addToCategory(perClinic[clinic].perCategory, entry);
  generalAddToMedic(perClinic[clinic].perMedic, entry);
  generalAddToTyper(perClinic[clinic].perTyper, entry);
}

function clinicAddToClinic(perClinic, entry) {
  var clinic = entry.clinic;
  if (!(clinic in perClinic)) {
    perClinic[clinic] = {
      medic_value: 0,
      typer_value: 0,
      value: 0,
      count: 0,
      perModality: {},
      perCategory: {}
    };
  }

  perClinic[clinic].value += entry.value;
  perClinic[clinic].count += entry.multiplier;

  clinicAddToModality(perClinic[clinic].perModality, entry);
  clinicAddToCategory(perClinic[clinic].perCategory, entry);
}

function messageAddToMethod(perMethod, entry) {
  var method = entry.delivery_method;
  if (!(method in perMethod)) {
    perMethod[method] = {
      value: 0,
      count: 0,
    }
  }

  perMethod[method].value += entry.value;
  perMethod[method].count += 1;
}


function buildSummary(entries) {
  var summary = {
    perModality: {
    },
    perClinic: {
    },
    perCategory: {
    },
    medic_value: 0,
    typer_value: 0,
    value: 0,
    count: 0,
  };

  for (var e in entries) {
    addToModality(summary.perModality, entries[e]);
    addToClinic(summary.perClinic, entries[e]);
    addToCategory(summary.perCategory, entries[e]);

    summary.medic_value += entries[e].medic_value;
    summary.typer_value += entries[e].typer_value;
    summary.value += entries[e].value;
  }
  summary.count = entries.length;
  return summary
}

function buildClinicSummary(entries) {
  var summary = {
    perModality: {
    },
    perClinic: {
    },
    perCategory: {
    },
    taxesConstants: {
    },
    taxesValues: {
    },
    taxTotal: 0,
    finalValue: 0,
    value: 0,
    count: 0,
  };

  for (var e in entries) {
    clinicAddToModality(summary.perModality, entries[e]);
    clinicAddToClinic(summary.perClinic, entries[e]);
    clinicAddToCategory(summary.perCategory, entries[e]);

    summary.value += entries[e].value;
    summary.taxesConstants = entries[e].taxes_constants;
    summary.count += entries[e].multiplier
  }

  for (let tax in summary.taxesConstants) {
    const taxValueNumber = parseFloat(summary.taxesConstants[tax]);
    const totalValueNumber = parseFloat(summary.value);
    var taxValue = taxValueNumber * totalValueNumber;
    summary.taxesValues[tax] = taxValue;
    summary.taxTotal += taxValue;
  }

  summary.finalValue = summary.value - summary.taxTotal;
  return summary
}

function buildMessageSummary(entries) {
  var summary = {
    perMethod: {
    },
    value: 0,
    count: 0.
  }

  for (var e in entries) {
    messageAddToMethod(summary.perMethod, entries[e]);

    summary.value += entries[e].value;
  }

  summary.count = entries.length;
  return summary
}

function generalAddToCategory(perCategory, entry) {
  var category = entry.category;
  if (!(category in perCategory)) {
    perCategory[category] = {
      value: 0,
      count: 0,
    }
  }

  perCategory[category].value += entry.value;
  perCategory[category].count += entry.multiplier;
}

function generalAddToMedic(perMedic, entry) {
  var medicFullName = '';
  if (!entry.medic.first_name || !entry.medic.last_name) {
    medicFullName = entry.medic.username;
  } else {
    medicFullName = utils.userFullName(entry.medic);
  }
  var medic = entry.medic.username;
  if (!(medic in perMedic)) {
    perMedic[medic] = {
      medic_value: 0,
      medic_name: medicFullName,
      count: 0,
      perCategory: {}
    }
  }

  addToCategory(perMedic[medic].perCategory, entry);
  perMedic[medic].medic_value += entry.medic_value;
  perMedic[medic].count += entry.multiplier;
}

function generalAddToTyper(perTyper, entry) {
  var typerFullName = '';
  if (!entry.typer) return
  if (!entry.typer.first_name || !entry.typer.last_name) {
    var typerFullName = entry.typer.username;
  } else {
    typerFullName = utils.userFullName(entry.typer);
  }
  var typer = entry.typer.username;
  if (!(typer in perTyper)) {
    perTyper[typer] = {
      typer_value: 0,
      typer_name: typerFullName,
      count: 0,
      perCategory: {}
    }
  }

  addToCategory(perTyper[typer].perCategory, entry);
  perTyper[typer].typer_value += entry.typer_value;
  perTyper[typer].count += entry.multiplier;
}

function buildGeneralSummary(entries) {
  var summary = {
    perModality: {
    },
    perClinic: {
    },
    perCategory: {
    },
    perMedic: {
    },
    perTyper: {
    },
    taxesConstants: {
    },
    taxesValues: {
    },
    taxTotal: 0,
    finalValue: 0,
    imagi_value: 0,
    medic_value: 0,
    typer_value: 0,
    value: 0,
    count: 0,
  };

  for (var e in entries) {
    generalAddToTyper(summary.perTyper, entries[e]);
    generalAddToCategory(summary.perCategory, entries[e]);
    generalAddToMedic(summary.perMedic, entries[e]);
    addToModality(summary.perModality, entries[e]);
    generalAddToClinic(summary.perClinic, entries[e]);

    summary.medic_value += entries[e].medic_value;
    summary.typer_value += entries[e].typer_value;
    summary.imagi_value += entries[e].imagi_value;
    summary.taxesConstants = entries[e].taxes_constants;
    summary.value += entries[e].value;
    summary.count += entries[e].multiplier
  }

  for (let tax in summary.taxesConstants) {
    const taxValueNumber = parseFloat(summary.taxesConstants[tax]);
    const totalValueNumber = parseFloat(summary.value);
    var taxValue = taxValueNumber * totalValueNumber;
    summary.taxesValues[tax] = taxValue;
    summary.taxTotal += taxValue;
  }

  summary.finalValue = (summary.value + summary.imagi_value + summary.medic_value + summary.typer_value) - summary.taxTotal;

  return summary
}

function billingGroupAddToClinic(perClinic, entry) {
  var clinic = entry.clinic;
  if (!(clinic in perClinic)) {
    perClinic[clinic] = {
      perGroup: {
      },
    }
  }

  billingGroupAddToGroup(perClinic[clinic].perGroup, entry);
}

function billingGroupAddToGroup(perGroup, entry) {
  var group_name = entry.name;
  if (!(group_name in perGroup)) {
    perGroup[group_name] = {
      perMember: {},
      valeu: 0,
  }
}

  billingGroupAddToMember(perGroup[group_name].perMember, entry);
  perGroup[group_name].value = entry.value;
}

function billingGroupAddToMember(perMember, entry) {
  for (var i=0; i < entry.members.length; i++) {   
    var member = entry.members[i].member.username;
    if (!(member in perMember)) {
      perMember[member] = {
        member: "",
      };
    }
  }
  
  perMember[member].member = member;
}

function buildBillingGroupSummary(entries) {
  var summary = {
    perClinic: {
    },
    perGroup: {
    },
    perMember: {
    }
  };

  for (var e in entries) {
    billingGroupAddToClinic(summary.perClinic, entries[e]);
    billingGroupAddToGroup(summary.perGroup, entries[e]);
    billingGroupAddToMember(summary.perMember, entries[e]);
  }

  return summary
}

export default billing;
