import _ from 'lodash';
import {
  EXAMS_SEARCH, EXAMS_SEARCH_FAILURE, EXAMS_SEARCH_EXAMS_TO_REVIEW, EXAMS_SEARCH_EXAMS_TO_REVIEW_FAILURE,
  EXAMS_GET, EXAMS_LIST, EXAMS_LIST_PENDING_DASHBOARD, EXAMS_LIST_RELEASED_DASHBOARD,
  EXAMS_CLEAR_CURRENT, EXAMS_CLEAR_ALL, EXAMS_GET_IMAGES_SUCCESS, EXAMS_GET_IMAGE_DATA_SUCCESS,
  EXAMS_DELETE_SUCCESS,
  EXAMS_UPDATE_IMAGES_SUCCESS, EXAMS_UPDATE_IMAGES_FAILURE, EXAMS_UPDATE_IMAGES_REQUEST,
  EXAMS_CREATE_MEDICAL_REPORT_ACCESS_TOKEN_REQUEST, EXAMS_CREATE_MEDICAL_REPORT_ACCESS_TOKEN_SUCCESS,
  EXAMS_CREATE_MEDICAL_REPORT_ACCESS_TOKEN_FAILURE,
  EXAMS_SEARCH_PATIENT_EXAMS, EXAMS_CLEAR_PATIENT_EXAMS, EXAMS_ASSOCIATE_REQUEST, EXAMS_ASSOCIATE_SUCCESS,
  EXAMS_ASSOCIATE_FAILURE,
  EXAMS_DISASSOCIATE_REQUEST, EXAMS_DISASSOCIATE_SUCCESS, EXAMS_DISASSOCIATE_FAILURE, EXAMS_GET_ACCESS_TOKEN_EXAM_REQUEST,
  EXAMS_GET_ACCESS_TOKEN_EXAM_SUCCESS, EXAMS_GET_ACCESS_TOKEN_EXAM_FAILURE, RETRIEVE_PATIENT_INFO_REQUEST,
  RETRIEVE_PATIENT_INFO_SUCCESS, RETRIEVE_PATIENT_INFO_FAILURE, EXAM_CREATE_EXAM_MANUALLY_REQUEST,
  EXAM_CREATE_EXAM_MANUALLY_SUCCESS, EXAM_CREATE_EXAM_MANUALLY_FAILURE, EXAMS_SEARCH_RELEASED_EXAMS,
  EXAMS_SEARCH_RELEASED_EXAMS_FAILURE, EXAMS_SEARCH_PENDING_EXAMS, EXAMS_SEARCH_PENDING_EXAMS_FAILURE

} from '../actions/exams';

import * as utils from '../utils';

const initialState = {
  current: null,
  list: [],
  listPendingDashboard: [],
  listReleasedDashboard: [],
  images: [],
  loadedImages: {},
  imageUploadError: null,
  count: 0,
  search: {},

  searchingExams: false,
  searchingExamsError: null,
  searchingReleasedExams: false,
  searchingReleasedExamsError: null,
  searchingPendingExams: false,
  searchingPendingExamsError: null,

  patientExams: null,

  createMedicalReportAccessToken: false,
  createMedicalReportAccessTokenError: null,
  creatingMedicalReportAccessToken: false,

  retrievingPatInfo: false,
  patientName: null,
  patientSex: null,
  patientBirthdate: null,
  retrievingPatInfoError: null,

  creatingExamManually: false,
  createExamManuallyError: null,

  associatingExams: false,
  associatingExamsError: null,
  disassociatingExams: false,
  disassociatingExamsError: null,

};

const exams = (state = initialState, action) => {
  switch (action.type) {
    case EXAMS_GET:
      return {
        ...state,
        current: utils.processExam(action.data)
      };
    case EXAMS_SEARCH:
      return {
        ...state,
        search: action.data,
        searchingExams: true,
      };
    case EXAMS_SEARCH_FAILURE:
      return {
        ...state,
        search: action.data,
        searchingExams: false,
        searchingExamsError: action.data,
      };
    case EXAMS_SEARCH_EXAMS_TO_REVIEW:
      return {
        ...state,
        searchingExams: true,
      };
    case EXAMS_SEARCH_EXAMS_TO_REVIEW_FAILURE:
      return {
        ...state,
        searchingExams: false,
        searchingExamsError: action.data,
      };
    case EXAMS_SEARCH_PATIENT_EXAMS:
      return {
        ...state,
        patientExams: action.data,
      };
    case EXAMS_CLEAR_PATIENT_EXAMS:
      return {
        ...state,
        patientExams: null,
      };
    case EXAMS_SEARCH_RELEASED_EXAMS:
      return {
        ...state,
        searchingReleasedExams: true,
      };
    case EXAMS_SEARCH_RELEASED_EXAMS_FAILURE:
      return {
        ...state,
        searchingReleasedExams: false,
        searchingReleasedExamsError: action.data,
      };
    case EXAMS_SEARCH_PENDING_EXAMS:
      return {
        ...state,
        searchingPendingExams: true,
      };
    case EXAMS_SEARCH_PENDING_EXAMS_FAILURE:
      return {
        ...state,
        searchingPendingExams: false,
        searchingPendingExamsError: action.data,
      };
    case EXAMS_LIST:
      return {
        ...state,
        list: utils.processExams(action.data.results),
        count: action.data.count,
        searchingExams: false,
        searchingExamsError: null,
      };
    case EXAMS_LIST_RELEASED_DASHBOARD:
      return {
        ...state,
        listReleasedDashboard: utils.processExams(action.data),
        searchingReleasedExams: false,
        searchingReleasedExamsError: null,
      };
    case EXAMS_LIST_PENDING_DASHBOARD:
      return {
        ...state,
        listPendingDashboard: utils.processExams(action.data),
        searchingPendingExams: false,
        searchingPendingExamsError: null,
      };
    case EXAMS_CLEAR_CURRENT:
      return {
        ...state,
        current: null,
        preview: null,
        imageUploadError: null,
        images: [],
        loadedImages: {},
      };
    case EXAMS_CLEAR_ALL:
      return {
        ...initialState
      };
    case EXAMS_GET_IMAGES_SUCCESS:
      return {
        ...state,
        images: addSaved(action.data),
        loadedImages: {}
      };
    case EXAMS_GET_IMAGE_DATA_SUCCESS:
      const images = _.clone(state.loadedImages);
      const generatedId = action.data.generated_id;
      images[generatedId] = action.data.data;
      return {
        ...state,
        loadedImages: images
      };
    case EXAMS_UPDATE_IMAGES_REQUEST:
      return {
          ...state,
          imageUploadError: null,
      }
    case EXAMS_UPDATE_IMAGES_SUCCESS:
      return {
          ...state,
          imageUploadError: null,
      }
    case EXAMS_UPDATE_IMAGES_FAILURE:
      return {
          ...state,
          imageUploadError: parseUploadError(action.data),
      }
    case EXAMS_DELETE_SUCCESS:
      {
        let subExamId = action.data;
        let examList = _.clone(state.list);
        _.remove(examList, e => e.id === subExamId);
        return {
          ...state,
          list: examList
        };
      }
    case EXAMS_CREATE_MEDICAL_REPORT_ACCESS_TOKEN_REQUEST:
      {
        return {
          ...state,
          createMedicalReportAccessToken: false,
          createMedicalReportAccessTokenError: null,
          creatingMedicalReportAccessToken: true,
        };
      }
    case EXAMS_CREATE_MEDICAL_REPORT_ACCESS_TOKEN_SUCCESS:
      {
        return {
          ...state,
          createMedicalReportAccessToken: true,
          createMedicalReportAccessTokenError: null,
          creatingMedicalReportAccessToken: false,
        };
      }
    case EXAMS_CREATE_MEDICAL_REPORT_ACCESS_TOKEN_FAILURE:
      {
        return {
          ...state,
          createMedicalReportAccessToken: false,
          creatingMedicalReportAccessToken: false,
          createMedicalReportAccessTokenError: parseCreateMedicalReportAccessTokenError(action.data),
        };
      }
    case EXAMS_GET_ACCESS_TOKEN_EXAM_REQUEST:
      {
        return {
          ...state,
          searchingExams: true,
        };
      }
    case EXAMS_GET_ACCESS_TOKEN_EXAM_SUCCESS:
      {
        return {
          ...state,
          searchingExams: false,
        };
      }
    case EXAMS_GET_ACCESS_TOKEN_EXAM_FAILURE:
      {
        return {
          ...state,
          searchingExams: false,
          searchingExamsError: action.data,
        };
      }
    case EXAMS_ASSOCIATE_REQUEST:
      {
        return {
          ...state,
          associatingExams: true,
        };
      }
    case EXAMS_ASSOCIATE_SUCCESS:
      {
        return {
          ...state,
          associatingExams: false,
        };
      }
    case EXAMS_ASSOCIATE_FAILURE:
      {
        return {
          ...state,
          associatingExams: false,
          associatingExamsError: action.data,
        };
      }
    case EXAMS_DISASSOCIATE_REQUEST:
      {
        return {
          ...state,
          disassociatingExams: true,
        };
      }
    case EXAMS_DISASSOCIATE_SUCCESS:
      {
        return {
          ...state,
          disassociatingExams: false,
        };
      }
    case EXAMS_DISASSOCIATE_FAILURE:
      {
        return {
          ...state,
          disassociatingExams: false,
          disassociatingExamsError: action.data,
        };
      }
    case RETRIEVE_PATIENT_INFO_REQUEST:
      {
        return {
          ...state,
          retrievingPatInfo: true,
        };
      }
    case RETRIEVE_PATIENT_INFO_SUCCESS:
      {
        return {
          ...state,
          patientName: action.data.patName,
          patientSex: action.data.patSex,
          patientBirthdate: action.data.patBirthdate,
          retrievingPatInfo: false,
        };
      }
    case RETRIEVE_PATIENT_INFO_FAILURE:
      {
        return {
          ...state,
          retrievingPatInfo: false,
          retrievingPatInfoError: action.data,
        };
      }
    case EXAM_CREATE_EXAM_MANUALLY_REQUEST:
      {
        return {
          ...state,
          creatingExamManually: true,
        };
      }
    case EXAM_CREATE_EXAM_MANUALLY_SUCCESS:
      {
        return {
          ...state,
          creatingExamManually: false,
        };
      }
    case EXAM_CREATE_EXAM_MANUALLY_FAILURE:
      {
        return {
          ...state,
          creatingExamManually: false,
          createExamManuallyError: action.data,
        };
      }

    default:
      return state;
  }
};

function parseUploadError(data) {
    return utils.getErrorMessageOrDefaultMessage(data);
}

function parseCreateMedicalReportAccessTokenError(data) {
    return utils.getErrorMessageOrDefaultMessage(data);
}

function addSaved(examImages) {
    for (let img of examImages) {
        img.saved = true;
    }

    return examImages;
}

export default exams;
