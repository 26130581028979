import React, { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import Button from '@mui/material/Button';

import * as authHttp from '../http/auth';


export default function LogoutButton() {
  let history = useHistory();

  const handleLogout = useCallback(async () => {
    history.push('/');
    authHttp.logout();
  }, [history]);

  return (
    <Button variant="contained" color="primary" disableElevation onClick={handleLogout}>
      LOGOUT
    </Button>
);
}