import React, { Fragment } from 'react';
import withStyles from '@mui/styles/withStyles';
import TableCell from '@mui/material/TableCell';

import { styled } from '@mui/material/styles';
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';


import * as utils from '../utils';

const styles = theme => ({
  root: {
    width: '100%',
  },
});

const HtmlTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#f5f5f9',
    color: 'rgba(0, 0, 0, 0.87)',
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(12),
    border: '1px solid #dadde9',
  },
}));


function ExamValueTableCell(props) {
  const { entry, valueField, enableTooltip } = props;

  return (
    <Fragment>
      <HtmlTooltip
        title={
          <React.Fragment>
            <Typography color="inherit">Detalhamento de pagamento</Typography>
            <ul>
              <li>Multiplicador: {entry.multiplier}</li>
              <li>Valor adicionado: {entry.added}</li>
              <li>Valor total: {utils.formatBillValue(entry[valueField])}</li>
            </ul>
          </React.Fragment>
              }
        arrow
        disableHoverListener={enableTooltip ? false : true}
      >
        <TableCell align="right">{utils.formatBillValue(entry[valueField])}</TableCell>
      </HtmlTooltip>
    </Fragment>
    )
}

export default withStyles(styles)(ExamValueTableCell);