import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import ListItemText from '@mui/material/ListItemText';

import * as utils from '../utils';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
  },
}));

export default function ExamDetailsTable(props) {
  const classes = useStyles();
  var exam = props.exam;
  if (exam == null) {
    exam = {};
  }
  var clinic = props.clinic;
  if (clinic == null) {
    clinic = {};
  }
  return (
    <Container maxWidth="xl" className={classes.container}>
      <Paper>
      <Grid container justifyContent="space-around" spacing={0}>
        <Grid item xs={12} md={3} lg={2}>
          <ListItemText primary={ exam.patient_name } secondary="Nome" />
        </Grid>
        <Grid item xs={12} md={3} lg={2}>
          <ListItemText primary={ exam.description } secondary="Descriçao" />
        </Grid>
        <Grid item xs={12} md={1} lg={1}>
          <ListItemText primary={ utils.datetime_to_age(exam.patient_birthdate) + " " + exam.patient_sex } secondary="Idade / Sexo" />
        </Grid>
        <Grid item xs={12} md={1} lg={1}>
          <ListItemText primary={ exam.modality } secondary="Modalidade" />
        </Grid>
        <Grid item xs={12} md={2} lg={2}>
          <ListItemText primary={ utils.formatDate(exam.study_datetime) }  secondary="Data exame" />
        </Grid>
        <Grid item xs={12} md={2} lg={2}>
          <ListItemText primary={ clinic.name } secondary="Clínica" />
        </Grid>
      </Grid>
      </Paper>
    </Container>
  );
}
