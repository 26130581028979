import qs from 'qs'
import React, { Fragment, useState, useEffect, useCallback } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'
import {
  ThemeProvider,
  StyledEngineProvider,
  adaptV4Theme
} from '@mui/material'
import { isFirefox } from 'react-device-detect'
import { createTheme } from '@mui/material/styles'
import withStyles from '@mui/styles/withStyles'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import TablePagination from '@mui/material/TablePagination'
import TableSortLabel from '@mui/material/TableSortLabel'
import Paper from '@mui/material/Paper'
import IconButton from '@mui/material/IconButton'
import Select from '@mui/material/Select'
import InputLabel from '@mui/material/InputLabel'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import FormControl from '@mui/material/FormControl'
import TextField from '@mui/material/TextField'
import Checkbox from '@mui/material/Checkbox'
import Tooltip from '@mui/material/Tooltip'
import Popover from '@mui/material/Popover'
import ArchiveIcon from '@mui/icons-material/Archive'
import UnarchiveIcon from '@mui/icons-material/Unarchive'
import PersonRemoveIcon from '@mui/icons-material/PersonRemove';
import FilterListIcon from '@mui/icons-material/FilterList'
import RefreshIcon from '@mui/icons-material/Refresh'
import SearchIcon from '@mui/icons-material/Search'
import DatePicker from '@mui/lab/DatePicker'
import { CircularProgress } from '@mui/material'

import AssociateMedicForm from '../components/AssociateMedicForm'
import ExamTableRow from '../components/ExamTableRow'
import ExamAnnexDialog from '../components/ExamAnnexDialog'
import TableToolbar from '../components/TableToolbar'
import ConfirmDialog from '../components/ConfirmDialog'
import ErrorDialog from '../components/ErrorDialog'
import ExamMultiplierForm from '../components/ExamMultiplierForm'
import ExamCategoryForm from '../components/ExamCategoryForm'
import ButtonWithMenu from '../components/ButtonWithMenu'
import Loading from '../components/Loading'

import { BULK_EXAMS_UPDATE } from '../actions/exams'
import { clearError } from '../actions/error'
import { createErrorDataSelector } from '../reducers/error'
import * as examsHttp from '../http/exams'
import * as superExamsHttp from '../http/super-exams'
import * as clinicsHttp from '../http/clinics'
import * as utils from '../utils'
import { modalities } from '../constants'
import { Button } from '@mui/material'

const enter = 13

const styles = theme => ({
  root: {
    width: '100%'
  },
  table: {
    minWidth: 700
  },
  tableHead: {
    fontWeight: 'bold'
  },
  tableContainer: {
    height: 320
  },
  formControl: {
    margin: theme.spacing(1),
    width: 280,
    verticalAlign: 'baseline',
    minWidth: 150
  },
  margin: {
    margin: theme.spacing(1),
    marginLeft: 'auto'
  },
  examRow: {
    padding: 0
  },
  autoUpdateText: {
    padding: '5px'
  },
  firefoxSpecialAlign: {
    position: 'relative',
    top: '-6px'
  },
  statuses: {
    textTransform: 'capitalize'
  }
})

const compactRowTheme = createTheme(
  adaptV4Theme({
    overrides: {
      MuiTableCell: {
        root: {
          //This can be referred from Material UI API documentation.
          padding: '2px',
          fontSize: '12px'
        }
      }
    }
  })
)

const mapStateToProps = state => {
  return {
    exams: state.exams.list,
    examsDeliveryTime: state.clinics.examsDeliveryTime,
    examsCount: state.exams.count,
    exam: state.exams.current,
    downloadingExamsImages: state.superExams.downloadingExamsImages,
    searchingExams: state.exams.searchingExams,
    search: state.exams.search,
    clinics: state.clinics.list,
    clinics_map: state.clinics.clinics_map,
    users: state.clinics.users,
    categories: state.clinics.categories,
    clinicsUrgentCategoryId: state.clinics.clinicsUrgentCategoryId,
    clinicsLoading: state.clinics.clinicsLoading,
    user: state.auth.currentUser,
    errors: createErrorDataSelector([BULK_EXAMS_UPDATE])(state)
  }
}

const autoUpdateValues = [
  { value: 120, label: '2 minutos' },
  { value: 300, label: '5 minutos' },
  { value: 600, label: '10 minutos' }
]

const statuses = ['aguardando', 'digitado', 'liberado', 'revisão']
const archives = ['Todos', 'Arquivados']

function currentDate() {
  return new Date()
}

function yesterday() {
  return new Date(Date.now() - 864e5)
}

function stringToDate(date) {
  var newdate = null
  if (date.length === 3) {
    newdate = new Date(Number(date[0]), Number(date[1]) - 1, Number(date[2]))
    return newdate
  } else {
    console.log('Invalid date: ' + date)
    return newdate
  }
}

function dateToString(date) {
  var newdate = null
  if (date) {
    newdate =
      date.getFullYear() + '-' + (date.getMonth() + 1) + '-' + date.getDate()
    return newdate
  }
}

const DEFAULT_ROWS_PER_PAGE = 20

function parseQuery(query) {
  let update = {}

  let fields = {
    clinic: 'clinicFilter',
    sector: 'sectorFilter',
    medic: 'medicFilter',
    modality: 'modalityFilter',
    status: 'statusFilter',
    category: 'categoryFilter',
    archived: 'archivedFilter',
    patName: 'patNameFilter',
    limit: 'rowsPerPage'
    // limit, studyDatetime, offset and order are handled separately
  }
  for (var f in fields) {
    if (f in query) {
      update[fields[f]] = query[f]
    }
  }

  if ('studyDatetimeFrom' in query) {
    var date = query.studyDatetimeFrom.split('-')
    var newdate = stringToDate(date)
    if (newdate) {
      update['studyDatetimeFromFilter'] = newdate
    }
  }

  if ('studyDatetimeTo' in query) {
    var dateTo = query.studyDatetimeTo.split('-')
    var newdateTo = stringToDate(dateTo)
    if (newdateTo) {
      update['studyDatetimeToFilter'] = newdateTo
    }
  }

  if ('limit' in query) {
    update['rowsPerPage'] = Number(query.limit)
  }

  if ('offset' in query) {
    var offset = query.offset
    var limit = update['limit']
    if (limit === undefined) {
      limit = DEFAULT_ROWS_PER_PAGE
    }

    if (offset === undefined || offset === null || isNaN(offset)) {
      offset = 0
    }

    update['page'] = Number(offset) / Number(limit)
  }

  if ('ordering' in query) {
    var ordering = query.ordering
    if (ordering.startsWith('-')) {
      update['order'] = 'desc'
      ordering = ordering.substring(1)
    } else {
      update['order'] = 'asc'
    }

    update['orderBy'] = ordering
  }

  return update
}

function readUrlQuery(search) {
  let query = qs.parse(search, { ignoreQueryPrefix: true })
  return parseQuery(query)
}

const tableHeaders = [
  { label: 'Paciente', field: 'patient_name' },
  { label: 'Data Exame', field: 'study_datetime' },
  { label: 'Data Inclusão', field: 'imported_datetime' },
  { label: 'Mod', align: 'left' },
  { label: 'Clínica', field: 'clinic_name', align: 'left' },
  { label: 'Descrição', field: 'description', align: 'left' },
  { label: 'Classificação', align: 'center' },
  { label: 'Status', align: 'center' }
]

function Exams(props) {
  const { clinics_map, users, examsCount } = props
  const {
    searchingExams,
    classes,
    exams,
    downloadingExamsImages,
    clinics,
    categories,
    clinicsUrgentCategoryId,
    clinicsLoading,
    user,
    examsDeliveryTime,
    errors
  } = props

  // TODO move timer to app/state
  //const [timer, setTimer] = useState(null);
  const [clinicFilter, setClinicFilter] = useState(null)
  const [sectorFilter, setSectorFilter] = useState(null)
  const [medicFilter, setMedicFilter] = useState(null)
  const [modalityFilter, setModalityFilter] = useState(null)
  const [statusFilter, setStatusFilter] = useState(null)
  const [categoryFilter, setCategoryFilter] = useState(null)
  const [archivedFilter, setArchivedFilter] = useState(null)
  const [studyDatetimeFromFilter, setStudyDatetimeFromFilter] = useState(
    currentDate()
  )
  const [studyDatetimeToFilter, setStudyDatetimeToFilter] = useState(
    currentDate()
  )
  const [patNameFilter, setPatNameFilter] = useState(null)
  const [descriptionFilter, setDescriptionFilter] = useState(null)
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(DEFAULT_ROWS_PER_PAGE)
  const [orderBy, setOrderBy] = useState('')
  const [order, setOrder] = useState('asc')
  const [selectedExams, setSelectedExams] = useState([])
  const [confirmArchive, setConfirmArchive] = useState(false)
  const [confirmUnarchive, setConfirmUnarchive] = useState(false)
  const [confirmAssociateMedic, setConfirmAssociateMedic] = useState(false)
  const [confirmDisassociateMedic, setConfirmDisassociateMedic] = useState(false)
  const [associateMedic, setAssociateMedic] = useState(0)
  const [annexExam, setAnnexExam] = useState(null)
  const [filterMenuOpen, setFilterMenuOpen] = useState(false)
  const [filterMenuAnchorEl, setFilterMenuAnchorEl] = useState(null)
  const [autoUpdateMenuAnchorEl, setAutoUpdateMenuAnchorEl] = useState(false)
  // const [autoUpdateValue, setAutoUpdateValue] = useState(null);
  const [updateMultiplierMenuOpen, setUpdateMultiplierMenuOpen] =
    useState(false)
  const [updateMultiplierMenuAnchorEl, setUpdateMultiplierMenuAnchorEl] =
    useState(null)
  const [updateMultiplierExam, setUpdateMultiplierExam] = useState(null)
  const [updateCategoryMenuOpen, setUpdateCategoryMenuOpen] = useState(false)
  const [updateCategoryMenuAnchorEl, setUpdateCategoryMenuAnchorEl] =
    useState(null)
  const [updateCategoryExam, setUpdateCategoryExam] = useState(null)
  const [hasParsedQuery, setHasParsedQuery] = useState(false)
  const [clinicSelected, setClinicSelected] = useState(false)

  useEffect(() => {
    async function initializeQuery() {
      let stateQuery = parseQuery(props.search)
      let stateUpdate = readUrlQuery(props.location.search)
      var data = Object.assign({}, stateQuery, stateUpdate)

      for (var key in data) {
        var value = data[key]
        switch (key) {
          case 'clinicFilter':
            setClinicFilter(value)
            break
          case 'sectorFilter':
            setSectorFilter(value)
            break
          case 'medicFilter':
            setMedicFilter(value)
            break
          case 'modalityFilter':
            setModalityFilter(value)
            break
          case 'statusFilter':
            setStatusFilter(value)
            break
          case 'categoryFilter':
            setCategoryFilter(value)
            break
          case 'archivedFilter':
            setArchivedFilter(value)
            break
          case 'patNameFilter':
            setPatNameFilter(value)
            break
          case 'rowsPerPage':
            setRowsPerPage(value)
            break
          case 'studyDatetimeFromFilter':
            setStudyDatetimeFromFilter(value)
            break
          case 'studyDatetimeToFilter':
            setStudyDatetimeToFilter(value)
            break
          case 'page':
            setPage(value)
            break
          case 'order':
            setOrder(value)
            break
          case 'orderBy':
            setOrderBy(value)
            break
          default:
            console.log('Unexpected query key ' + key)
            break
        }
      }
    }
    initializeQuery()
    setHasParsedQuery(true)
    return () => {
      examsHttp.clearAll();
    }
  }, [props.location.search, props.search])

  useEffect(() => {
    clinicsHttp.list();
    clinicsHttp.retrieveExamsDeliveryTime()
  }, []);

  const showReport = useCallback(
    user => {
      return (
        utils.user_has_permission(user, 'exams.add_medicalreport') ||
        utils.user_has_permission(user, 'exams.view_medicalreport') ||
        utils.user_has_permission(user, 'exams.view_medicalreport_in_progress')
      )
    },
    []
  )

  const showExam = useCallback(
    (examId, event) => {
      event.stopPropagation()
      if (showReport(user)) {
        props.history.push(`/exams/exam/${examId}`)
      }
    },
    [props, user, showReport]
  )

  const doSearch = useCallback(() => {
    // Don't do searches before we have parsed the URL parameters
    if (!hasParsedQuery) {
      return
    }
    // Close the menu when we search
    setFilterMenuOpen(false)
    setSelectedExams([])
    var datefromYYYYMMDD = dateToString(studyDatetimeFromFilter)
    var datetoYYYYMMDD = dateToString(studyDatetimeToFilter)
    var filters = {
      clinic: clinicFilter,
      sector: sectorFilter,
      medic: medicFilter,
      modality: modalityFilter,
      status: statusFilter,
      category: categoryFilter,
      archived: archivedFilter,
      studyDatetimeFrom: datefromYYYYMMDD,
      studyDatetimeTo: datetoYYYYMMDD,
      patName: patNameFilter,
      description: descriptionFilter,
      limit: rowsPerPage,
      offset: page * rowsPerPage,
      ordering: (order === 'desc' ? '-' : '') + orderBy
    }
    reallyDoSearch(filters)

    setClinicSelected(filters.clinic != null)
  }, [
    hasParsedQuery,
    clinicFilter,
    sectorFilter,
    medicFilter,
    modalityFilter,
    statusFilter,
    categoryFilter,
    archivedFilter,
    studyDatetimeFromFilter,
    studyDatetimeToFilter,
    patNameFilter,
    descriptionFilter,
    rowsPerPage,
    page,
    order,
    orderBy,
    reallyDoSearch
  ])

  function reallyDoSearch(filters) {
    let currentUrlParams = new URLSearchParams()

    for (let key in filters) {
      if (filters[key] !== undefined && filters[key] !== null) {
        currentUrlParams.set(key, filters[key])
      }
    }
    props.history.push(
      window.location.pathname + '?' + currentUrlParams.toString()
    )
    examsHttp.list(filters)
  }

  const getClinic = useCallback(
    clinicId => {
      var clinics = clinics_map
      if (clinics !== undefined) {
        if (clinicId in clinics) {
          return clinics[clinicId]
        }
      }
      return {}
    },
    [clinics_map]
  )

  const handleClinicChange = useCallback(
    event => {
      setClinicFilter(event.target.value)
      var clinic = getClinic(event.target.value)
      var sector = null
      if (clinic && clinic.sectors) {
        for (var i = 0; i < clinic.sectors.length; i++) {
          sector = clinic.sectors[i]
          if (sector.member) {
            setSectorFilter(sector.id)
            return
          }
        }
      }
      setSectorFilter(null)
    },
    [getClinic]
  )

  const handleOrderChange = useCallback(
    async (event, property) => {
      const isDesc = orderBy === property && order === 'desc'
      await setOrderBy(property)
      await setOrder(isDesc ? 'asc' : 'desc')
      const newOrder = isDesc ? 'asc' : 'desc'
      var datefromYYYYMMDD = dateToString(studyDatetimeFromFilter)
      var datetoYYYYMMDD = dateToString(studyDatetimeToFilter)
      var filters = {
        clinic: clinicFilter,
        sector: sectorFilter,
        medic: medicFilter,
        modality: modalityFilter,
        status: statusFilter,
        category: categoryFilter,
        archived: archivedFilter,
        studyDatetimeFrom: datefromYYYYMMDD,
        studyDatetimeTo: datetoYYYYMMDD,
        patName: patNameFilter,
        description: descriptionFilter,
        limit: rowsPerPage,
        offset: page * rowsPerPage,
        ordering: (newOrder === 'desc' ? '-' : '') + property
      }

      reallyDoSearch(filters)
    },
    [
      orderBy,
      order,
      studyDatetimeFromFilter,
      studyDatetimeToFilter,
      clinicFilter,
      sectorFilter,
      medicFilter,
      modalityFilter,
      statusFilter,
      categoryFilter,
      archivedFilter,
      patNameFilter,
      descriptionFilter,
      rowsPerPage,
      page,
      reallyDoSearch
    ]
  )

  const handlePatNameChange = useCallback(event => {
    setPatNameFilter(event.target.value)
  }, [])

  const handlePatNameKeyDown = useCallback(
    event => {
      if (event.which === enter && event.keyCode === enter) {
        var datefromYYYYMMDD = dateToString(studyDatetimeFromFilter)
        var datetoYYYYMMDD = dateToString(studyDatetimeToFilter)
        var filters = {
          clinic: clinicFilter,
          sector: sectorFilter,
          medic: medicFilter,
          modality: modalityFilter,
          status: statusFilter,
          category: categoryFilter,
          archived: archivedFilter,
          studyDatetimeFrom: datefromYYYYMMDD,
          studyDatetimeTo: datetoYYYYMMDD,
          patName: patNameFilter,
          description: descriptionFilter,
          limit: rowsPerPage,
          offset: page * rowsPerPage,
          ordering: (order === 'desc' ? '-' : '') + orderBy
        }

        reallyDoSearch(filters)
      }
    },
    [
      orderBy,
      order,
      studyDatetimeFromFilter,
      studyDatetimeToFilter,
      clinicFilter,
      sectorFilter,
      medicFilter,
      modalityFilter,
      statusFilter,
      categoryFilter,
      archivedFilter,
      patNameFilter,
      descriptionFilter,
      rowsPerPage,
      page,
      reallyDoSearch
    ]
  )

  const handlePatName = useCallback(event => {
    event.preventDefault()
  }, [])

  const handleDescriptionChange = useCallback(event => {
    setDescriptionFilter(event.target.value)
  }, [])

  const handleDescriptionKeyDown = useCallback(
    event => {
      if (event.which === enter && event.keyCode === enter) {
        var datefromYYYYMMDD = dateToString(studyDatetimeFromFilter)
        var datetoYYYYMMDD = dateToString(studyDatetimeToFilter)
        var filters = {
          clinic: clinicFilter,
          sector: sectorFilter,
          medic: medicFilter,
          modality: modalityFilter,
          status: statusFilter,
          category: categoryFilter,
          archived: archivedFilter,
          studyDatetimeFrom: datefromYYYYMMDD,
          studyDatetimeTo: datetoYYYYMMDD,
          patName: patNameFilter,
          description: descriptionFilter,
          limit: rowsPerPage,
          offset: page * rowsPerPage,
          ordering: (order === 'desc' ? '-' : '') + orderBy
        }

        reallyDoSearch(filters)
      }
    },
    [
      orderBy,
      order,
      studyDatetimeFromFilter,
      studyDatetimeToFilter,
      clinicFilter,
      sectorFilter,
      medicFilter,
      modalityFilter,
      statusFilter,
      categoryFilter,
      archivedFilter,
      patNameFilter,
      descriptionFilter,
      rowsPerPage,
      page,
      reallyDoSearch
    ]
  )

  const handleChangePage = useCallback((event, page) => {
    setPage(page)
  }, [])

  const handleChangeRowsPerPage = useCallback(event => {
    setRowsPerPage(event.target.value)
  }, [])

  useEffect(() => {
    doSearch()
  }, [page, rowsPerPage])

  const handleSelectExam = useCallback(
    examId => event => {
      const selectedIndex = selectedExams.indexOf(examId)
      let newSelected = []

      if (selectedIndex === -1) {
        newSelected = newSelected.concat(selectedExams, examId)
      } else if (selectedIndex === 0) {
        newSelected = newSelected.concat(selectedExams.slice(1))
      } else if (selectedIndex === selectedExams.length - 1) {
        newSelected = newSelected.concat(selectedExams.slice(0, -1))
      } else if (selectedIndex > 0) {
        newSelected = newSelected.concat(
          selectedExams.slice(0, selectedIndex),
          selectedExams.slice(selectedIndex + 1)
        )
      }

      setSelectedExams(newSelected)
    },
    [selectedExams]
  )

  const handleSelectAllExamsClick = useCallback(
    event => {
      if (event.target.checked) {
        let selectedExams = exams.filter(e => e.status !== 'liberado' ? e : null);
        const newSelecteds = selectedExams.map(e => e.id)
        setSelectedExams(newSelecteds)
        return
      }
      setSelectedExams([])
    },
    [exams]
  )

  const handleFilterMenuOpen = useCallback(event => {
    setFilterMenuOpen(true)
    setFilterMenuAnchorEl(event.currentTarget)
  }, [])

  const handleFilterMenuClose = useCallback(() => {
    setFilterMenuOpen(false)
    setFilterMenuAnchorEl(undefined)
  }, [])

  const handleUpdateMultiplierMenuOpen = useCallback((event, exam) => {
    setUpdateMultiplierMenuOpen(true)
    setUpdateMultiplierMenuAnchorEl(event.currentTarget)
    setUpdateMultiplierExam(exam)
  }, [])

  const handleUpdateMultiplierMenuClose = useCallback(() => {
    setUpdateMultiplierMenuOpen(false)
    setUpdateMultiplierMenuAnchorEl(undefined)
    setUpdateMultiplierExam(null)
  }, [])

  const handleUpdateCategoryMenuOpen = useCallback((event, exam) => {
    setUpdateCategoryMenuOpen(true)
    setUpdateCategoryMenuAnchorEl(event.currentTarget)
    setUpdateCategoryExam(exam)
  }, [])

  const handleUpdateCategoryMenuClose = useCallback(() => {
    setUpdateCategoryMenuOpen(false)
    setUpdateCategoryMenuAnchorEl(undefined)
    setUpdateCategoryExam(null)
  }, [])

  const getUser = useCallback(
    userId => {
      if (users !== undefined) {
        if (userId in users) {
          return users[userId]
        }
      }
      return {}
    },
    [users]
  )

  const getUserName = useCallback(
    userId => {
      var user = getUser(userId)
      if (user !== {}) {
        return user.first_name + ' ' + user.last_name
      }
      return ''
    },
    [getUser]
  )

  const handleDownloadReportClick = useCallback(
    exam => event => {
      window.open(`/exams/${exam.id}/report-pdf-viewer`, "_blank");
      return false
    },
    []
  )

  const canCloudDownload = useCallback(
    exam => {
      if (exam == null) return
      var clinic = getClinic(exam.clinic_id)
      if (clinic === {} || !clinic.dcm_image_download) return false
      return utils.user_has_permission(user, 'exams.download_exam_images')
    },
    [getClinic, user]
  )

  const downloadingExam = useCallback(
    examId => {
      const examIndex = downloadingExamsImages.indexOf(examId)
      if (examIndex === -1) {
        return false
      } else {
        return true
      }
    },
    [downloadingExamsImages]
  )

  const cloudDownload = useCallback(
    async exam => {
      const max_simultaneous_downloads = 3
      if (downloadingExamsImages.length < max_simultaneous_downloads) {
        await superExamsHttp.downloadSuperExamImages(exam.exam_id)
      } else {
        alert(
          'Número máximo de 3 downloads simultâneos atingido. Por favor, aguarde a finalização de um download.'
        )
      }
    },
    [downloadingExamsImages]
  )

  const handleArchiveConfirmDialogClick = useCallback(async () => {
    setConfirmArchive(false)
    const updateData = selectedExams.map(e => ({ id: e, archived: true }))
    await examsHttp.bulkUpdateExams(updateData)
    var datefromYYYYMMDD = dateToString(studyDatetimeFromFilter)
    var datetoYYYYMMDD = dateToString(studyDatetimeToFilter)
    var filters = {
      clinic: clinicFilter,
      sector: sectorFilter,
      medic: medicFilter,
      modality: modalityFilter,
      status: statusFilter,
      category: categoryFilter,
      archived: archivedFilter,
      studyDatetimeFrom: datefromYYYYMMDD,
      studyDatetimeTo: datetoYYYYMMDD,
      patName: patNameFilter,
      description: descriptionFilter,
      limit: rowsPerPage,
      offset: page * rowsPerPage,
      ordering: (order === 'desc' ? '-' : '') + orderBy
    }

    reallyDoSearch(filters)
  }, [
    selectedExams,
    orderBy,
    order,
    studyDatetimeFromFilter,
    studyDatetimeToFilter,
    clinicFilter,
    sectorFilter,
    medicFilter,
    modalityFilter,
    statusFilter,
    categoryFilter,
    archivedFilter,
    patNameFilter,
    descriptionFilter,
    rowsPerPage,
    page,
    reallyDoSearch
  ])

  const handleUnarchiveConfirmDialogClick = useCallback(async () => {
    setConfirmUnarchive(false)
    const updateData = selectedExams.map(e => ({ id: e, archived: false }))
    await examsHttp.bulkUpdateExams(updateData)
    var datefromYYYYMMDD = dateToString(studyDatetimeFromFilter)
    var datetoYYYYMMDD = dateToString(studyDatetimeToFilter)
    var filters = {
      clinic: clinicFilter,
      sector: sectorFilter,
      medic: medicFilter,
      modality: modalityFilter,
      status: statusFilter,
      category: categoryFilter,
      archived: archivedFilter,
      studyDatetimeFrom: datefromYYYYMMDD,
      studyDatetimeTo: datetoYYYYMMDD,
      patName: patNameFilter,
      description: descriptionFilter,
      limit: rowsPerPage,
      offset: page * rowsPerPage,
      ordering: (order === 'desc' ? '-' : '') + orderBy
    }

    reallyDoSearch(filters)
  }, [
    selectedExams,
    orderBy,
    order,
    studyDatetimeFromFilter,
    studyDatetimeToFilter,
    clinicFilter,
    sectorFilter,
    medicFilter,
    modalityFilter,
    statusFilter,
    categoryFilter,
    archivedFilter,
    patNameFilter,
    descriptionFilter,
    rowsPerPage,
    page,
    reallyDoSearch
  ])

  const handleAssociateMedicConfirmDialogClick = useCallback(async () => {
    const medicToAssociate = associateMedic
    setConfirmAssociateMedic(false)
    setAssociateMedic(0)
    const updateData = { medic_id: medicToAssociate, subexams: selectedExams }

    const result = await examsHttp.associateExams(updateData);
    if (result.status === 200) {
      alert('Associação de exames realizada com sucesso.')
    } else if (result.err.response.data.message) {
      alert(result.err.response.data.message)
    } else if (result.status === 404) {
      alert(
          'Médico não encontrado para os exames selecionados. Possivelmente não faz parte da clínica do exame.'
        )
    } else if (result.status === 403) {
      alert('O usuário não tem permissões de associar médicos a exames.')
    } else {
      alert('Ocorreu um erro na sua solicitação.')
    }

    var datefromYYYYMMDD = dateToString(studyDatetimeFromFilter)
    var datetoYYYYMMDD = dateToString(studyDatetimeToFilter)
    var filters = {
      clinic: clinicFilter,
      sector: sectorFilter,
      medic: medicFilter,
      modality: modalityFilter,
      status: statusFilter,
      category: categoryFilter,
      archived: archivedFilter,
      studyDatetimeFrom: datefromYYYYMMDD,
      studyDatetimeTo: datetoYYYYMMDD,
      patName: patNameFilter,
      description: descriptionFilter,
      limit: rowsPerPage,
      offset: page * rowsPerPage,
      ordering: (order === 'desc' ? '-' : '') + orderBy
    }

    reallyDoSearch(filters)
  }, [
    selectedExams,
    associateMedic,
    orderBy,
    order,
    studyDatetimeFromFilter,
    studyDatetimeToFilter,
    clinicFilter,
    sectorFilter,
    medicFilter,
    modalityFilter,
    statusFilter,
    categoryFilter,
    archivedFilter,
    patNameFilter,
    descriptionFilter,
    rowsPerPage,
    page,
    reallyDoSearch
  ])

  const handleDisassociateMedicConfirmDialogClick = useCallback(async () => {

    setConfirmDisassociateMedic(false)
    const updateData = { medic_id: 0, subexams: selectedExams }

    const res = await examsHttp.disassociateExams(updateData);
    if(!res.result) {
      alert('Não foi possível realizar solicitação.')
    }


    var datefromYYYYMMDD = dateToString(studyDatetimeFromFilter)
    var datetoYYYYMMDD = dateToString(studyDatetimeToFilter)
    var filters = {
      clinic: clinicFilter,
      sector: sectorFilter,
      medic: medicFilter,
      modality: modalityFilter,
      status: statusFilter,
      category: categoryFilter,
      archived: archivedFilter,
      studyDatetimeFrom: datefromYYYYMMDD,
      studyDatetimeTo: datetoYYYYMMDD,
      patName: patNameFilter,
      description: descriptionFilter,
      limit: rowsPerPage,
      offset: page * rowsPerPage,
      ordering: (order === 'desc' ? '-' : '') + orderBy
    }

    reallyDoSearch(filters)
  }, [
    selectedExams,
    orderBy,
    order,
    studyDatetimeFromFilter,
    studyDatetimeToFilter,
    clinicFilter,
    sectorFilter,
    medicFilter,
    modalityFilter,
    statusFilter,
    categoryFilter,
    archivedFilter,
    patNameFilter,
    descriptionFilter,
    rowsPerPage,
    page,
    reallyDoSearch
  ])

  // const handleAutoUpdateMenuClick = useCallback((event) => {
  //   setAutoUpdateMenuAnchorEl(event.currentTarget);
  // }, []);

  const handleAutoUpdateMenuClose = useCallback(() => {
    setAutoUpdateMenuAnchorEl(null)
  }, [])

  const handleAutoUpdateUpdate = useCallback(
    (valueSeconds, valueLabel) => {
      /*
    if (timer !== null) {
      clearInterval(timer);
    }
    setAutoUpdateValue(valueLabel);
    if (valueSeconds !== null) {
      timer = setInterval(() => {
        doSearch();
      }, valueSeconds * 1000);
    }
    handleAutoUpdateMenuClose();
    */
    },
    [
      /*timer, handleAutoUpdateMenuClose, doSearch*/
    ]
  )

  const clearFilters = useCallback(() => {
    setClinicFilter(null)
    setMedicFilter(null)
    setSectorFilter(null)
    setModalityFilter(null)
    setStatusFilter(null)
    setCategoryFilter(null)
    setArchivedFilter(null)
    setStudyDatetimeToFilter(null)
    setStudyDatetimeFromFilter(null)
    setPatNameFilter(null)
    setDescriptionFilter(null)
  }, [])

  const searchToday = useCallback(async () => {
    clearFilters()
    var date = currentDate()
    await setStudyDatetimeFromFilter(date)
    await setStudyDatetimeToFilter(date)
    var datefromYYYYMMDD = dateToString(date)
    var datetoYYYYMMDD = dateToString(date)
    var filters = {
      clinic: clinicFilter,
      sector: sectorFilter,
      medic: medicFilter,
      modality: modalityFilter,
      status: statusFilter,
      category: categoryFilter,
      archived: archivedFilter,
      studyDatetimeFrom: datefromYYYYMMDD,
      studyDatetimeTo: datetoYYYYMMDD,
      patName: patNameFilter,
      description: descriptionFilter,
      limit: rowsPerPage,
      offset: page * rowsPerPage,
      ordering: (order === 'desc' ? '-' : '') + orderBy
    }

    reallyDoSearch(filters)
  }, [
    clearFilters,
    orderBy,
    order,
    clinicFilter,
    sectorFilter,
    medicFilter,
    modalityFilter,
    statusFilter,
    categoryFilter,
    archivedFilter,
    patNameFilter,
    descriptionFilter,
    rowsPerPage,
    page,
    reallyDoSearch
  ])

  const searchYesterday = useCallback(async () => {
    clearFilters()
    var date = yesterday()
    await setStudyDatetimeFromFilter(date)
    await setStudyDatetimeToFilter(date)
    var datefromYYYYMMDD = dateToString(date)
    var datetoYYYYMMDD = dateToString(date)
    var filters = {
      clinic: clinicFilter,
      sector: sectorFilter,
      medic: medicFilter,
      modality: modalityFilter,
      status: statusFilter,
      category: categoryFilter,
      archived: archivedFilter,
      studyDatetimeFrom: datefromYYYYMMDD,
      studyDatetimeTo: datetoYYYYMMDD,
      patName: patNameFilter,
      description: descriptionFilter,
      limit: rowsPerPage,
      offset: page * rowsPerPage,
      ordering: (order === 'desc' ? '-' : '') + orderBy
    }

    reallyDoSearch(filters)
  }, [
    clearFilters,
    orderBy,
    order,
    clinicFilter,
    sectorFilter,
    medicFilter,
    modalityFilter,
    statusFilter,
    categoryFilter,
    archivedFilter,
    patNameFilter,
    descriptionFilter,
    rowsPerPage,
    page,
    reallyDoSearch
  ])

  const searchToReview = useCallback(() => {
    props.history.push('/exams/toreview/')
  }, [props])

  if (clinicsLoading) {
    return <Loading />;
  }

  var clinic_medics = utils.get_clinic_medics(getClinic(clinicFilter), user)

  const clinic = getClinic(clinicFilter)
  const isExamSelected = examId => selectedExams.indexOf(examId) !== -1
  const numExamsSelected = selectedExams.length
  const pageExamsCount = (exams || []).length
  const errorDetail = errors.detail

  const specialAlign = isFirefox ? classes.firefoxSpecialAlign : undefined

  const confirmArchiveDialog = (
    <ConfirmDialog
      open={confirmArchive}
      handleClose={() => setConfirmArchive(false)}
      handleConfirm={handleArchiveConfirmDialogClick}
      handleCancel={() => setConfirmArchive(false)}
      title={`Tem certeza disso?`}
      text={`A operação irá arquivar ${numExamsSelected} exames. Deseja continuar?`}
    />
  )

  const confirmUnarchiveDialog = (
    <ConfirmDialog
      open={confirmUnarchive}
      handleClose={() => setConfirmUnarchive(false)}
      handleConfirm={handleUnarchiveConfirmDialogClick}
      handleCancel={() => setConfirmUnarchive(false)}
      title={`Tem certeza disso?`}
      text={`A operação irá desarquivar ${numExamsSelected} exames. Deseja continuar?`}
    />
  )

  const confirmAssociateMedicDialog = (
    <ConfirmDialog
      open={confirmAssociateMedic}
      handleClose={() => {
        setAssociateMedic(0)
        setConfirmAssociateMedic(false)
      }}
      handleConfirm={handleAssociateMedicConfirmDialogClick}
      handleCancel={() => {
        setAssociateMedic(0)
        setConfirmAssociateMedic(false)
      }}
      title={`Associação de médico`}
      text={`Associação dos exames selecionados ao médico(a) ${getUserName(
        associateMedic
      )}. Deseja continuar?`}
    />
  )

  const confirmDisassociateMedicDialog = (
    <ConfirmDialog
      open={confirmDisassociateMedic}
      handleClose={() => {
        setConfirmDisassociateMedic(false)}}
      handleConfirm={handleDisassociateMedicConfirmDialogClick}
      handleCancel={() => {
        setConfirmDisassociateMedic(false)}}
      title={`Desassociação de médico`}
      text={`Desassociação do exame selecionado ao médico(a). Deseja continuar?`}
    />
  )

  const errorDialog = (
    <ErrorDialog
      open={!(errorDetail === null || errorDetail === undefined)}
      handleClose={() => clearError([BULK_EXAMS_UPDATE])}
      title={'Não foi possível completar a operação'}
      text={errorDetail}
    />
  )

  const examAnnexDialog = (
    <ExamAnnexDialog
      open={annexExam != null}
      annexAdded={doSearch}
      handleClose={() => setAnnexExam(null)}
      exam={annexExam}
      allowAdd={utils.user_has_permission(user, 'exams.add_subexamannexfile')}
    />
  )

  /* Re-enable once auto-updates are a thing
        <Tooltip title="Atualização automática">
          <IconButton aria-label="atualização-automática" onClick={handleAutoUpdateMenuClick}>
            <UpdateIcon />
            <Typography className={classes.autoUpdateText}>
              { autoUpdateValue === null ? "Desativado" : autoUpdateValue }
            </Typography>
          </IconButton>
        </Tooltip>
    */
  const toolbarComponent = (
    <div key="toolbarComponent">
      <Tooltip title="Filtrar">
        <IconButton
          aria-label="filtrar"
          onClick={handleFilterMenuOpen}
          size="large"
        >
          <FilterListIcon />
        </IconButton>
      </Tooltip>
      <Tooltip title="Atualizar">
        <IconButton aria-label="atualizar" onClick={doSearch} size="large">
          <RefreshIcon />
        </IconButton>
      </Tooltip>

      <Menu
        id="auto-refresh-menu"
        anchorEl={autoUpdateMenuAnchorEl}
        keepMounted
        open={Boolean(autoUpdateMenuAnchorEl)}
        onClose={handleAutoUpdateMenuClose}
      >
        <MenuItem onClick={() => handleAutoUpdateUpdate(null, null)}>
          Desativado
        </MenuItem>
        {autoUpdateValues.map(v => (
          <MenuItem
            key={v.value}
            value={v.value}
            onClick={() => handleAutoUpdateUpdate(v.value, v.label)}
          >
            {v.label}
          </MenuItem>
        ))}
      </Menu>
    </div>
  )

  const toolbarSelectedComponents = [
    <div key="toolbar-selected-component-archive">
      {utils.user_has_permission(user, 'exams.subexam_archive') && (
        <Tooltip title="Arquivar">
          <IconButton
            aria-label="arquivar"
            onClick={() => setConfirmArchive(true)}
            size="large"
          >
            <ArchiveIcon />
          </IconButton>
        </Tooltip>
      )}
    </div>,
    <div key="toolbar-selected-component-unarchive">
      {utils.user_has_permission(user, 'exams.subexam_archive') && (
        <Tooltip title="Desarquivar">
          <IconButton
            aria-label="arquivar"
            onClick={() => setConfirmUnarchive(true)}
            size="large"
          >
            <UnarchiveIcon />
          </IconButton>
        </Tooltip>
      )}
    </div>,
    <div key="toolbar-selected-component-associate">
      {clinicSelected &&
        utils.user_has_permission(user, 'exams.subexam_associate') && (
          <AssociateMedicForm
            value={associateMedic}
            currentClinic={clinicFilter}
            onChangeHandler={v => {
              setAssociateMedic(v)
              setConfirmAssociateMedic(true)
            }}
          />
        )}
    </div>,
    <div key="toolbar-selected-component-desassociate">
    {utils.user_has_permission(user, 'exams.subexam_associate') && (
      <Tooltip title="Desassociar médico">
        <IconButton
          aria-label="desassociar"
          onClick={() => setConfirmDisassociateMedic(true)}
          size="large"
        >
          <PersonRemoveIcon />
        </IconButton>
      </Tooltip>
    )}
  </div>
  ]

  const ClinicFilterComponent = props => (
    <FormControl className={classes.formControl} style={props.style}>
      <InputLabel htmlFor="clinics-selector">Clínica</InputLabel>
      <Select
        value={clinicFilter || ''}
        inputProps={{
          name: 'clinics',
          id: 'clinics-selector'
        }}
        label="Clínica"
        onChange={handleClinicChange}
      >
        <MenuItem>
          <em>Todas</em>
        </MenuItem>
        {clinics &&
          clinics.map(clinic => (
            <MenuItem key={clinic.id} value={clinic.id}>
              {clinic.name}
            </MenuItem>
          ))}
      </Select>
    </FormControl>
  )

  const ModalityFilterComponent = props => (
    <FormControl className={classes.formControl} style={props.style}>
      <InputLabel htmlFor="modalities-selector">Mod</InputLabel>
      <Select
        value={modalityFilter || ''}
        inputProps={{
          name: 'modalities',
          id: 'modalities-selector'
        }}
        label="Mod"
        onChange={event => setModalityFilter(event.target.value)}
      >
        <MenuItem>
          <em>Todas</em>
        </MenuItem>
        {modalities.map(modality => (
          <MenuItem key={modality} value={modality}>
            {modality}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )

  const CategoryFilterComponent = props => (
    <FormControl className={classes.formControl}>
      <InputLabel htmlFor="categories-selector">Classificação</InputLabel>
      <Select
        value={categoryFilter || ''}
        inputProps={{
          name: 'categories',
          id: 'categories-selector'
        }}
        label="Classificação"
        onChange={event => setCategoryFilter(event.target.value)}
      >
        <MenuItem>
          <em>Todas</em>
        </MenuItem>
        {Object.keys(categories).map(categoryId => (
          <MenuItem key={categoryId} value={categoryId}>
            {utils.titleCase(categories[categoryId].name)}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )

  const StatusFilterComponent = props => (
    <FormControl className={classes.formControl} style={props.style}>
      <InputLabel htmlFor="status-selector">Status</InputLabel>
      <Select
        value={statusFilter || ''}
        inputProps={{
          name: 'status',
          id: 'status-selector'
        }}
        label="Status"
        onChange={event => setStatusFilter(event.target.value)}
      >
        <MenuItem>
          <em>Todos</em>
        </MenuItem>
        {statuses.map(s => (
          <MenuItem className={classes.statuses} key={s} value={s}>
            {s}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )

  const StudyDateTimeFromFilterComponent = props => (
    <FormControl className={classes.formControl} style={props.style}>
      <InputLabel htmlFor="studydatetimefrom-selector"></InputLabel>
      <DatePicker
        disableToolbar
        className={specialAlign}
        variant="inline"
        format="dd-MM-yyyy"
        margin="normal"
        id="studydatetimefrom-selector"
        label="Data início"
        value={studyDatetimeFromFilter}
        onChange={(date, value) => setStudyDatetimeFromFilter(date)}
        KeyboardButtonProps={{
          'aria-label': 'change date'
        }}
        renderInput={props => <TextField {...props} />}
      />
    </FormControl>
  )

  const StudyDateTimeToFilterComponent = props => (
    <FormControl className={classes.formControl} style={props.style}>
      <InputLabel htmlFor="studydatetimeto-selector"></InputLabel>
      <DatePicker
        disableToolbar
        className={specialAlign}
        variant="inline"
        format="dd-MM-yyyy"
        margin="normal"
        id="studydatetimeto-selector"
        label="Data fim"
        value={studyDatetimeToFilter}
        onChange={(date, value) => setStudyDatetimeToFilter(date)}
        KeyboardButtonProps={{
          'aria-label': 'change date'
        }}
        renderInput={props => <TextField {...props} />}
      />
    </FormControl>
  )

  const SearchButtonComponent = props => (
    <Button
      type="input"
      className={classes.formControl}
      style={props.style}
      variant="contained"
      color="primary"
      endIcon={searchingExams ? <SpinnerAdornment /> : <SearchIcon />}
      onClick={doSearch}
    >
      Buscar
    </Button>
  )

  const examFilters = (
    <form onSubmit={doSearch} style={{ width: 600 }}>
      <ClinicFilterComponent />
      <FormControl className={classes.formControl}>
        <InputLabel htmlFor="clinics-selector">Médico</InputLabel>
        <Select
          value={medicFilter || ''}
          inputProps={{
            name: 'medics',
            id: 'medics-selector'
          }}
          label="Médico"
          onChange={event => setMedicFilter(event.target.value)}
        >
          <MenuItem>
            <em>Todos</em>
          </MenuItem>
          {!!clinic_medics &&
            clinic_medics.map(medic => (
              <MenuItem key={medic.id} value={medic.id}>
                {medic.first_name + ' ' + medic.last_name}
              </MenuItem>
            ))}
        </Select>
      </FormControl>
      <StudyDateTimeFromFilterComponent />
      <StudyDateTimeToFilterComponent />
      <ModalityFilterComponent />
      <CategoryFilterComponent />
      {utils.user_has_permission(user, 'exams.subexam_read_archive') && (
        <FormControl className={classes.formControl}>
          <InputLabel htmlFor="archived-selector">Arquivo</InputLabel>
          <Select
            value={archivedFilter || ''}
            inputProps={{
              name: 'archived',
              id: 'archived-selector'
            }}
            label="Arquivo"
            onChange={event => setArchivedFilter(event.target.value)}
          >
            <MenuItem>
              <em>Ativos</em>
            </MenuItem>
            {archives.map(s => (
              <MenuItem key={s} value={s}>
                {s}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      )}
      <StatusFilterComponent />
      <FormControl className={classes.formControl} style={props.style}>
        <InputLabel htmlFor="sector-selector">Setor</InputLabel>
        <Select
          value={sectorFilter || ''}
          inputProps={{
            name: 'sectors',
            id: 'sectors-selector'
          }}
          label="Setor"
          onChange={event => setSectorFilter(event.target.value)}
        >
          <MenuItem>
            <em>Todos</em>
          </MenuItem>
          {!!clinic &&
            clinic.sectors &&
            clinic.sectors.map(s => (
              <MenuItem key={s.id} value={s.id}>
                {s.name}
              </MenuItem>
            ))}
        </Select>
      </FormControl>
      <DescriptionFilterComponent
        classes={classes}
        descriptionFilter={descriptionFilter}
        handleDescriptionChange={handleDescriptionChange}
        handleDescriptionKeyDown={handleDescriptionKeyDown}
      />
      <SearchButtonComponent />
    </form>
  )

  const filterMenu = (
    <Popover
      id={filterMenuOpen ? 'filter-menu-popover' : undefined}
      anchorEl={filterMenuAnchorEl}
      open={filterMenuOpen}
      onClose={handleFilterMenuClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left'
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'left'
      }}
    >
      <Paper style={{ padding: 10 }}>{examFilters}</Paper>
    </Popover>
  )

  const updateMultiplierMenu = (
    <Popover
      id={
        updateMultiplierMenuOpen ? 'update-multiplier-menu-popover' : undefined
      }
      anchorEl={updateMultiplierMenuAnchorEl}
      open={updateMultiplierMenuOpen}
      onClose={handleUpdateMultiplierMenuClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left'
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'left'
      }}
    >
      <Paper style={{ padding: 10 }}>
        <ExamMultiplierForm
          exam={updateMultiplierExam}
          updatedCallback={doSearch}
        />
      </Paper>
    </Popover>
  )

  const updateCategoryMenu = (
    <Popover
      id={updateCategoryMenuOpen ? 'update-category-menu-popover' : undefined}
      anchorEl={updateCategoryMenuAnchorEl}
      open={updateCategoryMenuOpen}
      onClose={handleUpdateCategoryMenuClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left'
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'left'
      }}
    >
      <Paper style={{ padding: 10 }}>
        <ExamCategoryForm
          exam={updateCategoryExam}
          updatedCallback={doSearch}
        />
      </Paper>
    </Popover>
  )

  return (
    <Fragment>
      {confirmArchiveDialog}
      {confirmUnarchiveDialog}
      {confirmAssociateMedicDialog}
      {confirmDisassociateMedicDialog}
      {errorDialog}
      {examAnnexDialog}
      {filterMenu}
      {updateMultiplierMenu}
      {updateCategoryMenu}
      <div className={classes.tableContainer}>
        <form onSubmit={handlePatName}>
          <ClinicFilterComponent style={{ width: 200 }} />
          <FormControl className={classes.formControl}>
            <TextField
              value={patNameFilter || ''}
              label="Nome do paciente"
              onChange={handlePatNameChange}
              onKeyDown={handlePatNameKeyDown}
              autoComplete="off"
            />
          </FormControl>
          <StudyDateTimeFromFilterComponent style={{ width: 150 }} />
          <StudyDateTimeToFilterComponent style={{ width: 150 }} />
          <ModalityFilterComponent style={{ width: 50 }} />
          <StatusFilterComponent style={{ width: 150 }} />
          <ButtonWithMenu
            text="Buscar"
            style={{ width: 100 }}
            endIcon={searchingExams ? <SpinnerAdornment /> : <SearchIcon />}
            onClick={doSearch}
            options={[
              { name: 'Exames de hoje', onClick: () => searchToday() },
              { name: 'Exames de ontem', onClick: () => searchYesterday() },
              { name: 'Exames para revisar', onClick: () => searchToReview() }
            ]}
          />
        </form>
        <Paper className={classes.root}>
          <TableToolbar
            numSelected={numExamsSelected}
            title="Exames"
            component={[toolbarComponent]}
            selectedComponent={toolbarSelectedComponents}
          />
          <Table className={classes.table}>
            <StyledEngineProvider injectFirst>
              <ThemeProvider theme={compactRowTheme}>
                <TableHead>
                  <TableRow>
                    <TableCell
                      padding="checkbox"
                      key="exams-checkbox"
                      className={classes.tableHead}
                    >
                      <Checkbox
                        indeterminate={
                          numExamsSelected > 0 &&
                          numExamsSelected < pageExamsCount
                        }
                        checked={
                          pageExamsCount > 0 &&
                          numExamsSelected === pageExamsCount
                        }
                        onChange={handleSelectAllExamsClick}
                        inputProps={{ 'aria-label': 'selecionar todos' }}
                      />
                    </TableCell>
                    {tableHeaders.map(h => (
                      <TableCell
                        align={h.align}
                        key={h.label}
                        sortDirection={orderBy === h.field ? order : false}
                      >
                        <TableSortLabel
                          active={orderBy === h.field}
                          direction={order}
                          onClick={event => handleOrderChange(event, h.field)}
                        >
                          {h.label}
                        </TableSortLabel>
                      </TableCell>
                    ))}
                    {utils.user_has_permission(
                      user,
                      'exams.subexam_view_exam_multiplier'
                    ) && (
                      <TableCell
                        align="center"
                        key="exam-multiplier"
                        className={classes.tableHead}
                      >
                        Valor
                      </TableCell>
                    )}
                    {utils.user_has_permission(
                      user,
                      'exams.view_exam'
                    ) && (
                      <TableCell
                        align="center"
                        key="delivery-time"
                        className={classes.tableHead}
                      >
                        Status de Entrega
                      </TableCell>
                    )}
                    {utils.user_has_permission(
                      user,
                      'exams.list_subexamannexfile'
                    ) && (
                      <TableCell
                        align="center"
                        key="anexos"
                        className={classes.tableHead}
                      >
                        Anexos
                      </TableCell>
                    )}
                    {utils.user_has_permission(
                      user,
                      'exams.download_exam_images'
                    ) && (
                      <TableCell
                        align="center"
                        key="download-exam-images"
                        className={classes.tableHead}
                      >
                        Imagens
                      </TableCell>
                    )}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {exams &&
                    exams.map(e => (
                      <ExamTableRow
                        key={'exam-table-row-' + e.id}
                        exam={e}
                        handleSelectExam={handleSelectExam}
                        isExamSelected={isExamSelected}
                        showExam={showExam}
                        // base functions
                        getClinic={getClinic}
                        getUserName={getUserName}
                        setAnnexExam={setAnnexExam}
                        canCloudDownload={canCloudDownload}
                        cloudDownload={cloudDownload}
                        downloadingExam={downloadingExam}
                        // callbacks
                        handleUpdateCategoryMenuOpen={
                          handleUpdateCategoryMenuOpen
                        }
                        handleUpdateMultiplierMenuOpen={
                          handleUpdateMultiplierMenuOpen
                        }
                        handleDownloadReportClick={handleDownloadReportClick}
                        examsDeliveryTime={examsDeliveryTime}
                        isUrg = {e.category === clinicsUrgentCategoryId[e.clinic_id].id ? true : false}
                      />
                    ))}
                </TableBody>
              </ThemeProvider>
            </StyledEngineProvider>
          </Table>
          <TablePagination
            rowsPerPageOptions={[5, 10, 20, 50, 100]}
            component="div"
            count={examsCount}
            rowsPerPage={rowsPerPage}
            labelRowsPerPage="Itens por página"
            page={page}
            backIconButtonProps={{
              'aria-label': 'Página Anterior'
            }}
            nextIconButtonProps={{
              'aria-label': 'Próxima Página'
            }}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Paper>
      </div>
    </Fragment>
  )
}

Exams.propTypes = {
  classes: PropTypes.object.isRequired
}

// TODO this is horrible, but works
const DescriptionFilterComponent = props => (
  <FormControl
    className={[props.classes.formControl, props.specialAlign].join(' ')}
    style={props.style}
  >
    <TextField
      value={props.descriptionFilter || ''}
      label="Descrição"
      onChange={props.handleDescriptionChange}
      onKeyDown={props.handleDescriptionKeyDown}
    />
  </FormControl>
)

const SpinnerAdornment = withStyles(styles)(props => (
  <CircularProgress
    color="secondary"
    className={props.classes.spinner}
    size={20}
  />
))

export default withRouter(withStyles(styles)(connect(mapStateToProps)(Exams)))
