import React, { useEffect, useCallback } from 'react';
import withStyles from '@mui/styles/withStyles';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';

import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import { Button } from '@mui/material';
import Typography from '@mui/material/Typography';

import * as authHttp from '../http/auth';
import Loading from '../components/Loading';


const drawerWidth = 240;

const styles = theme => ({
  root: {
    display: 'flex',
  },
  toolbar: {
    paddingRight: 24, // keep right padding when drawer closed
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    minHeight: '100vh'
  },
  title: {
    flexGrow: 1,
    paddingLeft: 24,
  },
  paper: {
    padding: theme.spacing(2),
    maxHeight: "70vh",
    overflow: 'auto',
  },
});

const mapStateToProps = (state) => {
  return {
    privacyPolicyContentHtml: state.auth.privacyPolicyContentHtml,
    fetchingPolicy: state.auth.fetchingPolicy
  };
};


function Privacy (props) {
  const { classes, privacyPolicyContentHtml, fetchingPolicy } = props;

  useEffect(() => {
    authHttp.getPrivacyPolicy();
  }, []);

  let history = useHistory();

  const handleReturnButton = useCallback(async () => {
    history.push('/');
  }, [history]);

    return (
      <div className={classes.root}>
        <AppBar
          position="absolute"
        >
          <Toolbar className={classes.toolbar}>
            <Typography
              component="h1"
              variant="h6"
              color="inherit"
              noWrap
              className={classes.title}
            >
              Política de Privacidade
            </Typography>
            <Button variant="contained" color="primary" disableElevation onClick={handleReturnButton}>
              VOLTAR
            </Button>
          </Toolbar>
        </AppBar>

        <main className={classes.content}>
          <div className={classes.appBarSpacer} />
          {
            fetchingPolicy
              ?
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <Grid container justifyContent="center">
                    <Loading />
                  </Grid>
                </Grid>
              </Grid>
              :
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <Grid container justifyContent="center">
                    <Paper className={classes.paper} dangerouslySetInnerHTML={{__html: privacyPolicyContentHtml}} ></Paper>
                  </Grid>
                </Grid>
              </Grid>
          }
        </main>
      </div>
    );
}

export default withStyles(styles)(connect(mapStateToProps)(Privacy));
