import React from 'react';
import withStyles from '@mui/styles/withStyles';
import { Editor } from 'react-draft-wysiwyg';
import { EditorState } from 'draft-js';
import {getDefaultKeyBinding, KeyBindingUtil} from 'draft-js';
import SpeechRecognition, { useSpeechRecognition } from 'react-speech-recognition'

import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

const {hasCommandModifier} = KeyBindingUtil;

const styleMap = {
  'HIGHLIGHT': {
    'backgroundColor': '#7aff76',
  }
};

const styles = theme => ({
  wrapper: {
    border: '1px solid black',
    padding: '5px',
    minHeight: '800px',
    maxHeight: '800px',
    height: '100%',
    display: 'flex',
    flexDirection: 'column'
  },
});

const defaultEditorStyle = {
  paddingTop: '1cm',
  paddingBottom: '1cm',
  paddingLeft: '1cm',
  paddingRight: '1cm',
  overflowX: 'auto',
  fontSize: '16px',
};

function myKeyBindingFn(e: SyntheticKeyboardEvent): string | null {
  if (e.keyCode === 190 /* `.` key */ && hasCommandModifier(e)) {
    return 'toggle-speech';
  }
  return getDefaultKeyBinding(e);
}

function handleEditorTab(event) {
}

function ReportEditor(props) {
  const { classes } = props;
  let [,setState] = React.useState();

  const [editorState, setEditorState] = React.useState(
    EditorState.createEmpty()
  );

  React.useEffect(() => {
    if (props.content) {
      setEditorState(props.content);
    }
  }, [props.content]);

  const {
    listening,
  } = useSpeechRecognition({ transcribing: true, clearTranscriptOnListen: true })

  const handleEditorStateChange = React.useCallback((newEditorState) => {
    setEditorState(newEditorState);

    if (props.onContentChange) {
      props.onContentChange(newEditorState);
    }
  }, [props]);

    return (
      <Editor
        wrapperClassName={classes.wrapper}
        wrapperStyle={{ borderColor: props.disabled ? '#eaeaea' : (props.error ? 'red' : 'black') }}
        editorState={editorState}
        editorStyle={defaultEditorStyle}
        spellCheck={true}
        customStyleMap={styleMap}
        onEditorStateChange={handleEditorStateChange}
        toolbar={{
          options: ['inline', 'blockType', 'fontSize', 'fontFamily', 'list', 'textAlign', 'history'],
          inline: {
            options: ['bold', 'italic', 'underline'],
          },
          list: {
            options: ['unordered', 'ordered'],
          },
          fontSize: {
            options: [
              {label: 8, value: 10},
		      {label: 9, value: 12},
		      {label: 10, value: 13},
		      {label: 11, value: 14},
		      {label: 12, value: 16},
		      {label: 14, value: 19},
		      {label: 16, value: 21},
		      {label: 18, value: 24},
		      {label: 24, value: 32},
		      {label: 30, value: 40},
		      {label: 36, value: 48},
                     ],
	  },
        }}
        toolbarStyle={{ display: props.disabled ? 'none' : 'flex' }}
        readOnly={props.disabled}
        onTab={handleEditorTab}
        handlePastedText={() => false}
        handleKeyCommand={async (command) =>
        {
          if (command === 'toggle-speech') {
            if (SpeechRecognition.browserSupportsSpeechRecognition()) {
              if (!listening) {
                await SpeechRecognition.startListening({ language: 'pt-BR', continuous: true });
              } else {
                await SpeechRecognition.stopListening();
              }
              // Used to force a re-render
              setState({});
            }
            return 'handled';
          }
          return 'not-handled';
        }}
        keyBindingFn={myKeyBindingFn}
      />
    );
}

export default withStyles(styles)(ReportEditor);
