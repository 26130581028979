import { api } from './axios';
import store from '../store';
import * as authActions from '../actions/auth';

export const login = async (username, password) => {
  try {
    store.dispatch(authActions.requestLogin());
    const res = await api.post('/auth/token/', { username, password });
    localStorage.setItem('access_token', res.data.access);
    localStorage.setItem('refresh_token', res.data.refresh);
    api.defaults.headers.common['Authorization'] = 'Bearer ' + res.data.access;
    store.dispatch(authActions.loginSuccess(res.data));
    return res.data;
  } catch (err) {
    store.dispatch(authActions.loginError('Usuário ou senha inválidos'));
  }
};

export const logout = () => {
  store.dispatch(authActions.requestLogout());
  localStorage.removeItem('access_token');
  localStorage.removeItem('refresh_token');
  store.dispatch(authActions.receiveLogout());
};

export const sendResetPasswordEmail = async (data) => {
  try {
    store.dispatch(authActions.requestResetPassword());
    await api.post('/api/accounts/password_reset/', data);
    store.dispatch(authActions.resetPasswordSuccess());
  } catch (err) {
    store.dispatch(authActions.resetPasswordFailure('Não foi possível enviar o email de recuperação de senha'));
  }
};

export const updatePassword = async (data) => {
  try {
    store.dispatch(authActions.requestResetPassword());
    const res = await api.post('/api/accounts/password_reset/confirm/', data);
    store.dispatch(authActions.resetPasswordSuccess());
    return {result: true, data: res};
  } catch (err) {
    store.dispatch(authActions.resetPasswordFailure('Não foi possível atualizar sua senha.'));
    return {result: false, data: err};
  }
};

export const getCurrentUser = async () => {
  try {
    const res = await api.get('/api/user');
    store.dispatch(authActions.getCurrentUser(res.data));
  } catch (err) {
  }
};

export const getPrivacyPolicy = async () => {
  try {
    store.dispatch(authActions.getPrivacyPolicy());
    const res = await api.get('/api/accounts/privacypolicy');
    store.dispatch(authActions.getPrivacyPolicySuccess(res.data.content));
  } catch (err) {
    store.dispatch(authActions.getPrivacyPolicyError('Não foi possível encontrar os termos da Política de Privacidade'));
  }
};

export const acceptPrivacyPolicy = async (data) => {
  try {
    store.dispatch(authActions.acceptPrivacyPolicy());
    await api.post('/api/accounts/privacypolicyterms', data);
    store.dispatch(authActions.acceptPrivacyPolicySuccess());
  } catch (err) {
    store.dispatch(authActions.acceptPrivacyPolicyError('Não foi possível registrar a aceitação dos termos da Política de Privacidade'));
  }
};

export const activateTwoFactorAuth = async () => {
  try {
    store.dispatch(authActions.twoFactorAuthCreateDeviceRequest());
    const res = await api.post('/api/accounts/twofactor/setup');
    store.dispatch(authActions.twoFactorAuthCreateDeviceSuccess());
    return {result: true, data: res};
  } catch (err) {
    store.dispatch(authActions.twoFactorAuthCreateDeviceFailure('Não foi possível ativar a autenticação em dois fatores.'));
    return {result: false, data: err};
  }
}

export const deactivateTwoFactorAuth = async (data) => {
  try {
    store.dispatch(authActions.twoFactorAuthDeactivateRequest());
    const res = await api.delete('/api/accounts/twofactor/setup',{data: {data}});
    store.dispatch(authActions.twoFactorAuthDeactivateSuccess());
    return {result: true, data: res};
  } catch (err) {
    store.dispatch(authActions.twoFactorAuthDeactivateFailure('Não foi possível desativar a autenticação em dois fatores.'));
    return {result: false, data: err};
  }
}

export const twoFactorAuthIsActive = async () => {
  try {
    const res = await api.get('/api/accounts/twofactor/setup');
    store.dispatch(authActions.twoFactorAuthIsActive(res.data))
  } catch (err) {
  }
}

export const validateTwoFactorAuthToken = async (data) => {
  try {
    store.dispatch(authActions.twoFactorAuthValidateDeviceRequest());
    const res = await api.post('/api/accounts/twofactor/validatedevice', data);
    store.dispatch(authActions.twoFactorAuthValidateDeviceSuccess());
    return {result: true, data: res};
  } catch (err) {
    store.dispatch(authActions.twoFactorAuthValidateDeviceFailure('Não foi possível validar o dispositivo.'));
    return {result: false, data:err};
  }
}

export const validateTwoFactorAuthTokenLogin = async (data) => {
  try {
    store.dispatch(authActions.twoFactorAuthValidateLoginRequest());
    const res = await api.post('/api/accounts/twofactor/validatelogin', data);
    localStorage.setItem('access_token', res.data.access);
    localStorage.setItem('refresh_token', res.data.refresh);
    api.defaults.headers.common['Authorization'] = 'Bearer ' + res.data.access;
    store.dispatch(authActions.twoFactorAuthValidateLoginSuccess());
    return {result: true, data: res};
  } catch (err) {
    store.dispatch(authActions.twoFactorAuthValidateLoginFailure('Não foi possível validar o dispositivo.'));
    return {result: false, data: err};
  }
}