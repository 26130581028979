import React, { useState, useCallback, useEffect } from 'react';
import withStyles from '@mui/styles/withStyles';
import { connect } from 'react-redux';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import { Button } from '@mui/material';
import Typography from '@mui/material/Typography';
import DatePicker from '@mui/lab/DatePicker';
import * as clinicsHttp from '../http/clinics';
import * as examsHttp from '../http/exams';
import * as utils from '../utils';
import Loading from '../components/Loading';
import { modalities, sex } from '../constants'

const styles = theme => ({
  root: {
    display: 'flex',
  },
  toolbar: {
    paddingRight: 24, // keep right padding when drawer closed
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    minHeight: '100vh'
  },
  title: {
    flexGrow: 1,
    paddingLeft: 24,
  },
  submitButton: {
    margin: theme.spacing(1),
  },
});

const mapStateToProps = (state) => {
  return {
    clinics: state.clinics.list,
    categories: state.clinics.categories,
  };
};


function ManualExamCreation (props) {
  const { clinics, categories, classes } = props;

  const [ studyClinic, setStudyClinic ] = useState('');
  const [ accessionNumber, setAccessionNumber] = useState('');
  const [ patId, setPatId ] = useState('');
  const [ patName, setPatName ] = useState('');
  const [ patBirthdate, setPatBirthdate ] = useState('');
  const [ patSex, setPatSex ] = useState('');
  const [ studyDatetime, setStudyDatetime ] = useState('');
  const [ studyDesc, setStudyDesc ] = useState('');
  const [ modality, setModality ] = useState('');
  const [ studyCategory, setStudyCategory ] = useState('');
  const [ retrievingPatInfo, setRetrievingPatInfo ] = useState(false);
  const [ disablePatFields, setDisablePatFields ] = useState(true);
  const [ disablePatIdField, setDisablePatIdField ] = useState(true);
  const [ disableSubmitButton, setDisableSubmitButton ] = useState(false);
  const [ disablePatSearchButton, setDisablePatSearchButton] = useState(true);
  const [ patSearchStatus, setPatSearchStatus ] = useState('');

  useEffect(() => {
    clinicsHttp.list();
  }, [])

  const handleClinicChange = useCallback(
    event => {
      setStudyClinic(event.target.value);
      setDisablePatIdField(false);
      setDisablePatFields(false);
    },
    []
  )

  const searchPatInfo = useCallback(async (patId) => {
    setRetrievingPatInfo(true);
    if (!studyClinic) {
      alert('Por favor selecionar a clínica')
      return false;
    } else {
      var data = {
        patId: patId,
        clinicId: studyClinic
      }
      var response = await examsHttp.retrievePatInfo(data);
      setRetrievingPatInfo(false);
      return response;
    }
  }, [studyClinic]);

  const handlePatSearch = useCallback(async () => {
    setDisablePatSearchButton(true);
    var response = await searchPatInfo(patId);
    if (response.data.data) {
      fillPatInfos(response.data.data);
      setPatSearchStatus('Paciente já cadastrado.')
    } else {
      setPatSearchStatus('Paciente não encontrado. Novo cadastro.')
      setDisablePatFields(false);
      setPatName('');
      setPatBirthdate('');
      setPatSex('');
    }

    setDisablePatSearchButton(false);

  }, [ patId, searchPatInfo ]);

  const handlePatIdChange = useCallback(
    event => {
      setPatId(event.target.value);
      if (setPatId){
        setDisablePatSearchButton(false);
      }
    },
    []
  )

  function fillPatInfos(patInfos) {
    setDisablePatFields(true);
    setPatName(patInfos.patName);
    setPatSex(patInfos.patSex);
    setPatBirthdate(patInfos.patBirthdate);
  }

  const handlePatSexChange = (event) => {
    setPatSex(event.target.value);
  };

  const handleModalityChange = (event) => {
    setModality(event.target.value);
  }

  const handleStudyCategoryChange = (event) => {
    setStudyCategory(event.target.value);
  }

  function cleaningForm(filters) {
    setStudyClinic('');
    setAccessionNumber('');
    setPatId('');
    setPatName('');
    setPatBirthdate('');
    setPatSex('');
    setStudyDatetime('');
    setStudyDesc('');
    setModality('');
    setStudyCategory('');
  }

  const handleSubmit = useCallback(async () => {
    const data = {
      studyClinic: studyClinic,
      accessionNumber: accessionNumber,
      patId: patId,
      patName: patName,
      patBirthdate: patBirthdate,
      patSex: patSex,
      studyDatetime: studyDatetime,
      studyDesc: studyDesc,
      modality: modality,
      studyCategory: studyCategory
    };
    setDisableSubmitButton(true);
    const res = await examsHttp.createExamManually(data)
    if (res.result) {
      alert('Exam criado com sucesso.')
    } else {
      alert('Não foi possível criar o exame.')
    }

    setDisableSubmitButton(false);
    setDisablePatIdField(true);
    cleaningForm()
  }, [studyClinic, accessionNumber, patId, patName, patBirthdate, patSex, studyDatetime, studyDesc, modality, studyCategory]);


    return (
      <div className={classes.root}>
        <main className={classes.content}>
          <div>
            <Typography
              component="h1"
              variant="h6"
              color="inherit"
              noWrap
              className={classes.title}
            >
              Preencha os dados abaixo para criação manual de exame
            </Typography>
          </div>
          <div className={classes.appBarSpacer} />
            <Box
              component="form"
              sx={{
                '& .MuiTextField-root': { m: 1, width: '100ch' },
              }}
              noValidate
              autoComplete="off"
            >
              <div>
                <TextField
                  required
                  id="outlined-required-clinic"
                  select
                  label="Clínica"
                  value={studyClinic}
                  onChange={handleClinicChange}
                >
                    {clinics &&
                      clinics.map(clinic => (
                        <MenuItem key={clinic.id} value={clinic.id}>
                          {clinic.name}
                        </MenuItem>
                      ))}
                </TextField>
                <TextField
                  id="outlined-required-accession-number"
                  label="Número de atendimento"
                  value={accessionNumber}
                  type="number"
                  onChange={e => setAccessionNumber(e.target.value)}
                />
                <TextField
                  id="outlined-required-pat-id"
                  label="ID do paciente"
                  value={patId}
                  type="number"
                  disabled={disablePatIdField}
                  onChange={handlePatIdChange}
                />
                {retrievingPatInfo ?
                  <Loading/>
                  :
                  <React.Fragment>
                    <TextField
                      required
                      id="outlined-required-pat-name"
                      label="Nome do paciente"
                      value={patName}
                      disabled={disablePatFields}
                      onChange={e => setPatName(e.target.value)}
                    />
                    <DatePicker
                      variant="inline"
                      inputFormat="dd/MM/yyyy"
                      margin="normal"
                      id="pat-birthdate-selector"
                      label="Data de nascimento do paciente"
                      value={patBirthdate}
                      disabled={disablePatFields}
                      onChange={(date, value) => setPatBirthdate(date)}
                      KeyboardButtonProps={{
                        'aria-label': 'change date'
                      }}
                      renderInput={props => <TextField {...props} />}
                    />
                    <TextField
                      id="outlined-required-pat-sex"
                      select
                      label="Sexo do paciente"
                      value={patSex}
                      disabled={disablePatFields}
                      onChange={handlePatSexChange}
                    >
                      {sex.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </TextField>
                  </React.Fragment>
                }
                <DatePicker
                  variant="inline"
                  inputFormat="dd/MM/yyyy"
                  margin="normal"
                  id="study-datetime-selector"
                  label="Data do exame"
                  value={studyDatetime}
                  onChange={(date, value) => setStudyDatetime(date)}
                  KeyboardButtonProps={{
                    'aria-label': 'change date'
                  }}
                  renderInput={props => <TextField {...props} />}
                />
                <TextField
                  id="outlined-study-desc"
                  label="Descrição do exame"
                  value={studyDesc}
                  onChange={e => setStudyDesc(e.target.value)}
                />
                <TextField
                  id="outlined-required-modality"
                  select
                  label="Modalidade"
                  value={modality}
                  onChange={handleModalityChange}
                >
                  {modalities.map(modality => (
                    <MenuItem key={modality} value={modality}>
                      {modality}
                    </MenuItem>
                  ))}
                </TextField>
                <TextField
                  id="outlined-required-category"
                  select
                  label="Categoria"
                  value={studyCategory}
                  onChange={handleStudyCategoryChange}
                >
                  {Object.keys(categories).map(categoryId => (
                    <MenuItem key={categoryId} value={categoryId}>
                      {utils.titleCase(categories[categoryId].name)}
                    </MenuItem>
                  ))}
                </TextField>
              </div>
              <div>
                <Button
                 className={classes.submitButton}
                 type="submit"
                 variant="contained"
                 color="primary"
                 disabled={disableSubmitButton}
                 onClick={handleSubmit}>
                  Enviar
                </Button>
                <Button
                 className={classes.submitButton}
                 type="submit"
                 variant="contained"
                 color="primary"
                 disabled={disablePatSearchButton}
                 onClick={handlePatSearch}>
                  Buscar paciente
                </Button>
                <Typography
                  component="h1"
                  variant="h6"
                  color="inherit"
                  noWrap
                  className={classes.title}
                >
                  {patSearchStatus}
                </Typography>
              </div>
            </Box>
        </main>
      </div>
    );
}

export default withStyles(styles)(connect(mapStateToProps)(ManualExamCreation));