import { v4 as uuidv4 } from 'uuid';
import { api } from './axios';
import store from '../store';
import * as billingActions from '../actions/billing';

export const getUserFinance = async (fromDate, toDate, order, role) => {
  try {
    let rid = uuidv4();
    store.dispatch(billingActions.getUserFinance({requestId: rid}));
    const res = await api.get(`/api/billing/my`, {
        params: {
            fromDate,
            toDate,
            order,
            role
        }
    });
      store.dispatch(billingActions.getUserFinanceSuccess({data: res.data, requestId: rid}));
  } catch (err) {
    store.dispatch(billingActions.getUserFinanceFailure(err));
  }
};

export const getClinicFinance = async (fromDate, toDate, order, clinic) => {
  try {
    let rid = uuidv4();
    store.dispatch(billingActions.getClinicFinance({requestId: rid}));
    const res = await api.get(`/api/billing/clinic`, {
        params: {
            fromDate,
            toDate,
            order,
            clinic
        }
    });
      store.dispatch(billingActions.getClinicFinanceSuccess({data: res.data, requestId: rid}));
  } catch (err) {
    store.dispatch(billingActions.getClinicFinanceFailure(err));
  }
};

export const getMessageFinance = async (fromDate, toDate, order, clinic) => {
  try {
    let rid = uuidv4();
    store.dispatch(billingActions.getMessageFinance({requestId: rid}));
    const res = await api.get(`/api/billing/message`, {
        params: {
            fromDate,
            toDate,
            order,
            clinic
        }
    });
      store.dispatch(billingActions.getMessageFinanceSuccess({data: res.data, requestId: rid}));
  } catch (err) {
    store.dispatch(billingActions.getMessageFinanceFailure(err));
  }
};

export const getGeneralFinance = async (fromDate, toDate, order, role) => {
  try {
    let rid = uuidv4();
    store.dispatch(billingActions.getGeneralFinance({requestId: rid}));
    const res = await api.get(`/api/billing/general`, {
        params: {
            fromDate,
            toDate,
            order,
            role
        }
    });
      store.dispatch(billingActions.getGeneralFinanceSuccess({data: res.data, requestId: rid}));
  } catch (err) {
    store.dispatch(billingActions.getGeneralFinanceFailure(err));
  }
};

export const getBillingGroupFinance = async (clinic, role) => {
  try {
    let rid = uuidv4();
    store.dispatch(billingActions.getBillingGroupFinance({requestId: rid}));
    const res = await api.get(`/api/billing/billinggroup`, {
        params: {
            clinic,
            role
        }
    });
      store.dispatch(billingActions.getBillingGroupFinanceSuccess({data: res.data, requestId: rid}));
  } catch (err) {
    store.dispatch(billingActions.getBillingGroupFinanceFailure(err));
  }
};


export const clearCurrent = () => {
  store.dispatch(billingActions.clearCurrent());
};