import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import withStyles from '@mui/styles/withStyles';
import Box from '@mui/material/Box';
import Checkbox from '@mui/material/Checkbox';
import IconButton from '@mui/material/IconButton';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import Tooltip from '@mui/material/Tooltip';

import { CircularProgress } from '@mui/material';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import PrintIcon from '@mui/icons-material/Print';

import moment from 'moment';
import * as utils from '../utils';

const styles = theme => ({
  root: {
    width: '100%'
  },
  table: {
    minWidth: 700
  },
  tableHead: {
    fontWeight: "bold",
  },
  tableContainer: {
    height: 320
  },
  margin: {
    margin: theme.spacing(1),
    marginLeft: 'auto'
  },
  examRow: {
    padding: 0
  },
  autoUpdateText: {
    padding: '5px'
  },
  statuses: {
    textTransform: 'capitalize',
  },
});

const mapStateToProps = (state) => {
    return {
        exams: state.exams.list,
        user: state.auth.currentUser,
        categories: state.clinics.categories,
    };
};

function ExamTableRow(props) {
  const {
    classes,
    user,
    exam,
    categories,
    examsDeliveryTime,
    isUrg,

    // base functions
    getClinic,
    getUserName,
    setAnnexExam,
    canCloudDownload,
    cloudDownload,
    downloadingExam,

    // callbacks
    handleUpdateCategoryMenuOpen,
    handleUpdateMultiplierMenuOpen,
    handleDownloadReportClick,

    // functions
    handleSelectExam,
    isExamSelected,
    showExam,
  } = props;

  const e = exam;
  const [examDeliveryStatusColor, setExamDeliveryStatusColor] = useState('white');
  const [examDeliveryStatusText, setExamDeliveryStatusText] = useState('');

  useEffect(() => {
    async function fetchDeliveryColor() {
      if (exam) {
        var color = '';
        var text = '';
        var now = moment().format();

        if (isUrg) {
          if (exam.status === 'liberado') {
            let finishedDatetime = moment(exam.report.finished_timestamp).format();
            color = await utils.getUrgentExamDeliveryStatusColor(exam, examsDeliveryTime, finishedDatetime);
          } else {
            color = await utils.getUrgentExamDeliveryStatusColor(exam, examsDeliveryTime, now);
          }
          text = await utils.getUrgentDeliveryStatusText(color);
        } else {
          if (exam.status === 'liberado') {
            let finishedDatetime = moment(exam.report.finished_timestamp);
            color = await utils.getExamDeliveryStatusColor(exam, examsDeliveryTime, finishedDatetime);
          } else {
            color = await utils.getExamDeliveryStatusColor(exam, examsDeliveryTime, now);
          }
          text = await utils.getDeliveryStatusText(color);
        }
        setExamDeliveryStatusColor(color);
        setExamDeliveryStatusText(text);
      }
    }
    fetchDeliveryColor();

  }, [exam, examsDeliveryTime, isUrg]);


  return (
    <React.Fragment>
      <TableRow
          key={"exam-table-row-" + e.id}
          selected={isExamSelected(e.id)}
          hover
          className={classes.examRow}
        >
          <TableCell>
	    { e.status !== 'liberado' &&
            <Checkbox
              onChange={handleSelectExam(e.id)}
              checked={isExamSelected(e.id)}
            />
	    }
          </TableCell>
          <TableCell onClick={(event) => showExam(e.id, event)} component="th" scope="row">
            {e.patient_name}
          </TableCell>
          <TableCell onClick={(event) => showExam(e.id, event)} align="left"> {utils.formatDate(e.study_datetime)}</TableCell>
          <TableCell onClick={(event) => showExam(e.id, event)} align="left"> {utils.formatDate(e.exam.imported_datetime)}</TableCell>
          <TableCell onClick={(event) => showExam(e.id, event)} align="left">{e.modality}</TableCell>
          <TableCell onClick={(event) => showExam(e.id, event)} align="left">{getClinic(e.clinic_id).name}</TableCell>
          <TableCell onClick={(event) => showExam(e.id, event)} align="left">{e.description}</TableCell>
          <TableCell onClick={
              (utils.user_has_permission(user, "exams.subexam_update_category") ?
                  (event) => handleUpdateCategoryMenuOpen(event, e) :
                  (event) => showExam(e.id, event))
          } align="center" style={{ backgroundColor: "#" +
              (e.category in categories ? categories[e.category].color_rgb : "")}}>{
                  (e.category in categories ? utils.titleCase(categories[e.category].name) : e.category)
              }
          </TableCell>
          <TableCell align="center" style={{ backgroundColor: "#" + e.status_color_rgb }}
              onClick={(utils.user_has_permission(user, "exams.medicalreport_download") &&
                  (e.status === 'liberado')) ? handleDownloadReportClick(e) : undefined} >
              { utils.titleCase(e.status) }
              { e.printed ? <PrintIcon fontSize='small' /> : null}
              <br/>
              { e.report !== null ? getUserName(e.report.medic) : e.associated_medic_id !== null ? getUserName(e.associated_medic_id) : "" }
          </TableCell>
          {(utils.user_has_permission(user, "exams.subexam_view_exam_multiplier")) &&
            <TableCell align="center" onClick={
                (utils.user_has_permission(user, "exams.subexam_manage_multiplier") ?
                    (event) => handleUpdateMultiplierMenuOpen(event, e) : undefined)
                }>
                {e.multiplier !== null && e.multiplier !== undefined ?
                    "*" + e.multiplier.multiplier + "+" + e.multiplier.added :
                    "--"
                }
            </TableCell>
          }
          <TableCell align="center" onClick={(event) => showExam(e.id, event)}>
            <Tooltip title={examDeliveryStatusText } aria-label="delivery-exam-status">
              <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                <Box style={{backgroundColor: examDeliveryStatusColor, borderRadius: '50%', width:'25px', height: '25px'}} />
              </Box>
            </Tooltip>
          </TableCell>
          {(utils.user_has_permission(user, "exams.list_subexamannexfile")) &&
            <TableCell align="center">
              <AttachFileIcon fontSize="small" color={e.annexes !== undefined && e.annexes.length > 0 ? "primary" : "action"}
      onClick={() => setAnnexExam(e)} />
            </TableCell>
          }
            <TableCell align="center">
             {(canCloudDownload(e)) &&
              <Tooltip title="Baixar imagens do exame" aria-label="download-exam-images">
                  {downloadingExam(e.id)
                    ?
                    <SpinnerAdornment />
                    :
                    <IconButton
                      aria-label="Baixar imagens"
                      component="span"
                      onClick={() => cloudDownload(e)}
                      size="large">
                        <CloudDownloadIcon  />
                    </IconButton>
                  }
              </Tooltip>
              }
        </TableCell>
    </TableRow>
    </React.Fragment>
    );
}

// TODO refactor
const SpinnerAdornment = withStyles(styles)(props => (
  <CircularProgress
    color="secondary"
    className={props.classes.spinner}
    size={20}
  />
))

export default withRouter(withStyles(styles)(connect(mapStateToProps)(ExamTableRow)));