import qs from 'qs';
import React, { useState, useCallback } from 'react';
import withStyles from '@mui/styles/withStyles';
import { connect } from 'react-redux';

import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import classNames from 'classnames';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import { Button } from '@mui/material';

import * as authHttp from '../http/auth';
import Loading from '../components/Loading';

const drawerWidth = 240;

const styles = theme => ({
  root: {
    display: 'flex',
  },
  toolbar: {
    paddingRight: 24, // keep right padding when drawer closed
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    minHeight: '100vh'
  },
  title: {
    flexGrow: 1,
    paddingLeft: 24,
  },
});


const mapStateToProps = (state) => {
  return {
  };
};


function readUrlQuery(search) {
  let query = qs.parse(search, { ignoreQueryPrefix: true });
  return query;
}


function ResetPassword (props) {
  const { classes } = props;

  const [ newPassword1, setNewPassword1 ] = useState('');
  const [ newPassword2, setNewPassword2 ] = useState('');
  const [ updating, setUpdating ] = useState(false);
  const [ verificationError, setVerificationError ] = useState(false);
  const [ helperText, setHelperText ] = useState('')
  const open = false

  const updatePassword = useCallback(async (newPassword) => {
    let queryParams = readUrlQuery(props.location.search);
    if (newPassword.newPassword1 === newPassword.newPassword2) {
      const data = {
        token: queryParams.token,
        password:newPassword.newPassword1,
      };
      setUpdating(true);
      const response = await authHttp.updatePassword(data);
      if (response.result) {
        alert("Senha criada com sucesso!");
        props.history.push('/');
      } else {
        alert("Não foi possível atualizar sua senha. Tente solicitar novamente a recuperação de senha.")
      }
      setUpdating(false);
    } else {
      setHelperText('Senhas divergentes, por favor certifique que as senhas são iguais.');
      setVerificationError(true);
    }
  }, [props]);

  const handleRedirect = useCallback( () => {
    props.history.push('/');
  }, [props]);

    return (
      <div className={classes.root}>
        <AppBar
          position="absolute"
          className={classNames(classes.appBar, open && classes.appBarShift)}
        >
          <Toolbar disableGutters={!open} className={classes.toolbar}>
            <Typography
              component="h1"
              variant="h6"
              color="inherit"
              noWrap
              className={classes.title}
            >
              Recuperação de senha
            </Typography>
          </Toolbar>
        </AppBar>

        <main className={classes.content}>
          <div className={classes.appBarSpacer} />
          {
            updating
              ?
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <Grid container justifyContent="center">
                    <Loading />
                  </Grid>
                </Grid>
              </Grid>
              :
              <form id="update-password-form" onSubmit={(e) => { e.preventDefault(); updatePassword({newPassword1, newPassword2}); } }>
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <Grid container justifyContent="center">
                      <TextField
                        id="password1"
                        onChange={event => setNewPassword1(event.target.value)}
                        label="Informe a nova senha"
                        type="password"
                        variant="outlined"
                        error= {verificationError}
                        helperText={helperText}
                      />
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <Grid container justifyContent="center">
                      <TextField
                        id="password2"
                        onChange={event => setNewPassword2(event.target.value)}
                        label="Informe novamente a senha"
                        type="password"
                        variant="outlined"
                        error= {verificationError}
                        helperText={helperText}
                      />
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <Grid container justifyContent="center">
                      <Button
                        type="submit"
                        variant="contained"
                        color="primary"
                      >
                      Atualizar senha
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </form>
          }
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Grid container justifyContent="center">
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    onClick={handleRedirect}
                  >
                  Voltar para página inicial
                  </Button>
              </Grid>
            </Grid>
          </Grid>
        </main>
      </div>
    );
}

export default withStyles(styles)(connect(mapStateToProps)(ResetPassword));