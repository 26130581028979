import React from 'react';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Grow from '@mui/material/Grow';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';

export default function ButtonWithMenu(props) {
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);

  const handleMenuItemClick = (event, index, option) => {
    setOpen(false);
    if (option.onClick !== undefined) {
      option.onClick(event);
    }
  };

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  return (
      <React.Fragment>
        <ButtonGroup variant="contained" color="primary" ref={anchorRef} aria-label="split button">
           <Button onClick={props.onClick}
                   style={props.style}
                   variant="contained"
                   color="primary"
                   endIcon={props.endIcon}
            >
             {props.text}
           </Button>
           <Button
             color="primary"
             size="small"
             aria-controls={open ? 'split-button-menu' : undefined}
             aria-expanded={open ? 'true' : undefined}
             aria-label="atalhos"
             aria-haspopup="menu"
             onClick={handleToggle}
           >
             <ArrowDropDownIcon />
           </Button>
         </ButtonGroup>
         <Popper open={open} anchorEl={anchorRef.current} role={undefined} transition>
           {({ TransitionProps, placement }) => (
             <Grow
               {...TransitionProps}
                 style={{
                   transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
                 }}
             >
               <Paper>
                 <ClickAwayListener onClickAway={handleClose}>
                   <MenuList id="split-button-menu">
                     {props.options.map((option, index) => (
                       <MenuItem
                        key={"menu-"+index}
                        onClick={(event) => handleMenuItemClick(event, index, option)}
                       >
                         {option.name}
                       </MenuItem>
                     ))}
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      </React.Fragment>
    );
}
