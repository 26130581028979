import React from 'react';
import { withRouter, Switch, Route, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import withStyles from '@mui/styles/withStyles';
import CssBaseline from '@mui/material/CssBaseline';
import Drawer from '@mui/material/Drawer';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ReceiptIcon from '@mui/icons-material/Receipt';
import SettingsIcon from '@mui/icons-material/Settings';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import Chip from '@mui/material/Chip';
import AssignmentInd from '@mui/icons-material/AssignmentInd';

import PatientExamsMenu from '../components/PatientExamsMenu'
import ExamActionsMenu from '../components/ExamActionsMenu'
import ExamActionsButtons from '../components/ExamActionsButtons';
import Exams from './Exams';
import ExamsToReview from './ExamsToReview';
import ExamSetFields from './ExamSetFields';
import SuperExamDetails from './SuperExamDetails';
import SuperExamViewer from './SuperExamViewer';
import ReportPdfViewer from './ReportPdfViewer';
import Reports from './Reports';
import UserFinances from './UserFinances';
import ClinicFinances from './ClinicFinances';
import GeneralFinances from './GeneralFinances';
import BillingGroupFinances from './BillingGroupFinances';
import MessageFinances from './MessageFinances';
import MedicDashboard from './MedicDashboard';
import LogoutButton from '../components/LogoutButton'
import SubmitFeedbackButton from '../components/SubmitFeedbackButton';
import ManualExamCreationButton from '../components/ManualExamCreationButton';
import ManualExamCreation from './ManualExamCreation';
import ReportDetails from '../components/ReportDetails';
import ExamDetails from '../components/ExamDetails';
import ImageUploader from '../components/ImageUploader';
import Config from '../components/Config';
import * as utils from '../utils';
import * as authHttp from '../http/auth';
import * as accountsHttp from '../http/accounts';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import PersonIcon from '@mui/icons-material/Person';
import BusinessIcon from '@mui/icons-material/Business';
import BadgeIcon from '@mui/icons-material/Badge';
import LocalHospitalIcon from '@mui/icons-material/LocalHospital';
import MessageIcon from '@mui/icons-material/Message';
import DashboardIcon from '@mui/icons-material/Dashboard';

const drawerWidth = 240;

const styles = theme => ({
  root: {
    display: 'flex'
  },
  toolbar: {
    paddingRight: 24, // keep right padding when drawer closed
  },
  toolbarIcon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 8px',
    ...theme.mixins.toolbar,
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginLeft: 12,
    marginRight: 36,
  },
  menuButtonHidden: {
    display: 'none',
  },
  title: {
    flexGrow: 1,
  },
  drawerPaper: {
    flexShrink: 0,
    position: 'relative',
    whiteSpace: 'nowrap',
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerPaperClose: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing(7),
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9),
    },
    overflowX: 'hidden',
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    padding: theme.spacing(2),
    minHeight: '100vh'
  },
  examStateChip: {
    margin: '10px',
  },
});


const mapStateToProps = (state) => {
  return {
    exam: state.exams.current,
    currentUser: state.auth.currentUser,
    userFlags: state.accounts.userFlags,
  };
};

class Dashboard extends React.Component {
  state = {
    open: false,
  };

  componentWillMount() {
    authHttp.getCurrentUser();
    authHttp.twoFactorAuthIsActive();
    accountsHttp.retrieveUserFlags();

  }

  handleDrawerOpen = () => {
    this.setState({ open: true });
  };

  handleDrawerClose = () => {
    this.setState({ open: false });
  };

  render() {
    const { classes, exam, currentUser, userFlags } = this.props;

    const canViewClinicFinances = utils.user_has_permission(currentUser, 'billing.view_clinic_bills');
    const canViewGeneralFinances = utils.user_has_permission(currentUser, 'billing.view_general_bills');
    const canViewMessageFinances = utils.user_has_permission(currentUser, 'billing.view_messagebill');
    const canViewBillingGroupFinances = utils.user_has_permission(currentUser, 'billing.view_billinggroup');
    const canCreateExamManually = utils.user_has_permission(currentUser, 'exams.add_exam') &&
                                  utils.user_has_permission(currentUser, 'exams.view_exam')
    const canAccessDashboard = userFlags.canUseMedicDashboard;

    return (
      <div className={classes.root}>
        <CssBaseline />
        <AppBar
          position="absolute"
          className={classNames(classes.appBar, this.state.open && classes.appBarShift)}
        >
          <Toolbar disableGutters={!this.state.open} className={classes.toolbar}>
            <IconButton
              color="inherit"
              aria-label="Open drawer"
              onClick={this.handleDrawerOpen}
              className={classNames(
                classes.menuButton,
                this.state.open && classes.menuButtonHidden,
              )}
              size="large">
              <MenuIcon />
            </IconButton>
            <Switch >
                <Route exact path='/exams/exam/:id' component={() =>
                  <React.Fragment>
                      <Typography
                        component="h1"
                        variant="h6"
                        color="inherit"
                        noWrap
                        className={classes.title}
                      >
                        Exame
                      { exam ?
                        <Chip label={ exam.status }
                              style={{ backgroundColor: "#" + exam.status_color_rgb }}
			      className={classes.examStateChip} />
                        : <div></div>
                      }
                      </Typography>
                      <PatientExamsMenu exam={exam} user={currentUser} />
                      <ExamActionsButtons exam={exam} user={currentUser} />
                      <ExamActionsMenu exam={exam} user={currentUser} />
                  </React.Fragment>
                } />
                <Route exact path='/exams/:id/subexams' component={() =>
                  <React.Fragment>
                      <Typography
                        component="h1"
                        variant="h6"
                        color="inherit"
                        noWrap
                        className={classes.title}
                      >
                        Gerência de Sub Exames
                      </Typography>
                  </React.Fragment>
                } />
                <Route exact path='/exams/:id/edit' component={() =>
                  <React.Fragment>
                      <Typography
                        component="h1"
                        variant="h6"
                        color="inherit"
                        noWrap
                        className={classes.title}
                      >
                        Editar Exame
                      </Typography>
                  </React.Fragment>
                } />
                <Route path='/exams/:id/report-pdf-viewer' component={() =>
                  <React.Fragment>
                      <Typography
                        component="h1"
                        variant="h6"
                        color="inherit"
                        noWrap
                        className={classes.title}
                      >
                        Visualizador de laudo médico
                      </Typography>
                  </React.Fragment>
                } />
                <Route path='/exams/viewer' component={() =>
                  <React.Fragment>
                      <Typography
                        component="h1"
                        variant="h6"
                        color="inherit"
                        noWrap
                        className={classes.title}
                      >
                        Visualizador
                      </Typography>
                  </React.Fragment>
                } />
                <Route exact path='/exams' component={() =>
                    <Typography
                      component="h1"
                      variant="h6"
                      color="inherit"
                      noWrap
                      className={classes.title}
                    >
                      Exames
                    </Typography>
                } />
                <Route exact path='/exams/toreview' component={() =>
                  <React.Fragment>
                      <Typography
                        component="h1"
                        variant="h6"
                        color="inherit"
                        noWrap
                        className={classes.title}
                      >
                        Exames para revisar
                      </Typography>
                  </React.Fragment>
                } />
                <Route path='/laudario' component={() =>
                    <Typography
                      component="h1"
                      variant="h6"
                      color="inherit"
                      noWrap
                      className={classes.title}
                    >
                      Laudário
                    </Typography>
                } />
                <Route path='/finance/me' component={() =>
                    <Typography
                      component="h1"
                      variant="h6"
                      color="inherit"
                      noWrap
                      className={classes.title}
                    >
                      Meu Financeiro {currentUser != null ? " - " + utils.userFullName(currentUser) : ""}
                    </Typography>
                } />
                {canViewClinicFinances ?
                    <Route path='/finance/clinic' component={() =>
                        <Typography
                          component="h1"
                          variant="h6"
                          color="inherit"
                          noWrap
                          className={classes.title}
                        >
                          Financeiro Clínica
                        </Typography>
                    } />
                    : null
                }
                {canViewGeneralFinances ?
                    <Route path='/finance/general' component={() =>
                        <Typography
                          component="h1"
                          variant="h6"
                          color="inherit"
                          noWrap
                          className={classes.title}
                        >
                          Financeiro Geral
                        </Typography>
                    } />
                    : null
              }
                {canViewBillingGroupFinances ?
                    <Route path='/finance/billinggroup' component={() =>
                        <Typography
                          component="h1"
                          variant="h6"
                          color="inherit"
                          noWrap
                          className={classes.title}
                        >
                          Financeiro Funcionário
                        </Typography>
                    } />
                    : null
              }
                {canViewMessageFinances ?
                    <Route path='/finance/message' component={() =>
                        <Typography
                          component="h1"
                          variant="h6"
                          color="inherit"
                          noWrap
                          className={classes.title}
                        >
                          Financeiro Mensagens
                        </Typography>
                    } />
                    : null
              }
                <Route path='/config' component={() =>
                    <Typography
                      component="h1"
                      variant="h6"
                      color="inherit"
                      noWrap
                      className={classes.title}
                    >
                      Configurações
                    </Typography>
                } />
                {canCreateExamManually ?
                    <Route path='/exams/superexam' component={() =>
                        <Typography
                          component="h1"
                          variant="h6"
                          color="inherit"
                          noWrap
                          className={classes.title}
                        >
                          Criação Manual de Exames
                        </Typography>
                    } />
                    : null
              }
                {canAccessDashboard ?
                    <Route path='/dashboard' component={() =>
                        <Typography
                          component="h1"
                          variant="h6"
                          color="inherit"
                          noWrap
                          className={classes.title}
                        >
                          Painel
                        </Typography>
                    } />
                    : null
              }
                <Route path='/' component={() =>
                    <Redirect to="/exams" />
              } />
            </Switch>
            {canCreateExamManually ?
              <ManualExamCreationButton />
              :
              null
            }
            <SubmitFeedbackButton />
            <LogoutButton/>
          </Toolbar>
        </AppBar>
        <Drawer
          variant="permanent"
          classes={{
            paper: classNames(classes.drawerPaper, !this.state.open && classes.drawerPaperClose),
          }}
          open={this.state.open}
        >
          <div className={classes.toolbarIcon}>
            <IconButton onClick={this.handleDrawerClose} size="large">
              <ChevronLeftIcon />
            </IconButton>
          </div>
          <Divider />
          <List>
            <div>
              <ListItem button onClick={() => this.props.history.push('/exams')}>
                <ListItemIcon>
                  <AssignmentInd />
                </ListItemIcon>
                <ListItemText primary="Exames" />
              </ListItem>
            </div>
            {utils.user_has_permission(currentUser, "exams.view_clinicreport") ? (
            <div>
              <ListItem button onClick={() => this.props.history.push('/laudario')}>
                <ListItemIcon>
                  <ReceiptIcon />
                </ListItemIcon>
                <ListItemText primary="Laudário" />
              </ListItem>
            </div>
            ) : (<div/>)
            }
            <div>
              <Accordion elevation={0}>
                <AccordionSummary
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                ><AttachMoneyIcon color="action"/>
                </AccordionSummary>
                <AccordionDetails>
                  <ListItem button onClick={() => this.props.history.push('/finance/me')}>
                    <ListItemIcon>
                      <PersonIcon/>
                    </ListItemIcon>
                    <ListItemText primary="Meu Financeiro" />
                  </ListItem>
                {canViewClinicFinances ?
                    <ListItem button onClick={() => this.props.history.push('/finance/clinic')}>
                      <ListItemIcon>
                        <LocalHospitalIcon />
                      </ListItemIcon>
                      <ListItemText primary="Financeiro Clínica" />
                    </ListItem>
                    : null
                }
                {canViewGeneralFinances ?
                    <ListItem button onClick={() => this.props.history.push('/finance/general')}>
                      <ListItemIcon>
                        <BusinessIcon />
                      </ListItemIcon>
                      <ListItemText primary="Financeiro Geral" />
                    </ListItem>
                    : null
                }
                {canViewBillingGroupFinances ?
                    <ListItem button onClick={() => this.props.history.push('/finance/billinggroup')}>
                      <ListItemIcon>
                        <BadgeIcon />
                      </ListItemIcon>
                      <ListItemText primary="Financeiro Funcionário" />
                    </ListItem>
                    : null
                }
                {canViewMessageFinances ?
                    <ListItem button onClick={() => this.props.history.push('/finance/message')}>
                      <ListItemIcon>
                        <MessageIcon />
                      </ListItemIcon>
                      <ListItemText primary="Financeiro Mensagens" />
                    </ListItem>
                    : null
                }
                </AccordionDetails>
              </Accordion>
            </div>
            <div>
              <ListItem button onClick={() => this.props.history.push('/config')}>
                <ListItemIcon>
                  <SettingsIcon />
                </ListItemIcon>
                <ListItemText primary="Configurações" />
              </ListItem>
            </div>
            {canAccessDashboard ? (
            <div>
              <ListItem button onClick={() => this.props.history.push('/dashboard')}>
                <ListItemIcon>
                  <DashboardIcon />
                </ListItemIcon>
                <ListItemText primary="Painel" />
              </ListItem>
            </div>
            ) : (<div/>)
            }
          </List>
        </Drawer>
        <main className={classes.content}>
          <div className={classes.appBarSpacer} />
          <Switch >
            <Route exact path='/exams/exam/:id' component={ExamDetails} />
            <Route exact path='/exams/:id/subexams' component={SuperExamDetails} />
            <Route exact path='/exams/:id/edit' component={ExamSetFields} />
            <Route exact path='/exams/:id/report-pdf-viewer' component={ReportPdfViewer} />
            <Route path='/exams/viewer' component={SuperExamViewer} />
            <Route exact path='/exams' component={Exams} />
            <Route exact path='/exams/toreview' component={ExamsToReview} />
            <Route exact path='/laudario' component={Reports} />
            <Route exact path='/laudario/laudos/create'
              render={(routeProps) => (
                <ReportDetails {...routeProps} creating={true} />
              )}/>
            <Route exact path='/laudario/laudos/:id' component={ReportDetails} />
            <Route exact path='/images' component={ImageUploader} />
            <Route exact path='/finance/me' component={UserFinances} />
            <Route exact path='/finance/clinic' component={ClinicFinances} />
            <Route exact path='/finance/general' component={GeneralFinances} />
            <Route exact path='/finance/billinggroup' component={BillingGroupFinances} />
            <Route exact path='/finance/message' component={MessageFinances} />
            <Route exact path='/config' component={Config} />
            <Route exact path='/dashboard' component={MedicDashboard} />
            <Route exact path='/exams/superexam' component={ManualExamCreation} />
          </Switch>
        </main>
      </div>
    );
  }
}

Dashboard.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withRouter(withStyles(styles)(connect(mapStateToProps)(Dashboard)));
