import React, { useState, useEffect } from 'react';

import * as utils from '../utils'

function ReportPreview(props) {
  var [url, setUrl] = useState("");

  useEffect(() => {
    var blob = utils.b64toBlob(props.data, 'application/pdf');
    var newUrl = URL.createObjectURL(blob);
    setUrl(newUrl);
  }, [props.data])

  return (
    <embed
      src={url + '#toolbar=0'}
      type='application/pdf'
      width="100%"
      height="100%"
    />
  );
}

export default ReportPreview;
