export const SPEECH_GLOBAL_CONFIG_GET = 'SPEECH_GLOBAL_CONFIG_GET';
export const SPEECH_GLOBAL_CONFIG_GET_SUCCESS = 'SPEECH_GLOBAL_CONFIG_GET_SUCCESS';
export const SPEECH_GLOBAL_CONFIG_GET_FAILURE = 'SPEECH_GLOBAL_CONFIG_GET_FAILURE';
export const SPEECH_CONFIG_GET = 'SPEECH_CONFIG_GET';
export const SPEECH_CONFIG_GET_SUCCESS = 'SPEECH_CONFIG_GET_SUCCESS';
export const SPEECH_CONFIG_GET_FAILURE = 'SPEECH_CONFIG_GET_FAILURE';
export const SPEECH_CONFIG_ADD_TEXT_REPLACEMENT = 'SPEECH_CONFIG_ADD_TEXT_REPLACEMENT';
export const SPEECH_CONFIG_ADD_TEXT_REPLACEMENT_SUCCESS = 'SPEECH_CONFIG_ADD_TEXT_REPLACEMENT_SUCCESS';
export const SPEECH_CONFIG_ADD_TEXT_REPLACEMENT_FAILURE = 'SPEECH_CONFIG_ADD_TEXT_REPLACEMENT_FAILURE';
export const SPEECH_CONFIG_UPDATE_TEXT_REPLACEMENT = 'SPEECH_CONFIG_UPDATE_TEXT_REPLACEMENT';
export const SPEECH_CONFIG_UPDATE_TEXT_REPLACEMENT_SUCCESS = 'SPEECH_CONFIG_UPDATE_TEXT_REPLACEMENT_SUCCESS';
export const SPEECH_CONFIG_UPDATE_TEXT_REPLACEMENT_FAILURE = 'SPEECH_CONFIG_UPDATE_TEXT_REPLACEMENT_FAILURE';
export const SPEECH_CONFIG_DELETE_TEXT_REPLACEMENT = 'SPEECH_CONFIG_DELETE_TEXT_REPLACEMENT';
export const SPEECH_CONFIG_DELETE_TEXT_REPLACEMENT_SUCCESS = 'SPEECH_CONFIG_DELETE_TEXT_REPLACEMENT_SUCCESS';
export const SPEECH_CONFIG_DELETE_TEXT_REPLACEMENT_FAILURE = 'SPEECH_CONFIG_DELETE_TEXT_REPLACEMENT_FAILURE';

export const getGlobalConfig = () => {
  return {
    type: SPEECH_GLOBAL_CONFIG_GET,
  };
};

export const getGlobalConfigSuccess = (data) => {
  return {
    type: SPEECH_GLOBAL_CONFIG_GET_SUCCESS,
    data,
  };
};

export const getGlobalConfigFailure = (err) => {
  return {
    type: SPEECH_GLOBAL_CONFIG_GET_FAILURE,
    err
  };
};

export const getConfig = () => {
  return {
    type: SPEECH_CONFIG_GET,
  };
};

export const getConfigSuccess = (data) => {
  return {
    type: SPEECH_CONFIG_GET_SUCCESS,
    data,
  };
};

export const getConfigFailure = (err) => {
  return {
    type: SPEECH_CONFIG_GET_FAILURE,
    err
  };
};

export const addTextReplacement = () => {
  return {
    type: SPEECH_CONFIG_ADD_TEXT_REPLACEMENT,
  };
};

export const addTextReplacementSuccess = () => {
  return {
    type: SPEECH_CONFIG_ADD_TEXT_REPLACEMENT_SUCCESS,
  };
};

export const addTextReplacementFailure = () => {
  return {
    type: SPEECH_CONFIG_ADD_TEXT_REPLACEMENT_FAILURE,
  };
};

export const updateTextReplacement = () => {
  return {
    type: SPEECH_CONFIG_ADD_TEXT_REPLACEMENT,
  };
};

export const updateTextReplacementSuccess = () => {
  return {
    type: SPEECH_CONFIG_ADD_TEXT_REPLACEMENT_SUCCESS,
  };
};

export const updateTextReplacementFailure = () => {
  return {
    type: SPEECH_CONFIG_ADD_TEXT_REPLACEMENT_FAILURE,
  };
};

export const deleteTextReplacement = () => {
  return {
    type: SPEECH_CONFIG_DELETE_TEXT_REPLACEMENT,
  };
};

export const deleteTextReplacementSuccess = () => {
  return {
    type: SPEECH_CONFIG_DELETE_TEXT_REPLACEMENT_SUCCESS,
  };
};

export const deleteTextReplacementFailure = () => {
  return {
    type: SPEECH_CONFIG_DELETE_TEXT_REPLACEMENT_FAILURE,
  };
};
