export const BILLING_FINANCE_MINE_GET = 'BILLING_FINANCE_MINE_GET';
export const BILLING_FINANCE_MINE_GET_SUCCESS = 'BILLING_FINANCE_MINE_GET_SUCCESS';
export const BILLING_FINANCE_MINE_GET_FAILURE = 'BILLING_FINANCE_MINE_GET_FAILURE';
export const BILLING_FINANCE_CLINIC_GET = 'BILLING_FINANCE_CLINIC_GET';
export const BILLING_FINANCE_CLINIC_GET_SUCCESS = 'BILLING_FINANCE_CLINIC_GET_SUCCESS';
export const BILLING_FINANCE_CLINIC_GET_FAILURE = 'BILLING_FINANCE_CLINIC_GET_FAILURE';
export const BILLING_FINANCE_MESSAGE_GET = 'BILLING_FINANCE_MESSAGE_GET';
export const BILLING_FINANCE_MESSAGE_GET_SUCCESS = 'BILLING_FINANCE_MESSAGE_GET_SUCCESS';
export const BILLING_FINANCE_MESSAGE_GET_FAILURE = 'BILLING_FINANCE_MESSAGE_GET_FAILURE';
export const BILLING_FINANCE_GENERAL_GET = 'BILLING_FINANCE_GENERAL_GET';
export const BILLING_FINANCE_GENERAL_GET_SUCCESS = 'BILLING_FINANCE_GENERAL_GET_SUCCESS';
export const BILLING_FINANCE_GENERAL_GET_FAILURE = 'BILLING_FINANCE_GENERAL_GET_FAILURE';
export const BILLING_FINANCE_BILLING_GROUP_GET = 'BILLING_FINANCE_BILLING_GROUP_GET';
export const BILLING_FINANCE_BILLING_GROUP_GET_SUCCESS = 'BILLING_FINANCE_BILLING_GROUP_GET_SUCCESS';
export const BILLING_FINANCE_BILLING_GROUP_GET_FAILURE = 'BILLING_FINANCE_BILLING_GROUP_GET_FAILURE';
export const BILLING_CLEAR_CURRENT = 'BILLING_CLEAR_CURRENT';

export const getUserFinance = (data) => {
  return {
    type: BILLING_FINANCE_MINE_GET,
    data,
  };
};

export const getUserFinanceSuccess = (data) => {
  return {
    type: BILLING_FINANCE_MINE_GET_SUCCESS,
    data,
  };
};

export const getUserFinanceFailure = (err) => {
  return {
    type: BILLING_FINANCE_MINE_GET_FAILURE,
    err
  };
};

export const getClinicFinance = (data) => {
  return {
    type: BILLING_FINANCE_CLINIC_GET,
    data,
  };
};

export const getClinicFinanceSuccess = (data) => {
  return {
    type: BILLING_FINANCE_CLINIC_GET_SUCCESS,
    data,
  };
};

export const getClinicFinanceFailure = (err) => {
  return {
    type: BILLING_FINANCE_CLINIC_GET_FAILURE,
    err
  };
};

export const getMessageFinance = (data) => {
  return {
    type: BILLING_FINANCE_MESSAGE_GET,
    data,
  };
};

export const getMessageFinanceSuccess = (data) => {
  return {
    type: BILLING_FINANCE_MESSAGE_GET_SUCCESS,
    data,
  };
};

export const getMessageFinanceFailure = (err) => {
  return {
    type: BILLING_FINANCE_MESSAGE_GET_FAILURE,
    err
  };
};

export const getGeneralFinance = (data) => {
  return {
    type: BILLING_FINANCE_GENERAL_GET,
    data,
  };
};

export const getGeneralFinanceSuccess = (data) => {
  return {
    type: BILLING_FINANCE_GENERAL_GET_SUCCESS,
    data,
  };
};

export const getGeneralFinanceFailure = (err) => {
  return {
    type: BILLING_FINANCE_GENERAL_GET_FAILURE,
    err
  };
};

export const getBillingGroupFinance = (data) => {
  return {
    type: BILLING_FINANCE_BILLING_GROUP_GET,
    data,
  };
};

export const getBillingGroupFinanceSuccess = (data) => {
  return {
    type: BILLING_FINANCE_BILLING_GROUP_GET_SUCCESS,
    data,
  };
};

export const getBillingGroupFinanceFailure = (err) => {
  return {
    type: BILLING_FINANCE_BILLING_GROUP_GET_FAILURE,
    err
  };
};

export const clearCurrent = () => {
  return {
    type: BILLING_CLEAR_CURRENT
  };
};