import React from 'react';
import { connect } from 'react-redux';
import withStyles from '@mui/styles/withStyles';
import Typography from '@mui/material/Typography';


const styles = theme => ({
  footer: {
    width: '100%',
    marginTop: '1rem',
    padding: '1rem',
    position: 'fixed',
    bottom: 0,
    left: 0,
    backgroundColor: theme.palette.primary.light,
  },
});

const mapStateToProps = (state) => {
  return {
  };
};

function FooterLGPD(props) {
  const { classes } = props;

  return (
    <React.Fragment>
      <div className={classes.footer}>
      <Typography variant="subtitle1" color="textPrimary" align="center">
        {'Se você precisar exercer algum dos direitos referente a reclamações e solicitações a respeito da Lei Geral de Proteção de Dados, ou se você tiver dúvidas, comentários ou sugestões, entre em contato conosco no seguinte endereço: suporte@imagisolucoes.com.br'}
      </Typography>
      </div>
    </React.Fragment>
  );
}

export default withStyles(styles)(connect(mapStateToProps)(FooterLGPD));
