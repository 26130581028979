import React, { useState, useCallback } from 'react';
import { withRouter } from 'react-router-dom';
import withStyles from '@mui/styles/withStyles';
import { connect } from 'react-redux';

import fileDownload from 'js-file-download';

import Typography from '@mui/material/Typography';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import CropFreeIcon from '@mui/icons-material/CropFree';
import ImageIcon from '@mui/icons-material/Image';
import ErrorIcon from '@mui/icons-material/Error';
import ButtonGroup from '@mui/material/ButtonGroup';
import { CircularProgress } from '@mui/material';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';
import TextField from '@mui/material/TextField';
import { FormControl, FormControlLabel } from '@mui/material';
import Button from '@mui/material/Button';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormLabel from '@mui/material/FormLabel';

import * as superExamsHttp from '../http/super-exams';
import * as examsHttp from '../http/exams'
import * as utils from '../utils';


const styles = theme => ({
  root: {
    width: '100%',
  },
  formControl: {
    margin: theme.spacing(2),
  },
  submitButton: {
    margin: theme.spacing(2),
  }
});

const SpinnerAdornment = withStyles(styles)(props => (
  <CircularProgress
    color="secondary"
    className={props.classes.spinner}
    size={20}
  />
))

const mapStateToProps = (state) => {
  return {
    isDownloading: state.superExams.downloadingImages,
    downloadingImagesError: state.superExams.downloadingImagesError,
    clinics: state.clinics,
    isCreatingMedicalReportAccessToken: state.exams.creatingMedicalReportAccessToken,
    createMedicalReportAccessTokenError: state.exams.createMedicalReportAccessTokenError,
  };
};

function ExamActionsButtons(props) {
  const { classes, exam, user, clinics } = props;
  const [accessTokenDialogOpen, setAccessTokenDialogOpen] = useState(false);
  const [accessKey, setAccessKey] = useState('')
  const [radioValue, setRadioValue] = useState('print');
  const [openEmailField, setOpenEmailField] = useState(false);
  const [openPhoneField, setOpenPhoneField] = useState(false);
  const [tokenEmail, setTokenEmail] = useState('');
  const [tokenPhone, setTokenPhone] = useState('');


  var clinic = null;
  if (clinics.clinics_map !== undefined && exam != null) {
    clinic = clinics.clinics_map[exam.clinic_id];
  }

  const errorText = props.downloadingImagesError || props.createMedicalReportAccessTokenError;
  const loading = props.isDownloading || props.isCreatingMedicalReportAccessToken;

  const canUseViewer = clinic != null && clinic.wadoserver != null && utils.user_has_permission(user, 'exams.can_use_viewer');
  const openViewer = useCallback(() => {
    if (exam == null)
      return;
    window.open('/exams/viewer/viewer/' + exam.exam.study_iuid, "_blank")
  }, [exam]);

  const canDownloadImages = clinic != null && clinic.dcm_image_download && utils.user_has_permission(user, 'exams.download_exam_images');
  const cloudDownload = useCallback(() => {
    if (exam == null)
      return;
    superExamsHttp.downloadSuperExamImages(exam.exam_id);
  }, [exam]);

  const canGenerateAccessToken = utils.user_has_permission(user, 'exams.add_medicalreportaccesstoken');
  const handleAccessToken = useCallback(async () => {
    setAccessTokenDialogOpen(true);
  }, []);

  const handleClose = useCallback(async (value) => {
    setAccessTokenDialogOpen(false);
  }, []);

  const handleRadioChange = (event) => {
    setRadioValue(event.target.value);
    if (event.target.value === 'email') {
      setOpenEmailField(true);
      setOpenPhoneField(false);
      setTokenPhone('');
    } else if (event.target.value === 'sms') {
      setOpenPhoneField(true);
      setOpenEmailField(false);
      setTokenEmail('');
    } else if (event.target.value === 'whatsapp') {
      setOpenPhoneField(true);
      setOpenEmailField(false);
      setTokenEmail('');
    } else {
      setOpenEmailField(false);
      setOpenPhoneField(false);
      setTokenEmail('');
      setTokenPhone('');
    }
  };

  const download = useCallback(async (exam, data) => {
    if (exam == null)
      return;
    try {
      const res = await examsHttp.createAccessToken(data);
      var res_data = res.data
      res_data = utils.b64toBlob(res_data, 'application/pdf');
      fileDownload(res_data, `report_access_token_${exam.patient_name}_download_${exam.study_datetime}.pdf`, 'application/pdf');
      return true;
    } catch (err) {
      return false;
    }
  }, []);

  const generateAccessToken = useCallback(async (value) => {
    if (exam == null)
      return;
    var method_key = '';
    if (value.tokenEmail) {
      method_key = value.tokenEmail;
    } else if (value.tokenPhone) {
      method_key = value.tokenPhone;
    }

    let data = {'subexam_id': exam.id, 'access_key': value.accessKey, 'delivery_method': value.radioValue, 'method_key': method_key};
    setAccessTokenDialogOpen(false);

    if (value.radioValue === 'print') {
      const response = await download(exam, data);
      if (!response) {
        alert("Falha ao criar o token de acesso")
      }
      return false;
    } else {
      const response = await examsHttp.createAccessToken(data);
      if (response.status === 201) {
        alert(response.data.message)
      } else {
        alert("Falha ao criar o token de acesso")
      }
    }

  }, [exam, download]);

  if (exam == null || clinic == null)
    return null;

  return (
    <React.Fragment>
      { errorText != null ?
      <Typography color="warning.light" fontWeight="bold">
            <IconButton
              color='inherit'
              aria-label="Error"
              component="span"
              disableRipple="true"
              size="medium">
                <ErrorIcon />
            </IconButton>{ errorText }
      </Typography>
      : null }
      { loading ? <SpinnerAdornment /> : null }
      <ButtonGroup>
          {canDownloadImages ?
              <Tooltip title="Baixar imagens do exame" aria-label="download-exam-images">
                  <IconButton
                    aria-label="Baixar imagens"
                    component="span"
                    onClick={() => cloudDownload()}
                    size="large">
                      <CloudDownloadIcon style={{ color: "white" }} />
                  </IconButton>
              </Tooltip>
              : null
          }
          {canGenerateAccessToken ?
              <Tooltip title="Gerar token de acesso" aria-label="generate-access-token">
                  <IconButton
                    aria-label="Gerar token de acesso"
                    component="span"
                    onClick={() => handleAccessToken()}
                    size="large">
                      <CropFreeIcon style={{ color: "white" }}/>
                  </IconButton>
              </Tooltip>
              : null
          }
          {canUseViewer ?
              <Tooltip title="Visualizador" aria-label="download-exam-images">
                  <IconButton
                    aria-label="Visualizador"
                    component="span"
                    onClick={() => openViewer()}
                    size="large">
                      <ImageIcon style={{ color: "white" }} />
                  </IconButton>
              </Tooltip>
              : null
          }
          <Dialog onClose={handleClose} aria-labelledby="simple-dialog-title" open={accessTokenDialogOpen}>
            <form id="access-key-form" onSubmit={(e) => { e.preventDefault(); generateAccessToken({accessKey, radioValue, tokenEmail, tokenPhone}); handleClose(); } }>
              <DialogTitle id="form-dialog-title">Gerar token para o exame</DialogTitle>
                <DialogContent>
                  <DialogContentText>
                    Informe a chave de acesso do paciente para cadastro.
                  </DialogContentText>
                  <TextField
                    autoFocus
                    margin="dense"
                    id="access-key-input"
                    label="Chave de acesso"
                    required={true}
                    type="number"
                    helperText="Preencher apenas com números"
                    errorText="A chave de acesso deverá conter apenas números e possuir 11 dígitos."
                    onChange={event => setAccessKey(event.target.value)}
                    fullWidth
                  />
                  <DialogContentText>
                  </DialogContentText>
                  <FormControl component="fieldset" fullWidth>
                    <FormLabel component="legend">Métodos de envio:</FormLabel>
                    <RadioGroup
                      aria-label="token-sender"
                      name="controlled-radio-buttons-group"
                      value={radioValue}
                      required
                      onChange={handleRadioChange}
                      row
                    >
                      <FormControlLabel value="email" control={<Radio />} label="E-mail" />
                      <FormControlLabel value="sms" control={<Radio />} label="SMS" />
                      <FormControlLabel value="whatsapp" control={<Radio />} label="whatsapp" />
                      <FormControlLabel value="print" control={<Radio />} label="Impresso" />
                    </RadioGroup>
                    {openEmailField ?
                      <TextField
                        autoFocus
                        margin="dense"
                        id="token-email-input"
                        label="E-mail"
                        autoComplete="off"
                        required={true}
                        helperText="Inserir e-mail válido"
                        onChange={event => setTokenEmail(event.target.value)}
                        fullWidth
                      />
                      : null
                    }
                    {openPhoneField ?
                      <TextField
                        autoFocus
                        margin="dense"
                        id="token-phone-input"
                        label="Telefone"
                        type="number"
                        autoComplete='off'
                        required={true}
                        helperText="Inserir telefone no formato: DDD + Número"
                        onChange={event => setTokenPhone(event.target.value)}
                        fullWidth
                      />
                      : null
                    }
                  </FormControl>
                </DialogContent>
                <DialogActions>
                  <Button onClick={handleClose} variant="contained" color="primary" className={classes.submitButton}>
                    Cancelar
                  </Button>
                  <Button variant="contained" color="primary" className={classes.submitButton} type="submit">
                    Criar
                  </Button>
                </DialogActions>
            </form>
          </Dialog>
      </ButtonGroup>
    </React.Fragment>
  );
}

export default withRouter(withStyles(styles)(connect(mapStateToProps)(ExamActionsButtons)));
