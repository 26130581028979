import React, { useState, useCallback } from 'react';
import { connect } from 'react-redux';
import withStyles from '@mui/styles/withStyles';

import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';

import LogoutButton from '../components/LogoutButton';
import Loading from '../components/Loading';
import Copyright from '../components/Copyright'

import * as authHttp from '../http/auth';


const styles = theme => ({
  bannerLogo: {
      maxWidth: '100px',
  },
  logoutButton: {
    paddingTop: '10px',
    minWidth: '250px',
  },
  passwordResetButton: {
    background: 'none!important',
    border: 'none',
    padding: '0!important',
    textDecoration: 'underline',
    cursor: 'pointer',
  },
  form: {
      maxWidth: '250px',
  },
  paper: {
    marginTop: theme.spacing(8),
    color: 'inherit',
    backgroundColor: 'transparent',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
});

const mapStateToProps = (state) => {
  return {
    errorMessage: state.auth.errorMessage,
    user: state.auth.currentUser
  };
};

function LoginTwoFactorToken(props) {
  const { classes } = props;
  const [ loading, setLoading ] = useState(false);
  const [ validationToken, setValidationToken ] = useState('');
  const [ enabledValidationButton, setEnabledValidationButton ] = useState(false);


  const submitCallback = useCallback(async (event) => {
    event.preventDefault();
    const data = {
      token: validationToken,
    };
    setEnabledValidationButton(true);
    setLoading(true);
    const res = await authHttp.validateTwoFactorAuthTokenLogin(data);
    setLoading(false);
    setEnabledValidationButton(false);
    if (!res.result) {
      alert('Não foi possível validar o dispositivo, tente novamente.');
    }
  }, [validationToken]);


  return (
    <Container component="main" maxWidth="xs">
      <div className={classes.paper}>
        <img src="/imagiris-logo.png" alt="logo" className={classes.bannerLogo} /><br />
        {
          props.errorMessage &&
          <Typography color='error'>{props.errorMessage}</Typography>
        }
        <form className={classes.form} noValidate>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="two-factor-token"
            label="Código de autenticação."
            name="two-factor-token"
            autoComplete="off"
            autoFocus
            helperText="Preencher com o código que aparece em seu aplicativo."
            onChange={event => setValidationToken(event.target.value)}
          />
          {
            loading
              ?
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <Grid container justifyContent="center">
                    <Loading />
                  </Grid>
                </Grid>
              </Grid>
              :
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                className={classes.submit}
                onClick={submitCallback}
                disabled={enabledValidationButton}
               >
                Entrar
              </Button>
          }
          <Grid container  className={classes.logoutButton}>
            <Grid item xs >
              <LogoutButton />
            </Grid>
          </Grid>
      </form>
      </div>
      <Box mt={8}>
        <Copyright />
      </Box>
    </Container>
  );
}

export default connect(mapStateToProps)(withStyles(styles)(LoginTwoFactorToken));