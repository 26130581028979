import { combineReducers } from 'redux';

import { LOGOUT_SUCCESS } from '../actions/auth';

import auth from './auth';
import speech from './speech';
import exams from './exams';
import reports from './reports';
import clinics from './clinics';
import billing from './billing';
import loading from './loading';
import error from './error';
import superExams from './super-exams';
import accounts from './accounts';

const appReducer = combineReducers({
  auth, exams, speech, reports, clinics, billing, loading, error, superExams, accounts,
});

const rootReducer = (state, action) => {
  if (action.type === LOGOUT_SUCCESS) {
    state = undefined
  }

  return appReducer(state, action)
};

export default rootReducer;
