import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import makeStyles from '@mui/styles/makeStyles';
import Container from '@mui/material/Container';

import { installViewer } from '@ohif/viewer';

import * as authHttp from '../http/auth';

const mapStateToProps = (state) => {
  return {
    currentUser: state.auth.currentUser,
    exam: state.superExams.current
  };
};

const useStyles = makeStyles(theme => ({
  title: {
    marginTop: theme.spacing(5)
  }
}));

function SuperExamViewer(props) {
  const { exam, currentUser } = props;
  const classes = useStyles();
  const containerId = 'viewerDiv';

  useEffect(() => {
    const timer = setInterval(() => {
        authHttp.getCurrentUser();
    }, 5000);
    return () => clearInterval(timer);
  });

  useEffect(() => {
//    var base_url = 'https://server.dcmjs.org/dcm4chee-arc/aets/DCM4CHEE';
    var base_url = 'https://api.imagiris.com.br/viewer';
    var ohifViewerConfig = {
	  // default: '/'
	  routerBasename: '/exams/viewer',
	  servers: {
	    dicomWeb: [
	    {
          name: 'DCM4CHEE',
          wadoUriRoot: base_url + '/wado',
          qidoRoot: base_url + '/rs',
          wadoRoot: base_url + '/rs',
          qidoSupportsIncludeField: true,
          imageRendering: 'wadouri',
          thumbnailRendering: 'wadouri',
          requestOptions: {
            auth: (options) => {
              return 'Bearer ' + localStorage.getItem('access_token');
            },
          }
        },],
      },
      studyPrefetcher: {
        enabled: true,
        order: 'closest',
        displaySetCount: 3,
        preventCache: false,
        prefetchDisplaySetsTimeout: 300,
        maxNumPrefetchRequests: 4,
        displayProgress: true,
        includeActiveDisplaySet: true,
      },
     };
    var componentRenderedOrUpdatedCallback = function() {
      console.log('OHIF Viewer rendered/updated');
    };
    installViewer(
      ohifViewerConfig,
      containerId,
      componentRenderedOrUpdatedCallback
    );
   })

  // useEffect(() => { authHttp.getCurrentUser(); superExamsHttp.getSuperExam(props.match.params.id); }, [props.match.params.id]);

  return (
    <div id="root">
      <Container maxWidth="xl" id={containerId}>
      </Container>
    </div>
  );
};

export default connect(mapStateToProps)(SuperExamViewer);
