import React, { useState } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import FormGroup from '@mui/material/FormGroup';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';

import * as superExamsHttp from '../http/super-exams';

const useStyles = makeStyles(theme => ({
  form: {
    marginTop: theme.spacing(2)
  },
  textField: {
    width: 300
  },
  button: {
    marginLeft: theme.spacing(2),
  }
}));

export default function SuperExamAddExam(props) {
  const classes = useStyles();
  const [ description, setDescription ] = useState();

  const { exam } = props;

  const handleSubmit = async event => {
    event.preventDefault();
    await superExamsHttp.addExam(exam.id, { description });
    setDescription("");
  };

  return (
    <form className={classes.form} autoComplete="off" onSubmit={handleSubmit}>
      <FormGroup row>
        <TextField className={classes.textField} value={description} required variant="outlined" label="Descrição" onChange={event => setDescription(event.target.value)}/>
        <Button className={classes.button} variant="contained" color="primary" type="submit">Adicionar</Button>
      </FormGroup>
    </form>
  );
};
