import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import ListItemText from '@mui/material/ListItemText';

const useStyles = makeStyles(theme => ({
  examPaper: {
    padding: '5px'
  },
}));

export default function SuperExamDetailsTable(props) {
  const { exam } = props;
  const classes = useStyles();

  return (
    <Paper className={ classes.examPaper }>
      <Grid container justifyContent="space-around" spacing={0}>
        <Grid item xs={12} md={3} lg={1}>
          <ListItemText primary={exam.pat_name_clean} secondary="Paciente" />
        </Grid>
        <Grid item xs={12} md={3} lg={1}>
          <ListItemText primary={exam.patient_age} secondary="Idade" />
        </Grid>
        <Grid item xs={12} md={3} lg={1}>
          <ListItemText primary={exam.study_desc} secondary="Exame" />
        </Grid>
        <Grid item xs={12} md={3} lg={1}>
          <ListItemText primary={exam.pat_sex} secondary="Sexo" />
        </Grid>
        <Grid item xs={12} md={3} lg={1}>
          <ListItemText primary={exam.study_datetime_short} secondary="Timestamp" />
        </Grid>
        <Grid item xs={12} md={3} lg={1}>
          <ListItemText primary={exam.modality} secondary="Modalidade" />
        </Grid>
        <Grid item xs={12} md={3} lg={1}>
          <ListItemText primary={exam.clinic} secondary="Clínica" />
        </Grid>
        <Grid item xs={12} md={3} lg={1}>
          <ListItemText primary={exam.created_time_short} secondary="Inclusão PACS" />
        </Grid>
      </Grid>
    </Paper>
  );
};
