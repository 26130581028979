export function TranscriptionState() {
    this.working = false;
    this.originalText = '';
    this.transcriptText = '';
}

export function fixTextSpacing(text) {
    const searchRegExpDot = /\s\./g;
    const searchRegExpComma = /\s,/g;

    text = text.replace(searchRegExpDot, ".")
    text = text.replace(searchRegExpComma, ",")

    const textSplit = text.split('');

    var i;
    for (i = 0; i < textSplit.length; i++) {
        if (textSplit[i] === '.' && textSplit[i+2]) {
            textSplit[i+2] = textSplit[i+2].toUpperCase();
        };
    };

    const textJoin = textSplit.join('');
    text = textJoin

    return text;
}


export function transformText(speechConfig, text) {
    text = fixTextSpacing(text);
    if (speechConfig == null) {
        return text;
    }
    var globalCommands = speechConfig.globalTextReplacements || [];
    for (var i = 0; i < globalCommands.length; i++) {
        var regEx = new RegExp(globalCommands[i].text, "ig");
        text = text.replace(regEx, globalCommands[i].replacement_text);
    }
    var commands = speechConfig.textReplacements || [];
    for (i = 0; i < commands.length; i++) {
        regEx = new RegExp(commands[i].text, "ig");
        text = text.replace(regEx, commands[i].replacement_text);
    }
    return text;
}
