import React, { useState, useEffect, useCallback } from 'react';
import { connect } from 'react-redux';
import withStyles from '@mui/styles/withStyles';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import UserFinanceSummary from '../components/UserFinanceSummary';
import MedicFinanceSummary from '../components/MedicFinanceSummary';
import GeneralFinanceSummary from '../components/GeneralFinanceSummary';
import PeriodSearchForm from '../components/PeriodSearchForm';
import * as billingHttp from '../http/billing';
import * as clinicHttp from '../http/clinics';
import * as authHttp from '../http/auth';
import * as utils from '../utils';
import TyperFinanceSummary from '../components/TyperFinanceSummary';

const styles = theme => ({
  root: {
    width: '100%',
  },
  title: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(1),
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
    verticalAlign: 'baseline',
  },
});

const mapStateToProps = (state) => {
  return {
    summary: state.billing.general.summary,
    myEntries: state.billing.general.entries,
    clinics: state.clinics,
    errorMessage: state.billing.general.requestError != null ? state.billing.general.requestError.response.data.detail : null,
    loading: state.billing.general.loading,
    currentUser: state.auth.currentUser,
  };
};

function GeneralFinances(props) {
  const { classes,
          clinics,
          loading,
          summary,
          errorMessage,
          currentUser,
        } = props;

  const [fromDate, setFromDate] = useState();
  const [toDate, setToDate] = useState();
  const [order, setOrder] = useState("");

  var role = null;
  if (currentUser != null && currentUser.roles != null && currentUser.roles.length > 0) {
    role = currentUser.roles[0];
  }

  const orders = [
    {label: "Data do laudo", value: "report-date"},
    {label: "Data do exame", value: "exam-date"},
    {label: "Nome do Paciente", value: "patient-name"}
  ];

  useEffect(() => {
    authHttp.getCurrentUser();
    clinicHttp.list();
    billingHttp.clearCurrent();
  }, []);

  const doQuery = useCallback(() => {
    let formattedFromDate = utils.formatDateToString(fromDate);
    let formattedToDate = utils.formatDateToString(toDate);
    billingHttp.getGeneralFinance(formattedFromDate, formattedToDate, order, role);
  }, [fromDate, toDate, order, role]);

  const setFromDateCallback = useCallback((date) => {
    setFromDate(date);
  }, []);

  const setToDateCallback = useCallback((date) => {
    setToDate(date);
  }, []);

  const getClinicName = useCallback((clinicId) => {
    if (clinicId in clinics.clinics_map) {
      return clinics.clinics_map[clinicId].name;
    }
    return clinicId;
  }, [clinics]);

  var urgCategory = null;
  for (var cId in clinics.categories) {
    if (utils.caseInsensitiveEquals(clinics.categories[cId].name, 'URGÊNCIA')) {
      urgCategory = Number(cId);
      break;
    }
  }

  const errorComponent = errorMessage != null ?
      <Typography variant="subtitle1" color="error">{errorMessage}</Typography>
      : null

  const loadingComponent = loading ?
      <Typography variant="subtitle1" color="primary">Carregando</Typography>
      : null

  return <div className={classes.root}>
    <form>
      <PeriodSearchForm fromDate={fromDate} toDate={toDate} setFromDate={setFromDateCallback} setToDate={setToDateCallback} />
      <FormControl className={classes.formControl} style={{top: "6px"}}>
        <InputLabel htmlFor="order-selector">Ordenar por</InputLabel>
        <Select
          value={order}
          inputProps={{
            name: "order",
            id: "order-selector",
          }}
          label="Ordernar por"
          onChange={(order) => setOrder(order.target.value)}
        >
          <MenuItem value="">
            <em>...</em>
          </MenuItem>
          {orders.map((order) => (
            <MenuItem key={order.value} value={order.value}>
              {order.label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <Button variant="contained" color="primary" onClick={doQuery}>
        Carregar
      </Button>
      <FormControl className={classes.formControl}>
        { loadingComponent }
      </FormControl>
    </form>
    { errorComponent }
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <UserFinanceSummary
          summary={ summary.perModality }
          totals={ summary }
          urgCategory={ urgCategory }
          valueField={"value"}
        />
      </Grid>
    </Grid>
    <Typography className={classes.title} style={{paddingTop: "20px"}} variant="h4">Clínicas</Typography>
    <Grid container spacing={2}>
      {Object.keys(summary.perClinic).map((key) => (
        <Grid item xs={12} sm={6} md={6} lg={4} xl={3} key={key}>
          <Typography className={classes.title} variant="h6">{ getClinicName(key) }</Typography>
          <UserFinanceSummary
            summary={ summary.perClinic[key].perModality }
            totals={ summary.perClinic[key] }
            urgCategory={ urgCategory }
            valueField={"value"}
          />
          <MedicFinanceSummary
            summaryPerMedic={ summary.perClinic[key].perMedic }
            summaryPerCategory={ summary.perCategory }
            totals={ summary.perClinic[key] }
            urgCategory={ urgCategory }
          />
          <TyperFinanceSummary
            summaryPerTyper={ summary.perClinic[key].perTyper }
            summaryPerCategory={ summary.perCategory }
            totals={ summary.perClinic[key] }
          />
        </Grid>
      ))}
    </Grid>
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography className={classes.title} style={{paddingTop: "20px"}} variant="h4">Resumo</Typography>
        <GeneralFinanceSummary
          summary={ summary }
          totals={ summary }
          valueField={"value"}
        />
      </Grid>
    </Grid>
    </div>
};

export default withStyles(styles)(connect(mapStateToProps)(GeneralFinances));