import React from 'react';
import QRCode from 'react-qr-code'

export default function QRCodeGenerator({ qrText }) {

  return (
    <React.Fragment>
      {qrText.length > 0 && <QRCode value={qrText} />}
    </React.Fragment>
  )
}
