import { api } from './http/axios';
import fileDownload from 'js-file-download';
import moment from 'moment';
import business from 'moment-business';
import holidays from 'date-holidays';
import * as currency from 'currency.js';


export function caseInsensitiveEquals(a, b) {
    return typeof a === 'string' && typeof b === 'string'
        ? a.localeCompare(b, undefined, { sensitivity: 'accent' }) === 0
        : a === b;
}

export function formatDateToString(date) {
  if (date === undefined) {
    return undefined;
  }
  if (date === null) {
    return null
  }
  const yyyy = date.getFullYear();
  let mm = date.getMonth() + 1; // Months start at 0
  let dd = date.getDate()

  if (dd < 10) dd = '0' + dd;
  if (mm < 10) mm = '0' + mm;

  const formattedDate = yyyy + '-' + mm + '-' + dd;

  return formattedDate
}


export function formatBillValue(value) {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }

  return currency(value, { symbol: "R$", separator: ".", decimal: "," }).divide(100).format()
}

export function datetime_to_age(dateString) {
    if (dateString === undefined) {
        return undefined;
    }
    if (dateString === null) {
        return null;
    }
    var today = new Date();
    var pattern = /(\d{2})\/(\d{2})\/(\d{4})/;
    var birthDate = new Date(dateString.replace(pattern,'$3-$2-$1'));
    var ageTime = today.getTime() - birthDate.getTime();
    var daysOld = Math.floor(ageTime/ (1000*60*60*24));
    var yearsOld = Math.floor(daysOld/365);
    var y = daysOld/365 - yearsOld;
    var monthsOld = Math.floor(((y*365)/30));

    var age = 0;
    if (yearsOld < 1) {
      if (monthsOld < 1) {
        if (daysOld === 1){
          age = daysOld + ' dia';
        } else {
          age = daysOld + ' dias';
        }
      } else if (monthsOld === 1){
        age = monthsOld + ' mês';
      } else {
        age = monthsOld + ' meses';
      }
    } else {
      age = yearsOld;
    }

    return age;
}

export function formatDate(dateString) {
    if (dateString === undefined) {
        return undefined;
    }
    if (dateString === null) {
        return null;
    }
    var date = new Date(dateString);
    return date.toLocaleString('pt-BR', { timeZone: 'America/Recife' })
  };

export function user_has_permission(user, permission) {
    if (!!user && !!user.perms) {
        if (user.perms.indexOf(permission) > -1) {
            return true;
        }
    }
    return false;
}

export function user_is_author(user, exam) {
    if (user == null) {
        return false;
    }
    if (!!exam && !!exam.report) {
        return user.id === exam.report.medic;
    }
    return false;
}

export function user_is_member(clinic, user) {
    if (user == null) {
        return false;
    }
    if (!!clinic) {
        if (!!clinic.members) {
            for (let m = 0; m < clinic.members.length; m++) {
                if (clinic.members[m].id === user.id) {
                    return true;
                }
            }
        }
    }
    return false;
}

export function user_has_role(user, role) {
    if (user == null) {
        return false;
    }
    if (user.roles.indexOf(role) > -1) {
        return true;
    }
    return false;
}

export function get_clinic_medics(clinic, user) {
    var medics = [];

    if(user_is_member(clinic, user)) {
        clinic.members.forEach (m => {
            if(user_has_role(m, 'medic')) {
                medics.push(m)
            }
        })
    }
    return medics;
}

export function subexam_can_be_deleted(user, subexam) {
    return subexam.status === 'aguardando'
}

export function b64toBlob(b64Data, contentType='', sliceSize=512) {
  const byteCharacters = atob(b64Data);
  const byteArrays = [];

  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    const slice = byteCharacters.slice(offset, offset + sliceSize);

    const byteNumbers = new Array(slice.length);
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);
    byteArrays.push(byteArray);
  }

  const blob = new Blob(byteArrays, {type: contentType});
  return blob;
}

export function capitalizeFirstLetter(string) {
  if (string === undefined) {
    return undefined;
  }
  if (string === null) {
    return null;
  }
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export function titleCase(string) {
  if (string === undefined) {
    return undefined;
  }
  if (string === null) {
    return null;
  }
  return string[0].toUpperCase() + string.slice(1).toLowerCase()
}

export async function downloadUrlBlob(url, mimetype, filename) {
    try {
      const response = await api.get(url, {responseType: 'blob'});
      var data = response.data;
      fileDownload(data, filename, mimetype);
      return true;
    } catch (err) {
      console.log(err);
      return false;
    }
}

export async function downloadUrl(url, mimetype, filename) {
    try {
      const response = await api.get(url);
      var data = response.data;
      data = b64toBlob(data, mimetype);
      fileDownload(data, filename, mimetype);
      return true;
    } catch (err) {
      console.log(err);
      return false;
    }
}

export async function downloadUrlData(url, mimetype, filename) {
    try {
      const response = await api.get(url);
      var data = response.data;
      return data;
    } catch (err) {
      console.log(err);
      return false;
    }
}

export function isNumber(value) {
  return !isNaN(value);
}

export function userFullName(user) {
  if (user == null) {
    return "";
  }
  return user.first_name + " " + user.last_name;
}

export function getErrorMessageOrDefaultMessage(res) {
  if (!!res.response) {
    if (!!res.response.data) {
      if (!!res.response.data.message) {
        return res.response.data.message
      } else if (!!res.response.data.detail) {
        return res.response.data.detail
      } else {
        switch (res.response.status) {
          case 413:
            return 'Arquivo maior que o tamanho limite';
          case 400:
            return "Verifique os parâmetros da solicitação";
          default:
            return 'Ocorreu um erro na solicitação'
        }
      }
    }
  } else {
    if (!!res.message) {
      return 'Erro inesperado na solicitação: ' + res.message
    }
  }
  return 'Ocorreu um erro na solicitação'
}


export function processExams(exams) {
  for(var i = 0; i < exams.length; i++) {
      exams[i] = processExam(exams[i]);
  }
  return exams;
}

export function processExam(exam) {
  addExamStatus(exam);
  return exam;
}

export function addExamStatus(exam) {
  if (exam.report == null) {
      exam.status = "aguardando";
      exam.status_color_rgb = "FFAAAA";
  } else {
      if (exam.report.reviewmedic != null) {
          if (exam.report.review_requested) {
              exam.status = "revisão";
              exam.status_color_rgb = "AAAAFF";
          } else {
              exam.status = "liberado";
              exam.status_color_rgb = "AAFFAA";
          }
      } else {
          exam.status = "digitado";
          exam.status_color_rgb = "FFF0BA";
      }
  }
}

export function cleanReportText(text) {
  return text.replace(/[\n\r]+/g, ' ');
}

export function openInTab(url) {
    window.open(url, '_blank');
}

export function clinic_has_viewer(clinic) {
    return clinic.wadoserver != null;
}

export function holidaysCount(startDate, endDate) {
  const hd = new holidays('BR');

  var dates = [];
  var currentDate = moment(startDate).startOf('day');
  var lastDate = moment(endDate).startOf('day');

  while(currentDate.add(1, 'days').diff(lastDate) < 0) {
      if (hd.isHoliday(currentDate)){
        dates.push(currentDate.clone().toDate());
      }
  }

  return dates
}

export function examEndDateMoment(startDate, deliveryTime) {
  let endMoment;
  const startMoment = moment(startDate);
  const currentMoment = moment();
  const currentDuration = calculateDurationInMinutes(startMoment, currentMoment)

  endMoment = startMoment.clone().add(deliveryTime.delivery_time_minutes, 'minutes')

  if (!deliveryTime.clinic.include_weekend_as_work_day && currentDuration > 1440) {
    var weekendDays = business.weekendDays(startMoment, endMoment); // how many days are weekend in the interval
    endMoment = endMoment.add(weekendDays, 'days');
  }

  var holidays = holidaysCount(startDate, endMoment);
  if (holidays.length > 0) {
    var totalHolidaysMinutes = holidays.length * 1440;
    endMoment = endMoment.add(totalHolidaysMinutes, 'minutes')
  }

  return endMoment;
}

export function calculateDurationInMinutes(startDateMoment, endDateMoment) {
  const duration = moment.duration(endDateMoment.diff(startDateMoment));
  const durationInMinutes = duration.asMinutes()

  return durationInMinutes;
}

export function getExamDeliverytimeColor(examStartMoment, examEndMoment, examDuration) {
  var color = 'white';
  var oneDayLeftMoment = examEndMoment.clone().add(-1440, 'minutes');
  const durationOneDayLeft = calculateDurationInMinutes(examStartMoment, oneDayLeftMoment);
  const durationExamEndDate = calculateDurationInMinutes(examStartMoment, examEndMoment);

  if (examDuration < durationExamEndDate && examDuration < durationOneDayLeft) {
    color = '#90EE90' // light green
  } else if (examDuration >= durationOneDayLeft && examDuration <= durationExamEndDate) {
    color = '#FFEA00' // yellow
  } else {
    color = '#B2102F' //red
  }

  return color;
}

export function examDeliverytimeColor(deliveryTime, examStudyDatetime, currentDatetime) {
  const startMoment = moment(examStudyDatetime);
  var examEndMoment = examEndDateMoment(examStudyDatetime, deliveryTime);

  var currentDatetimeMoment = moment(currentDatetime);
  var examDuration = calculateDurationInMinutes(moment(examStudyDatetime), currentDatetimeMoment);

  const color = getExamDeliverytimeColor(startMoment, examEndMoment, examDuration);

  return color;
}

export function getExamDeliveryStatusColor(exam, deliveryTimes, currentDatetime) {
  if (exam.category == null){
    return 'white'
  };
  if (deliveryTimes == null){
    return 'white'
  };

  for (var i=0; i < deliveryTimes.length; i++){
    if (deliveryTimes[i].exam_category === exam.category && deliveryTimes[i].clinic.id === exam.clinic_id) {
      var examColor = examDeliverytimeColor(deliveryTimes[i], exam.study_datetime, currentDatetime);
    }
  }
  return examColor;
}

export function urgentExamEndDateMoment(startDate, deliveryTime) {
  let endMoment;
  const startMoment = moment(startDate);

  endMoment = startMoment.clone().add(deliveryTime.delivery_time_minutes, 'minutes');

  return endMoment;
}

export function getUrgentExamDeliverytimeColor(examStartMoment, examEndMoment, examDuration) {
  var color = 'white';
  var oneHourLeftMoment = examEndMoment.clone().add(-60, 'minutes');
  const durationOneHourLeft = calculateDurationInMinutes(examStartMoment, oneHourLeftMoment);
  const durationExamEndDate = calculateDurationInMinutes(examStartMoment, examEndMoment);

  if (examDuration < durationExamEndDate && examDuration < durationOneHourLeft) {
    color = '#90EE90' // light green
  } else if (examDuration <= durationExamEndDate && examDuration >= durationOneHourLeft) {
    color = '#FFEA00' // yellow
  } else {
    color = '#B2102F' //red
  }

  return color;
}

export function urgentExamDeliverytimeColor(deliveryTime, examStudyDatetime, currentDatetime) {
  const startMoment = moment(examStudyDatetime);
  var examEndMoment = urgentExamEndDateMoment(examStudyDatetime, deliveryTime);

  var currentDatetimeMoment = moment(currentDatetime);
  var examDuration = calculateDurationInMinutes(moment(examStudyDatetime), currentDatetimeMoment);

  const color = getUrgentExamDeliverytimeColor(startMoment, examEndMoment, examDuration);

  return color;
}

export function getUrgentExamDeliveryStatusColor(exam, deliveryTimes, currentDatetime) {
  if (exam.category == null){
    return 'white'
  };
  if (deliveryTimes == null){
    return 'white'
  };

  for (var i=0; i < deliveryTimes.length; i++){
    if (deliveryTimes[i].exam_category === exam.category && deliveryTimes[i].clinic.id === exam.clinic_id) {
      var examColor = urgentExamDeliverytimeColor(deliveryTimes[i], exam.study_datetime, currentDatetime);
    }
  }
  return examColor;
}

export function getUrgentDeliveryStatusText(color) {
  var text = 'Exame sem status de entrega';

  if (color === '#90EE90') {
    text = 'Exame dentro do prazo';
  } else if (color === '#FFEA00') {
    text = 'Menos de 1 hora para o prazo final';
  } else if (color === '#B2102F') {
    text = 'Exame fora do prazo de entrega.'
  }
  return text;
}

export function getDeliveryStatusText(color) {
  var text = 'Exame sem status de entrega';

  if (color === '#90EE90') {
    text = 'Exame dentro do prazo';
  } else if (color === '#FFEA00') {
    text = 'Menos de 1 dia para o prazo final';
  } else if (color === '#B2102F') {
    text = 'Exame fora do prazo de entrega.'
  }
  return text;
}

export function getMedicCurrentPeriodReportsCount(exams, currentDatetime, periodType) {
  var examsCount = 0;
  var currentDatetimeMoment = moment(currentDatetime);

  for (let i = 0; i < exams.length; i++) {
    var reportFinishedTimestamp = exams[i].report.finished_timestamp;
    var reportFinishedTimestampMoment = moment(reportFinishedTimestamp);

    var startOfPeriod = currentDatetimeMoment.clone().startOf(periodType);
    if (reportFinishedTimestampMoment.isBetween(startOfPeriod, currentDatetimeMoment)) {
      examsCount += 1
    }
  }

  return examsCount;
}

export function getMedicLastMonthReportsCount(exams, currentDatetime) {
  var examsCount = 0;
  var currentDatetimeMoment = moment(currentDatetime);
  var startOfLastMonth = currentDatetimeMoment.clone().subtract(1, 'months').startOf('month');
  var endOfLastMonth = currentDatetimeMoment.clone().subtract(1, 'months').endOf('month');

  for (let i = 0; i < exams.length; i++) {
    var reportFinishedTimestamp = exams[i].report.finished_timestamp;
    var reportFinishedTimestampMoment = moment(reportFinishedTimestamp);

    if (reportFinishedTimestampMoment.isBetween(startOfLastMonth, endOfLastMonth)) {
      examsCount += 1
    }
  }

  return examsCount;
}
