import _ from 'lodash';
import React, { useState, useEffect, useCallback } from 'react';
import { connect } from 'react-redux';
import withStyles from '@mui/styles/withStyles';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import RefreshIcon from '@mui/icons-material/Refresh';
import DownloadIcon from '@mui/icons-material/Download';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Paper from '@mui/material/Paper';
import CircularProgress from '@mui/material/CircularProgress';
import Loading from './Loading';
import ReportEditor from './ReportEditor';
import ReportPreview from './ReportPreview';
import SpeechRecognitionPanel from './SpeechRecognitionPanel';
import * as reportsHttp from '../http/reports';
import * as examsHttp from '../http/exams';
import * as speechHttp from '../http/speech';
import * as utils from '../utils';
import * as draftjsutils from '../draftjsutils';
import * as speechutils from '../speechutils';

import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { EditorState } from 'draft-js';

const fieldHasError = (errors, field) => {
  if (!errors) {
    return false;
  }
  return !_.isNil(errors[field]);
};

const styles = theme => ({
  reportPaper: {
    position: 'relative',
    display: 'flex',
    flexFlow: 'column',
    flex: '0 1 auto',
    height: '100%',
    padding: '10px',
  },
  formControl: {
  },
  formButton: {
    verticalAlign: "bottom",
    //marginTop: '16px',
  },
  formModelSelector: {
    minWidth: '80px',
  },
  selectEmpty: {
  },
  reportPaperGrid: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    })
  },
  saveButton: {
    margin: theme.spacing(1),
  },
  editButton: {
    margin: theme.spacing(1),
  },
  publishButton: {
    margin: theme.spacing(1),
  },
  cancelButton: {
    margin: theme.spacing(1),
  },
});

const mapStateToProps = (state) => {
  return {
    preview: state.reports.preview,
    user: state.auth.currentUser,
    clinics_map: state.clinics.clinics_map,
    speechConfig: state.speech.config,
  };
};

function ExamReportEditor(props) {
    const {
      classes,
      preview,
      loadingReport,
      loadingPreview,
      saveError,
      reports,
      exam,
      clinics_map,
      user,
      speechConfig,
    } = props;

    const [currentExam, setExam] = useState(null);
    const [reportText, setReportText] = useState(EditorState.createEmpty());
    const [reportContent, setReportContent] = useState(EditorState.createEmpty());
    const [editing, setEditing] = useState(true);
    const [medic, setMedic] = useState(undefined);
    const [clinicReport, setClinicReport] = useState(undefined);
    const [ loading, setLoading ] = useState(false);
    const [transcriptionState] = useState(new speechutils.TranscriptionState());

    const writeable = (exam != null && exam.status === 'aguardando') || editing;
    const downloadableExam = (exam != null && exam.status === 'liberado');

    useEffect(() => {
        speechHttp.getGlobalConfig();
        speechHttp.getConfig();
        return () => {
          reportsHttp.clearCurrent();
        };
        }, []);

    useEffect(() => {
      if (utils.user_has_role(user, "medic")) {
        setMedic(user.id);
      }
    }, [user]);

    useEffect(() => {
        if (exam !== currentExam) {
          var newReportContent = "";

          setExam(exam);
          if (exam != null && exam.report != null) {
            if (exam.report.content != null){
              newReportContent = exam.report.content;
            }
            setMedic(exam.report.medic.toString());
          }
          var editorState = draftjsutils.htmlToEditorState(reportText, newReportContent);
          setReportText(editorState);
          setReportContent(editorState);

          if (exam != null) {
            setEditing(exam.status === "aguardando");
          }

          reportsHttp.setPreviewExam(exam);
          reportsHttp.setPreviewContent(newReportContent);
          reportsHttp.updatePreview();
        } else if (exam === null) {
          reportsHttp.clearCurrent();
        }

    }, [exam, currentExam, reportText]);

    var saveButton = false;
    var editButton = false;
    var publishButton = false;
    var cancelButton = false;
    if (exam != null) {
      saveButton = exam.status === 'aguardando' || editing;
      // TODO replace with permission check
      if (utils.user_has_role(user, "medic")) {
          publishButton = exam.status !== 'liberado' || editing;
      }
      if (!editing) {
        publishButton = publishButton || (exam.status === 'digitado' && (utils.user_is_author(user, exam) ||
                                                                         utils.user_has_role(user, "admin")));
        editButton = exam.status === 'digitado' && (utils.user_is_author(user, exam) ||
                                                    utils.user_has_role(user, "admin"));
      }
      cancelButton = exam.status === 'liberado' &&
	  utils.user_has_permission(user, "exams.medicalreport_cancel");
    }

    var clinic = null;
    if (exam && clinics_map) {
      clinic = clinics_map[exam.clinic_id];
    }

    function handleSpeechTranscript(speechConfig, text, finalVersion, listening) {
        if (!transcriptionState.working) {
            transcriptionState.working = true;
            transcriptionState.originalText = reportText;
        }

        text = speechutils.transformText(speechConfig, text);

        if (finalVersion && !listening) {
            const newText = draftjsutils.appendTextToEditor(transcriptionState.originalText, text, false);
            transcriptionState.transcriptText = '';
            transcriptionState.working = false;
            setReportContent(newText);
            setReportText(newText);
        } else {
            transcriptionState.transcriptText = text;
            const newText = draftjsutils.appendTextToEditor(transcriptionState.originalText, text, true);
            setReportContent(newText);
            setReportText(newText);
        }
    }

    function handleLoadClinicReport() {
      let text = '';
      if (clinicReport !== undefined) {
        const searchId = Number(clinicReport);
        let report = props.reports.find((r) => r.id === searchId);
        if (report !== undefined) {
          text = report.report;
        }
      }
      var editorState = draftjsutils.htmlToEditorState(reportText, text);
      setReportText(editorState);
      setReportContent(editorState);
    }

    async function handleUpdatePreviewClick() {
      const html = draftjsutils.editorStateToHtml(reportText);
      reportsHttp.setPreviewExam(exam);
      reportsHttp.setPreviewContent(html);
      reportsHttp.updatePreview();
    }

    const download = useCallback(async (exam) => {
      setLoading(true);
      const res = await utils.downloadUrl(`/api/reports/exam/${exam.id}/download`,
                   'application/pdf',
                   `Laudo ${exam.patient_name} ${exam.study_datetime}`);
      setLoading(false);
      return res
    }, []);

    const handleDownloadReportClick = useCallback(exam => event => {
      const response = download(exam);
      if (!response) {
        alert("Falha no download do laudo")
      }
      return false;
    }, [download]);

    function handleMedicChange(event) {
      setMedic(event.target.value);
    }

    function handleClinicReportChange(event) {
      setClinicReport(event.target.value);
    }

    function handleEdit() {
      setEditing(true);
    }

    const handleSave = useCallback(
      async (refresh) => {
      // TODO use react components for these notifications
      if (medic == null) {
        alert("Por favor, selecione o médico");
        return false;
      }

      const html = draftjsutils.editorStateToHtml(reportText);
      if (html === "") {
        alert("Por favor, preencha o laudo");
        return false;
      }

      const data = {
        subexam: exam.id,
        content: html,
        medic: medic,
      };

      if (!utils.user_has_role(user, "medic")) {
        // medic cannot be a typer
        if (!exam.report)
          // typer cannot be changed
          data["typer"] = user.id;
      }

      const result = await examsHttp.createExamReport(data);
      if (result.result) {
        setEditing(false);
        if (refresh) {
          await examsHttp.get(exam.id);
        }
        return true;
      } else {
        var message = utils.getErrorMessageOrDefaultMessage(result.data);
        alert(message);
        return false;
      }
    },
      [medic, exam, reportText, user]
    );

    const doReview = useCallback(
      async () => {
      const data = {
        subexam: exam.id,
      };
      const result = await examsHttp.createExamReportReview(data);
      if (result.result) {
        setEditing(false);
        await examsHttp.get(exam.id);
      } else {
        var message = result.data.response.data.message;
        alert(message);
      }
    },
      [exam]
    );

    const handleReview = useCallback(
      async () => {
          var res = true;
          if (editing) {
            res = await handleSave(false);
          }
          if (res) {
            doReview()
          }
      },
      [editing, doReview, handleSave]
    );

    async function handleCancel() {
      const data = {
        subexam: props.exam.id,
      };
      const success = await examsHttp.deleteExamReportReview(data);
      if (success) {
          await examsHttp.get(props.exam.id);
      } else {
      }
    }

    const isMedic = utils.user_has_role(user, "medic") && !utils.user_has_role(user, "typer");
    var medicSelector = null;
    var extraSpace = null;
    if (isMedic) {
      extraSpace = (
        <Grid item xs={5} md={2} />
      );
    } else {
      medicSelector = (
      <Grid item xs={5} md={2} >
        <FormControl className={classes.formControl}>
          <InputLabel shrink={medic != null} htmlFor="medic-select">Médico</InputLabel>
          <Select
            native
            value={medic}
            inputProps={{
              name: 'médico',
              id: 'medic-select',
            }}
            onChange={handleMedicChange}
            disabled={!writeable}
          >
            <option></option>
              {clinic && clinic.members.filter(m => m.roles.indexOf("medic") >= 0).map(m => (
                <option key={m.id} value={m.id}>{m.first_name + ' ' + m.last_name}</option>
              ))}
           </Select>
         </FormControl>
       </Grid>
      );
    }

    return (
      <Container maxWidth="xl" className={classes.container}>
        <Grid container spacing={1}>
	  {medicSelector}
	  {writeable ?
          <Grid item xs={6} md={4} >

            <FormControl className={classes.formControl}>
              <InputLabel htmlFor="report-select">Fórmula</InputLabel>
              <Select
	        className={classes.formModelSelector}
                native
                value={clinicReport}
                inputProps={{
                  name: 'fórmula',
                  id: 'report-select',
                }}
                onChange={handleClinicReportChange}
              >
                <option></option>
                {reports && reports.map(r => (
                  <option key={r.id} value={r.id}>{r.description}</option>
                  ))}
              </Select>
            </FormControl>
            <Button className={classes.formButton} onClick={handleLoadClinicReport}>
              Carregar
            </Button>
          </Grid>
      :
          <Grid item xs={6} md={4} />
      }
	  {extraSpace}
          <Grid item xs={1} md={1}>
              {writeable ?
            <SpeechRecognitionPanel
                handleTranscript={(r, l) => handleSpeechTranscript(speechConfig, r, false, l)}
                handleFinalTranscript={(r, l) => handleSpeechTranscript(speechConfig, r, true, l)}
              />
              : null}
          </Grid>
          <Grid item xs={false} md={2}>
          </Grid>
          <Grid item xs={6} md={3} className={classes.reportPaperGrid} style={{width: '100%'}} >
            <Button
              className={classes.formButton}
              color="primary"
              aria-label="Atualizar"
              onClick={handleUpdatePreviewClick}
            >
              <RefreshIcon />
              Atualizar
            </Button>
            {
              downloadableExam
                ?
                  loading
                    ?
                    <CircularProgress size='1rem'/>
                    :
                    <Button
                      className={classes.formButton}
                      color="primary"
                      aria-label="Baixar Laudo Definitivo"
                      onClick={(utils.user_has_permission(user, "exams.medicalreport_download"))
                              ? handleDownloadReportClick(exam) : undefined}
                    >
                      <DownloadIcon />
                      Baixar Laudo Definitivo
                    </Button>
                :
                <Button disabled="true">
                  <DownloadIcon />
                  Baixar Laudo
                </Button>
            }
          </Grid>
        </Grid>
        <Grid container spacing={2} className={classes.content}>
          <Grid item xs={12} md={7} className={classes.reportPaperGrid} >
            {loadingReport
              ? <Loading />
              :
              <Paper className={classes.reportPaper}>
                <ReportEditor
                  error={fieldHasError(saveError, 'report')}
                  disabled={!writeable}
                  content={reportContent}
                  onContentChange={setReportText}
                />
              </Paper>
            }
          </Grid>
          <Grid item xs={12} md={5} className={classes.reportPaperGrid} style={{width: '100%'}} >
            <Paper className={classes.reportPaper}>
              {loadingPreview ? <Loading /> : preview && <ReportPreview data={preview}/>}
            </Paper>
          </Grid>
          <Grid item xs={11} md={6}>
              { saveButton ?
              <Button variant="contained" color="primary" onClick={() => handleSave(true)} className={classes.saveButton}>
                  Salvar
              </Button>
              : <div></div>
              }
              { publishButton ?
              <Button variant="contained" color="primary" onClick={handleReview} className={classes.reviewButton}>
                Liberar
              </Button>
              : <div></div>
              }
              { editButton ?
                <Button variant="contained" color="secondary" onClick={handleEdit} className={classes.editButton}>
                  Editar
                </Button>
              : <div></div>
              }
              { cancelButton ?
              <Button variant="contained" color="secondary" onClick={handleCancel} className={classes.cancelButton}>
                  Cancelar liberação
              </Button>
              : <div></div>
              }
          </Grid>
          <Grid item xs={1} md={1}>
              {writeable ? <SpeechRecognitionPanel /> : null}
          </Grid>
        </Grid>
      </Container>
    )
}

export default withStyles(styles)(connect(mapStateToProps)(ExamReportEditor));
