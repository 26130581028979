import _ from 'lodash';
import React, { useState, useEffect, useCallback } from 'react';
import { connect } from 'react-redux';
import withStyles from '@mui/styles/withStyles';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Fab from '@mui/material/Fab';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import CloseIcon from '@mui/icons-material/Close';
import RefreshIcon from '@mui/icons-material/Refresh';
import Paper from '@mui/material/Paper';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Dialog from '@mui/material/Dialog';
import Checkbox from '@mui/material/Checkbox';

import Loading from '../components/Loading';
import ReportEditor from './ReportEditor';
import ReportPreview from './ReportPreview';
import SpeechRecognitionPanel from './SpeechRecognitionPanel';

import { EditorState } from 'draft-js';
import * as draftjsutils from '../draftjsutils';

import { createLoadingSelector } from '../reducers/loading';
import { createErrorDataSelector } from '../reducers/error';
import { REPORTS_GET, REPORTS_CREATE, REPORTS_UPDATE, REPORTS_PREVIEW } from '../actions/reports';
import * as authHttp from '../http/auth';
import * as reportsHttp from '../http/reports';
import * as clinicsHttp from '../http/clinics';
import * as speechutils from '../speechutils';
import * as speechHttp from '../http/speech';
import * as utils from '../utils';
import { modalities } from '../constants'

const fieldHasError = (errors, field) => {
  return !_.isNil(errors[field]);
};

const getErrorMessage = (errorData) => {
  var errorCode = errorData && errorData[0];
  if (!errorCode) return null;

  let errorMessage = null;

  switch (errorCode)
  {
    case 'blank':
      errorMessage = 'Campo obrigatório';
      break;
    case 'required':
      errorMessage = 'Campo obrigatório';
      break;
    default:
      errorMessage = null;
      break;
  }

  return errorMessage;
};

const styles = theme => ({
  content: {
    display: 'flex',
    height: '100%',
    width: '100%',
    flexFlow: 'row',
    flex: '0 1 auto',
    padding: '10px',
    overflow: 'hidden'
  },
  appBar: {
    position: 'static',
    backgroundColor: theme.palette.primary.light
  },
  flex: {
    flex: 1,
  },
  reportPaper: {
    position: 'relative',
    display: 'flex',
    flexFlow: 'column',
    flex: '0 1 auto',
    height: '100%',
    padding: '10px'
  },
  reportPaperGrid: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    })
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  fab: {
    margin: theme.spacing(1),
    zIndex: 1
  }
});

const mapStateToProps = (state) => {
  return {
    report: state.reports.current,
    preview: state.reports.preview,
    clinics: state.clinics.list,
    margins: state.clinics.currentMargins,
    user: state.auth.currentUser,
    loadingReport: createLoadingSelector([ REPORTS_GET ])(state),
    loadingPreview: createLoadingSelector([ REPORTS_PREVIEW ])(state),
    savingReport: createLoadingSelector([ REPORTS_CREATE, REPORTS_UPDATE ])(state),
    saveError: createErrorDataSelector([ REPORTS_CREATE, REPORTS_UPDATE ])(state),
    speechConfig: state.speech.config,
  };
};

function EditReport(props) {
    const {
      classes, clinics, propsPreview, user, margins, loadingReport, loadingPreview, savingReport,
      saveError, speechConfig
    } = props;

    const [reportText, setReportText] = useState(EditorState.createEmpty());
    const [reportContent, setReportContent] = useState(EditorState.createEmpty());
    const [reportDescription, setReportDescription] = useState('');
    const [editing, setEditing] = useState(true);
    const [selectedClinic, setSelectedClinic] = useState('');
    const [selectedModality, setSelectedModality] = useState('');
    const [dialogText, setDialogText] = useState('');
    const [dialogOpen, setDialogOpen] = useState(false);
    const [dialogConfirmAction, setDialogConfirmAction] = useState(null);
    const [preview, setPreview] = useState(propsPreview);
    const [selectedClinicPreview, setSelectedClinicPreview] = useState('');
    const [transcriptionState] = useState(new speechutils.TranscriptionState());
    const [isPrivate, setIsPrivate] = useState(false);

    const previewOpen = true;

    useEffect(() => {
      authHttp.getCurrentUser();
      if (!props.creating) {
        reportsHttp.get(props.match.params.id);
      }
      clinicsHttp.list();
      clinicsHttp.getMargins();
    }, [props.creating, props.match.params.id]);

    // Should we load this only once?
    useEffect(() => {
        speechHttp.getGlobalConfig();
        speechHttp.getConfig();
    }, []);

    useEffect(() => {
      setPreview(props.preview);
    }, [props.preview]);

    useEffect(() => {
      if (props.report) {
        setReportDescription(props.report.description);
        setSelectedClinic(props.report.clinic.id);
        setSelectedModality(props.report.modality);
        setIsPrivate(props.report.is_private);
      }
    }, [props.report]);

    useEffect(() => {
      if (props.report) {
        var editorState = draftjsutils.htmlToEditorState(reportText, props.report.report);
        setReportText(editorState);
        setReportContent(editorState);
        updatePreview(editorState, props.report.clinic.id, props.report.modality);
      }
    }, [props.report, reportText]);

    function handleSpeechTranscript(speechConfig, text, finalVersion, listening) {
        if (!transcriptionState.working) {
            transcriptionState.working = true;
            transcriptionState.originalText = reportText;
        }

        text = speechutils.transformText(speechConfig, text);

        if (finalVersion && !listening) {
            const newText = draftjsutils.appendTextToEditor(transcriptionState.originalText, text, false);
            transcriptionState.transcriptText = '';
            transcriptionState.working = false;
            setReportContent(newText);
            setReportText(newText);
        } else {
            transcriptionState.transcriptText = text;
            const newText = draftjsutils.appendTextToEditor(transcriptionState.originalText, text, true);
            setReportContent(newText);
            setReportText(newText);
        }
    }

    function updatePreview(text, clinic, modality) {
      const html = draftjsutils.editorStateToHtml(text);
      reportsHttp.setPreviewExam(null);
      reportsHttp.setPreviewClinic(clinic);
      reportsHttp.setPreviewModality(modality);
      reportsHttp.setPreviewContent(html);
      reportsHttp.updatePreview(html);
    }

    const handleClose = useCallback(
      () => {
        if (editing) {
          setDialogText('Deseja descartar as alterações?');
          setDialogConfirmAction(
            (prevValue) => () => {
              if (props.creating) {
                props.history.push('/laudario')
              } else {
                var editorState = null;
                if (props.report.report) {
                  editorState = draftjsutils.htmlToEditorState(reportText, props.report.report);
                }
                setDialogOpen(false);
                setDialogText('');
                setDialogConfirmAction(null);
                setEditing(false);
                setReportText(editorState || EditorState.createEmpty());
                setReportContent(editorState || EditorState.createEmpty());
                setReportDescription(props.report.description || '');
                setSelectedClinic((props.report.clinic || {}).id || '');
                setSelectedModality(props.report.modality || '');
              }
            }
	  );
          setDialogOpen(true);
        } else {
          reportsHttp.clearCurrent();
          props.history.push('/laudario');
        }
      },
      [editing, props.report, props.creating, props.history, reportText],
    );

    const confirmSave = useCallback(
      async () => {
        const html = draftjsutils.editorStateToHtml(reportText);
        const data = {
          clinic: selectedClinic || null,
          modality: selectedModality,
          report: html,
          description: reportDescription,
          is_private: isPrivate,
        };
        if (props.creating) {
          const result = await reportsHttp.create(data);
          if (result.result) {
            reportsHttp.clearCurrent();
            props.history.push('/laudario');
          } else {
            setDialogOpen(false);
            setDialogConfirmAction(null);
            setDialogText('');

            // TODO switch to using reducer for this
            alert(utils.getErrorMessageOrDefaultMessage(result.data));
          }
        } else {
          const result = await reportsHttp.update(props.report.id, data);
          if (result.result) {
            setEditing(false);
            setDialogOpen(false);
            setDialogText('');
            setDialogConfirmAction(null);
          } else {
            setDialogOpen(false);
            setDialogText('');
            setDialogConfirmAction(null);
            // TODO switch to using reducer for this
            alert(utils.getErrorMessageOrDefaultMessage(result.data));
          }
        }
      },
      [props.creating, props.report, selectedClinic, selectedModality, reportText, reportDescription, props.history, isPrivate]
    );

    const handleEditOrSave = useCallback(
      async () => {
        if (editing) {
          setDialogConfirmAction((prevState) => confirmSave);
          setDialogText('Tem certeza que deseja salvar o laudo?');
          setDialogOpen(true);
        } else {
          setEditing(true);
        }
      },
      [editing, confirmSave]
    );

    const handleDialogCancel = useCallback(() => {
      setDialogOpen(false);
      setDialogText('');
      setDialogConfirmAction(null);
    }, []);

    const handleDialogConfirm = useCallback(() => {
      dialogConfirmAction();
    }, [dialogConfirmAction]);

    const handleUpdatePreviewClick = useCallback(async () => {
      await updatePreview(reportText, selectedClinicPreview, selectedModality);
    }, [reportText, selectedClinicPreview, selectedModality]);

    if ((!props.report || !clinics) && !props.creating) {
      return <div></div>;
    }
    const canViewClinic = user && (
      _.includes(user.roles, 'manager') || _.includes(user.roles, 'admin')
    );

    return (
      <Dialog fullScreen open={true}>
        <AppBar className={classes.appBar}>
          <Toolbar>
            <IconButton color="inherit" onClick={handleClose} aria-label="Close" size="large">
              {!props.creating && editing ? <NavigateBeforeIcon /> : <CloseIcon />}
            </IconButton>
            <Typography variant="h6" color="inherit" className={classes.flex}>
              {reportDescription || (props.creating && 'Novo Laudo')}
            </Typography>
            <Button color="inherit" onClick={handleEditOrSave}>
              {editing ? 'Salvar' : 'Editar'}
            </Button>
          </Toolbar>
        </AppBar>
        <Grid container spacing={2} className={classes.content}>
          <Grid item className={classes.reportPaperGrid} xs={6}>
            {loadingReport
              ? <Loading />
              :
              <Paper className={classes.reportPaper}>
                <Grid container spacing={2}>
                <Grid item className={classes.reportPaperGrid} xs={10}>
                <form>
                  {canViewClinic &&
                    <FormControl disabled={!editing} className={classes.formControl}>
                      <InputLabel shrink htmlFor="clinics-selector">Clínica</InputLabel>
                      <Select
                        displayEmpty
                        value={selectedClinic || ''}
                        inputProps={{
                          name: 'clinics',
                          id: 'clinics-selector'
                        }}
                        onChange={(event) => setSelectedClinic(event.target.value)}
                      >
                        <MenuItem value=""><em>Nenhuma</em></MenuItem>
                        {clinics && clinics.map(clinic => (
                          <MenuItem key={clinic.id} value={clinic.id}>{clinic.name}</MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  }
                  <FormControl disabled={!editing} className={classes.formControl}>
                    <InputLabel
                      shrink
                      htmlFor="modalities-selector"
                      error={fieldHasError(saveError, 'modality')}
                    >
                      {getErrorMessage(saveError['modality']) || 'Modalidade'}
                    </InputLabel>
                    <Select
                      displayEmpty
                      required
                      value={selectedModality || ''}
                      inputProps={{
                        name: 'modalities',
                        id: 'modalities-selector'
                      }}
                      onChange={(event) => setSelectedModality(event.target.value)}
                    >
                      <MenuItem value=""><em>Nenhuma</em></MenuItem>
                      {modalities.map(modality => (
                        <MenuItem key={modality} value={modality}>{modality}</MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  <FormControl disabled={!editing} className={classes.formControl}>
                    <InputLabel
                      shrink
                      htmlFor="description-field"
                      error={fieldHasError(saveError, 'description')}
                    >
                      {getErrorMessage(saveError['description']) || 'Descrição'}
                    </InputLabel>
                    <TextField
                      disabled={!editing}
                      value={reportDescription || ''}
                      inputProps={{
                        name: 'description',
                        id: 'description-field'
                      }}
                      label={getErrorMessage(saveError['description']) || 'Descrição'}
                      onChange={(event) => setReportDescription(event.target.value)}
                    />
                  </FormControl>
                  <FormControl disabled={!editing} className={classes.formControl}>
                    <InputLabel shrink htmlFor="private-checkbox">
                    Laudo Privado
                    </InputLabel>
                    <Checkbox
                      id="private-checkbox"
                      checked={isPrivate}
                      onChange={(event) => setIsPrivate(event.target.checked)}
                    />
                  </FormControl>
                </form>
              </Grid>
              <Grid item className={classes.reportPaperGrid} xs={2}>
                {editing ?
                  <SpeechRecognitionPanel
                    handleTranscript={(r, l) => handleSpeechTranscript(speechConfig, r, false, l)}
                    handleFinalTranscript={(r, l) => handleSpeechTranscript(speechConfig, r, true, l)}
                  />
                  : null}
              </Grid>
              </Grid>
              <Grid container spacing={2} className={classes.content}>
              <Grid item className={classes.reportPaperGrid} xs={12}>
                <ReportEditor
                  error={fieldHasError(saveError, 'report')}
                  disabled={!editing}
                  content={reportContent}
                  onContentChange={(report) => setReportText(report)}
                />
              </Grid>
              </Grid>
              </Paper>
            }
          </Grid>
          <Grid item className={classes.reportPaperGrid} style={{width: '100%'}} xs={6}>
            {previewOpen &&
              <Paper className={classes.reportPaper}>
              <div>
                <Fab
                  color="primary"
                  variant="extended"
                  aria-label="Atualizar"
                  className={classes.fab}
                  onClick={handleUpdatePreviewClick}
                >
                  <RefreshIcon />
                  Atualizar
                </Fab>
                <FormControl className={classes.formControl}>
                  <InputLabel shrink htmlFor="clinics-selector">Clínica</InputLabel>
                  <Select
                    displayEmpty
                    value={selectedClinicPreview || ''}
                    inputProps={{
                      name: 'clinics',
                      id: 'clinics-selector'
                    }}
                    onChange={
		      async (event) => {
                        setSelectedClinicPreview(event.target.value);
                        clinicsHttp.getMargins(event.target.value);
                        await updatePreview(reportText, event.target.value, selectedModality);
                      }
		    }
                  >
                    <MenuItem value=""><em>Nenhuma</em></MenuItem>
                    {clinics && clinics.map(clinic => (
                      <MenuItem key={clinic.id} value={clinic.id}>{clinic.name}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
                {margins && `(${margins.top}cm, ${margins.bottom}cm, ${margins.left}cm, ${margins.right}cm)`}
              </div >
              {loadingPreview ? <Loading /> : preview && <ReportPreview data={preview}/>}
              </Paper>
            }
          </Grid>
        </Grid>
        <Dialog
          open={dialogOpen}
        >
          <DialogContent>
            <Typography>{dialogText}</Typography>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleDialogCancel} color="primary">
              Cancelar
            </Button>
            <Button onClick={handleDialogConfirm} color="primary">
              Ok
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog
          open={savingReport}
          style={{ margin: 'auto' }}
          PaperProps={{
            style: {
              width: '100px', height: '100px',
              backgroundColor: 'transparent',
              boxShadow: 'none'
            }
          }}
        >
          <Loading />
        </Dialog >
      </Dialog>
    );
}

export default withStyles(styles)(connect(mapStateToProps)(EditReport));
