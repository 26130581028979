import { api } from './axios';
import store from '../store';
import * as speechActions from '../actions/speech';

export const getGlobalConfig = async () => {
  try {
    store.dispatch(speechActions.getGlobalConfig());
    const res = await api.get(`/api/speechrecognition/replacementtext/global/`);
    store.dispatch(speechActions.getGlobalConfigSuccess(res.data));
  } catch (err) {
    store.dispatch(speechActions.getGlobalConfigFailure(err));
  }
};

export const getConfig = async () => {
  try {
    store.dispatch(speechActions.getConfig());
    const res = await api.get(`/api/speechrecognition/replacementtext/`);
    store.dispatch(speechActions.getConfigSuccess(res.data));
  } catch (err) {
    store.dispatch(speechActions.getConfigFailure(err));
  }
};

export const addTextReplacement = async (data) => {
  try {
    store.dispatch(speechActions.addTextReplacement());
    const res = await api.post(`/api/speechrecognition/replacementtext/`, data);
    store.dispatch(speechActions.addTextReplacementSuccess(res.data));
    return true;
  } catch (err) {
    store.dispatch(speechActions.addTextReplacementFailure(err));
    return false;
  }
};

export const updateTextReplacement = async (data) => {
  try {
    store.dispatch(speechActions.updateTextReplacement());
    const res = await api.put(`/api/speechrecognition/replacementtext/${data.id}/`, data);
    store.dispatch(speechActions.updateTextReplacementSuccess(res.data));
    return true;
  } catch (err) {
    store.dispatch(speechActions.updateTextReplacementFailure(err));
    return false;
  }
};

export const deleteTextReplacement = async (id) => {
  try {
    store.dispatch(speechActions.deleteTextReplacement());
    const res = await api.delete(`/api/speechrecognition/replacementtext/${id}/`);
    store.dispatch(speechActions.deleteTextReplacementSuccess(res.data));
    return true;
  } catch (err) {
    store.dispatch(speechActions.deleteTextReplacementFailure(err));
    return false;
  }
};

