import React, { useCallback, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import withStyles from '@mui/styles/withStyles';
import Grid from '@mui/material/Grid';
import Loading from '../components/Loading';

import * as authHttp from '../http/auth';

import * as notificationHttp from '../http/accounts';
import { Button } from '@mui/material';


const styles = theme => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
});

const mapStateToProps = (state) => {

  return {
    currentUser: state.auth.currentUser,
    receive_exam_association_email: state.accounts.receive_exam_association_email,
  };
};


function Notifications(props) {

  const { classes, receive_exam_association_email } = props;
  const [loading, setLoading] = useState(false);

  useEffect(() => { 
    authHttp.getCurrentUser(); 
    notificationHttp.notificationIsActive()}, 
    []
  );

  const handleDeactivation = useCallback(async (event) => {
    event.preventDefault();
    const data = {
      receive_exam_association_email: false,
    }
    setLoading(true);
    const res = await notificationHttp.deactivateNotification(data); 
    setLoading(false);
    if(!res.result) {
      alert('Não foi possível desativar as notificações.')
    } 
  }, [])

  const handleActivation = useCallback (async (event) => {
    event.preventDefault();
    
    const data = {
      receive_exam_association_email: true,
    }
    setLoading(true);
    const res = await notificationHttp.activateNotification(data);
    setLoading(false);
    if(!res.result) {
      alert('Não foi possível ativar as notificações.')
    } 
  }, [])

  return (
    <div className={classes.root}>	    
      <Grid container spacing={2}>
      {
        loading
          ?
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Grid container justifyContent="center">
                <Loading />
              </Grid>
            </Grid>
          </Grid>
          :          
            <Grid container spacing={0}>
              {
                receive_exam_association_email
                  ?
                  <Grid item xs={12}>
                    <div>
                      Status: notificações por e-mail sobre exames a laudar ativadas. Clique no botão para desativar.
                    </div>
                    <Button className={classes.pos} variant="contained" color="primary" disableElevation onClick={handleDeactivation}>
                      Desativar
                    </Button>
                  </Grid>
                  :
                  <Grid item xs={12}>
                    <Grid container >
                      Status: notificações por e-mail sobre exames a laudar desativadas. Clique no botão para ativar.
                    </Grid>
                    <Button className={classes.pos} variant="contained" color="primary" disableElevation onClick={handleActivation}>
                      Ativar
                    </Button>              
                  </Grid>
              } 
            </Grid>
      }   
      </Grid>
    </div>
  );
}

export default withStyles(styles)(connect(mapStateToProps)(Notifications));
