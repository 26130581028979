import React, { Component, createRef } from 'react';
import withStyles from '@mui/styles/withStyles';
import { connect } from 'react-redux';
import Button from '@mui/material/Button';

import ImageUploader from './ImageUploader';

import { createLoadingSelector } from '../reducers/loading';
import  { EXAMS_GET_IMAGES, EXAMS_UPDATE_IMAGES } from '../actions/exams';
import * as examsHttp from '../http/exams';
import * as reportsHttp from '../http/reports';

const styles = theme => ({
  container: {
    padding: '1px'
  },
  imageUploadError: {
    color: 'red',
  }
});

const mapStateToProps = state => {
  return {
    images: state.exams.images,
    imageUploadError: state.exams.imageUploadError,
    loadedImages: state.exams.loadedImages,
    disabled: createLoadingSelector([ EXAMS_GET_IMAGES, EXAMS_UPDATE_IMAGES ])(state)
  }
};

class ExamImageUploader extends Component {
  constructor(props) {
    super(props);

    this.uploaderRef = createRef();
  }

  componentWillReceiveProps(nextProps, prevState) {
    if (nextProps.exam && nextProps.exam !== this.props.exam) {
      examsHttp.getExamImages(nextProps.exam.id, 'order');
    }
    if (nextProps.images && nextProps.images !== this.props.images && !this.props.images.length) {
      for (let image of nextProps.images) {
        examsHttp.getExamImageData(nextProps.exam.id, image.generated_id);
      }
    }
    if (nextProps.loadedImages) {
      for (let image of this.props.images) {
        const fileData = nextProps.loadedImages[image.generated_id];
        if (fileData) {
          image = Object.assign(image, { fileData: nextProps.loadedImages[image.generated_id] });
        }
      }
    }
  }

  handleSave = async () => {
    if (this.uploaderRef.current) {
      const files = this.uploaderRef.current.getFiles();
      await examsHttp.updateExamImages(this.props.exam.id, { files: files.map(f => f.fileData) });

      // TODO this actually doesn't know if preview is on screen, better to defer it to a higher component?
      reportsHttp.updatePreview();
    }
  }

  render() {
    const { classes, images, loadedImages, disabled, imageUploadError } = this.props;

    // we can't use redux because it triggers multiple actions with the same name
    const loading = disabled || images.length !== Object.keys(loadedImages).length;

    return (
      <div className={classes.container}>
        {imageUploadError && <div className={classes.imageUploadError}>{imageUploadError}</div>}
        <ImageUploader ref={this.uploaderRef} initialFiles={images} loading={loading}/>
        <Button disabled={disabled} color="inherit" onClick={this.handleSave}>
          Salvar
        </Button>
      </div>
    )
  }

};

export default withStyles(styles)(connect(mapStateToProps)(ExamImageUploader));
