import React, { useCallback } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import Button from '@mui/material/Button';

const mapStateToProps = (state) => {
  return {
  };
};

function ManualExamCreationButton(props) {
  let history = useHistory();

  const handleExamCreation = useCallback(async () => {
    history.push('/exams/superexam');
  }, [history]);


  return (
    <Button variant="contained" color="primary" disableElevation onClick={handleExamCreation}>
      Criar Exame
    </Button>
  );
}

export default connect(mapStateToProps)(ManualExamCreationButton);


