import { EditorState, ContentState, convertToRaw, Modifier } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import { cleanReportText } from './utils';


export function htmlToEditorState(state, text) {
    text = cleanReportText(text);
    const blocks = htmlToDraft(text);
    return EditorState.push(
        state,
        ContentState.createFromBlockArray(blocks.contentBlocks, blocks.entityMap)
    );
}

export function editorStateToHtml(state) {
    return draftToHtml(convertToRaw(state.getCurrentContent()), {}, false);
}

function capitalizeFirstLetter(text) {
    if (text.length === 0) {
        return text;
    }
    return text.charAt(0).toUpperCase() + text.slice(1);
}

function shouldCapitalizeAfter(c) {
    var punctuations = ".;!?";
    return punctuations.indexOf(c) > -1;
}

function isWhiteSpace(c) {
    return /\s/.test(c);
}

export function shouldCapitalizeForInsert(editorState) {
    const currentContent = editorState.getCurrentContent(),
          currentSelection = editorState.getSelection();
    var start = currentSelection.getStartOffset();
    var anchorKey = currentSelection.getAnchorKey();
    var currentContentBlock = currentContent.getBlockForKey(anchorKey);
    var selectedText = currentContentBlock.getText();

    var previousChar = null;
    while (currentContentBlock != null && previousChar == null) {
        // Try to find from 'start' towards beginning of current block
        for (var i = start; i >= 0; i--) {
            if (i >= selectedText.length) {
                continue;
            }
            var curChar = selectedText[i];
            if (!isWhiteSpace(curChar)) {
              return shouldCapitalizeAfter(curChar);
            }
        }

        // move to previous block
        currentContentBlock = currentContent.getBlockBefore(currentContentBlock.getKey());
        if (currentContentBlock == null) {
          break;
        }
        selectedText = currentContentBlock.getText();
        start = selectedText.length - 1;
    }

    return true;
}

export function appendTextToEditor(editorState, text, preview) {
    var newStyles = editorState.getCurrentInlineStyle();
    const currentContent = editorState.getCurrentContent(),
          currentSelection = editorState.getSelection();

    if (shouldCapitalizeForInsert(editorState)) {
      text = capitalizeFirstLetter(text);
    }

    if (preview) {
      newStyles = newStyles.add("HIGHLIGHT");
    }

    var selection = collapseSelection(currentSelection);

    const newContent = Modifier.insertText(
        currentContent,
        selection,
        text,
	newStyles
    );

    return EditorState.push(editorState, newContent, 'insert-characters');
}

function collapseSelection(selection) {
  if (selection.isCollapsed()) {
    return selection;
  }
  return selection.set("focusKey", selection.anchorKey).set("focusOffset", selection.anchorOffset)
}

