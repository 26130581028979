import React from 'react';
import withStyles from '@mui/styles/withStyles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import * as utils from '../utils';

const styles = theme => ({
  root: {
    width: '100%',
  },
  table: {
    minWidth: 250,
  },
  tableHeader: {
    fontWeight: "bold",
  },
  firstRow: {
    backgroundColor: "#c0c0c0",
  },
  member: {
    display: 'block',
  }
});

function BillingGroupFinanceSummary(props) {
    const { classes, summary } = props;
    
    return <TableContainer component={Paper}>
        <Table className={classes.table} size="small" aria-label="exames">
          <TableHead>
            <TableRow className={classes.firstRow}>
              <TableCell className={classes.tableHeader}>Grupo</TableCell>
              <TableCell align="left" className={classes.tableHeader}>Valor</TableCell>
              <TableCell align="left" className={classes.tableHeader}>Membros</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {Object.keys(summary.perGroup).map((key) => (
              <TableRow key={key}>
                <TableCell>{key}</TableCell>
                <TableCell>{utils.formatBillValue(summary.perGroup[key].value)}</TableCell>
                <TableCell>
                {Object.keys(summary.perGroup[key].perMember).map((key2) => (
                  <div key={key2} className={classes.member}>{key2}</div>
                ))}
                </TableCell>
              </TableRow>
            ))} 
          </TableBody>
        </Table>
      </TableContainer>
  
}

export default withStyles(styles)(BillingGroupFinanceSummary);