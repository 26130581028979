import React, { useEffect, useCallback, useState } from 'react'
import moment from 'moment'
import withStyles from '@mui/styles/withStyles'
import { connect } from 'react-redux'
import Paper from '@mui/material/Paper'
import Grid from '@mui/material/Grid'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'

import * as utils from '../utils'
import * as accountsHttp from '../http/accounts'
import * as examsHttp from '../http/exams'
import * as clinicHttp from '../http/clinics'
import Loading from '../components/Loading'


const styles = theme => ({
  heading: {
    fontSize: theme.typography.pxToRem(18),
    flexBasis: '33.33%',
    flexShrink: 0,
    fontWeight: 'bold',
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
  descriptionText: {
    margin: 'auto',
    padding: 5,
    textAlign:'center',
    maxWidth: 400,
    maxHeight: 100
  },
  inactive: {
    opacity: 0.4
  },
  root: {
    width: '100%'
  },
  table: {
    minWidth: 700
  },
  tableContainer: {
    height: 320
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  margin: {
    margin: theme.spacing(1),
    marginLeft: 'auto'
  }
});

const mapStateToProps = (state) => {
  return {
    currentUser: state.auth.currentUser,
    examsReleasedDashboard: state.exams.listReleasedDashboard,
    examsPendingDashboard: state.exams.listPendingDashboard,
    clinics: state.clinics,
    userFlags: state.accounts.userFlags,
  };
};


function MedicDashboard (props) {
  const { classes, currentUser, examsReleasedDashboard, examsPendingDashboard, clinics, userFlags} = props;
  const [ loadingReleasedExams, setLoadingReleasedExams ] = useState(false);
  const [ loadingPendingExams, setLoadingPendingExams ] = useState(false);
  var canViewClinic = true;
  var now = moment().format();

  const canViewMedicDashboard = userFlags.canUseMedicDashboard;

  const filterFromState = useCallback(async (status) => {
    if (currentUser === null) {
      return null;
    }
    return {
      medic: currentUser !== null ? currentUser.id : null,
      status: status,
      ordering: "study_datetime"
    };
  }, [currentUser]);

  const doSearchReleasedExams = useCallback(async () => {
    var filters = await filterFromState('liberado');
    if (filters == null) {
      return;
    }

    setLoadingReleasedExams(true);
    await examsHttp.listReleasedDashboard(filters);
    setLoadingReleasedExams(false);
  }, [filterFromState]);

  const doSearchPendingExams = useCallback(async () => {
    var filters = await filterFromState('digitado');
    if (filters == null) {
      return;
    }

    setLoadingPendingExams(true);
    await examsHttp.listPendingDashboard(filters);
    setLoadingPendingExams(false);
  }, [filterFromState]);

  useEffect(() => {
    accountsHttp.retrieveUserFlags();
    clinicHttp.list();
    doSearchReleasedExams();
    doSearchPendingExams();
  }, [currentUser, doSearchReleasedExams, doSearchPendingExams]);

  const getClinicName = useCallback((clinicId) => {
    if (clinicId in clinics.clinics_map) {
      return clinics.clinics_map[clinicId].name;
    }
    return clinicId;
  }, [clinics]);

  const showExam = useCallback((examId, event) => {
    event.stopPropagation();
    props.history.push(`/exams/exam/${examId}`)
  }, [props]);


  return (
    <React.Fragment>
    {canViewMedicDashboard ?
      <div className={classes.root}>
        <Grid container spacing={2}>
          <Grid item xs={5}>
            <Paper>
              {
                loadingReleasedExams
                ? <Loading/>
                :
                <Table className={classes.table}>
                  <TableHead>
                    <TableRow>
                      <TableCell align="center"
                      >
                        Qnt. laudos mensal
                      </TableCell>
                      <TableCell align="center"
                      >
                        Qnt. laudos semanal
                      </TableCell>
                      <TableCell align="center"
                      >
                        Qnt. laudos do mês passado
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow>
                      <TableCell align="center">{utils.getMedicCurrentPeriodReportsCount(examsReleasedDashboard, now, 'month')}</TableCell>
                      <TableCell align="center">{utils.getMedicCurrentPeriodReportsCount(examsReleasedDashboard, now, 'week')}</TableCell>
                      <TableCell align="center">{utils.getMedicLastMonthReportsCount(examsReleasedDashboard, now)}</TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              }
            </Paper>
          </Grid>
          <Grid item xs={12}>
            <Paper className={classes.root}>
              {
                loadingPendingExams
                ? <Loading />
                :
                <Table className={classes.table}>
                  <TableHead>
                    <TableRow>
                      <TableCell align="center"
                      >
                        Nome do paciente
                      </TableCell>
                      <TableCell align="center"
                      >
                        Modalidade
                      </TableCell>
                      {canViewClinic &&
                        <TableCell align="center"
                        >
                          Clínica
                        </TableCell>
                      }
                      <TableCell align="center"
                      >
                        Data do exame
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {examsPendingDashboard && examsPendingDashboard.map(e => (
                      <TableRow
                        key={e.id}
                        hover
                        onClick={(event) => showExam(e.id, event)}
                      >
                        <TableCell align="center">
                          {e.patient_name}
                        </TableCell>
                        <TableCell align="center">{e.modality}</TableCell>
                        {canViewClinic && <TableCell align="center">{getClinicName(e.clinic_id) || '-'}</TableCell>}
                        <TableCell align="center">{moment(e.study_datetime).format('LLLL')}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              }
            </Paper>
          </Grid>
        </Grid>

      </div>
    : null }
    </React.Fragment>
    )
}

export default withStyles(styles)(connect(mapStateToProps)(MedicDashboard));
