
export const modalities = [ 'CT', 'MR', 'CR', 'PT', 'MG', 'NM', 'XA', 'DX', 'BMD', 'US' ];

export const sex = [
  {
    value: 'M',
    label: 'Masculino',
  },
  {
    value: 'F',
    label: 'Feminino',
  },
];
