import React, { useCallback } from 'react';
import { connect } from 'react-redux';

import withStyles from '@mui/styles/withStyles';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';

import * as utils from '../utils';

const styles = theme => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
    width: 500,
    verticalAlign: 'baseline',
  },
});

const mapStateToProps = (state) => {
  return {
    clinics: state.clinics.clinics_map,
  };
};


function AssociateMedicForm(props) {
  const { classes, clinics, onChangeHandler, value, currentClinic
        } = props;

  const handleChange = useCallback((event) => {
    onChangeHandler(event.target.value);
  }, [onChangeHandler]);

  if (currentClinic == null || currentClinic === undefined) {
    return null;
  }

  var clinic_medics = [];
  const clinic = clinics[currentClinic];
  for (var i = 0; i < clinic.members.length; i++) {
      var member = clinic.members[i];
      if (utils.user_has_role(member, 'medic')) {
        clinic_medics.push({
          id: member.id,
          name: utils.userFullName(member)
        });
      }
  }

  clinic_medics.sort(function(a, b) { return a.name.localeCompare(b.name)})

  return (
    <React.Fragment>
      <FormControl className={classes.formControl}>
          <InputLabel htmlFor="associate-medic-selector">Associar médico</InputLabel>
          <Select
            inputProps={{
              name: 'associate-medic',
              id: 'associate-medic-selector'
            }}
            label="Associar"
            value={value}
            onChange={handleChange}
          >
            <MenuItem value="0"><em> Associar Médico </em></MenuItem>
            {clinic_medics.map(s => (
              <MenuItem key={s.id} value={s.id}>
                {s.name}
              </MenuItem>
              ))}
          </Select>
        </FormControl>
    </React.Fragment>
  );
};

export default withStyles(styles)(connect(mapStateToProps)(AssociateMedicForm));
