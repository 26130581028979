import React, { Component, createRef } from 'react';
import withStyles from '@mui/styles/withStyles';
import Dropzone from 'react-dropzone'
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import SaveIcon from '@mui/icons-material/Save';
import LinearProgress from '@mui/material/LinearProgress';

const DEFAULT_MAX_FILES = 6;

const styles = theme => ({
  thumbsContainer: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    border: '1px solid #eaeaea',
    alignContent: 'center',
    margin: 'auto'
  },
  thumb: {
    margin: 5,
    border: '1px solid #eaeaea',
    width: 100,
    height: 100,
    boxSizing: 'border-box',
    position: 'relative'
  },
  thumbActive: {
    '&:hover': {
      '& $imgPreview': {
        opacity: 0.4
      },
      '& $removeImageButton': {
        visibility: 'visible'
      }
    }
  },
  thumbInner: {
    minWidth: 0,
    overflow: 'hidden',
  },
  imgPreview: {
    width: '100%',
    height: 'auto',
    maxWidth: 100,
    maxHeight: 100
  },
  savedImageIcon: {
    position: 'absolute',
    top: 0,
    left: 0,
    padding: 0,
    visibility: 'visible'
  },
  removeImageButton: {
    position: 'absolute',
    top: 0,
    right: 0,
    padding: 0,
    visibility: 'hidden'
  },
  addImageButton: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    padding: 0,
    transform: 'translate(-50%, -50%)',
    '-ms-transform': 'translate(-50%, -50%)'
  },
  descriptionText: {
    margin: 'auto',
    padding: 5,
    textAlign:'center',
    maxWidth: 400,
    maxHeight: 100
  },
  inactive: {
    opacity: 0.4
  }
});

class ImageUploader extends Component {
  constructor(props) {
    super(props);

    this.dropzoneRef = createRef();

    let files = [];
    if (props.initialFiles) {
      for (let file of props.initialFiles) {
        file.preview = file.fileData && URL.createObjectURL(file.fileData);
        files.push(file);
      }
    }

    this.state = {
      files: files || [],
      loading: props.loading || false
    }
  }

  get maxFiles() {
    return this.props.maxFiles !== undefined ? this.props.maxFiles : DEFAULT_MAX_FILES;
  }

  getFiles() {
    return this.state.files;
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.initialFiles !== this.props.initialFiles) {
      this.props.initialFiles.forEach(file => URL.revokeObjectURL(file.preview));
      this.setState({ files: this.props.initialFiles});
    } else {
      let update = false;
      let files = this.props.initialFiles;
      for (let i in this.props.initialFiles) {
        let file = this.props.initialFiles[i];
        if (file.fileData && !file.preview) {
          file.preview = URL.createObjectURL(file.fileData);
          update = true;
        }
      }
      if (update) this.setState({ files })
    }
  }

  handleOnDrop = (acceptedFiles, fileRejections) => {
    const existingFiles = this.state.files;
    if (acceptedFiles.length + existingFiles.length <= this.maxFiles) {
      const files = acceptedFiles.map((file, i) =>
        ({ fileData: file, preview: URL.createObjectURL(file), order: existingFiles.length + i })
      );
      this.setState({ files: existingFiles.concat(files) });
    }
  }

  handleFileRemoveButtonClick = order => (event) => {
    event.stopPropagation();
    const files = this.state.files.filter(f => f.order !== order);
    files.forEach((f, i) => f.order = i);
    this.setState({ files });
  }

  handleAddImageClick = () => {
    if (this.dropzoneRef.current) {
      this.dropzoneRef.current.open();
    }
  }

  handleOnPaste = (event) => {
    let newFiles = [];
    let clipboardData = event.clipboardData || (event.originalEvent && event.originalEvent.clipboardData);
    if (clipboardData) {
      for (let file of clipboardData.files) {
        if (file.type.split('image/').length) {
          newFiles.push(file);
        }
      }
    }

    if (newFiles.length) {
      this.handleOnDrop(newFiles);
    }
  }

  handleOnFocus = (event) => {
    window.addEventListener('paste', this.handleOnPaste);
  }

  handleOnBlur = (event) => {
    window.removeEventListener('paste', this.handleOnPaste);
  }

  render() {
    const { classes, loading } = this.props;

    const thumbs = this.state.files && this.state.files.map(file => (
      <div className={`${classes.thumb} ${!loading && classes.thumbActive}`} key={file.order}>
        <div className={classes.thumbInner}>
          <img className={classes.imgPreview} src={file.preview} alt={file.order} />
          {file.saved &&
            <SaveIcon className={classes.savedImageIcon} />
          }
          <IconButton
            className={classes.removeImageButton}
            onClick={this.handleFileRemoveButtonClick(file.order)}
            size="large">
            <HighlightOffIcon />
          </IconButton>
        </div>
      </div>
    ));

    const addImageThumb = (
      <div className={classes.thumb} key='add-img-button'>
        <div className={classes.thumbInner}>
          <IconButton
            className={classes.addImageButton}
            disabled={loading}
            onClick={this.handleAddImageClick}
            size="large">
            <AddCircleOutlineIcon />
          </IconButton>
        </div>
      </div>
    );

    return (
      <Dropzone
        onDrop={this.handleOnDrop}
        ref={this.dropzoneRef}
        noClick={true}
        noKeyboard={false}
        accept='image/*'>
        {({ getRootProps, getInputProps }) => (
          <div>
            <div
              {...getRootProps({ className: 'dropzone' })}
              onFocus={this.handleOnFocus}
              onBlur={this.handleOnBlur}
            >
              <input {...getInputProps()}/>
              <div className={`${classes.thumbsContainer} ${loading ? classes.inactive : ''}`}>
                {
                  (!this.state.files || !this.state.files.length) &&
                  <Typography className={classes.descriptionText} variant='subtitle2'>Arraste e solte arquivos de imagem aqui, clique aqui e cole ou clique no botão ao lado para adicionar imagens</Typography>
                }
                {thumbs}
                {(this.state.files && this.state.files.length < this.maxFiles) && addImageThumb}
                </div>
              </div>
            {loading && <LinearProgress />}
          </div>
        )}
      </Dropzone>
    );
  }
};

export default withStyles(styles)(ImageUploader);
