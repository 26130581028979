import {
  LOGIN_REQUEST, LOGIN_SUCCESS, LOGIN_FAILURE, LOGOUT_SUCCESS, CURRENT_USER_GET, PRIVACY_POLICY_REQUIRED, PRIVACY_POLICY_GET,
  PRIVACY_POLICY_GET_SUCCESS, PRIVACY_POLICY_GET_FAILURE, PRIVACY_POLICY_ACCEPT, PRIVACY_POLICY_ACCEPT_SUCCESS,
  PRIVACY_POLICY_ACCEPT_FAILURE, RESET_PASSWORD_REQUEST, RESET_PASSWORD_SUCCESS, RESET_PASSWORD_FAILURE,
  TWO_FACTOR_AUTH_CREATE_DEVICE_REQUEST, TWO_FACTOR_AUTH_CREATE_DEVICE_SUCCESS, TWO_FACTOR_AUTH_CREATE_DEVICE_FAILURE,
  TWO_FACTOR_AUTH_DEACTIVATE_REQUEST, TWO_FACTOR_AUTH_DEACTIVATE_SUCCESS, TWO_FACTOR_AUTH_DEACTIVATE_FAILURE,
  TWO_FACTOR_AUTH_IS_ACTIVE, TWO_FACTOR_AUTH_VALIDATE_DEVICE_REQUEST, TWO_FACTOR_AUTH_VALIDATE_DEVICE_SUCCESS,
  TWO_FACTOR_AUTH_VALIDATE_DEVICE_FAILURE, TWO_FACTOR_AUTH_VALIDATE_DEVICE_REQUIRED, TWO_FACTOR_AUTH_VALIDATE_LOGIN_REQUEST,
  TWO_FACTOR_AUTH_VALIDATE_LOGIN_SUCCESS, TWO_FACTOR_AUTH_VALIDATE_LOGIN_FAILURE,
} from '../actions/auth';

const initialState = {
  isFetching: false,
  isAuthenticated: localStorage.getItem('access_token') ? true : false,
  currentUser: null,
  hasPrivacyPolicy: true,
  privacyPolicyContentHtml: null,
  fetchingPolicy: false,
  resetingPassword: false,
  creatingTwoFactorDevice: false,
  twoFactorSetupStatus: 'deactivated',
  isVerified: localStorage.getItem('access_token') ? checkUserFullAccess(localStorage.getItem('access_token')) : false,
  deactivatingTwoFactor: false,
  validatingTwoFactor: false,
};

const auth = (state = initialState, action) => {
  switch (action.type) {
    case LOGIN_REQUEST:
      return {
        ...state,
        isFetching: true,
        isAuthenticated: false
      };
    case LOGIN_SUCCESS:
      return {
        ...state,
        isFetching: false,
        isAuthenticated: true,
        errorMessage: ''
      };
    case LOGIN_FAILURE:
      return {
        ...state,
        isFetching: false,
        isAuthenticated: false,
        errorMessage: action.message
      };
    case LOGOUT_SUCCESS:
      return {
        ...state,
        isFetching: true,
        isAuthenticated: false
      };
    case RESET_PASSWORD_REQUEST:
      return {
        ...state,
        resetingPassword: true,
      };
    case RESET_PASSWORD_SUCCESS:
      return {
        ...state,
        resetingPassword: false,
      };
    case RESET_PASSWORD_FAILURE:
      return {
        ...state,
        resetingPassword: false,
        errorMessage: action.message
      };
    case CURRENT_USER_GET:
      return {
        ...state,
        currentUser: action.data
      };
    case PRIVACY_POLICY_REQUIRED:
      return {
        ...state,
        hasPrivacyPolicy: false
      };
    case PRIVACY_POLICY_GET:
      return {
        ...state,
        fetchingPolicy: true
      };
    case PRIVACY_POLICY_GET_SUCCESS:
      return {
        ...state,
        fetchingPolicy: false,
        privacyPolicyContentHtml: action.data
      };
    case PRIVACY_POLICY_GET_FAILURE:
      return {
        ...state,
        fetchingPolicy: false,
        errorMessage: action.message
      };
    case PRIVACY_POLICY_ACCEPT:
      return {
        ...state,
        acceptingPolicy: true
      };
    case PRIVACY_POLICY_ACCEPT_SUCCESS:
      return {
        ...state,
        acceptingPolicy: false,
        hasPrivacyPolicy: true
      };
    case PRIVACY_POLICY_ACCEPT_FAILURE:
      return {
        ...state,
        acceptingPolicy: false,
        errorMessage: action.message
      };
    case TWO_FACTOR_AUTH_CREATE_DEVICE_REQUEST:
      return {
        ...state,
        creatingTwoFactorDevice: true,
        twoFactorSetupStatus: 'deactivated',
      };
    case TWO_FACTOR_AUTH_CREATE_DEVICE_SUCCESS:
      return {
        ...state,
        creatingTwoFactorDevice: false,
        twoFactorSetupStatus: 'activating',
      };
    case TWO_FACTOR_AUTH_CREATE_DEVICE_FAILURE:
      return {
        ...state,
        creatingTwoFactorDevice: false,
        twoFactorSetupStatus: 'deactivated',
        errorMessage: action.message
      };
    case TWO_FACTOR_AUTH_DEACTIVATE_REQUEST:
      return {
        ...state,
        deactivatingTwoFactor: true,
      };
    case TWO_FACTOR_AUTH_DEACTIVATE_SUCCESS:
      return {
        ...state,
        deactivatingTwoFactor: false,
        twoFactorSetupStatus: 'deactivated',
      };
    case TWO_FACTOR_AUTH_DEACTIVATE_FAILURE:
      return {
        ...state,
        deactivatingTwoFactor: false,
        errorMessage: action.message
      };
    case TWO_FACTOR_AUTH_IS_ACTIVE:
      return {
        ...state,
        twoFactorSetupStatus: action.data.is_active
      };
    case TWO_FACTOR_AUTH_VALIDATE_DEVICE_REQUEST:
      return {
        ...state,
        validatingTwoFactor: true,
      };
    case TWO_FACTOR_AUTH_VALIDATE_DEVICE_SUCCESS:
      return {
        ...state,
        validatingTwoFactor: false,
        twoFactorSetupStatus: 'activated',
        isVerified: true,
      };
    case TWO_FACTOR_AUTH_VALIDATE_DEVICE_FAILURE:
      return {
        ...state,
        validatingTwoFactor: false,
        errorMessage: action.message
      };
    case TWO_FACTOR_AUTH_VALIDATE_DEVICE_REQUIRED:
      return {
        ...state,
        isVerified: false,
      };
    case TWO_FACTOR_AUTH_VALIDATE_LOGIN_REQUEST:
      return {
        ...state,
        validatingTwoFactor: true,
        isVerified: false,
      };
    case TWO_FACTOR_AUTH_VALIDATE_LOGIN_SUCCESS:
      return {
        ...state,
        validatingTwoFactor: false,
        isVerified: true,
      };
    case TWO_FACTOR_AUTH_VALIDATE_LOGIN_FAILURE:
      return {
        ...state,
        validatingTwoFactor: false,
        isVerified: false,
      };
    default:
      return state
  }
};

function checkUserFullAccess(token) {
  try {
    const my_token = JSON.parse(atob(token.split('.')[1]));

    if (my_token.user_access === 'full'){
      return true
    } else{
      return false
    };
  } catch (e) {
    return false;
  }
};

export default auth;
