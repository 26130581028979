import React, { useCallback } from 'react';
import withStyles from '@mui/styles/withStyles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import * as utils from '../utils';
import * as links from '../links';

const styles = theme => ({
  root: {
    width: '100%',
  },
  table: {
    minWidth: 650,
  },
  tableHeader: {
    fontWeight: "bold",
  },
});

function FinanceEntry(props) {
  const { entry, clinics, urgCategory, valueField } = props;

  const getClinicName = useCallback((clinicId) => {
    if (clinics.clinics_map == null) {
      return clinics[0].name;
    }
    else if (clinicId in clinics.clinics_map) {
      return clinics.clinics_map[clinicId].name;
    }
    return clinicId;
  }, [clinics]);

  return <TableRow style={{cursor: 'pointer'}} key={entry.subexam.id} hover onClick={()=> window.open(links.examLink(entry.subexam.id), "_blank")}>
      <TableCell component="th" scope="row">
        {entry.subexam.id}
      </TableCell>
      <TableCell align="left">{getClinicName(entry.clinic)}</TableCell>
      <TableCell align="left">{utils.formatDate(entry.timestamp)}</TableCell>
      <TableCell align="left">{utils.formatDate(entry.subexam.study_datetime)}</TableCell>
      <TableCell align="left">{entry.subexam.patient_name}</TableCell>
      <TableCell align="left">{entry.modality}</TableCell>
      <TableCell align="left">{(urgCategory != null && entry.category === urgCategory) ? "x" : ""}</TableCell>
      <TableCell align="right">{utils.formatBillValue(entry[valueField])}</TableCell>
    </TableRow>
}

function FinanceEntriesList(props) {
  const { classes, clinics, urgCategory, valueField } = props;

  return <TableContainer component={Paper}>
      <Table className={classes.table} size="small" aria-label="exames">
        <TableHead>
          <TableRow>
            <TableCell className={classes.tableHeader}>Exame</TableCell>
            <TableCell align="left" className={classes.tableHeader}>Clínica</TableCell>
            <TableCell align="left" className={classes.tableHeader}>Data do laudo</TableCell>
            <TableCell align="left" className={classes.tableHeader}>Data do exame</TableCell>
            <TableCell align="left" className={classes.tableHeader}>Paciente</TableCell>
            <TableCell align="left" className={classes.tableHeader}>Mod</TableCell>
            <TableCell align="left" className={classes.tableHeader}>Urg</TableCell>
            <TableCell align="right" className={classes.tableHeader}>Valor</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {props.entries.map((e, index) => (
            <FinanceEntry clinics={clinics} entry={e} key={e.subexam.id} urgCategory={urgCategory} valueField={valueField} />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
}

export default withStyles(styles)(FinanceEntriesList);
