import qs from 'qs';
import React, { useState, useCallback } from 'react';
import withStyles from '@mui/styles/withStyles';
import { connect } from 'react-redux';
import { api } from '../http/axios';

import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import classNames from 'classnames';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import { Button } from '@mui/material';

import fileDownload from 'js-file-download';

import * as superExamsHttp from '../http/super-exams';
import * as examsHttp from '../http/exams'
import * as utils from '../utils';
import Loading from '../components/Loading';

const drawerWidth = 240;

const styles = theme => ({
  root: {
    display: 'flex',
  },
  toolbar: {
    paddingRight: 24, // keep right padding when drawer closed
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    minHeight: '100vh'
  },
  title: {
    flexGrow: 1,
    paddingLeft: 24,
  },
  submitButton: {
    margin: theme.spacing(1),
  }
});


const mapStateToProps = (state) => {
  return {
  };
};


function readUrlQuery(search) {
  let query = qs.parse(search, { ignoreQueryPrefix: true });
  return query;
}


function ReportTokenAccess (props) {
  const { classes } = props;

  const open = false
  const [ accessing, setAccessing] = useState(false);
  const [ downloading, setDownloading] = useState(false);
  const [ verificationKey, setVerificationKey ] = useState('');
  const [ verificationError, setVerificationError ] = useState(false);
  const [ helperText, setHelperText ] = useState('')
  const [ verificationKeyIsValid, setVerificationKeyIsValid ] = useState(false);

  const handleSubmit = useCallback(async (value) => {
    let queryParams = readUrlQuery(props.location.search);
    const url = '/api/exams/report/token/access/'
    const data = {
      'token': queryParams.token,
      'verificationKey': value.verificationKey,
      'access': true
    }
    if (value.verificationKey.length === 11) {
      setAccessing(true);
      const res = await api.post(url, data);
      setAccessing(false);
      if (res.status === 200) {
        setVerificationKeyIsValid(true);
      }
    } else {
      setHelperText('A chave de acesso deverá conter apenas números e possuir 11 dígitos.');
      setVerificationError(true);
    }
  }, [props]);

  const download = useCallback(async (url, data) => {
    try {
      const res = await api.post(url, data);
      if (res.status === 200) {
        var res_data = res.data;
        res_data = utils.b64toBlob(res_data, 'application/pdf');
        fileDownload(res_data, `Laudo.pdf`, 'application/pdf');
        return true;
      }
    } catch (err) {
      const message = utils.getErrorMessageOrDefaultMessage(err);
      alert(message);
      return false;
    }
  }, []);

  const downloadReportAccessToken = useCallback(async (value) => {
    let queryParams = readUrlQuery(props.location.search);
    const url = '/api/exams/report/token/access/'
    const data = {
      'token': queryParams.token,
      'verificationKey': value.verificationKey,
      'download': true
    }
    if (value.verificationKey.length === 11) {
      setDownloading(true);
      await download(url, data);
      setDownloading(false);
      return false;
    }
  }, [download, props]);

  const viewReportImagesAccess = useCallback(async () => {
    let queryParams = readUrlQuery(props.location.search);
    const url = '/viewer/access_token'
    const data = {
      'token': queryParams.token,
    }
    const res = await api.post(url, data);
    if (res.data.link) {
      window.open(res.data.link, "_blank", "noreferrer");
    } else {
      alert('Falha ao visualizar as imagens do exame.')
    }
  }, [props])

  const downloadReportImagesAccessToken = useCallback(async () => {
    let queryParams = readUrlQuery(props.location.search);
    var data = {
      'token': queryParams.token,
    }
    setDownloading(true);
    const response = await examsHttp.getAccessTokenExam(data);
    if (!response.result) {
      setDownloading(false);
      alert('Falha ao acessar o exame através do token.')
    } else {
      data = {
      'token': queryParams.token,
      'examId': response.data.exam_id,
      }
      const res = await superExamsHttp.downloadAccessTokenSuperExamImages(data);
      setDownloading(false);
      if (!res.result) {
        alert('Falha ao baixar imagens do exame.')
      }
    }
  }, [props])

    return (
      <div className={classes.root}>
        <AppBar
          position="absolute"
          className={classNames(classes.appBar, open && classes.appBarShift)}
        >
          <Toolbar disableGutters={!open} className={classes.toolbar}>
            <Typography
              component="h1"
              variant="h6"
              color="inherit"
              noWrap
              className={classes.title}
            >
              Área do cliente
            </Typography>
          </Toolbar>
        </AppBar>

        <main className={classes.content}>
          <div className={classes.appBarSpacer} />
          {
            verificationKeyIsValid
              ?
              downloading
                ?
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <Grid container justifyContent="center">
                      <Loading />
                    </Grid>
                  </Grid>
                </Grid>
                :
                <div>
                  <form id="verification-key-form" onSubmit={(e) => { e.preventDefault(); downloadReportAccessToken({verificationKey}); } }>
                    <Grid container spacing={3}>
                      <Grid item xs={12}>
                        <Grid container >
                          <Button
                            type="submit"
                            variant="contained"
                            color="primary"
                            className={classes.submitButton}
                          >
                          Baixar Laudo
                          </Button>
                        </Grid>
                      </Grid>
                    </Grid>
                  </form>
                  <form id="report-images-form" onSubmit={(e) => { e.preventDefault(); viewReportImagesAccess(); } }>
                    <Grid container spacing={3}>
                      <Grid item xs={12}>
                        <Grid container >
                          <Button
                            type="submit"
                            variant="contained"
                            color="primary"
                            className={classes.submitButton}
                          >
                          Visualizar Imagens
                          </Button>
                        </Grid>
                      </Grid>
                    </Grid>
                  </form>
                  <form id="report-images-download-form" onSubmit={(e) => { e.preventDefault(); downloadReportImagesAccessToken(); } }>
                    <Grid container spacing={3}>
                      <Grid item xs={12}>
                        <Grid container >
                          <Button
                            type="submit"
                            variant="contained"
                            color="primary"
                            className={classes.submitButton}
                          >
                          Baixar Imagens
                          </Button>
                        </Grid>
                      </Grid>
                    </Grid>
                  </form>
                </div>
              :
              accessing
                ?
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <Grid container justifyContent="center">
                      <Loading />
                    </Grid>
                  </Grid>
                </Grid>
                :
                 <form id="verification-key-form" onSubmit={(e) => { e.preventDefault(); handleSubmit({verificationKey}); } }>
                  <Grid container spacing={3}>
                    <Grid item xs={12}>
                      <Grid container justifyContent="center">
                        <TextField
                          id="verification"
                          onChange={event => setVerificationKey(event.target.value)}
                          label="Informe a chave de acesso"
                          variant="outlined"
                          error= {verificationError}
                          helperText={helperText}
                          type="number"
                        />
                        <Button
                          type="submit"
                          variant="contained"
                          color="primary"
                        >
                        Entrar
                        </Button>
                      </Grid>
                    </Grid>
                  </Grid>
                </form>
          }
        </main>
      </div>
    );
}

export default withStyles(styles)(connect(mapStateToProps)(ReportTokenAccess));