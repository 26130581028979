import React, { useState, useEffect, useCallback } from 'react';
import { connect } from 'react-redux';
import withStyles from '@mui/styles/withStyles';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import MessageFinanceSummary from '../components/MessageFinanceSummary';
import PeriodSearchForm from '../components/PeriodSearchForm';
import * as billingHttp from '../http/billing';
import * as clinicHttp from '../http/clinics';
import * as utils from '../utils';
import Loading from '../components/Loading';

const styles = theme => ({
  root: {
    width: '100%',
  },
  title: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(1),
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
    verticalAlign: 'baseline',
  },
});

const mapStateToProps = (state) => {
  return {
    summary: state.billing.message.summary,
    clinics: state.clinics.list,
    clinics_map: state.clinics.clinics_map,
    errorMessage: state.billing.message.requestError != null ? state.billing.message.requestError.response.data.detail : null,
    loading: state.billing.message.loading,
  };
};

function MessageFinances(props) {
  const {clinics_map} = props;
  const { classes,
          clinics,
          loading,
          summary,
          errorMessage } = props;

  const [fromDate, setFromDate] = useState();
  const [toDate, setToDate] = useState();
  const [clinicFilter, setClinicFilter] = useState();
  const [order, setOrder] = useState("exam-date");

  const orders = [
    {label: "Método de envio", value: "delivery-method"},
    {label: "Data de envio", value: "timestamp"}
  ];

  useEffect(() => {
    billingHttp.clearCurrent();
    clinicHttp.list();
  }, []);

  const doQuery = useCallback(() => {
    let formattedFromDate = utils.formatDateToString(fromDate);
    let formattedToDate = utils.formatDateToString(toDate);
    billingHttp.getMessageFinance(formattedFromDate, formattedToDate, order, clinicFilter);
  }, [fromDate, toDate, order, clinicFilter]);

  const setFromDateCallback = useCallback((date, value) => {
    setFromDate(date);
  }, []);

  const setToDateCallback = useCallback((date, value) => {
    setToDate(date);
  }, []);

  const handleClinicChange = useCallback(async (event) => {
    setClinicFilter(event.target.value);
  }, []);

  if (Object.keys(clinics_map).length === 0) {
    return <Loading />;
  }

  const errorComponent = errorMessage != null ?
      <Typography variant="subtitle1" color="error">{errorMessage}</Typography>
      : null

  const loadingComponent = loading ?
      <Typography variant="subtitle1" color="primary">Carregando</Typography>
      : null

  return <div className={classes.root}>
    <form>
      <PeriodSearchForm fromDate={fromDate} toDate={toDate} setFromDate={setFromDateCallback} setToDate={setToDateCallback} />

      <FormControl className={classes.formControl}>
        <InputLabel htmlFor="clinic-selector">Clínica</InputLabel>
        <Select
          value={clinicFilter || ''}
          inputProps={{
            name: 'clinics',
            id: 'clinics-selector'
          }}
          label="Clínica"
          onChange={handleClinicChange}
          >
          <MenuItem><em>Todas</em></MenuItem>
          {clinics && clinics.map(clinic => (
            <MenuItem key={clinic.id} value={clinic.id}>{clinic.name}</MenuItem>
            ))}
        </Select>
      </FormControl>
      <FormControl className={classes.formControl}>
        <InputLabel htmlFor="order-selector">Ordenar por</InputLabel>
        <Select
          value={order}
          inputProps={{
            name: "order",
            id: "order-selector",
          }}
          label="Ordernar por"
          onChange={(order) => setOrder(order.target.value)}
        >
          <MenuItem value="">
            <em>...</em>
          </MenuItem>
          {orders.map((order) => (
            <MenuItem key={order.value} value={order.value}>
              {order.label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <Button variant="contained" color="primary" onClick={doQuery}>
        Carregar
      </Button>
      <FormControl className={classes.formControl}>
        { loadingComponent }
      </FormControl>
    </form>
    { errorComponent }
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography className={classes.title} style={{paddingTop: "0px"}} variant="h4">Resumo</Typography>
        <MessageFinanceSummary summary={ summary.perMethod } totals={ summary } valueField={"value"} />
      </Grid>
    </Grid>
    </div>
};

export default withStyles(styles)(connect(mapStateToProps)(MessageFinances));