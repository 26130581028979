export const REPORTS_LIST = 'REPORTS_LIST';
export const REPORTS_LIST_REQUEST = 'REPORTS_LIST_REQUEST';
export const REPORTS_LIST_SUCCESS = 'REPORTS_LIST_SUCCESS';
export const REPORTS_LIST_FAILURE = 'REPORTS_LIST_FAILURE';

export const REPORTS_GET = 'REPORTS_GET';
export const REPORTS_GET_REQUEST = 'REPORTS_GET_REQUEST';
export const REPORTS_GET_SUCCESS = 'REPORTS_GET_SUCCESS';
export const REPORTS_GET_FAILURE = 'REPORTS_GET_FAILURE';

export const REPORTS_LIST_FOR_EXAM = 'REPORTS_LIST_FOR_EXAM';
export const REPORTS_LIST_FOR_EXAM_REQUEST = 'REPORTS_LIST_FOR_EXAM_REQUEST';
export const REPORTS_LIST_FOR_EXAM_SUCCESS = 'REPORTS_LIST_FOR_EXAM_SUCCESS';
export const REPORTS_LIST_FOR_EXAM_FAILURE = 'REPORTS_LIST_FOR_EXAM_FAILURE';

export const REPORTS_CLEAR_CURRENT_REPORT_FOR_EXAM = 'REPORTS_CLEAR_CURRENT_REPORT_FOR_EXAM';

export const REPORTS_CREATE = 'REPORTS_CREATE';
export const REPORTS_CREATE_REQUEST = 'REPORTS_CREATE_REQUEST';
export const REPORTS_CREATE_SUCCESS = 'REPORTS_CREATE_SUCCESS';
export const REPORTS_CREATE_FAILURE = 'REPORTS_CREATE_FAILURE';

export const REPORTS_UPDATE = 'REPORTS_UPDATE';
export const REPORTS_UPDATE_REQUEST = 'REPORTS_UPDATE_REQUEST';
export const REPORTS_UPDATE_SUCCESS = 'REPORTS_UPDATE_SUCCESS';
export const REPORTS_UPDATE_FAILURE = 'REPORTS_UPDATE_FAILURE';

export const REPORTS_CLEAR_CURRENT = 'REPORTS_CLEAR_CURRENT';

export const REPORTS_PREVIEW = 'REPORTS_PREVIEW';
export const REPORTS_PREVIEW_REQUEST = 'REPORTS_PREVIEW_REQUEST';
export const REPORTS_PREVIEW_SUCCESS = 'REPORTS_PREVIEW_SUCCESS';
export const REPORTS_PREVIEW_FAILURE = 'REPORTS_PREVIEW_FAILURE';

export const REPORTS_PREVIEW_SET_EXAM = 'REPORTS_PREVIEW_SET_EXAM';
export const REPORTS_PREVIEW_SET_CONTENT = 'REPORTS_PREVIEW_SET_CONTENT';
export const REPORTS_PREVIEW_SET_CLINIC = 'REPORTS_PREVIEW_SET_CLINIC';
export const REPORTS_PREVIEW_SET_MODALITY = 'REPORTS_PREVIEW_SET_MODALITY';
export const REPORTS_PREVIEW_SET_PRIVATE = 'REPORTS_PREVIEW_SET_PRIVATE';

export const listRequest = (data) => {
  return {
    type: REPORTS_LIST_REQUEST
  };
};

export const listSuccess = (data) => {
  return {
    type: REPORTS_LIST_SUCCESS,
    data
  };
};

export const listFailure = (data) => {
  return {
    type: REPORTS_LIST_FAILURE,
    data
  };
};

export const getRequest = (data) => {
  return {
    type: REPORTS_GET_REQUEST
  };
};

export const getSuccess = (data) => {
  return {
    type: REPORTS_GET_SUCCESS,
    data
  };
};

export const getFailure = (data) => {
  return {
    type: REPORTS_GET_FAILURE,
    data
  };
};

export const listClinicReportForExamRequest = (data) => {
  return {
    type: REPORTS_LIST_FOR_EXAM,
  };
};

export const listClinicReportForExamSuccess = (data) => {
  return {
    type: REPORTS_LIST_FOR_EXAM_SUCCESS,
    data
  };
};

export const listClinicReportForExamFailure = (data) => {
  return {
    type: REPORTS_LIST_FOR_EXAM_FAILURE,
    data
  };
};

export const createRequest = (data) => {
  return {
    type: REPORTS_CREATE_REQUEST
  };
};

export const createSuccess = (data) => {
  return {
    type: REPORTS_CREATE_SUCCESS,
    data
  };
};

export const createFailure = (data) => {
  return {
    type: REPORTS_CREATE_FAILURE,
    data
  };
};

export const uptadeRequest = (data) => {
  return {
    type: REPORTS_UPDATE_REQUEST
  };
};

export const uptadeSuccess = (data) => {
  return {
    type: REPORTS_UPDATE_SUCCESS,
    data
  };
};

export const uptadeFailure = (data) => {
  return {
    type: REPORTS_UPDATE_FAILURE,
    data
  };
};

export const clearCurrent = () => {
  return {
    type: REPORTS_CLEAR_CURRENT
  };
};

export const previewRequest = (data) => {
  return {
    type: REPORTS_PREVIEW_REQUEST,
    data,
  };
};

export const previewSuccess = (data) => {
  return {
    type: REPORTS_PREVIEW_SUCCESS,
    data
  };
};

export const previewFailure = (data) => {
  return {
    type: REPORTS_PREVIEW_FAILURE,
    data
  };
};

export const clearCurrentReportsForExam = () => {
  return {
    type: REPORTS_CLEAR_CURRENT_REPORT_FOR_EXAM,
  };
};

export const previewSetContent = (data) => {
  return {
    type: REPORTS_PREVIEW_SET_CONTENT,
    data
  };
};

export const previewSetExam = (data) => {
  return {
    type: REPORTS_PREVIEW_SET_EXAM,
    data
  };
};

export const previewSetClinic = (data) => {
  return {
    type: REPORTS_PREVIEW_SET_CLINIC,
    data
  };
};

export const previewSetModality = (data) => {
  return {
    type: REPORTS_PREVIEW_SET_MODALITY,
    data
  };
};
