export const EXAMS_SEARCH = 'EXAMS_SEARCH';
export const EXAMS_SEARCH_FAILURE = 'EXAMS_SEARCH_FAILURE';
export const EXAMS_SEARCH_EXAMS_TO_REVIEW = 'EXAMS_SEARCH_EXAMS_TO_REVIEW';
export const EXAMS_SEARCH_EXAMS_TO_REVIEW_FAILURE = 'EXAMS_SEARCH_EXAMS_TO_REVIEW_FAILURE';
export const EXAMS_SEARCH_PATIENT_EXAMS = 'EXAMS_SEARCH_PATIENT_EXAMS';
export const EXAMS_CLEAR_PATIENT_EXAMS = 'EXAMS_CLEAR_PATIENT_EXAMS';
export const EXAMS_SEARCH_PATIENT_EXAMS_FAILURE = 'EXAMS_SEARCH_PATIENT_EXAMS_FAILURE';
export const EXAMS_SEARCH_RELEASED_EXAMS = 'EXAMS_SEARCH_RELEASED_EXAMS';
export const EXAMS_SEARCH_RELEASED_EXAMS_FAILURE = 'EXAMS_SEARCH_RELEASED_EXAMS_FAILURE';
export const EXAMS_SEARCH_PENDING_EXAMS = 'EXAMS_SEARCH_PENDING_EXAMS';
export const EXAMS_SEARCH_PENDING_EXAMS_FAILURE = 'EXAMS_SEARCH_PENDING_EXAMS_FAILURE';
export const EXAMS_LIST = 'EXAMS_LIST';
export const EXAMS_LIST_PENDING_DASHBOARD = 'EXAMS_LIST_PENDING_DASHBOARD';
export const EXAMS_LIST_RELEASED_DASHBOARD = 'EXAMS_LIST_RELEASED_DASHBOARD';
export const EXAMS_GET = 'EXAMS_GET';
export const EXAMS_CLEAR_CURRENT = 'EXAMS_CLEAR_CURRENT';
export const EXAMS_CLEAR_ALL = 'EXAMS_CLEAR_ALL';
export const EXAMS_CREATE_EXAM_REPORT_REQUEST = 'EXAMS_CREATE_EXAM_REPORT_REQUEST';
export const EXAMS_CREATE_EXAM_REPORT_SUCCESS = 'EXAMS_CREATE_EXAM_SUCCESS';
export const EXAMS_CREATE_EXAM_REPORT_FAILURE = 'EXAMS_CREATE_EXAM_FAILURE';
export const EXAMS_CREATE_EXAM_REPORT_REVIEW_REQUEST = 'EXAMS_CREATE_EXAM_REPORT_REVIEW_REQUEST';
export const EXAMS_CREATE_EXAM_REPORT_REVIEW_SUCCESS = 'EXAMS_CREATE_EXAM_REPORT_REVIEW_SUCCESS';
export const EXAMS_CREATE_EXAM_REPORT_REVIEW_FAILURE = 'EXAMS_CREATE_EXAM_REPORT_REVIEW_FAILURE';
export const EXAMS_DELETE_EXAM_REPORT_REVIEW_REQUEST = 'EXAMS_DELETE_EXAM_REPORT_REVIEW_REQUEST';
export const EXAMS_DELETE_EXAM_REPORT_REVIEW_SUCCESS = 'EXAMS_DELETE_EXAM_REPORT_REVIEW_SUCCESS';
export const EXAMS_DELETE_EXAM_REPORT_REVIEW_FAILURE = 'EXAMS_DELETE_EXAM_REPORT_REVIEW_FAILURE';
export const EXAMS_GET_IMAGES = 'EXAMS_GET_IMAGES';
export const EXAMS_GET_IMAGES_REQUEST = 'EXAMS_GET_IMAGES_REQUEST';
export const EXAMS_GET_IMAGES_SUCCESS = 'EXAMS_GET_IMAGES_SUCCESS';
export const EXAMS_GET_IMAGES_FAILURE = 'EXAMS_GET_IMAGES_FAILURE';
export const EXAMS_GET_IMAGE_DATA_REQUEST = 'EXAMS_GET_IMAGE_DATA_REQUEST';
export const EXAMS_GET_IMAGE_DATA_SUCCESS = 'EXAMS_GET_IMAGE_DATA_SUCCESS';
export const EXAMS_GET_IMAGE_DATA_FAILURE = 'EXAMS_GET_IMAGE_DATA_FAILURE';
export const EXAMS_UPDATE_IMAGES = 'EXAMS_UPDATE_IMAGES';
export const EXAMS_UPDATE_IMAGES_REQUEST = 'EXAMS_UPDATE_IMAGES_REQUEST';
export const EXAMS_UPDATE_IMAGES_SUCCESS = 'EXAMS_UPDATE_IMAGES_SUCCESS';
export const EXAMS_UPDATE_IMAGES_FAILURE = 'EXAMS_UPDATE_IMAGES_FAILURE';
export const BULK_EXAMS_UPDATE = 'BULK_EXAMS_UPDATE';
export const BULK_EXAMS_UPDATE_REQUEST = 'BULK_EXAMS_UPDATE_REQUEST';
export const BULK_EXAMS_UPDATE_SUCCESS = 'BULK_EXAMS_UPDATE_SUCCESS';
export const BULK_EXAMS_UPDATE_FAILURE = 'BULK_EXAMS_UPDATE_FAILURE';
export const EXAMS_UPLOAD_ANNEX = 'EXAMS_UPLOAD_ANNEX';
export const EXAMS_UPLOAD_ANNEX_REQUEST = 'EXAMS_UPLOAD_ANNEX_REQUEST';
export const EXAMS_UPLOAD_ANNEX_SUCCESS = 'EXAMS_UPLOAD_ANNEX_SUCCESS';
export const EXAMS_UPLOAD_ANNEX_FAILURE = 'EXAMS_UPLOAD_ANNEX_FAILURE';
export const EXAMS_DELETE_REQUEST = 'EXAMS_DELETE_REQUEST';
export const EXAMS_DELETE_SUCCESS = 'EXAMS_DELETE_SUCCESS';
export const EXAMS_DELETE_FAILURE = 'EXAMS_DELETE_FAILURE';
export const EXAMS_CREATE_MEDICAL_REPORT_ACCESS_TOKEN_REQUEST = 'EXAMS_CREATE_MEDICAL_REPORT_ACCESS_TOKEN_REQUEST';
export const EXAMS_CREATE_MEDICAL_REPORT_ACCESS_TOKEN_SUCCESS = 'EXAMS_CREATE_MEDICAL_REPORT_ACCESS_TOKEN_SUCCESS';
export const EXAMS_CREATE_MEDICAL_REPORT_ACCESS_TOKEN_FAILURE = 'EXAMS_CREATE_MEDICAL_REPORT_ACCESS_TOKEN_FAILURE';
export const EXAMS_GET_ACCESS_TOKEN_EXAM_REQUEST = 'EXAMS_GET_ACCESS_TOKEN_EXAM_REQUEST';
export const EXAMS_GET_ACCESS_TOKEN_EXAM_SUCCESS = 'EXAMS_GET_ACCESS_TOKEN_EXAM_SUCCESS';
export const EXAMS_GET_ACCESS_TOKEN_EXAM_FAILURE = 'EXAMS_GET_ACCESS_TOKEN_EXAM_FAILURE';
export const EXAMS_ASSOCIATE_REQUEST = 'EXAMS_ASSOCIATE_REQUEST';
export const EXAMS_ASSOCIATE_SUCCESS = 'EXAMS_ASSOCIATE_SUCCESS';
export const EXAMS_ASSOCIATE_FAILURE = 'EXAMS_ASSOCIATE_FAILURE';
export const EXAMS_DISASSOCIATE_REQUEST = 'EXAMS_DISASSOCIATE_REQUEST';
export const EXAMS_DISASSOCIATE_SUCCESS = 'EXAMS_DISASSOCIATE_SUCCESS';
export const EXAMS_DISASSOCIATE_FAILURE = 'EXAMS_DISASSOCIATE_FAILURE';
export const RETRIEVE_PATIENT_INFO_REQUEST = 'RETRIEVE_PATIENT_INFO_REQUEST';
export const RETRIEVE_PATIENT_INFO_SUCCESS = 'RETRIEVE_PATIENT_INFO_SUCCESS';
export const RETRIEVE_PATIENT_INFO_FAILURE = 'RETRIEVE_PATIENT_INFO_FAILURE';
export const EXAM_CREATE_EXAM_MANUALLY_REQUEST = 'EXAM_CREATE_EXAM_MANUALLY_REQUEST';
export const EXAM_CREATE_EXAM_MANUALLY_SUCCESS = 'EXAM_CREATE_EXAM_MANUALLY_SUCCESS';
export const EXAM_CREATE_EXAM_MANUALLY_FAILURE = 'EXAM_CREATE_EXAM_MANUALLY_FAILURE';


export const search = (data) => {
  return {
    type: EXAMS_SEARCH,
    data
  };
};

export const searchFailure = (data) => {
  return {
    type: EXAMS_SEARCH_FAILURE,
    data
  };
};

export const searchExamsToReview = (data) => {
  return {
    type: EXAMS_SEARCH_EXAMS_TO_REVIEW,
    data
  };
};

export const searchExamsToReviewFailure = (data) => {
  return {
    type: EXAMS_SEARCH_EXAMS_TO_REVIEW_FAILURE,
    data
  };
};

export const searchPatientExams = (data) => {
  return {
    type: EXAMS_SEARCH_PATIENT_EXAMS,
    data
  }
}

export const clearPatientExams = (data) => {
  return {
    type: EXAMS_CLEAR_PATIENT_EXAMS,
    data
  }
}

export const searchReleasedExams = (data) => {
  return {
    type: EXAMS_SEARCH_RELEASED_EXAMS,
    data
  };
};

export const searchReleasedExamsFailure = (data) => {
  return {
    type: EXAMS_SEARCH_RELEASED_EXAMS_FAILURE,
    data
  };
};

export const searchPendingExams = (data) => {
  return {
    type: EXAMS_SEARCH_PENDING_EXAMS,
    data
  };
};

export const searchPendingExamsFailure = (data) => {
  return {
    type: EXAMS_SEARCH_PENDING_EXAMS_FAILURE,
    data
  };
};

export const list = (data) => {
  return {
    type: EXAMS_LIST,
    data
  };
};

export const listPendingDashboard = (data) => {
  return {
    type: EXAMS_LIST_PENDING_DASHBOARD,
    data
  };
};

export const listReleasedDashboard = (data) => {
  return {
    type: EXAMS_LIST_RELEASED_DASHBOARD,
    data
  };
};

export const get = (data) => {
  return {
    type: EXAMS_GET,
    data
  };
}

export const clearCurrent = () => {
  return {
    type: EXAMS_CLEAR_CURRENT
  };
}

export const clearAll = () => {
  return {
    type: EXAMS_CLEAR_ALL
  };
}

export const createExamReportRequest = () => {
  return {
    type: EXAMS_CREATE_EXAM_REPORT_REQUEST
  };
}

export const createExamReportSuccess = () => {
  return {
    type: EXAMS_CREATE_EXAM_REPORT_SUCCESS
  };
}

export const createExamReportFailure = () => {
  return {
    type: EXAMS_CREATE_EXAM_REPORT_FAILURE
  };
}

export const createExamReportReviewRequest = () => {
  return {
    type: EXAMS_CREATE_EXAM_REPORT_REVIEW_REQUEST
  };
}

export const createExamReportReviewSuccess = () => {
  return {
    type: EXAMS_CREATE_EXAM_REPORT_REVIEW_SUCCESS
  };
}

export const createExamReportReviewFailure = () => {
  return {
    type: EXAMS_CREATE_EXAM_REPORT_REVIEW_FAILURE
  };
}

export const deleteExamReportReviewRequest = () => {
  return {
    type: EXAMS_DELETE_EXAM_REPORT_REVIEW_REQUEST
  };
}

export const deleteExamReportReviewSuccess = () => {
  return {
    type: EXAMS_DELETE_EXAM_REPORT_REVIEW_SUCCESS
  };
}

export const deleteExamReportReviewFailure = () => {
  return {
    type: EXAMS_DELETE_EXAM_REPORT_REVIEW_FAILURE
  };
}

export const getExamImagesRequest = () => {
  return {
    type: EXAMS_GET_IMAGES_REQUEST
  };
}

export const getExamImagesSuccess = (data) => {
  return {
    type: EXAMS_GET_IMAGES_SUCCESS,
    data
  };
}

export const getExamImagesFailure = (data) => {
  return {
    type: EXAMS_GET_IMAGES_FAILURE,
    data
  };
}

export const getExamImageDataRequest = () => {
  return {
    type: EXAMS_GET_IMAGE_DATA_REQUEST
  };
}

export const getExamImageDataSuccess = (data) => {
  return {
    type: EXAMS_GET_IMAGE_DATA_SUCCESS,
    data
  };
}

export const getExamImageDataFailure = (data) => {
  return {
    type: EXAMS_GET_IMAGE_DATA_FAILURE,
    data
  };
}

export const updateExamImagesRequest = () => {
  return {
    type: EXAMS_UPDATE_IMAGES_REQUEST
  };
}

export const updateExamImagesSuccess = (data) => {
  return {
    type: EXAMS_UPDATE_IMAGES_SUCCESS,
    data
  };
}

export const updateExamImagesFailure = (data) => {
  return {
    type: EXAMS_UPDATE_IMAGES_FAILURE,
    data
  };
}

export const bulkUpdateExamsRequest = () => {
  return {
    type: BULK_EXAMS_UPDATE_REQUEST
  };
}

export const bulkUpdateExamsSuccess = (data) => {
  return {
    type: BULK_EXAMS_UPDATE_SUCCESS,
    data
  };
}

export const bulkUpdateExamsFailure = (data) => {
  return {
    type: BULK_EXAMS_UPDATE_FAILURE,
    data
  };
}

export const uploadExamAnnexRequest = () => {
  return {
    type: EXAMS_UPLOAD_ANNEX_REQUEST
  };
}

export const uploadExamAnnexSuccess = (data) => {
  return {
    type: EXAMS_UPLOAD_ANNEX_SUCCESS,
    data
  };
}

export const uploadExamAnnexFailure = (data) => {
  return {
    type: EXAMS_UPLOAD_ANNEX_FAILURE,
    data
  };
}

export const deleteSubExamRequest = (data) => {
  return {
    type: EXAMS_DELETE_REQUEST,
    data
  };
}

export const deleteSubExamSuccess = (data) => {
  return {
    type: EXAMS_DELETE_SUCCESS,
    data
  };
}

export const deleteSubExamFailure = (data) => {
  return {
    type: EXAMS_DELETE_FAILURE,
    data
  };
}

export const createMedicalReportAccessTokenRequest = (data) => {
  return {
    type: EXAMS_CREATE_MEDICAL_REPORT_ACCESS_TOKEN_REQUEST,
    data
  }
}

export const createMedicalReportAccessTokenSuccess = (data) => {
  return {
    type: EXAMS_CREATE_MEDICAL_REPORT_ACCESS_TOKEN_SUCCESS,
    data
  }
}

export const createMedicalReportAccessTokenFailure = (data) => {
  return {
    type: EXAMS_CREATE_MEDICAL_REPORT_ACCESS_TOKEN_FAILURE,
    data
  }
}

export const getAccessTokenExamRequest = (data) => {
  return {
    type: EXAMS_GET_ACCESS_TOKEN_EXAM_REQUEST,
    data
  }
}

export const getAccessTokenExamSuccess = (data) => {
  return {
    type: EXAMS_GET_ACCESS_TOKEN_EXAM_SUCCESS,
    data
  }
}

export const getAccessTokenExamFailure = (data) => {
  return {
    type: EXAMS_GET_ACCESS_TOKEN_EXAM_FAILURE,
    data
  }
}

export const associateExamsRequest = () => {
  return {
    type: EXAMS_ASSOCIATE_REQUEST,
  }
}

export const associateExamsSuccess = (data) => {
  return {
    type: EXAMS_ASSOCIATE_SUCCESS,
    data
  }
}

export const associateExamsFailure = (data) => {
  return {
    type: EXAMS_ASSOCIATE_FAILURE,
    data
  }
}

export const disassociateExamsRequest = () => {
  return {
    type: EXAMS_DISASSOCIATE_REQUEST,
  }
}

export const disassociateExamsSuccess = () => {
  return {
    type: EXAMS_DISASSOCIATE_SUCCESS,
  }
}

export const disassociateExamsFailure = (data) => {
  return {
    type: EXAMS_DISASSOCIATE_FAILURE,
    data
  }
}

export const retrievePatInfoRequest = (data) => {
  return {
    type:RETRIEVE_PATIENT_INFO_REQUEST,
    data
  }
}

export const retrievePatInfoSuccess = (data) => {
  return {
    type: RETRIEVE_PATIENT_INFO_SUCCESS,
    data
  };
}

export const retrievePatInfoFailure = (data) => {
  return {
    type: RETRIEVE_PATIENT_INFO_FAILURE,
    data
  };
}

export const createExamManuallyRequest = (data) => {
  return {
    type: EXAM_CREATE_EXAM_MANUALLY_REQUEST,
    data
  }
}

export const createExamManuallySuccess = () => {
  return {
    type: EXAM_CREATE_EXAM_MANUALLY_SUCCESS,
  }
}

export const createExamManuallyFailure = (data) => {
  return {
    type: EXAM_CREATE_EXAM_MANUALLY_FAILURE,
    data
  }
}
