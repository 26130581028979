import React, { useCallback } from 'react';
import { connect } from 'react-redux';
import Button from '@mui/material/Button';
import * as utils from '../utils';

const FEEDBACK_LINK = "https://forms.gle/2RdE6q2GvYzv4GS77"

const mapStateToProps = (state) => {
  return {
    user: state.auth.currentUser,
  };
};

function SubmitFeedbackButton(props) {
  const {user} = props;
  const openFeedback = useCallback(async () => {
    window.open(FEEDBACK_LINK, "_blank");
  }, []);

  if (!utils.user_has_permission(user, 'accounts.can_submit_feedback')) {
    return null
  }

  return (
    <Button variant="contained" color="primary" disableElevation onClick={openFeedback}>
      Enviar sugestão
    </Button>
  );
}

export default connect(mapStateToProps)(SubmitFeedbackButton);
