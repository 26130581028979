import React, { useCallback } from 'react';
import withStyles from '@mui/styles/withStyles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import * as utils from '../utils';

const styles = theme => ({
  root: {
    width: '100%',
  },
  table: {
    minWidth: 250,
  },
  tableHeader: {
    fontWeight: "bold",
  },
  firstRow: {
    backgroundColor: "#c0c0c0",
  }
});

function MedicFinanceSummary(props) {
  const { classes, summaryPerMedic, totals, urgCategory } = props;

  const getUrgencyCount = useCallback((catMap) => {
    if (urgCategory == null) {
      return "";
    }

    if (urgCategory in catMap) {
      return catMap[urgCategory].count;
    }
    return 0;
  }, [urgCategory]);

  return <TableContainer component={Paper}>
      <Table className={classes.table} size="small" aria-label="exames">
        <TableHead>
          <TableRow className={classes.firstRow}>
            <TableCell className={classes.tableHeader}>Médicos</TableCell>
            <TableCell align="left" className={classes.tableHeader}>Exames</TableCell>
            <TableCell align="left" className={classes.tableHeader}>Urg</TableCell>
            <TableCell align="left" className={classes.tableHeader}>Total</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {Object.keys(summaryPerMedic).map((key) => (
            <TableRow key={key}>
              <TableCell>{summaryPerMedic[key].medic_name}</TableCell>
              <TableCell>{ summaryPerMedic[key].count }</TableCell>
              <TableCell>{getUrgencyCount(summaryPerMedic[key].perCategory)}</TableCell>
              <TableCell>{ utils.formatBillValue(summaryPerMedic[key].medic_value) }</TableCell>
            </TableRow>
	  ))}
          <TableRow>
            <TableCell></TableCell>
            <TableCell align="left">{ totals.count }</TableCell>
            <TableCell>{getUrgencyCount(totals.perCategory)}</TableCell>
            <TableCell align="left">{ utils.formatBillValue(totals.medic_value) }</TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
}

export default withStyles(styles)(MedicFinanceSummary);