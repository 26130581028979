import React, { useState, useCallback, useEffect } from 'react';
import withStyles from '@mui/styles/withStyles';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';

import * as examsHttp from '../http/exams';
import * as utils from '../utils';

const styles = theme => ({
  formControl: {
    margin: theme.spacing(2),
  },
  submitButton: {
    margin: theme.spacing(2),
  }
});

function ExamMultiplierForm(props) {
  const { classes,
          exam,
          updatedCallback
        } = props;

  const [updating, setUpdating] = useState("");
  const [multiplier, setMultiplier] = useState(1.0);
  const [added, setAdded] = useState(0.0);
  const [errorMessage, setErrorMessage] = useState("");

  useEffect(() => {
    if (exam == null || exam === undefined) {
      return;
    }
    if (exam.multiplier != null) {
      setMultiplier(exam.multiplier.multiplier);
      setAdded(exam.multiplier.added);
    } else {
      setMultiplier(1.0);
      setAdded(0.0);
    }
  }, [exam])

  const doUpdate = useCallback(async () => {
    setUpdating("Atualizando...");
    setErrorMessage("");

    // some input validation
    if (!utils.isNumber(multiplier) || !utils.isNumber(added)) {
      setUpdating("");
      setErrorMessage("Valores devem ser números");
      return;
    }

    var multiplierData = {
      multiplier: parseFloat(multiplier),
      added: parseFloat(added)
    }

    var {result, data} = await examsHttp.updateMultiplier({
        subexam: exam.id,
        multiplier: multiplierData,
    });
    if (result) {
      setUpdating("Atualizado"); 
      if (updatedCallback != null && updatedCallback !== undefined) {
        updatedCallback(exam, multiplierData)
      }
    } else {
      setUpdating(""); 
      if (data.response.status === 404) {
        setErrorMessage("Exame não encontrado");
      } else if (data.response.status === 500) {
        setErrorMessage("Um erro foi encontrado");
      } else {
        var message = data.response.data.message;
        setErrorMessage(message);
      }
    }
  }, [exam, multiplier, added, updatedCallback]);

  if (exam == null || exam === undefined) {
    return null;
  }

  return (
      <React.Fragment>
      <Typography variant="subtitle1">Exame: {exam.patient_name}</Typography>
        <form>
          <FormControl className={classes.formControl}>
            <TextField label="Multiplicador" value={multiplier} onChange={(event) => setMultiplier(event.target.value)} />
            <TextField label="Adição" value={added} onChange={(event) => setAdded(event.target.value)} />
            <Button onClick={doUpdate} variant="contained" color="primary" className={classes.submitButton}>
              Atualizar
            </Button>
          </FormControl>
        </form>
        <Typography color="primary" variant="body1">{updating}</Typography>
        <Typography color="secondary" variant="body1">{errorMessage}</Typography>
      </React.Fragment>
  );
};

export default withStyles(styles)(ExamMultiplierForm);
