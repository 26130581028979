import React from 'react';
import Typography from '@mui/material/Typography';


export default function Copyright() {
  return (
    <React.Fragment>
      <Typography variant="body2" color="textSecondary" align="center">
        {'Copyright © 2012-2022 Imagi Soluções Tecnológicas Ltda.'}
      </Typography>
      <Typography variant="body2" color="textSecondary" align="center">
        {'CNPJ 17.264.229/0001-36'}
      </Typography>
    </React.Fragment>
    );
}
