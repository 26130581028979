import _ from 'lodash';

const loading = (state = {}, action) => {
  if (!action) return {};

  const { type } = action;
  const matches = /(.*)_(REQUEST|SUCCESS|FAILURE)/.exec(type);

  // not a *_REQUEST / *_SUCCESS /  *_FAILURE actions, so we ignore them
  if (!matches) return state;

  const [, requestName, requestState] = matches;

  return {
    ...state,
    [requestName]: requestState === 'REQUEST',
  };
};

export const createLoadingSelector = (actions) => (state) => {
  return _.some(actions, action => _.get(state, `loading.${action}`));
};

export default loading;
