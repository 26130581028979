import React from 'react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableBody from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import Button from '@mui/material/Button';

import * as examsHttp from '../http/exams';
import * as utils from '../utils';

export default function SuperExamExamsList(props) {
  const { subExams, user } = props;

  const tableHeaders = [
    { label: 'Id', field: 'patient_name' },
    { label: 'Descrição', field: 'study_datetime' },
    { label: 'Status', align: 'center' }
  ];

  const handleRemoveClick = subExamId => () => {
    examsHttp.deleteExam(subExamId);
  };

  return (
    <Paper>
      <Table>
        <TableHead>
          <TableRow>
            {tableHeaders.map(h => (
              <TableCell align={h.align} key={h.label}>
                {h.label}
              </TableCell>
            ))}
            {utils.user_has_permission(user, "exams.delete_subexam") &&
              <TableCell align="center" key="Apagar">
                Apagar
              </TableCell>
            }
          </TableRow>
        </TableHead>
        <TableBody>
          {subExams.map(se => (
            <TableRow key={se.id} hover>
              <TableCell align="left">{se.id}</TableCell>
              <TableCell align="left">{se.description}</TableCell>
              <TableCell align="center" style={{ backgroundColor: `#${se.status_color_rgb}`}}>{se.status}</TableCell>
              {utils.user_has_permission(user, "exams.delete_subexam") &&
                <TableCell align="center">
                {utils.subexam_can_be_deleted(user, se) &&
                  <Button variant="contained" color="secondary" onClick={handleRemoveClick(se.id)}>Apagar</Button>
                }
                </TableCell>
              }
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Paper>
  );
};
