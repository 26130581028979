 import {
  REPORTS_GET_SUCCESS, REPORTS_LIST_SUCCESS, REPORTS_CLEAR_CURRENT, REPORTS_UPDATE_SUCCESS,
  REPORTS_PREVIEW_REQUEST, REPORTS_PREVIEW_SUCCESS, REPORTS_CLEAR_CURRENT_REPORT_FOR_EXAM,
  REPORTS_LIST_FOR_EXAM_SUCCESS,
  REPORTS_PREVIEW_SET_EXAM, REPORTS_PREVIEW_SET_CONTENT,
  REPORTS_PREVIEW_SET_CLINIC, REPORTS_PREVIEW_SET_MODALITY
} from '../actions/reports';

const initialState = {
  current: null,
  list: [],
  count: 0,
  preview: null,
  previewData: {
    content: "",
    clinic: null,
    modality: "",
    exam: null,
    requestId: "",
  },
};

const reports = (state = initialState, action) => {
  switch (action.type) {
    case REPORTS_GET_SUCCESS:
      return {
        ...state,
        current: action.data
      };
    case REPORTS_LIST_SUCCESS:
      return {
        ...state,
        list: action.data.results,
        count: action.data.count
      };
    case REPORTS_LIST_FOR_EXAM_SUCCESS:
      return {
        ...state,
        currentReportsForExam: action.data,
      };
    case REPORTS_UPDATE_SUCCESS:
      const index = state.list.findIndex(r => r.id === action.data.id);
      let updatedList = state.list;
      if (index !== -1) {
        updatedList = [].concat(
          state.list.splice(0, index),
          action.data,
          state.list.splice(index + 1, state.list.length)
        )
      }
      return {
        ...state,
        list: updatedList,
        current: action.data
      };
    case REPORTS_CLEAR_CURRENT:
      return {
        ...state,
        current: null,
        preview: null,
        previewData: {
          content: "",
          clinic: null,
          modality: "",
          exam: null,
        }
      };
    case REPORTS_CLEAR_CURRENT_REPORT_FOR_EXAM:
      return {
        ...state,
        currentReportsForExam: [],
      };

    case REPORTS_PREVIEW_REQUEST:
      return {
        ...state,
        previewData: {
          ...state.previewData,
          requestId: action.data.requestId,
        }
      };
    case REPORTS_PREVIEW_SUCCESS:
      if (state.previewData.requestId === action.data.requestId) {
        return {
          ...state,
          preview: action.data.data
        };
      } else {
        return {
          ...state,
        };
      }
    case REPORTS_PREVIEW_SET_CONTENT:
      return {
        ...state,
        previewData: {
          ...state.previewData,
          content: action.data,
        }
      };
    case REPORTS_PREVIEW_SET_CLINIC:
      return {
        ...state,
        previewData: {
          ...state.previewData,
          clinic: action.data,
        }
      };
    case REPORTS_PREVIEW_SET_MODALITY:
      return {
        ...state,
        previewData: {
          ...state.previewData,
          modality: action.data,
        }
      };
    case REPORTS_PREVIEW_SET_EXAM:
      return {
        ...state,
        previewData: {
          ...state.previewData,
          clinic: action.data ? action.data.clinic : null,
          modality: action.data ? action.data.modality : "",
          exam: action.data,
        }
      };
    default:
      return state;
  }
};

export default reports;
