import React, { Fragment, useState } from 'react';
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import DialogActions from "@mui/material/DialogActions";
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import TextField from "@mui/material/TextField";

import * as examsHttp from '../http/exams';
import * as utils from '../utils';

export default function ExamAnnexDialog({
  open,
  annexAdded,
  handleClose,
  exam,
  allowAdd
}) {

  const [file, setFile] = useState(null);

  const handleCloseInner = () => {
    setFile(null);
    handleClose();
  }

  const handleDownload = async (annex) => {
    return await utils.downloadUrlBlob(annex.link,
	                     'application/pdf',
	                     annex.filename ? annex.filename : "anexo.pdf");
  }

  const uploadAnnex = async () => {
    var res = await examsHttp.uploadExamAnnex(exam.id, file);
    if (res instanceof Error) {
      const message = utils.getErrorMessageOrDefaultMessage(res)
      alert(message);
    } else {
      setFile(null);
      annexAdded();
      handleClose();
    }
  }

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">Anexos do exame: {exam ? exam.patient_name : ""}</DialogTitle>
      <DialogContent>
        <List component="nav" aria-label="secondary mailbox folders">
          {(exam && exam.annexes.length > 0 )
            ?
            exam.annexes.map(a => (
            <ListItem button onClick={() => handleDownload(a)} key={a.uuid}>
              <ListItemText primary={a.filename !== "" ? a.filename : "Arquivo sem nome"} />
            </ListItem>
            ))
            :
            <ListItem>
              <div>Sem anexos</div>
            </ListItem>
          }
        </List>
      	{(allowAdd && exam && exam.status === 'aguardando'
          ?
        	<Fragment>
        	  <TextField
                    autoFocus
                    margin="dense"
                    id="name"
                    label="Novo anexo"
                    type="file"
                    fullWidth
        	    onChange={(event) => setFile(event.target.files[0])}
                  />
                <DialogActions>
                  <Button onClick={handleCloseInner} color="primary">
                    Cancelar
                  </Button>
                  <Button onClick={uploadAnnex} color="primary">
                    Anexar
                  </Button>
                </DialogActions>
        	</Fragment>
          :
          <div>
          Não é possível anexar novos arquivos à exames digitados ou liberados.
          </div>
        )}
      </DialogContent>
    </Dialog>
  );
}
