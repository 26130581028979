import React from 'react';
import withStyles from '@mui/styles/withStyles';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import DatePicker from '@mui/lab/DatePicker';

const styles = theme => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
    verticalAlign: 'baseline',
  },
});

function PeriodSearchForm(props) {
  const { classes,
          fromDate,
          toDate,
          setFromDate,
          setToDate,
        } = props;

  return (
    <React.Fragment>
      <FormControl className={classes.formControl}>
        <DatePicker
          disableToolbar
          variant="inline"
          format="dd-MM-yyyy"
          id="studydatetimefrom-selector"
          label="Data início"
          inputstyle={{ textAlign: "center" }}
          value={fromDate}
          onChange={(date, value) => setFromDate(date, value)}
          KeyboardButtonProps={{
            "aria-label": "change date",
          }}
          renderInput={(props) => <TextField {...props} />}
        />
      </FormControl>
      <FormControl className={classes.formControl}>
        <DatePicker
          disableToolbar
          variant="inline"
          format="dd-MM-yyyy"
          id="studydatetimeto-selector"
          label="Data fim"
          value={toDate}
          onChange={(date, value) => setToDate(date, value)}
          KeyboardButtonProps={{
            "aria-label": "change date",
          }}
          renderInput={(props) => <TextField {...props} />}
        />
      </FormControl>
    </React.Fragment>
  );
};

export default withStyles(styles)(PeriodSearchForm);
