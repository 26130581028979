import React, { useState, useEffect, useCallback } from 'react';
import { connect } from 'react-redux';
import withStyles from '@mui/styles/withStyles';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import ClinicFinanceSummary from '../components/ClinicFinanceSummary';
import ClinicFinanceEntriesList from '../components/ClinicFinanceEntriesList';
import PeriodSearchForm from '../components/PeriodSearchForm';
import * as billingHttp from '../http/billing';
import * as clinicHttp from '../http/clinics';
import * as authHttp from '../http/auth';
import * as utils from '../utils';
import Loading from '../components/Loading';

const styles = theme => ({
  root: {
    width: '100%',
  },
  title: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(1),
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
    verticalAlign: 'baseline',
  },
});

const DEFAULT_MODE = "sintético"

const mapStateToProps = (state) => {
  return {
    summary: state.billing.clinic.summary,
    myEntries: state.billing.clinic.entries,
    clinics: state.clinics.list,
    clinics_map: state.clinics.clinics_map,
    errorMessage: state.billing.clinic.requestError != null ? state.billing.clinic.requestError.response.data.detail : null,
    loading: state.billing.clinic.loading,
    currentUser: state.auth.currentUser,
  };
};

function ClinicFinances(props) {
  const {clinics_map} = props;
  const { classes,
          clinics,
          loading,
          summary,
          errorMessage,
          currentUser,
          myEntries } = props;

  const [fromDate, setFromDate] = useState();
  const [toDate, setToDate] = useState();
  const [clinicFilter, setClinicFilter] = useState();
  const [order, setOrder] = useState("exam-date");
  const [mode, setMode] = useState(DEFAULT_MODE);

  const orders = [
    {label: "Data do laudo", value: "report-date"},
    {label: "Data do exame", value: "exam-date"},
    {label: "Nome do Paciente", value: "patient-name"}
  ];

  useEffect(() => {
    billingHttp.clearCurrent();
    clinicHttp.list();
    authHttp.getCurrentUser();
  }, []);

  const doQuery = useCallback(() => {
    let formattedFromDate = utils.formatDateToString(fromDate);
    let formattedToDate = utils.formatDateToString(toDate);
    billingHttp.getClinicFinance(formattedFromDate, formattedToDate, order, clinicFilter);
  }, [fromDate, toDate, order, clinicFilter]);

  const setFromDateCallback = useCallback((date, value) => {
    setFromDate(date);
  }, []);

  const setToDateCallback = useCallback((date, value) => {
    setToDate(date);
  }, []);

  const handleClinicChange = useCallback(async (event) => {
    setClinicFilter(event.target.value);
  }, []);

  var urgCategory = null;
  if (Object.keys(clinics_map).length === 0) {
    return <Loading />;
  } else {
    for (var cId in Object.values(clinics_map)[0].profile.exam_categories) {
      if (utils.caseInsensitiveEquals(Object.values(clinics_map)[0].profile.exam_categories[cId].name, 'URGÊNCIA')) {
        urgCategory = Number(Object.values(clinics_map)[0].profile.exam_categories[cId].id);
        break;
      }
    }
  }

  const errorComponent = errorMessage != null ?
      <Typography variant="subtitle1" color="error">{errorMessage}</Typography>
      : null

  const loadingComponent = loading ?
      <Typography variant="subtitle1" color="primary">Carregando</Typography>
      : null

  return <div className={classes.root}>
    <form>
      <PeriodSearchForm fromDate={fromDate} toDate={toDate} setFromDate={setFromDateCallback} setToDate={setToDateCallback} />

      <FormControl className={classes.formControl}>
        <InputLabel htmlFor="clinic-selector">Clínica</InputLabel>
        <Select
          value={clinicFilter || ''}
          inputProps={{
            name: 'clinics',
            id: 'clinics-selector'
          }}
          label="Clínica"
          onChange={handleClinicChange}
          >
          <MenuItem><em>Todas</em></MenuItem>
          {clinics && clinics.map(clinic => (
            <MenuItem key={clinic.id} value={clinic.id}>{clinic.name}</MenuItem>
            ))}
        </Select>
      </FormControl>
      <FormControl className={classes.formControl}>
        <InputLabel htmlFor="order-selector">Ordenar por</InputLabel>
        <Select
          value={order}
          inputProps={{
            name: "order",
            id: "order-selector",
          }}
          label="Ordernar por"
          onChange={(order) => setOrder(order.target.value)}
        >
          <MenuItem value="">
            <em>...</em>
          </MenuItem>
          {orders.map((order) => (
            <MenuItem key={order.value} value={order.value}>
              {order.label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <FormControl className={classes.formControl}>
        <InputLabel htmlFor="mode-selector">Modo</InputLabel>
        <Select
          value={mode}
          inputProps={{
            name: "mode",
            id: "mode-selector",
          }}
          label="Modo"
          onChange={(mode) => setMode(mode.target.value)}
        >
          <MenuItem value="analítico">
            Analítico
          </MenuItem>
          <MenuItem value="sintético">
            Sintético
          </MenuItem>
        </Select>
      </FormControl>
      <Button variant="contained" color="primary" onClick={doQuery}>
        Carregar
      </Button>
      <FormControl className={classes.formControl}>
        { loadingComponent }
      </FormControl>
    </form>
    { errorComponent }
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography className={classes.title} style={{paddingTop: "0px"}} variant="h4">Resumo</Typography>
        <ClinicFinanceSummary summary={ summary.perModality } totals={ summary } urgCategory={ urgCategory } valueField={"value"} />
      </Grid>
    </Grid>
    {mode === "analítico" ?
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography className={classes.title} variant="h4">Laudos</Typography>
        <ClinicFinanceEntriesList entries={ myEntries } clinics={ clinics } urgCategory={ urgCategory } valueField={"value"} user={currentUser}/>
      </Grid>
    </Grid>
    : null
    }
    </div>
};

export default withStyles(styles)(connect(mapStateToProps)(ClinicFinances));