import React, { Component } from 'react';
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { connect } from 'react-redux'
import { adaptV4Theme } from '@mui/material/styles';

import withStyles from '@mui/styles/withStyles';

import { createTheme, ThemeProvider, StyledEngineProvider } from '@mui/material/styles';

import CookieConsent from "react-cookie-consent";

import './App.css';

import CssBaseline from '@mui/material/CssBaseline';
import Dashboard from './pages/Dashboard';
import Login from './pages/Login';
import PrivacyPolicy from './pages/PrivacyPolicy';
import ReportTokenAccess from './pages/ReportTokenAccess';
import ResetPassword from './pages/ResetPassword';
import LoginTwoFactorToken from './pages/LoginTwoFactorToken';
import TechSupport from './pages/TechSupport';
import Contact from './pages/Contact';
import Privacy from './pages/Privacy';

const styles = theme => ({
  cookieConsent: {
    background: "#00FF00",
  }
});

const theme = createTheme(adaptV4Theme({
  typography: {
    fontSize: 12,
  },
}));

const mapStateToProps = (state, ownProps) => {
  const { auth } = state;
  const { isAuthenticated, errorMessage, hasPrivacyPolicy, twoFactorSetupStatus, isVerified } = auth;

  return {
    isAuthenticated,
    errorMessage,
    hasPrivacyPolicy,
    twoFactorSetupStatus,
    isVerified,
  };
};

class App extends Component {
  render() {
    const { isAuthenticated, hasPrivacyPolicy, twoFactorSetupStatus, isVerified } = this.props;

    return (
      <React.Fragment>
        <CssBaseline />
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
          <Router>
            <Switch>
              <Route path="/exams/report/token/access" component={ReportTokenAccess} />
              <Route path="/accounts/password_reset/" component={ResetPassword} />
              <Route path="/techsupport/" component={TechSupport} />
              <Route path="/contact/" component={Contact} />
              <Route path="/privacy/" component={Privacy} />
              {
                isAuthenticated
                  ?
                    twoFactorSetupStatus === 'activated'
                      ?
                        isVerified
                          ?
                            hasPrivacyPolicy
                              ?
                                <Dashboard />
                              :
                                <PrivacyPolicy />
                          :
                            <LoginTwoFactorToken />
                      :
                        hasPrivacyPolicy
                          ?
                            <Dashboard />
                          :
                            <PrivacyPolicy />
                  :
                    <Route path="/" component={Login} />
              }
            </Switch>
          </Router>
      <CookieConsent
        style={{
          background: "#58b0c0"
        }}
        buttonStyle={{
          background: "#eeeeee"
        }}
        buttonText="Concordo"
        expires={5}
      >
          Usamos cookies para personalizar e melhorar sua experiência no nosso site. Ao usar o nosso site, você concorda com o uso de cookies. Acesse nossa Política de Privacidade para saber mais. <a target="_blank" rel="noopener noreferrer" style={{color: '#FFF', ":hover": {color: '#00F'}}} href="https://www.cookiesandyou.com/">Clique aqui para aprender mais sobre cookies.</a>
      </CookieConsent>
        </ThemeProvider>
      </StyledEngineProvider>
    </React.Fragment>
    );
  }
}

export default withStyles(styles)(connect(mapStateToProps)(App));
