import React from 'react';
import withStyles from '@mui/styles/withStyles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import * as utils from '../utils';

const styles = theme => ({
  root: {
    width: '100%',
  },
  table: {
    minWidth: 250,
  },
  tableHeader: {
    fontWeight: "bold",
  },
  firstRow: {
    backgroundColor: "#c0c0c0",
  }
});

function GeneralFinanceSummary(props) {
  const { classes, summary, valueField } = props;

  const renderRows = () => {
    const rows = [
      { label: "Exames", count: summary.count, value: summary[valueField] },
      { label: "Médicos", value: summary.medic_value },
      { label: "Digitação", value: summary.typer_value },
      { label: "IMAGI", value: summary.imagi_value },
      { label: "Impostos", value: summary.taxTotal },
      { label: "Resultado (desconsiderando impostos)", value: summary.finalValue }
    ];

    return rows.map((row, index) => (
      <TableRow key={index}>
        <TableCell>{row.label}</TableCell>
        <TableCell>{row.count || ""}</TableCell>
        <TableCell>{utils.formatBillValue(row.value)}</TableCell>
      </TableRow>
    ));
  };

  return (
    <TableContainer component={Paper}>
      <Table className={classes.table} size="small" aria-label="exames">
        <TableHead>
          <TableRow className={classes.firstRow}>
            <TableCell className={classes.tableHeader}></TableCell>
            <TableCell align="left" className={classes.tableHeader}>Quantidade</TableCell>
            <TableCell align="left" className={classes.tableHeader}>Total</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {renderRows()}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export default withStyles(styles)(GeneralFinanceSummary);